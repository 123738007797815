@charset "UTF-8";
/****** UTILS ******/
/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/

/* Target the scrollbar */

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out; /* Smooth fade-in effect */
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out; /* Smooth fade-out effect */
}

.modal-slide-in {
    animation: slideIn 0.8s ease-out forwards;
}

.modal-slide-out {
    animation: slideOut 0.8s ease-in forwards;
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.ant-table-content {
    overflow-x: scroll;
}

.btn-primary span {
    padding: 0 5px;
}
.w-20 {
    width: 20%;
}
.css-1nmdiq5-menu {
    z-index: 10 !important ;
    position: relative;
}
.form-control__menu-list {
    z-index: 90;
}
.react-select__menu-list {
    position: absolute;
    z-index: 9999;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.valid {
    color: green;
    position: relative;
    transition-duration: 0.3s;
}
.border-bottom-design {
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.valid::before {
    content: "\f058"; /* Example Unicode for the check-circle, replace with the actual code if available */
    font-family: "fontawesome"; /* Ensure Feather font is loaded */
    margin-right: 8px;
    position: absolute;
    top: 0;
    left: -18px;
}
.pwd-instructions li {
    margin-bottom: 5px;
}
.password-field {
    border: 1px solid #e0e0e0;
    color: #3f4254;
    height: 40px;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: border-color 0.3s ease;
    position: relative;
}
.password-field:focus {
    border: 1px solid #02002c;
}

.password-field:focus-within {
    border-color: #02002c; /* Change border color to red when input is focused */
}

.password-field input {
    flex-grow: 1;
    border: none;
    outline: none; /* Remove default input outline */
    padding: 0.5rem;
}

/* f058 */

.password-status {
    -webkit-appearance: none; /* Hide default appearance */
    appearance: none; /* Hide default appearance */
    width: 100%; /* Full width */
    height: 8px; /* Track height */
    background-color: #ddd; /* Default track color */
    border-radius: 5px; /* Rounded track */
    outline: none; /* Remove outline */
    opacity: 1; /* Fully opaque */
}

.password-status::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove thumb on WebKit browsers */
    appearance: none; /* Remove thumb */
}

.password-status::-moz-range-thumb {
    appearance: none; /* Remove thumb on Firefox */
}

.password-status::-ms-thumb {
    appearance: none; /* Remove thumb on Internet Explorer */
}

.css-equal-content,
.css-equal-heights {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}
.table_action_button {
    background: #f3f3f3;
    color: #3f4254;
    width: 28px;
    height: 28px;
    padding: 0;
    border: none;
    border-radius: 50px;
    margin-right: 5px;
}

.invoice-template-tab.invoices-main-tabs .invoices-tabs ul,
.invoices-main-tabs .invoices-tabs ul,
.date-list ul,
.invoices-items-main-tabs ul,
.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.rs-b {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.css-equal-content {
    color: red;
    float: left;
}

.invoice-template-tab.invoices-main-tabs .btn,
.sidebar-settings .sidebar-top .btn-closed,
.sidebar-layout .sidebar-content .sidebar-top .btn-closed,
.sticky-sidebar > li > a,
.sticky-sidebar,
.invoices-main-tabs .btn,
.dash-widget-header .dash-widget-icon,
.change-photo-btn,
.blog-catagories,
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .kanban-ticket
    .kanban-head
    span,
.invoices-page-header .invoices-breadcrumb-item i,
.invoices-page-header .invoices-breadcrumb-item a,
.invoice-load-btn .btn,
.invoices-settings-btn .btn,
[data-sidebar="dark"] body .two-col-bar .sidebar .sidebar-left .nav-link,
.avatar-edit,
.sidebar .nav-link,
div#global-loader,
.user-img,
.chat-attachment-item,
.avatar .avatar-title {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.invoice-four .invoice-wrapper .inv-content .bank-details,
.invoice-four .invoice-wrapper .inv-content .invoice-table-footer,
.invoice-one .totalamount-footer,
.invoice-one .invoice-table-footer,
.invoice-two .inv-content .bank-details,
.invoice-two .inv-content .totalamount-footer,
.invoice-two .inv-content .invoice-table-footer,
.invoice-two .invoice-header,
.invoice-two .inv-details,
.invoice-template-tab.invoices-main-tabs .btn,
.index-five .inv-content .totalamount-footer,
.index-five .company-info,
.index-five .invoiceten-header .invoice-header,
.index-four .inv-content .totalamount-footer,
.index-four .company-info,
.index-three .inv-content .totalamount-footer,
.index-three .inv-content .invoice-header .inv-header-right,
.index-three .company-info,
.index-three .invoice-header-top,
.index-two .inv-content .totalamount-footer,
.index-two .company-info,
.inv-content .bank-details,
.inv-content .invoice-table-footer,
.invoice-header,
.inv-details,
.train-ticket .inv-content .totalamount-footer,
.student-billing .inv-content .totalamount-footer,
.flight-booking .inv-content .totalamount-footer,
.domain-hosting .inv-content .totalamount-footer,
.car-booking .inv-content .totalamount-footer,
.bus-ticket .inv-content .totalamount-footer,
.setting,
.sidebar-settings .sidebar-top .btn-closed,
.sidebar-layout .sidebar-content .layout,
.sidebar-layout .sidebar-content .sidebar-top .btn-closed,
.sticky-sidebar > li > a,
.invoices-main-tabs .btn,
.dash-widget-header .dash-widget-icon,
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .chat-msg-info,
.chat-search .input-group .input-group-prefix,
.change-photo-btn,
.blog-catagories,
.blog-views,
.post-author a,
.grid-blog .entry-meta,
.custom_radio,
.custom_radio_one,
.invoice-total-box .service-amount,
.payment-details,
.invoices-page-header .invoices-breadcrumb-item i,
.invoices-page-header .invoices-breadcrumb-item a,
.invoice-load-btn .btn,
.card-middle-avatar,
.invoices-settings-btn .btn,
.invoices-settings-btn,
.multipleSelection .selectbox,
.sortby .selectboxes,
[data-sidebar="dark"] body .two-col-bar .sidebar .sidebar-left .nav-link,
[data-layout="horizontal"] body nav.greedy,
.avatar-edit,
.table tbody td a,
.sidebar .sub-menu a,
.sidebar .nav-link,
div#global-loader,
.user-img,
.user-menu .dropdown-menu .dropdown-item,
.chat-attachment-item,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prefix,
.chat-window .chat-cont-left .chat-header,
.avatar .avatar-title {
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.invoice-four .invoice-wrapper .inv-content .invoice-header,
.invoice-three .invoice-wrapper .inv-content .invoice-header {
    align-items: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
}

.invoice-two .inv-content .two-invoice-details .company-booking-address,
.invoice-two .invoice-header,
.invoice-two .inv-details,
.index-five .company-details .company-content,
.index-five .invoiceten-header .invoice-header,
.index-four .company-details .company-content,
.index-three .invoice-footer,
.index-three .inv-content .bank-details .account-info .account-details-infotop,
.index-three .inv-content .invoice-header .inv-header-right,
.index-three .invoice-header-top,
.invoice-header,
.inv-details,
.train-ticket .inv-content .flight-invoice-details .invoice-infomation,
.train-ticket .inv-content .flight-invoice-details .bus-booking-address,
.student-billing .inv-content .flight-invoice-details .invoice-infomation,
.money-exchange .inv-content .sendto-details .sendto-left div,
.money-exchange .inv-content .sendto-details .sendto-right div,
.money-exchange .inv-content .sendto-details,
.money-exchange .inv-content .exchange-details .exchange-info div,
.money-exchange .inv-content .exchange-details .exchange-info,
.hotel-booking .inv-content .service-details table tr td div,
.flight-booking .inv-content .flight-invoice-details .invoice-infomation,
.domain-hosting .inv-content .service-details table tr td div,
.domain-hosting .inv-content .flight-invoice-details .invoice-infomation,
.car-booking .inv-content .flight-invoice-details .invoice-infomation,
.car-booking .inv-content .flight-invoice-details .bus-booking-address,
.bus-ticket .inv-content .flight-invoice-details .invoice-infomation,
.bus-ticket .inv-content .flight-invoice-details .bus-booking-address,
.mail-provider,
.payment-toggle,
.chat-cont-left .chat-scroll .chat-block .media-body,
.edit-options,
.grid-blog .entry-meta,
.invoice-total-box .service-amount,
.payment-details,
.multipleSelection .selectbox,
.sortby .selectboxes,
.app-listing,
.sidebar .sub-menu a,
.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.chat-block .media-body,
.chat-window .chat-cont-left .chat-header {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.packages .package-header .btn-action-icon,
.template-invoice-card .blog .blog-image a.preview-invoice,
.invoice-star,
.list-btn .btn-filters,
.price-table-main .plan-header .plan-widget-icon,
.price-table-main .plan-selected,
.table tbody td h2.table-avatar a.product-list-item-bg,
.ticket-information .support-details .btn-action-icon,
.table .dropdown-item,
.table tbody td a.btn-action-icon,
.sidebar .sidebar-header .logo,
.sidebar .sidebar-header .dark-logo,
.sidebar .sidebar-header,
.settings-icon span,
.align-center {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.invoice-four .invoice-wrapper .inv-content .bank-details,
.invoice-four .invoice-wrapper .inv-content .invoice-address-four,
.invoice-two .inv-content .bank-details,
.invoice-two .inv-content .invoice-address-details,
.inv-content .bank-details,
.inv-content .invoice-address {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.invoice-template-tab.invoices-main-tabs .invoices-tabs ul,
.invoices-main-tabs .invoices-tabs ul,
.date-list ul,
.invoices-items-main-tabs ul,
.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.comments .describe-btn span,
.comments .describe-btn .badge,
.ticket-history .card-inform .ticket-info .ticket-upload,
.avatar-group {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.filter-btn.tax,
.from-to-date,
.invoice-four .invoice-wrapper .inv-content .bank-details,
.invoice-four .invoice-wrapper .inv-content .invoice-table-footer,
.invoice-four .invoice-wrapper .inv-content .invoice-address-four,
.invoice-one .invoice-table-footer,
.notifications .user-list-item,
.invoice-two .inv-content .bank-details,
.invoice-two .inv-content .invoice-table-footer,
.invoice-two .inv-content .invoice-address-details,
.invoice-two .invoice-header,
.invoice-two .inv-details,
.invoice-template-tab.invoices-main-tabs .btn,
.pay-online-list li,
.index-five .company-info,
.index-five .company-details .company-content,
.index-five .invoiceten-header .invoice-header,
.index-four .company-info,
.index-four .company-details .company-content,
.index-three .invoice-footer,
.index-three .inv-content .bank-details .account-info .account-details-infotop,
.index-three .inv-content .invoice-header .inv-header-right,
.index-three .company-info,
.index-three .invoice-header-bottom,
.index-three .invoice-header-top,
.index-two .company-info,
.inv-content .bank-details,
.inv-content .invoice-table-footer,
.inv-content .invoice-address,
.invoice-header,
.inv-details,
.hotel-booking .inv-content .service-details table tr td div,
.domain-hosting .inv-content .service-details table tr td div,
.setting,
.sidebar-settings .sidebar-top .btn-closed,
.sidebar-layout .sidebar-content .layout,
.sidebar-layout .sidebar-content .sidebar-image,
.sidebar-layout .sidebar-content .sidebar-top .btn-closed,
.sticky-sidebar > li > a,
.sticky-sidebar,
.invoices-main-tabs .btn,
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .chat-msg-info,
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body,
.chat-cont-left .chat-scroll .chat-block .media-body,
.chat-search .input-group .input-group-prefix,
.change-photo-btn,
.edit-options,
.blog-catagories,
.blog-views,
.post-author a,
.grid-blog .entry-meta,
.ticket-sidebar .dropdown-menu .dropdown-item,
.invoice-total-box .service-amount,
.payment-details,
.invoices-page-header .invoices-breadcrumb-item i,
.invoices-page-header .invoices-breadcrumb-item a,
.invoices-settings-btn .btn,
.invoices-settings-btn,
.multipleSelection .selectbox,
.sortby .selectboxes,
.app-listing,
[data-sidebar="dark"] body .two-col-bar .sidebar .sidebar-left .nav-link,
[data-layout="horizontal"] body nav.greedy,
.sidebar.sidebar-menu-five ul,
.sidebar-menu-five ul,
.table tbody td a,
.sidebar .nav-items .nav-link,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu ul li a,
.sidebar .sidebar-menu .menu-title,
div#global-loader,
.user-img,
.user-menu .dropdown-menu .dropdown-item,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.chat-block .media-body,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prefix,
.chat-window .chat-cont-left .chat-header,
.chat-window,
.avatar .avatar-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.company-booking-address.company-five-address,
.invoice-address-details.invoice-five-group,
.inovices-card .inovices-widget-header,
.multipleSelection .selectbox,
.multipleSelection .selectboxes,
.app-listing,
.error-page,
.toggle-sidebar .sidebar-header,
.form-group-add .booking-option,
.content-invoice-header,
.content-page-header,
.inovices-widget-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.invoice-five .inv-content .bank-details,
.invoice-five .invoice-table-footer,
.invoice-five .inv-content .invoice-five-details,
.notifications .user-list-item .users-list-body .chats-delete i,
.notifications .user-list-item .users-list-body .chats-delete,
.notifications .user-list-item .users-list-body,
.preview-boxs,
.template-invoice-card .blog .invoice-content-title,
.select-status select,
.input-block.check-mark,
.ticket-sidebar .action-sets,
.billing-btn,
.bank-details .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.invoice-five .inv-content .bank-details .bank-qr-detail,
.invoice-five .inv-content .invoice-five-details .invoice-date,
.inovices-card .inovice-trending,
.invoices-settings-btn,
.list-btn .filter-list li ul li,
.list-btn .filter-list .short-filter,
.list-btn .filter-list,
.dash-widget-header,
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-ticket-img
    li
    img,
.price-table-main .plan-description ul li,
.price-table-main .plan-header,
.profile-picture .upload-profile,
.profile-picture,
.inovices-widget-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.table .dropdown-item {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
}

.invoice-two .inv-content .two-invoice-details .company-booking-address,
.invoice-two .invoice-header,
.invoice-two .inv-details,
.index-five .company-details .company-content,
.index-five .invoiceten-header .invoice-header,
.index-four .company-details .company-content,
.index-three .invoice-footer,
.index-three .inv-content .bank-details .account-info .account-details-infotop,
.index-three .inv-content .invoice-header .inv-header-right,
.index-three .invoice-header-top,
.invoice-header,
.inv-details,
.train-ticket .inv-content .flight-invoice-details .invoice-infomation,
.train-ticket .inv-content .flight-invoice-details .bus-booking-address,
.student-billing .inv-content .flight-invoice-details .invoice-infomation,
.money-exchange .inv-content .sendto-details .sendto-left div,
.money-exchange .inv-content .sendto-details .sendto-right div,
.money-exchange .inv-content .sendto-details,
.money-exchange .inv-content .exchange-details .exchange-info div,
.money-exchange .inv-content .exchange-details .exchange-info,
.hotel-booking .inv-content .service-details table tr td div,
.flight-booking .inv-content .flight-invoice-details .invoice-infomation,
.domain-hosting .inv-content .service-details table tr td div,
.domain-hosting .inv-content .flight-invoice-details .invoice-infomation,
.car-booking .inv-content .flight-invoice-details .invoice-infomation,
.car-booking .inv-content .flight-invoice-details .bus-booking-address,
.bus-ticket .inv-content .flight-invoice-details .invoice-infomation,
.bus-ticket .inv-content .flight-invoice-details .bus-booking-address,
.mail-provider,
.payment-toggle,
.chat-cont-left .chat-scroll .chat-block .media-body,
.edit-options,
.grid-blog .entry-meta,
.invoice-total-box .service-amount,
.payment-details,
.multipleSelection .selectbox,
.sortby .selectboxes,
.app-listing,
.sidebar .sub-menu a,
.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.chat-block .media-body,
.chat-window .chat-cont-left .chat-header {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

#edit_package form .checkboxes,
#add_newpackage form .checkboxes,
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .chat-msg-info {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.chat-cont-right ul.list-unstyled .chat-block.sent .media-body {
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.chat-cont-right ul.list-unstyled .chat-block.sent .media-body,
.sidebar .nav-items .nav-link {
    -ms-flex-direction: column;
    flex-direction: column;
}

/******* BASE *******/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

body {
    overflow-x: hidden;
}

/* HTML5 display-role reset for older browsers */
* {
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after {
    content: "";
    content: none;
}

q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1 {
    font-weight: 500;
    font-size: 40px;
    font-family: "Inter", sans-serif;
    color: #28084b;
}
@media (max-width: 767px) {
    h1 {
        font-size: 27px;
    }
}

h2 {
    font-weight: 500;
    font-size: 30px;
    color: #28084b;
}
@media (max-width: 767px) {
    h2 {
        font-size: 23px;
    }
}

h3 {
    font-weight: 500;
    font-size: 28px;
    color: #28084b;
}
@media (max-width: 1023px) {
    h3 {
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    h3 {
        font-size: 16px;
    }
}

h4 {
    font-weight: 500;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    color: #28084b;
}
@media (max-width: 1023px) {
    h4 {
        font-size: 18px;
    }
}
@media (max-width: 767px) {
    h4 {
        font-size: 18px;
    }
}

h5 {
    font-weight: 500;
    font-size: 1.25rem;
    color: #28084b;
}
@media (max-width: 992px) {
    h5 {
        font-size: 18px;
        margin: 0 0 0;
    }
}

h6 {
    font-weight: 500;
    font-size: 16px;
    color: #28084b;
}
@media (max-width: 1023px) {
    h6 {
        font-size: 16px;
    }
}

h1.card-title,
h2.card-title,
h3.card-title,
h4.card-title,
h5.card-title,
h6.card-title {
    color: #28084b;
}

.card-title {
    color: #8a8e9c;
}

.card-body {
    color: #8a8e9c;
}

body {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #878a99;
    line-height: 1.5;
    background-color: #f7f8f9;
}

.container {
    width: 100%;
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

img {
    max-width: 100%;
    height: auto;
}

* {
    outline: none;
}

button:focus {
    box-shadow: none !important;
}

a {
    color: #7539ff;
    cursor: pointer;
    text-decoration: none;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
a:hover {
    color: #6b36de;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
a:focus {
    outline: 0;
}

p {
    font-size: 14px;
    font-weight: 400;
}
p:last-child {
    padding: 0 0;
}

strong {
    font-weight: 600;
}

.dropdown.main .btn-white:hover {
    background-color: #7539ff;
    border-color: #7539ff;
    color: #fff;
    box-shadow: inset 0 50px 0 0 #7539ff;
}

/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
.css-equal-heights,
.css-equal-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

.invoice-template-tab.invoices-main-tabs .invoices-tabs ul,
.invoices-main-tabs .invoices-tabs ul,
.date-list ul,
.invoices-items-main-tabs ul,
.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.css-equal-content {
    color: red;
    float: left;
}

.invoice-template-tab.invoices-main-tabs .btn,
.sidebar-settings .sidebar-top .btn-closed,
.sidebar-layout .sidebar-content .sidebar-top .btn-closed,
.sticky-sidebar > li > a,
.sticky-sidebar,
.invoices-main-tabs .btn,
.dash-widget-header .dash-widget-icon,
.change-photo-btn,
.blog-catagories,
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .kanban-ticket
    .kanban-head
    span,
.invoices-page-header .invoices-breadcrumb-item i,
.invoices-page-header .invoices-breadcrumb-item a,
.invoice-load-btn .btn,
.invoices-settings-btn .btn,
[data-sidebar="dark"] body .two-col-bar .sidebar .sidebar-left .nav-link,
.avatar-edit,
.sidebar .nav-link,
div#global-loader,
.user-img,
.chat-attachment-item,
.avatar .avatar-title {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.invoice-four .invoice-wrapper .inv-content .bank-details,
.invoice-four .invoice-wrapper .inv-content .invoice-table-footer,
.invoice-one .totalamount-footer,
.invoice-one .invoice-table-footer,
.invoice-two .inv-content .bank-details,
.invoice-two .inv-content .totalamount-footer,
.invoice-two .inv-content .invoice-table-footer,
.invoice-two .invoice-header,
.invoice-two .inv-details,
.invoice-template-tab.invoices-main-tabs .btn,
.index-five .inv-content .totalamount-footer,
.index-five .company-info,
.index-five .invoiceten-header .invoice-header,
.index-four .inv-content .totalamount-footer,
.index-four .company-info,
.index-three .inv-content .totalamount-footer,
.index-three .inv-content .invoice-header .inv-header-right,
.index-three .company-info,
.index-three .invoice-header-top,
.index-two .inv-content .totalamount-footer,
.index-two .company-info,
.inv-content .bank-details,
.inv-content .invoice-table-footer,
.invoice-header,
.inv-details,
.train-ticket .inv-content .totalamount-footer,
.student-billing .inv-content .totalamount-footer,
.flight-booking .inv-content .totalamount-footer,
.domain-hosting .inv-content .totalamount-footer,
.car-booking .inv-content .totalamount-footer,
.bus-ticket .inv-content .totalamount-footer,
.setting,
.sidebar-settings .sidebar-top .btn-closed,
.sidebar-layout .sidebar-content .layout,
.sidebar-layout .sidebar-content .sidebar-top .btn-closed,
.sticky-sidebar > li > a,
.invoices-main-tabs .btn,
.dash-widget-header .dash-widget-icon,
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .chat-msg-info,
.chat-search .input-group .input-group-prefix,
.change-photo-btn,
.blog-catagories,
.blog-views,
.post-author a,
.grid-blog .entry-meta,
.custom_radio,
.custom_radio_one,
.invoice-total-box .service-amount,
.payment-details,
.invoices-page-header .invoices-breadcrumb-item i,
.invoices-page-header .invoices-breadcrumb-item a,
.invoice-load-btn .btn,
.card-middle-avatar,
.invoices-settings-btn .btn,
.invoices-settings-btn,
.multipleSelection .selectbox,
.sortby .selectboxes,
[data-sidebar="dark"] body .two-col-bar .sidebar .sidebar-left .nav-link,
[data-layout="horizontal"] body nav.greedy,
.avatar-edit,
.table tbody td a,
.sidebar .sub-menu a,
.sidebar .nav-link,
div#global-loader,
.user-img,
.user-menu .dropdown-menu .dropdown-item,
.chat-attachment-item,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prefix,
.chat-window .chat-cont-left .chat-header,
.avatar .avatar-title {
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.invoice-four .invoice-wrapper .inv-content .invoice-header,
.invoice-three .invoice-wrapper .inv-content .invoice-header {
    align-items: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
}

.invoice-two .inv-content .two-invoice-details .company-booking-address,
.invoice-two .invoice-header,
.invoice-two .inv-details,
.index-five .company-details .company-content,
.index-five .invoiceten-header .invoice-header,
.index-four .company-details .company-content,
.index-three .invoice-footer,
.index-three .inv-content .bank-details .account-info .account-details-infotop,
.index-three .inv-content .invoice-header .inv-header-right,
.index-three .invoice-header-top,
.invoice-header,
.inv-details,
.train-ticket .inv-content .flight-invoice-details .invoice-infomation,
.train-ticket .inv-content .flight-invoice-details .bus-booking-address,
.student-billing .inv-content .flight-invoice-details .invoice-infomation,
.money-exchange .inv-content .sendto-details .sendto-left div,
.money-exchange .inv-content .sendto-details .sendto-right div,
.money-exchange .inv-content .sendto-details,
.money-exchange .inv-content .exchange-details .exchange-info div,
.money-exchange .inv-content .exchange-details .exchange-info,
.hotel-booking .inv-content .service-details table tr td div,
.flight-booking .inv-content .flight-invoice-details .invoice-infomation,
.domain-hosting .inv-content .service-details table tr td div,
.domain-hosting .inv-content .flight-invoice-details .invoice-infomation,
.car-booking .inv-content .flight-invoice-details .invoice-infomation,
.car-booking .inv-content .flight-invoice-details .bus-booking-address,
.bus-ticket .inv-content .flight-invoice-details .invoice-infomation,
.bus-ticket .inv-content .flight-invoice-details .bus-booking-address,
.mail-provider,
.payment-toggle,
.chat-cont-left .chat-scroll .chat-block .media-body,
.edit-options,
.grid-blog .entry-meta,
.invoice-total-box .service-amount,
.payment-details,
.multipleSelection .selectbox,
.sortby .selectboxes,
.app-listing,
.sidebar .sub-menu a,
.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.chat-block .media-body,
.chat-window .chat-cont-left .chat-header {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.packages .package-header .btn-action-icon,
.template-invoice-card .blog .blog-image a.preview-invoice,
.invoice-star,
.list-btn .btn-filters,
.price-table-main .plan-header .plan-widget-icon,
.price-table-main .plan-selected,
.table tbody td h2.table-avatar a.product-list-item-bg,
.ticket-information .support-details .btn-action-icon,
.table .dropdown-item,
.table tbody td a.btn-action-icon,
.sidebar .sidebar-header .logo,
.sidebar .sidebar-header .dark-logo,
.sidebar .sidebar-header,
.settings-icon span,
.align-center {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.invoice-four .invoice-wrapper .inv-content .bank-details,
.invoice-four .invoice-wrapper .inv-content .invoice-address-four,
.invoice-two .inv-content .bank-details,
.invoice-two .inv-content .invoice-address-details,
.inv-content .bank-details,
.inv-content .invoice-address {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.invoice-template-tab.invoices-main-tabs .invoices-tabs ul,
.invoices-main-tabs .invoices-tabs ul,
.date-list ul,
.invoices-items-main-tabs ul,
.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.comments .describe-btn span,
.comments .describe-btn .badge,
.ticket-history .card-inform .ticket-info .ticket-upload,
.avatar-group {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.filter-btn.tax,
.from-to-date,
.invoice-four .invoice-wrapper .inv-content .bank-details,
.invoice-four .invoice-wrapper .inv-content .invoice-table-footer,
.invoice-four .invoice-wrapper .inv-content .invoice-address-four,
.invoice-one .invoice-table-footer,
.notifications .user-list-item,
.invoice-two .inv-content .bank-details,
.invoice-two .inv-content .invoice-table-footer,
.invoice-two .inv-content .invoice-address-details,
.invoice-two .invoice-header,
.invoice-two .inv-details,
.invoice-template-tab.invoices-main-tabs .btn,
.pay-online-list li,
.index-five .company-info,
.index-five .company-details .company-content,
.index-five .invoiceten-header .invoice-header,
.index-four .company-info,
.index-four .company-details .company-content,
.index-three .invoice-footer,
.index-three .inv-content .bank-details .account-info .account-details-infotop,
.index-three .inv-content .invoice-header .inv-header-right,
.index-three .company-info,
.index-three .invoice-header-bottom,
.index-three .invoice-header-top,
.index-two .company-info,
.inv-content .bank-details,
.inv-content .invoice-table-footer,
.inv-content .invoice-address,
.invoice-header,
.inv-details,
.hotel-booking .inv-content .service-details table tr td div,
.domain-hosting .inv-content .service-details table tr td div,
.setting,
.sidebar-settings .sidebar-top .btn-closed,
.sidebar-layout .sidebar-content .layout,
.sidebar-layout .sidebar-content .sidebar-image,
.sidebar-layout .sidebar-content .sidebar-top .btn-closed,
.sticky-sidebar > li > a,
.sticky-sidebar,
.invoices-main-tabs .btn,
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .chat-msg-info,
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body,
.chat-cont-left .chat-scroll .chat-block .media-body,
.chat-search .input-group .input-group-prefix,
.change-photo-btn,
.edit-options,
.blog-catagories,
.blog-views,
.post-author a,
.grid-blog .entry-meta,
.ticket-sidebar .dropdown-menu .dropdown-item,
.invoice-total-box .service-amount,
.payment-details,
.invoices-page-header .invoices-breadcrumb-item i,
.invoices-page-header .invoices-breadcrumb-item a,
.invoices-settings-btn .btn,
.invoices-settings-btn,
.multipleSelection .selectbox,
.sortby .selectboxes,
.app-listing,
[data-sidebar="dark"] body .two-col-bar .sidebar .sidebar-left .nav-link,
[data-layout="horizontal"] body nav.greedy,
.sidebar.sidebar-menu-five ul,
.sidebar-menu-five ul,
.table tbody td a,
.sidebar .nav-items .nav-link,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu ul li a,
.sidebar .sidebar-menu .menu-title,
div#global-loader,
.user-img,
.user-menu .dropdown-menu .dropdown-item,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.chat-block .media-body,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prefix,
.chat-window .chat-cont-left .chat-header,
.chat-window,
.avatar .avatar-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.company-booking-address.company-five-address,
.invoice-address-details.invoice-five-group,
.inovices-card .inovices-widget-header,
.multipleSelection .selectbox,
.multipleSelection .selectboxes,
.app-listing,
.error-page,
.toggle-sidebar .sidebar-header,
.form-group-add .booking-option,
.content-invoice-header,
.content-page-header,
.inovices-widget-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.invoice-five .inv-content .bank-details,
.invoice-five .invoice-table-footer,
.invoice-five .inv-content .invoice-five-details,
.notifications .user-list-item .users-list-body .chats-delete i,
.notifications .user-list-item .users-list-body .chats-delete,
.notifications .user-list-item .users-list-body,
.preview-boxs,
.template-invoice-card .blog .invoice-content-title,
.select-status select,
.input-block.check-mark,
.ticket-sidebar .action-sets,
.billing-btn,
.bank-details .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.invoice-five .inv-content .bank-details .bank-qr-detail,
.invoice-five .inv-content .invoice-five-details .invoice-date,
.inovices-card .inovice-trending,
.invoices-settings-btn,
.list-btn .filter-list li ul li,
.list-btn .filter-list .short-filter,
.list-btn .filter-list,
.dash-widget-header,
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-ticket-img
    li
    img,
.price-table-main .plan-description ul li,
.price-table-main .plan-header,
.profile-picture .upload-profile,
.profile-picture,
.inovices-widget-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.table .dropdown-item {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
}

.invoice-two .inv-content .two-invoice-details .company-booking-address,
.invoice-two .invoice-header,
.invoice-two .inv-details,
.index-five .company-details .company-content,
.index-five .invoiceten-header .invoice-header,
.index-four .company-details .company-content,
.index-three .invoice-footer,
.index-three .inv-content .bank-details .account-info .account-details-infotop,
.index-three .inv-content .invoice-header .inv-header-right,
.index-three .invoice-header-top,
.invoice-header,
.inv-details,
.train-ticket .inv-content .flight-invoice-details .invoice-infomation,
.train-ticket .inv-content .flight-invoice-details .bus-booking-address,
.student-billing .inv-content .flight-invoice-details .invoice-infomation,
.money-exchange .inv-content .sendto-details .sendto-left div,
.money-exchange .inv-content .sendto-details .sendto-right div,
.money-exchange .inv-content .sendto-details,
.money-exchange .inv-content .exchange-details .exchange-info div,
.money-exchange .inv-content .exchange-details .exchange-info,
.hotel-booking .inv-content .service-details table tr td div,
.flight-booking .inv-content .flight-invoice-details .invoice-infomation,
.domain-hosting .inv-content .service-details table tr td div,
.domain-hosting .inv-content .flight-invoice-details .invoice-infomation,
.car-booking .inv-content .flight-invoice-details .invoice-infomation,
.car-booking .inv-content .flight-invoice-details .bus-booking-address,
.bus-ticket .inv-content .flight-invoice-details .invoice-infomation,
.bus-ticket .inv-content .flight-invoice-details .bus-booking-address,
.mail-provider,
.payment-toggle,
.chat-cont-left .chat-scroll .chat-block .media-body,
.edit-options,
.grid-blog .entry-meta,
.invoice-total-box .service-amount,
.payment-details,
.multipleSelection .selectbox,
.sortby .selectboxes,
.app-listing,
.sidebar .sub-menu a,
.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.chat-block .media-body,
.chat-window .chat-cont-left .chat-header {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

#edit_package form .checkboxes,
#add_newpackage form .checkboxes,
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .chat-msg-info {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.chat-cont-right ul.list-unstyled .chat-block.sent .media-body {
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.chat-cont-right ul.list-unstyled .chat-block.sent .media-body,
.sidebar .nav-items .nav-link {
    -ms-flex-direction: column;
    flex-direction: column;
}

.col {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    position: relative;
    min-height: 1px;
}
@media (min-width: 320px) {
    .col.col-xs-12 {
        width: 100%;
    }
    .col.col-xs-11 {
        width: 91.66666667%;
    }
    .col.col-xs-10 {
        width: 83.33333333%;
    }
    .col.col-xs-9 {
        width: 75%;
    }
    .col.col-xs-8 {
        width: 66.66666667%;
    }
    .col.col-xs-7 {
        width: 58.33333333%;
    }
    .col.col-xs-6 {
        width: 50%;
    }
    .col.col-xs-5 {
        width: 41.66666667%;
    }
    .col.col-xs-4 {
        width: 33.33333333%;
    }
    .col.col-xs-3 {
        width: 25%;
    }
    .col.col-xs-2 {
        width: 16.66666667%;
    }
    .col.col-xs-1 {
        width: 8.33333333%;
    }
}
@media (min-width: 568px) {
    .col.col-sm-12 {
        width: 100%;
    }
    .col.col-sm-11 {
        width: 91.66666667%;
    }
    .col.col-sm-10 {
        width: 83.33333333%;
    }
    .col.col-sm-9 {
        width: 75%;
    }
    .col.col-sm-8 {
        width: 66.66666667%;
    }
    .col.col-sm-7 {
        width: 58.33333333%;
    }
    .col.col-sm-6 {
        width: 50%;
    }
    .col.col-sm-5 {
        width: 41.66666667%;
    }
    .col.col-sm-4 {
        width: 33.33333333%;
    }
    .col.col-sm-3 {
        width: 25%;
    }
    .col.col-sm-2 {
        width: 16.66666667%;
    }
    .col.col-sm-1 {
        width: 8.33333333%;
    }
}
@media (min-width: 768px) {
    .col.col-md-12 {
        width: 100%;
    }
    .col.col-md-11 {
        width: 91.66666667%;
    }
    .col.col-md-10 {
        width: 83.33333333%;
    }
    .col.col-md-9 {
        width: 75%;
    }
    .col.col-md-8 {
        width: 66.66666667%;
    }
    .col.col-md-7 {
        width: 58.33333333%;
    }
    .col.col-md-6 {
        width: 50%;
    }
    .col.col-md-5 {
        width: 41.66666667%;
    }
    .col.col-md-4 {
        width: 33.33333333%;
    }
    .col.col-md-3 {
        width: 25%;
    }
    .col.col-md-2 {
        width: 16.66666667%;
    }
    .col.col-md-1 {
        width: 8.33333333%;
    }
}
@media (min-width: 1024px) {
    .col.col-lg-12 {
        width: 100%;
    }
    .col.col-lg-11 {
        width: 91.66666667%;
    }
    .col.col-lg-10 {
        width: 83.33333333%;
    }
    .col.col-lg-9 {
        width: 75%;
    }
    .col.col-lg-8 {
        width: 66.66666667%;
    }
    .col.col-lg-7 {
        width: 58.33333333%;
    }
    .col.col-lg-6 {
        width: 50%;
    }
    .col.col-lg-5 {
        width: 41.66666667%;
    }
    .col.col-lg-4 {
        width: 33.33333333%;
    }
    .col.col-lg-3 {
        width: 25%;
    }
    .col.col-lg-2 {
        width: 16.66666667%;
    }
    .col.col-lg-1 {
        width: 8.33333333%;
    }
}

/******* COMPONENTS ******/
.btn {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin: 0;
    z-index: 1;
    text-transform: initial;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    padding: 8px 15px;
}
.btn:hover {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.btn-white {
    background-color: #fff;
    border-color: #e7eaf3;
    color: #7539ff;
    box-shadow: inset 0 0 0 0 #fff;
}
.btn-white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #fff;
    box-shadow: inset 0 50px 0 0 #7539ff;
}

.btn-white-outline {
    background-color: #fff;
    border-color: #e7eaf3;
    color: #3f4254;
}
.btn-white-outline:hover {
    background-color: #fff;
    border-color: #e7eaf3;
    color: #3f4254;
}

.btn-primary {
    background-color: #7539ff;
    border: 1px solid #7539ff;
    box-shadow: inset 0 0 0 0 #fff;
    border-radius: 4px;
}
.btn-primary:hover {
    background-color: #fff;
    border-color: #7539ff;
    color: #7539ff;
    box-shadow: inset 0 50px 0 0 #fff;
}

.btn-gray {
    background-color: #e0e0e0;
    border: 1px solid #e0e0e0;
    box-shadow: inset 0 0 0 0 #fff;
    color: #808080;
    padding: 8px 20px;
    border-radius: 4px;
}
.btn-gray:hover {
    background-color: #fff;
    border-color: #e0e0e0;
    color: #808080;
}

.btn-primary.tax {
    background-color: #e3e3e3;
    border: 1px solid #e3e3e3;
    box-shadow: inset 0 0 0 0 #fff;
    border-radius: 4px;
    color: #808080;
}
.btn-primary.tax:hover,
.btn-primary.tax.active {
    background-color: #7539ff;
    border-color: #7539ff;
    color: #fff;
}

.btn-upload {
    background-color: #7539ff;
    border: 1px solid #7539ff;
    box-shadow: inset 0 0 0 0 #fff;
    color: #fff;
    border-radius: 4px;
    box-shadow: inset 0 50px 0 0 #7539ff;
}
.btn-upload:hover {
    background-color: #fff;
    border-color: #7539ff;
    color: #7539ff;
    box-shadow: inset 0 50px 0 0 #fff;
}

.btn-gray {
    background-color: #efefef;
    border: 1px solid #efefef;
    box-shadow: inset 0 0 0 0 #fff;
}
.btn-gray:hover {
    background-color: #fff;
    border-color: #efefef;
    color: #878787;
    box-shadow: inset 0 50px 0 0 #fff;
}

.btn-gray-outline {
    background-color: #fff;
    border: 1px solid #efefef;
    box-shadow: inset 0 0 0 0 #fff;
}
.btn-gray-outline:hover {
    background-color: #efefef;
    border-color: #fff;
    color: #878787;
    box-shadow: inset 0 50px 0 0 #fff;
}

.btn-outline-primary {
    color: #7539ff;
    border-color: #7539ff;
}
.btn-outline-primary:hover {
    background-color: #7539ff;
    border-color: #7539ff;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
    box-shadow: none !important;
}

.btn:focus {
    box-shadow: none !important;
}
.btn.btn-greys {
    background: #efefef;
    color: #28084b;
    font-size: 14px;
    padding: 8px 16px;
    font-weight: 600;
}

.cancel {
    background-color: #eaddff;
    box-shadow: inset 0 0 0 0 #377dff;
    color: #7539ff;
    border-radius: 6px;
}
.cancel:hover {
    background-color: #7539ff;
    border: 1px solid #eaddff;
    color: #eaddff;
    box-shadow: inset 0 50px 0 0 #7539ff;
}

.btn.btn-primary:active,
.btn.btn-outline-primary:active {
    background: #7539ff;
    border-color: #7539ff;
}

.nav-pills.navtab-bg .nav-link.active {
    background: #7539ff;
}
.nav-pills.flex-column .nav-link.active {
    background: #7539ff;
}

.nav-tabs.nav-bordered .nav-link.active {
    border-bottom-color: #7539ff;
}

.filter-list .dropdown.dropdown-action {
    width: auto;
}

.react-datepicker-wrapper {
    display: block;
}

/****** LAYOUT ******/
.avatar {
    background-color: transparent;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 34px;
    position: relative;
    white-space: nowrap;
    margin: 0 10px 0 0;
    border-radius: 50%;
}
.avatar.avatar-xs {
    width: 24px;
    height: 24px;
}
.avatar > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
}
.avatar .avatar-title {
    width: 100%;
    height: 100%;
    background-color: #2da7ff;
    color: #fff;
}
.avatar.avatar-online::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #28c76f;
}
.avatar.avatar-offline::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #f62d51;
}
.avatar.avatar-away::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #ff9900;
}
.avatar .border {
    border-width: 3px !important;
}
.avatar .rounded {
    border-radius: 6px !important;
}
.avatar .avatar-title {
    font-size: 18px;
}
.avatar .avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
}
.avatar .avatar-xs .border {
    border-width: 2px !important;
}
.avatar .avatar-xs .rounded {
    border-radius: 4px !important;
}
.avatar .avatar-xs .avatar-title {
    font-size: 12px;
}
.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
    border-width: 1px;
}

.avatar-sm {
    width: 27px;
    height: 27px;
    min-width: 27px;
}
.avatar-sm .border {
    border-width: 3px !important;
}
.avatar-sm .rounded {
    border-radius: 4px !important;
}
.avatar-sm .avatar-title {
    font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
    border-width: 2px;
}

.avatar-lg {
    width: 3.75rem;
    height: 3.75rem;
}
.avatar-lg .border {
    border-width: 3px !important;
}
.avatar-lg .rounded {
    border-radius: 8px !important;
}
.avatar-lg .avatar-title {
    font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
    border-width: 3px;
}

.avatar-xl {
    width: 5rem;
    height: 5rem;
}
.avatar-xl .border {
    border-width: 4px !important;
}
.avatar-xl .rounded {
    border-radius: 8px !important;
}
.avatar-xl .avatar-title {
    font-size: 28px;
}
.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
    border-width: 4px;
}

.avatar-xxl {
    width: 5.125rem;
    height: 5.125rem;
}
@media (min-width: 768px) {
    .avatar-xxl {
        width: 8rem;
        height: 8rem;
    }
}
.avatar-xxl .border {
    border-width: 6px !important;
}
@media (min-width: 768px) {
    .avatar-xxl .border {
        border-width: 4px !important;
    }
}
.avatar-xxl .rounded {
    border-radius: 8px !important;
}
@media (min-width: 768px) {
    .avatar-xxl .rounded {
        border-radius: 12px !important;
    }
}
.avatar-xxl .avatar-title {
    font-size: 30px;
}
@media (min-width: 768px) {
    .avatar-xxl .avatar-title {
        font-size: 42px;
    }
}
.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
    border-width: 4px;
}
@media (min-width: 768px) {
    .avatar-xxl.avatar-away::before,
    .avatar-xxl.avatar-offline::before,
    .avatar-xxl.avatar-online::before {
        border-width: 4px;
    }
}

.avatar-group .avatar + .avatar {
    margin-left: -0.75rem;
}
.avatar-group .avatar:hover {
    z-index: 1;
}
.avatar-group .avatar-xs + .avatar-xs {
    margin-left: -0.40625rem;
}
.avatar-group .avatar-sm + .avatar-sm {
    margin-left: -0.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
    margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
    margin-left: -1.28125rem;
}

.avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #fc8543;
    text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #fc8543;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
    background: transparent;
}

.bor-b1 {
    border-bottom: 1px solid #e9ecef;
}

.progress-example .progress {
    margin-bottom: 1.5rem;
}

.progress-xs {
    height: 4px;
}

.progress-sm {
    height: 15px;
}

.progress.progress-sm {
    height: 6px;
}
.progress.progress-md {
    height: 8px;
}
.progress.progress-lg {
    height: 18px;
}

.alert-dismissible .btn-close {
    width: 20px;
    height: 20px;
    top: 3px;
    right: 3px;
    font-size: 8px;
}

.breadcrumb-line .breadcrumb-item:before {
    content: "-";
}
.breadcrumb-line .breadcrumb-item:first-child:before {
    content: "";
}

.breadcrumb-dot .breadcrumb-item:before {
    content: "•";
}
.breadcrumb-dot .breadcrumb-item:first-child:before {
    content: "";
}

.breadcrumb-separatorless .breadcrumb-item:before {
    content: "";
}

.breadcrumb {
    margin: 0;
}

.breadcrumb-colored {
    margin: 0 0 10px;
    padding: 12px 16px;
    border-radius: 4px;
}
.breadcrumb-colored li a {
    color: #fff;
}
.breadcrumb-colored .breadcrumb-item.active {
    color: #fff;
    opacity: 0.8;
}
.breadcrumb-colored .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
}

.twitter-bs-wizard-pager-link {
    display: inline-block;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}

.calendar-events:hover {
    border-color: #eeeeee;
    background-color: #fff;
}

.calendar-events i {
    margin-right: 8px;
}

.calendar {
    float: left;
    margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}

.none-border .modal-footer {
    border-top: none;
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
    font-family: "Roboto", sans-serif;
}

.fc-day {
    background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}

.fc th.fc-widget-header {
    background: #eeeeee;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}

.fc-button {
    background: #f1f1f1;
    border: none;
    color: #797979;
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 16px;
}

.fc-state-hover {
    background: #f3f3f3;
}

.fc-state-highlight {
    background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: #7539ff !important;
    color: #fff !important;
    text-shadow: none !important;
}

.fc-cell-overlay {
    background: #f0f0f0;
}

.fc-unthemed .fc-today {
    background: #fff;
}

.fc-event {
    border-radius: 2px;
    border: none;
    color: #fff !important;
    cursor: move;
    font-size: 13px;
    margin: 1px 7px;
    padding: 5px 5px;
    text-align: center;
}

.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}

.fc-basic-view td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}

.event-form .input-group .form-control {
    height: 40px;
}

.submit-section {
    text-align: center;
    margin-top: 40px;
}

.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

@media (max-width: 320px) {
    .fc-toolbar .fc-right {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        right: 30px;
    }
}

@media (max-width: 320px) {
    .fc-left {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        left: 35px;
    }
}

.float-start .dropdown-action {
    margin-left: 2px;
    float: left;
}

@media (max-width: 768px) {
    .float-start .dropdown-action {
        margin-bottom: 5px;
    }
}
@media (max-width: 575.98px) {
    .float-start .dropdown-action {
        margin-bottom: 5px;
    }
}
@media (max-width: 320px) {
    .float-start .dropdown-action {
        margin-bottom: 5px;
    }
}

.apexcharts-legend.apexcharts-align-center.position-right {
    display: none;
}

.apexcharts-toolbar {
    display: none !important;
}

#sales_charts,
.chart-set {
    overflow: hidden;
}

.apexcharts-canvas {
    width: 100% !important;
}

.chat-window {
    background: #fff;
    border: 1px solid #efefef;
    box-shadow: 0px 2px 4px rgba(190, 190, 190, 0.2);
    border-radius: 5px;
    overflow: hidden;
}
.chat-window .chat-cont-left {
    border-right: 1px solid #e5e5e5;
    flex: 0 0 39%;
    left: 0;
    max-width: 39%;
    position: relative;
    z-index: 4;
}
@media (max-width: 991.98px) {
    .chat-window .chat-cont-left {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
.chat-window .chat-cont-left .chat-header {
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    color: #324148;
    height: 72px;
    padding: 0 15px;
}
.chat-window .chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 600;
    line-height: 0;
    text-transform: capitalize;
}
.chat-window .chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
    display: inline-flex;
}
.chat-window .chat-cont-left .chat-search {
    background-color: #f7f8f9;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    width: 100%;
}
.chat-window .chat-cont-left .chat-search .input-group {
    width: 100%;
}
.chat-window .chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px !important;
    padding-left: 36px;
}
.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: rgba(38, 17, 85, 0.05);
    box-shadow: none;
    z-index: 1;
}
.chat-window .chat-cont-left .chat-search .input-group .input-group-prefix {
    bottom: 0;
    color: #666;
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}
.chat-window .chat-cont-left .chat-users-list {
    background-color: #fff;
}
.chat-window .chat-cont-left .chat-users-list a.chat-block {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.chat-window .chat-cont-left .chat-users-list a.chat-block:last-child {
    border-bottom: 0;
}
.chat-window .chat-cont-left .chat-users-list a.chat-block .media-img-wrap {
    margin-right: 0;
    position: relative;
}
.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-img-wrap
    .avatar {
    height: 45px;
    width: 45px;
}
.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-img-wrap
    .status {
    bottom: 7px;
    height: 10px;
    right: 4px;
    position: absolute;
    width: 10px;
    border: 2px solid #fff;
}
.chat-window .chat-cont-left .chat-users-list a.chat-block .media-body {
    width: 100%;
}
.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-body
    > div:first-child
    .user-name {
    color: #333;
    text-transform: capitalize;
}
.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-body
    > div:first-child
    .user-last-chat {
    color: #8a8a8a;
    font-size: 14px;
    line-height: 24px;
}
.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-body
    > div:last-child {
    text-align: right;
}
.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-body
    > div:last-child
    .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}
.chat-window .chat-cont-left .chat-users-list a.chat-block:hover {
    background-color: #f7f8f9;
}
.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block.read-chat
    .media-body
    > div:last-child
    .last-chat-time {
    color: #8a8a8a;
}
.chat-window .chat-cont-left .chat-users-list a.chat-block.active {
    background-color: #f7f8f9;
}
.chat-window .chat-scroll {
    max-height: calc(100vh - 255px);
    overflow-y: auto;
}
.chat-window .chat-cont-right {
    flex: 0 0 65%;
    max-width: 65%;
}
.chat-window .chat-cont-right .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    height: 72px;
    padding: 0 15px;
}
.chat-window .chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}
@media (max-width: 991.98px) {
    .chat-window .chat-cont-right .chat-header .back-user-list {
        display: block;
    }
}
.chat-window .chat-cont-right .chat-header .chat-block {
    align-items: center;
}
.chat-window .chat-cont-right .chat-header .chat-block .media-img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0;
}
.chat-window .chat-cont-right .chat-header .chat-block .media-img-wrap .avatar {
    height: 50px;
    width: 50px;
}
.chat-window .chat-cont-right .chat-header .chat-block .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}
.chat-window .chat-cont-right .chat-header .chat-block .media-body .user-name {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}
.chat-window
    .chat-cont-right
    .chat-header
    .chat-block
    .media-body
    .user-status {
    color: #666;
    font-size: 14px;
}
.chat-window .chat-cont-right .chat-header .chat-options {
    display: flex;
}
.chat-window .chat-cont-right .chat-header .chat-options > a {
    align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
}
.chat-window .chat-cont-right .chat-header .chat-options span i {
    font-size: 20px;
}
.chat-window .chat-cont-right .chat-body {
    background-color: #f7f8f9;
}
.chat-window .chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}
.chat-window .chat-cont-right .chat-body .chat-block .avatar {
    height: 30px;
    width: 30px;
}
.chat-window .chat-cont-right .chat-body .chat-block .media-body {
    margin-left: 20px;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block
    .media-body
    .msg-box
    > div {
    padding: 0;
    border-radius: 0.25rem;
    display: inline-block;
    position: relative;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block
    .media-body
    .msg-box
    > div
    p {
    color: #333;
    margin-bottom: 0;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block
    .media-body
    .msg-box
    + .msg-box {
    margin-top: 5px;
}
.chat-window .chat-cont-right .chat-body .chat-block.received {
    margin-bottom: 20px;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.received
    .media-body
    .msg-box {
    position: relative;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.received
    .media-body
    .msg-box
    > div {
    background-color: #fff;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.received
    .media-body
    .msg-box
    > div
    .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.received
    .media-body
    .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-right: 6px solid #fff;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: -6px;
    position: absolute;
    right: auto;
    top: 8px;
    width: 0;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.received
    .media-body
    .msg-box-typing {
    line-height: 0;
}
.chat-window .chat-cont-right .chat-body .chat-block:last-child {
    margin-bottom: 0;
}
.chat-window .chat-cont-right .chat-body .chat-block.sent {
    margin-bottom: 20px;
}
.chat-window .chat-cont-right .chat-body .chat-block.sent .media-body {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0;
}
.chat-window .chat-cont-right .chat-body .chat-block.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box
    > div {
    background-color: #e3e3e3;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box
    > div
    p {
    color: #333;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box
    > div
    .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box
    > div
    .chat-msg-info {
    flex-direction: row-reverse;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box
    > div
    .chat-msg-info
    li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box
    > div
    .chat-msg-attachments {
    flex-direction: row-reverse;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box
    > div:hover
    .chat-msg-actions {
    opacity: 1;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-left: 6px solid #e3e3e3;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: -11px;
    top: 8px;
    width: 0;
}
.chat-window
    .chat-cont-right
    .chat-body
    .chat-block.sent
    .media-body
    .msg-box
    .chat-msg-info
    li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}
.chat-window .chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-window .chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}
.chat-window .chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}
.chat-window .chat-cont-right .chat-footer {
    background-color: #fff;
    border-top: 1px solid #e0e3e4;
    padding: 10px 15px;
    position: relative;
}
.chat-window .chat-cont-right .chat-footer .input-group {
    width: 100%;
    align-items: center;
}
.chat-window .chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f7f8f9;
    border: none;
    border-radius: 50px !important;
}
.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f7f8f9;
    border: none;
    box-shadow: none;
}
.chat-window
    .chat-cont-right
    .chat-footer
    .input-group
    .input-group-addon
    .btn.msg-send-btn {
    background-color: #7539ff;
    border-color: #7539ff;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
    padding: 8px 12px;
}

.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-body
    > div:first-child
    .user-name,
.chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (max-width: 992px) {
    .chat-window
        .chat-cont-left
        .chat-users-list
        a.chat-block
        .media-body
        > div:first-child
        .user-name,
    .chat-window
        .chat-cont-left
        .chat-users-list
        a.chat-block
        .media-body
        > div:first-child
        .user-last-chat {
        max-width: 120px;
    }
}

.chat-body {
    margin: 0;
}

.chat-window
    .chat-cont-right
    .chat-footer
    .input-group
    .input-group-prefix
    .btn,
.chat-window
    .chat-cont-right
    .chat-footer
    .input-group
    .input-group-addon
    .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}

.msg-typing {
    width: auto;
    height: 24px;
    padding-top: 8px;
}
.msg-typing span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #a0a0a0;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}
.msg-typing span:nth-of-type(1) {
    animation: 1s blink infinite 0.33333s;
}
.msg-typing span:nth-of-type(2) {
    animation: 1s blink infinite 0.66666s;
}
.msg-typing span:nth-of-type(3) {
    animation: 1s blink infinite 0.99999s;
}

.chat-msg-info {
    align-items: center;
    display: flex;
    clear: both;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}
.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}
.chat-msg-info li:not(:last-child):after {
    position: absolute;
    right: 8px;
    top: 50%;
    content: "";
    height: 4px;
    width: 4px;
    background: #d2dde9;
    border-radius: 50%;
    transform: translate(50%, -50%);
}
.chat-msg-info li a {
    color: #777;
}
.chat-msg-info li a:hover {
    color: #2c80ff;
}

.chat-seen i {
    color: #00d285;
    font-size: 16px;
}

.chat-msg-attachments {
    padding: 4px 0;
    display: flex;
    width: 100%;
    margin: 0 -1px;
}
.chat-msg-attachments > div {
    margin: 0 1px;
}

.chat-attachment {
    position: relative;
    max-width: 130px;
    overflow: hidden;
    border-radius: 0.25rem;
}
.chat-attachment img {
    max-width: 100%;
}
.chat-attachment:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    content: "";
    opacity: 0.4;
    transition: all 0.4s;
}
.chat-attachment:hover:before {
    opacity: 0.6;
}
.chat-attachment:hover .chat-attach-caption {
    opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
    opacity: 1;
}

.chat-attach-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 7px 15px;
    font-size: 13px;
    opacity: 1;
    transition: all 0.4s;
}

.chat-attach-download {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.4s;
    color: #fff;
    width: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
}
.chat-attach-download:hover {
    color: #495463;
    background: #fff;
}

.chat-attachment-list {
    display: flex;
    margin: -5px;
}
.chat-attachment-list li {
    width: 33.33%;
    padding: 5px;
}

.chat-attachment-item {
    border: 5px solid rgba(230, 239, 251, 0.5);
    height: 100%;
    min-height: 60px;
    text-align: center;
    font-size: 30px;
}

.chat-msg-actions {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s;
    z-index: 2;
}
.chat-msg-actions > a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}
.chat-msg-actions > a:hover {
    color: #2c80ff;
}

.btn-file {
    align-items: center;
    display: inline-flex;
    font-size: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}

.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}
.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

.action-icon.dropdown-toggle::after {
    border-top: 0;
    display: none;
}

a.action-icon {
    justify-content: flex-end;
}

.section-header {
    margin-bottom: 1.875rem;
}
.section-header .section-title {
    color: #333;
    font-weight: 600;
}
.section-header .line {
    background-color: #7539ff;
    height: 2px;
    margin: 0;
    width: 60px;
}

.comp-section .card-header {
    border: 1px solid #f0f1f5;
    background-color: #fff;
    padding: 10px;
}
.comp-section .card-header .card-title {
    margin: 0;
}
.comp-section .tab-content {
    padding-top: 20px;
}
.comp-section .bullets {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
}
.comp-section .bullets li {
    margin-bottom: 5px;
}
.comp-section .bullets ul {
    padding-left: 20px;
}
.comp-section ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
}
.comp-section ol li {
    margin-bottom: 5px;
}
.comp-section ol ul {
    padding-left: 20px;
}
.comp-section .list-unstyled li {
    margin-bottom: 5px;
}
.comp-section .list-unstyled ul {
    padding-left: 20px;
}
.comp-section .nav-tabs > li > a {
    margin-right: 0;
    color: #888888;
}
.comp-section .avatar-group .avatar {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
}
.comp-section .avatar-group .avatar + .avatar {
    margin-left: -1.35rem;
}
.comp-section .nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0;
}
.comp-section .nav-tabs.nav-tabs-solid.nav-tabs-rounded a {
    border-radius: 50px;
}
.comp-section .nav-tabs.nav-tabs-solid a {
    padding: 10px 30px;
}
.comp-section .nav-tabs.nav-tabs-solid a.active {
    background-color: #7539ff;
    border-color: #7539ff;
    color: #fff;
}
.comp-section .nav-tabs .nav-tabs-bottom {
    border-bottom: 1px solid #e6e6e6;
}
.comp-section .nav-tabs .nav-tabs-bottom li {
    margin-bottom: -1px;
}
.comp-section .nav-tabs .nav-tabs-bottom li a.active {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: #7539ff;
    background-color: transparent;
    transition: none 0s ease 0s;
}
.comp-section .progress {
    margin-bottom: 1.5rem;
}
.comp-section .progress.progress-lg {
    height: 18px;
}
.comp-section .progress.progress-md {
    height: 8px;
}
.comp-section .progress.progress-sm {
    height: 6px;
}
.comp-section .progress.progress-xs {
    height: 4px;
}

table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
    right: 1em;
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    bottom: 8px;
    font-size: 13px;
    color: #878a99;
    opacity: 1;
    font-weight: 900;
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\f0d8";
    font-family: "Font Awesome 5 Free";
    color: #d1bbff;
    bottom: 15px;
    font-weight: 900;
    font-size: 13px;
    opacity: 1;
}

div.dataTables_wrapper div.dataTables_length select {
    font-size: 14px;
}

.paging_numbers {
    float: right;
}

.pagination li.previous {
    margin-right: 0;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 0;
    white-space: nowrap;
    display: none;
    font-size: 15px;
}

.pagination .previous {
    margin: 0 !important;
}
.pagination .next {
    margin: 0 !important;
}
.pagination li {
    margin: 0 5px !important;
}
@media (max-width: 320px) {
    .pagination li {
        margin: 0 2px !important;
    }
}
.pagination li a {
    border-radius: 5px;
    color: #333;
}
.pagination li a:hover {
    background: #7539ff;
    color: #fff;
}
.pagination li a:focus {
    outline: none;
    box-shadow: none;
}
.pagination li.active a.page-link {
    background: #7539ff;
    border-color: #7539ff;
    border-radius: 5px;
}
@media (max-width: 320px) {
    .pagination .page-link {
        padding: 8px 12px;
        font-size: 14px;
    }
}

.dataTables_length {
    margin-top: 0px !important;
    width: 50%;
    float: left;
}

.dataTables_paginate {
    margin-top: 0 !important;
    width: 50%;
    float: left;
}

.pagination .paginate_button a {
    background: transparent;
    border: 0;
    font-size: 14px;
}
.pagination .paginate_button.previous .page-link {
    margin-right: 20px;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
}
.pagination .paginate_button.previous .page-link:hover {
    background: #7539ff;
}
.pagination .paginate_button.next .page-link {
    margin-left: 20px;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
}
.pagination .paginate_button.next .page-link:hover {
    background: #7539ff;
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0;
    display: inline-block;
    width: auto;
    padding-left: 30px;
    border: 1px solid #e5e5e5;
    color: #1b2559;
    height: 40px;
    border-radius: 8px;
}
div.dataTables_wrapper div.dataTables_filter label {
    margin-bottom: 0;
    position: relative;
}
div.dataTables_wrapper div.dataTables_filter label i {
    position: absolute;
    top: 13px;
    left: 10px;
    font-size: 14px;
}
@media (max-width: 320px) {
    div.dataTables_wrapper div.dataTables_paginate {
        margin: 5px;
        white-space: nowrap;
        text-align: right;
        float: right;
    }
}

.pagination li {
    margin: 0;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.settings-icon {
    z-index: 999;
    position: fixed;
    right: 20px;
    bottom: 20px;
}
@media (max-width: 991.98px) {
    .settings-icon {
        display: none;
    }
}
.settings-icon span {
    width: 45px;
    height: 45px;
    border-radius: 45px;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    background-color: #7539ff;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

.header {
    background: #fff;
    -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
    height: 60px;
    z-index: 999;
    transition: all 0.2s ease-in-out 0s;
}
@media (max-width: 1199.98px) {
    .header {
        left: 0;
    }
}
@media (max-width: 991.98px) {
    .header {
        position: fixed;
        left: 0;
        top: 0;
        height: 60px;
    }
}
.header .header-left {
    float: left;
    height: 60px;
    padding: 0 24px;
    position: relative;
    width: 274px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 991.98px) {
    .header .header-left {
        position: absolute;
        width: 100%;
        height: 60px;
    }
}
.header .header-left .logo,
.header .header-left .dark-logo {
    display: inline-block;
    line-height: 60px;
    display: flex;
}
.header .header-left .logo img,
.header .header-left .dark-logo img {
    max-height: 45px;
    width: auto;
}
.header .header-left .white-logo {
    display: none;
    line-height: 60px;
}
.header .header-left .logo.logo-small {
    display: none;
}
.header .header-left-two {
    float: left;
    height: 60px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 247px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 991.98px) {
    .header .header-left-two {
        position: fixed;
        left: 0;
        top: 0;
        height: 60px;
    }
}
.header .header-left-two .logo-small {
    display: none;
}
@media (max-width: 1199.98px) {
    .header .header-left-two {
        width: 200px;
    }
}
.header .header-left-four {
    float: left;
    height: 60px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 247px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 991.98px) {
    .header .header-left-four {
        position: fixed;
        left: 0;
        top: 0;
        height: 60px;
    }
}
@media (max-width: 991.98px) {
    .header .header-left-four .logo {
        display: none !important;
    }
}
.header .header-left-four .logo-small {
    display: none !important;
}
@media (max-width: 1199.98px) {
    .header .header-left-four {
        width: 160px;
    }
}
.header .header-left-five {
    height: 60px;
}
@media (max-width: 991.98px) {
    .header .header-left-five {
        position: fixed;
        left: 0;
        top: 0;
        height: 30px;
    }
}
@media (max-width: 991.98px) {
    .header .header-left-five .logo {
        display: none !important;
    }
}
@media (max-width: 1199.98px) {
    .header .header-left-five {
        width: 160px;
    }
}
.header .user-menu-four .dropdown-menu .dropdown-item {
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
}
.header .has-arrow .dropdown-toggle:after {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #333;
    border-right: 2px solid #333;
    content: "";
    height: 8px;
    display: inline-block;
    pointer-events: none;
    width: 8px;
    vertical-align: 2px;
    transform-origin: 66% 66%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;
    top: 0;
}
@media (max-width: 991.98px) {
    .header .has-arrow .dropdown-toggle:after {
        display: none;
    }
}

.header-two {
    background: #fff;
    box-shadow: 0px 14px 25px rgba(202, 202, 202, 0.12);
    left: 247px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
    height: 60px;
    z-index: 999;
    transition: all 0.2s ease-in-out 0s;
}
@media (max-width: 991.98px) {
    .header-two {
        left: 0 !important;
    }
}
@media (max-width: 1199.98px) {
    .header-two {
        left: 200px;
    }
}

.header-three {
    left: 0;
}

.header-four {
    left: 0;
    height: 60px;
}

.header-five {
    left: 0;
    border-radius: 0;
    height: 60px;
}
.header-five #toggle_btn {
    height: 60px;
    margin: 0 10px 0 30px;
    padding: 0;
}
.header-five .top-nav-search form {
    width: 250px;
}

.toggle-bars {
    position: relative;
    display: block;
    top: -10px;
}
.toggle-bars .bar-icons {
    position: absolute;
    background: #fff;
    border-radius: 50px;
    height: 2px;
}
.toggle-bars .bar-icons:nth-child(1) {
    width: 18px;
    top: 0;
}
.toggle-bars .bar-icons:nth-child(2) {
    width: 10px;
    top: 5px;
}
.toggle-bars .bar-icons:nth-child(3) {
    width: 18px;
    top: 10px;
}
.toggle-bars .bar-icons:nth-child(4) {
    width: 10px;
    top: 15px;
}

.top-nav-search {
    float: left;
    margin-left: 15px;
}
@media (max-width: 991.98px) {
    .top-nav-search {
        display: none;
    }
}
.top-nav-search form {
    margin-top: 9px;
    position: relative;
    width: 320px;
}
@media (max-width: 1199.98px) {
    .top-nav-search form {
        width: 250px;
    }
}
.top-nav-search form .form-control {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #878a99;
    height: 40px;
    padding: 10px 15px 10px 30px;
    font-size: 14px;
}
.top-nav-search form .btn {
    background-color: transparent;
    border-color: transparent;
    color: #333;
    min-height: 40px;
    padding: 7px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
}
.top-nav-search form .btn:focus {
    border: 0;
}

.top-nav-search-two {
    margin-left: 15px;
}

.top-nav-search-five form {
    margin-top: 9px;
}

.mobile_btn {
    display: none;
    float: left;
}
@media (max-width: 991.98px) {
    .mobile_btn {
        color: #333;
        cursor: pointer;
        display: block;
        font-size: 24px;
        height: 60px;
        left: 0;
        line-height: 60px;
        padding: 0 15px;
        position: absolute;
        text-align: center;
        top: 0;
        z-index: 10;
    }
}

.mobile_btn-two {
    display: none;
    float: left;
}
@media (max-width: 991.98px) {
    .mobile_btn-two {
        color: #333;
        cursor: pointer;
        display: block;
        font-size: 24px;
        height: 60px;
        left: 0;
        line-height: 60px;
        padding: 0 15px;
        position: absolute;
        text-align: center;
        top: 0;
        z-index: 10;
    }
}

.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
    display: flex !important;
    padding-right: 20px;
    border: 0;
}
@media (max-width: 991.98px) {
    .user-menu {
        padding-right: 0;
    }
}
.user-menu.nav > li > a {
    color: #333;
    font-size: 14px;
    padding: 0;
    height: 60px;
    display: -webkit-flex;
    align-items: center;
}
@media (max-width: 991.98px) {
    .user-menu.nav > li > a {
        height: 60px;
    }
}
.user-menu.nav > li > a .badge {
    display: block;
    font-size: 10px;
    font-weight: bold;
    background-color: #7539ff;
    min-height: 7px;
    min-width: 7px;
    color: #fff;
    position: absolute;
    right: 11px;
    top: 10px;
    padding: 2px;
}
.user-menu .user-img img {
    width: 32px;
    border-radius: 50%;
}
.user-menu .dropdown-menu .dropdown-item {
    padding: 10px 15px;
    font-size: 14px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #7539ff;
}
.user-menu .dropdown-menu .dropdown-item img {
    margin-right: 8px;
    width: 20px;
}

.user-menu-four.nav > li > a {
    height: 60px;
}

.user-menu-five.nav > li > a {
    height: 60px;
}

.flag-nav img {
    margin-right: 5px;
}
@media (max-width: 991.98px) {
    .flag-nav {
        display: none;
    }
}

@media (max-width: 991.98px) {
    #toggle_btn {
        display: none;
    }
}
@media (min-width: 992px) {
    .mini-sidebar.expand-menu .header-left {
        flex-direction: row;
        justify-content: space-between;
    }
    .mini-sidebar.expand-menu .sidebar .sidebar-header .logo-small {
        display: none;
        padding: 20px 0 0;
    }
    .mini-sidebar.expand-menu .sidebar .sidebar-header .logo {
        display: block;
    }
    .mini-sidebar.expand-menu .sidebar .sidebar-header .logo.logo-small {
        display: none;
        padding: 20px 0 0;
    }
    .mini-sidebar .header {
        /*left: 80px;*/
    }
    .mini-sidebar .header-two {
        left: 80px;
    }
    .mini-sidebar .header-five {
        left: 0;
    }
    .mini-sidebar .sidebar .sidebar-header .logo-small {
        display: block;
        padding: 0;
    }
    .mini-sidebar .sidebar .sidebar-header .logo {
        display: none;
    }
    .mini-sidebar .sidebar .sidebar-header .logo.logo-small {
        display: block;
        padding: 20px 0 0;
    }
    .mini-sidebar .header .header-left {
        width: 80px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .mini-sidebar .header .header-left .logo {
        display: none;
    }
    .mini-sidebar .header .header-left .logo.logo-small {
        display: block;
        padding: 20px 0 0;
    }
    .mini-sidebar .menu-arrow {
        display: none !important;
    }
    .mini-sidebar .chat-user {
        display: none !important;
    }
    .mini-sidebar .badge {
        display: inline-block !important;
    }
    .mini-sidebar .user-menu .badge {
        display: flex !important;
    }
    .mini-sidebar.expand-menu .menu-arrow {
        display: block !important;
    }
    .mini-sidebar.expand-menu .chat-user {
        display: block !important;
    }
    .mini-sidebar.expand-menu .badge {
        display: inline-block !important;
    }
    .mini-sidebar.expand-menu .logo-small {
        display: none;
    }
    .mini-sidebar.expand-menu .logo {
        display: block;
    }
    .mini-sidebar.expand-menu #toggle_btn {
        opacity: 1;
    }
    .mini-sidebar.expand-menu .header-left {
        padding: 0 20px;
        display: flex;
    }
    .mini-sidebar .noti-dot:before {
        display: none;
    }
    .mini-sidebar .header .header-left .logo img {
        max-height: 30px;
        width: auto;
    }
    .mini-sidebar .header .header-left .dark-logo img {
        max-height: 35px;
        width: auto;
    }
    #toggle_btn {
        align-items: center;
        color: #333;
        display: inline-flex;
        float: left;
        font-size: 30px;
        width: 17px;
        height: 60px;
        margin: 0 10px 0 30px;
        padding: 0;
    }
}
.user-menu.nav li.nav-item {
    margin-right: 10px;
}
.user-menu.nav li.nav-item:last-child {
    margin-right: 0;
}
.user-menu.nav .flag-nav.has-arrow {
    padding-right: 5px;
}
.user-menu.nav .flag-nav span {
    /*             padding-right: 5px; */
}
.user-menu.nav .dropdown-heads > a {
    background-color: #f7f7fa;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    justify-content: center;
    font-size: 20px;
}
.user-menu.nav .dropdown-heads > a:hover {
    background-color: #eeeeee;
}
.user-menu.nav .dropdown-heads > a i {
    color: #3f4254;
}

.user-link .user-img {
    margin-right: 3px;
    position: relative;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-link .user-img img {
    width: 40px;
    height: 40px;
    -o-object-fit: cover;
    object-fit: cover;
}

.animate-circle {
    -webkit-animation: heartbit 1s ease-out;
    animation: heartbit 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border: 5px solid #4c40ed;
    border-radius: 70px;
    position: absolute;
    left: -6px;
    top: -6px;
    width: 52px;
    height: 52px;
    z-index: -1;
}
@media (max-width: 1199.98px) {
    .animate-circle {
        width: 52px;
        height: 52px;
    }
}

@keyframes heartbit {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    25% {
        transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
.user-content {
    padding-left: 8px;
}
.user-content .user-name {
    display: block;
    color: #28084b;
    font-size: 14px;
    font-weight: 600;
}
.user-content .user-details {
    color: #9e9e9e;
    font-size: 12px;
    font-weight: 400;
}

.user-menu-four .user-content {
    padding-left: 8px;
}
.user-menu-four .user-content .user-name {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}

.user-menu {
    height: 60px;
    align-items: center;
}
@media (max-width: 991.98px) {
    .user-menu {
        height: 60px;
    }
}
.user-menu .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.35em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}
.user-menu .feather {
    width: 18px;
    height: 18px;
}

.user-menu-four {
    height: 60px;
    align-items: center;
}
@media (max-width: 991.98px) {
    .user-menu-four {
        height: 60px;
    }
}

.user-menu-five {
    height: 60px;
    align-items: center;
}
@media (max-width: 991.98px) {
    .user-menu-five {
        height: 60px;
    }
}

.user-img {
    margin-right: 8px;
    position: relative;
}

.nav-tabs .nav-link:focus {
    /*background-color: $__cloud__gray;*/
    border-color: transparent;
    color: #333;
    border-radius: 0;
}
.nav-tabs .nav-link:hover {
    /*background-color: $__cloud__gray;*/
    border-color: transparent;
    color: #333;
    border-radius: 0;
}

@media (max-width: 992px) {
    .header .header-left .logo {
        top: 0;
        position: relative;
        line-height: initial;
    }
    .header .header-left .logo-small {
        top: -10px;
        position: relative;
        line-height: initial;
    }
}
.main-logo {
    height: 60px;
    width: 247px;
    background: #fff;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    padding: 0 24px;
}
.main-logo .logo-small {
    display: none;
}

[data-layout="horizontal"] .main-logo {
    background: none;
    box-shadow: none;
}

.mini-sidebar .main-logo {
    width: 80px;
}
.mini-sidebar .main-logo .logo-blue {
    display: none;
}
.mini-sidebar .main-logo .logo-small {
    display: block;
}
.mini-sidebar.expand-menu .main-logo {
    width: 247px;
}
.mini-sidebar.expand-menu .main-logo .logo-blue {
    display: block;
}
.mini-sidebar.expand-menu .main-logo .logo-small {
    display: none;
}

.device-logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    height: inherit;
}

.sidebar-horizantal .submenu ul {
    display: none;
}

.sidebar .sidebar-menu ul.sidebar-vertical li a:hover {
    background: none;
}

.sidebar .sidebar-menu ul.sidebar-vertical .menu-title {
    /*padding: 15px 0;*/
    margin-bottom: 3px;
    padding-top: 20px;
}
.sidebar .sidebar-menu ul.sidebar-vertical .menu-title:first-child {
    padding-top: 5px;
}

.sidebar .sidebar-menu ul.sidebar-vertical {
    padding: 0;
}

.sidebar .sidebar-menu ul.sidebar-vertical > li {
    margin-bottom: 3px;
}

.sidebar .sidebar-menu ul li a.subdrop,
.sidebar .sidebar-menu ul li.submenu > a:hover {
    background: rgba(118, 56, 255, 0.05);
}

.two-col-bar .sidebar .sidebar-left .nav-link {
    padding: 22px 15px;
}

.sidebar.sidebar-three .tab .tablinks {
    position: relative;
}

.sidebar.sidebar-three .tab .tablinks.active:after {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: -25px;
    top: 0;
    background: #7638ff;
    transition: all 0.5s;
}

.sidebar .sidebar-menu .sidebar-horizantal ul li a span {
    padding: 0 5px;
}

.sidebar .sidebar-menu .sidebar-horizantal ul li a.active::after {
    display: none;
}

@media (max-width: 479px) {
    .device-logo {
        transform: none;
        position: static;
        margin-left: 50px;
    }
}
div#global-loader {
    background: #fff;
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100vh;
}
div#global-loader .whirly-loader:not(:required) {
    animation: whirly-loader 1.25s linear infinite;
    background: transparent;
    box-shadow: 0 26px 0 6px #7539ff, 0.90971px 26.05079px 0 5.93333px #7539ff,
        1.82297px 26.06967px 0 5.86667px #7539ff,
        2.73865px 26.05647px 0 5.8px #7539ff,
        3.65561px 26.01104px 0 5.73333px #7539ff,
        4.57274px 25.93327px 0 5.66667px #7539ff,
        5.48887px 25.8231px 0 5.6px #7539ff,
        6.40287px 25.68049px 0 5.53333px #7539ff,
        7.31358px 25.50548px 0 5.46667px #7539ff,
        8.21985px 25.2981px 0 5.4px #7539ff,
        9.12054px 25.05847px 0 5.33333px #7539ff,
        10.01448px 24.78672px 0 5.26667px #7539ff,
        10.90054px 24.48302px 0 5.2px #7539ff,
        11.77757px 24.1476px 0 5.13333px #7539ff,
        12.64443px 23.78072px 0 5.06667px #7539ff,
        13.5px 23.38269px 0 5px #7539ff,
        14.34315px 22.95384px 0 4.93333px #7539ff,
        15.17277px 22.49455px 0 4.86667px #7539ff,
        15.98776px 22.00526px 0 4.8px #7539ff,
        16.78704px 21.48643px 0 4.73333px #7539ff,
        17.56953px 20.93855px 0 4.66667px #7539ff,
        18.33418px 20.36217px 0 4.6px #7539ff,
        19.07995px 19.75787px 0 4.53333px #7539ff,
        19.80582px 19.12626px 0 4.46667px #7539ff,
        20.5108px 18.468px 0 4.4px #7539ff,
        21.1939px 17.78379px 0 4.33333px #7539ff,
        21.85416px 17.07434px 0 4.26667px #7539ff,
        22.49067px 16.34043px 0 4.2px #7539ff,
        23.10251px 15.58284px 0 4.13333px #7539ff,
        23.68881px 14.80241px 0 4.06667px #7539ff, 24.24871px 14px 0 4px #7539ff,
        24.7814px 13.1765px 0 3.93333px #7539ff,
        25.28607px 12.33284px 0 3.86667px #7539ff,
        25.76198px 11.46997px 0 3.8px #7539ff,
        26.2084px 10.58888px 0 3.73333px #7539ff,
        26.62462px 9.69057px 0 3.66667px #7539ff,
        27.01001px 8.77608px 0 3.6px #7539ff,
        27.36392px 7.84648px 0 3.53333px #7539ff,
        27.68577px 6.90284px 0 3.46667px #7539ff,
        27.97502px 5.94627px 0 3.4px #7539ff,
        28.23116px 4.97791px 0 3.33333px #7539ff,
        28.4537px 3.99891px 0 3.26667px #7539ff,
        28.64223px 3.01042px 0 3.2px #7539ff,
        28.79635px 2.01364px 0 3.13333px #7539ff,
        28.91571px 1.00976px 0 3.06667px #7539ff, 29px 0 0 3px #7539ff,
        29.04896px -1.01441px 0 2.93333px #7539ff,
        29.06237px -2.03224px 0 2.86667px #7539ff,
        29.04004px -3.05223px 0 2.8px #7539ff,
        28.98185px -4.07313px 0 2.73333px #7539ff,
        28.88769px -5.09368px 0 2.66667px #7539ff,
        28.75754px -6.1126px 0 2.6px #7539ff,
        28.59138px -7.12863px 0 2.53333px #7539ff,
        28.38926px -8.14049px 0 2.46667px #7539ff,
        28.15127px -9.1469px 0 2.4px #7539ff,
        27.87755px -10.1466px 0 2.33333px #7539ff,
        27.56827px -11.1383px 0 2.26667px #7539ff,
        27.22365px -12.12075px 0 2.2px #7539ff,
        26.84398px -13.09268px 0 2.13333px #7539ff,
        26.42956px -14.05285px 0 2.06667px #7539ff,
        25.98076px -15px 0 2px #7539ff,
        25.49798px -15.93291px 0 1.93333px #7539ff,
        24.98167px -16.85035px 0 1.86667px #7539ff,
        24.43231px -17.75111px 0 1.8px #7539ff,
        23.85046px -18.63402px 0 1.73333px #7539ff,
        23.23668px -19.49789px 0 1.66667px #7539ff,
        22.5916px -20.34157px 0 1.6px #7539ff,
        21.91589px -21.16393px 0 1.53333px #7539ff,
        21.21024px -21.96384px 0 1.46667px #7539ff,
        20.4754px -22.74023px 0 1.4px #7539ff,
        19.71215px -23.49203px 0 1.33333px #7539ff,
        18.92133px -24.2182px 0 1.26667px #7539ff,
        18.10379px -24.91772px 0 1.2px #7539ff,
        17.26042px -25.58963px 0 1.13333px #7539ff,
        16.39217px -26.23295px 0 1.06667px #7539ff,
        15.5px -26.84679px 0 1px #7539ff,
        14.58492px -27.43024px 0 0.93333px #7539ff,
        13.64796px -27.98245px 0 0.86667px #7539ff,
        12.69018px -28.50262px 0 0.8px #7539ff,
        11.7127px -28.98995px 0 0.73333px #7539ff,
        10.71663px -29.4437px 0 0.66667px #7539ff,
        9.70313px -29.86317px 0 0.6px #7539ff,
        8.67339px -30.2477px 0 0.53333px #7539ff,
        7.6286px -30.59666px 0 0.46667px #7539ff,
        6.57001px -30.90946px 0 0.4px #7539ff,
        5.49886px -31.18558px 0 0.33333px #7539ff,
        4.41643px -31.42451px 0 0.26667px #7539ff,
        3.32401px -31.6258px 0 0.2px #7539ff,
        2.22291px -31.78904px 0 0.13333px #7539ff,
        1.11446px -31.91388px 0 0.06667px #7539ff, 0 -32px 0 0 #7539ff,
        -1.11911px -32.04713px 0 -0.06667px #7539ff,
        -2.24151px -32.05506px 0 -0.13333px #7539ff,
        -3.36582px -32.02361px 0 -0.2px #7539ff,
        -4.49065px -31.95265px 0 -0.26667px #7539ff,
        -5.61462px -31.84212px 0 -0.33333px #7539ff,
        -6.73634px -31.69198px 0 -0.4px #7539ff,
        -7.8544px -31.50227px 0 -0.46667px #7539ff,
        -8.9674px -31.27305px 0 -0.53333px #7539ff,
        -10.07395px -31.00444px 0 -0.6px #7539ff,
        -11.17266px -30.69663px 0 -0.66667px #7539ff,
        -12.26212px -30.34982px 0 -0.73333px #7539ff,
        -13.34096px -29.96429px 0 -0.8px #7539ff,
        -14.4078px -29.54036px 0 -0.86667px #7539ff,
        -15.46126px -29.07841px 0 -0.93333px #7539ff,
        -16.5px -28.57884px 0 -1px #7539ff,
        -17.52266px -28.04212px 0 -1.06667px #7539ff,
        -18.52792px -27.46878px 0 -1.13333px #7539ff,
        -19.51447px -26.85936px 0 -1.2px #7539ff,
        -20.48101px -26.21449px 0 -1.26667px #7539ff,
        -21.42625px -25.53481px 0 -1.33333px #7539ff,
        -22.34896px -24.82104px 0 -1.4px #7539ff,
        -23.2479px -24.07391px 0 -1.46667px #7539ff,
        -24.12186px -23.29421px 0 -1.53333px #7539ff,
        -24.96967px -22.48279px 0 -1.6px #7539ff,
        -25.79016px -21.64052px 0 -1.66667px #7539ff,
        -26.58223px -20.76831px 0 -1.73333px #7539ff,
        -27.34477px -19.86714px 0 -1.8px #7539ff,
        -28.07674px -18.938px 0 -1.86667px #7539ff,
        -28.7771px -17.98193px 0 -1.93333px #7539ff,
        -29.44486px -17px 0 -2px #7539ff,
        -30.07908px -15.99333px 0 -2.06667px #7539ff,
        -30.67884px -14.96307px 0 -2.13333px #7539ff,
        -31.24325px -13.91039px 0 -2.2px #7539ff,
        -31.7715px -12.83652px 0 -2.26667px #7539ff,
        -32.26278px -11.74269px 0 -2.33333px #7539ff,
        -32.71634px -10.63018px 0 -2.4px #7539ff,
        -33.13149px -9.5003px 0 -2.46667px #7539ff,
        -33.50755px -8.35437px 0 -2.53333px #7539ff,
        -33.84391px -7.19374px 0 -2.6px #7539ff,
        -34.14px -6.0198px 0 -2.66667px #7539ff,
        -34.39531px -4.83395px 0 -2.73333px #7539ff,
        -34.60936px -3.63759px 0 -2.8px #7539ff,
        -34.78173px -2.43218px 0 -2.86667px #7539ff,
        -34.91205px -1.21916px 0 -2.93333px #7539ff, -35px 0 0 -3px #7539ff,
        -35.04531px 1.22381px 0 -3.06667px #7539ff,
        -35.04775px 2.45078px 0 -3.13333px #7539ff,
        -35.00717px 3.6794px 0 -3.2px #7539ff,
        -34.92345px 4.90817px 0 -3.26667px #7539ff,
        -34.79654px 6.13557px 0 -3.33333px #7539ff,
        -34.62643px 7.36007px 0 -3.4px #7539ff,
        -34.41316px 8.58016px 0 -3.46667px #7539ff,
        -34.15683px 9.79431px 0 -3.53333px #7539ff,
        -33.85761px 11.001px 0 -3.6px #7539ff,
        -33.5157px 12.19872px 0 -3.66667px #7539ff,
        -33.13137px 13.38594px 0 -3.73333px #7539ff,
        -32.70493px 14.56117px 0 -3.8px #7539ff,
        -32.23675px 15.72291px 0 -3.86667px #7539ff,
        -31.72725px 16.86968px 0 -3.93333px #7539ff,
        -31.17691px 18px 0 -4px #7539ff,
        -30.58627px 19.11242px 0 -4.06667px #7539ff,
        -29.95589px 20.2055px 0 -4.13333px #7539ff,
        -29.28642px 21.27783px 0 -4.2px #7539ff,
        -28.57852px 22.32799px 0 -4.26667px #7539ff,
        -27.83295px 23.35462px 0 -4.33333px #7539ff,
        -27.05047px 24.35635px 0 -4.4px #7539ff,
        -26.23192px 25.33188px 0 -4.46667px #7539ff,
        -25.37819px 26.27988px 0 -4.53333px #7539ff,
        -24.49018px 27.1991px 0 -4.6px #7539ff,
        -23.56888px 28.0883px 0 -4.66667px #7539ff,
        -22.6153px 28.94626px 0 -4.73333px #7539ff,
        -21.6305px 29.77183px 0 -4.8px #7539ff,
        -20.61558px 30.56385px 0 -4.86667px #7539ff,
        -19.57168px 31.32124px 0 -4.93333px #7539ff,
        -18.5px 32.04294px 0 -5px #7539ff,
        -17.40175px 32.72792px 0 -5.06667px #7539ff,
        -16.27818px 33.37522px 0 -5.13333px #7539ff,
        -15.1306px 33.98389px 0 -5.2px #7539ff,
        -13.96034px 34.55305px 0 -5.26667px #7539ff,
        -12.76875px 35.08186px 0 -5.33333px #7539ff,
        -11.55724px 35.56951px 0 -5.4px #7539ff,
        -10.32721px 36.01527px 0 -5.46667px #7539ff,
        -9.08014px 36.41843px 0 -5.53333px #7539ff,
        -7.81748px 36.77835px 0 -5.6px #7539ff,
        -6.54075px 37.09443px 0 -5.66667px #7539ff,
        -5.25147px 37.36612px 0 -5.73333px #7539ff,
        -3.95118px 37.59293px 0 -5.8px #7539ff,
        -2.64145px 37.77443px 0 -5.86667px #7539ff,
        -1.32385px 37.91023px 0 -5.93333px #7539ff;
    display: inline-block;
    height: 8px;
    overflow: hidden;
    position: relative;
    text-indent: -9999px;
    width: 8px;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    border-radius: 100%;
}

@keyframes loader {
    to {
        left: -100vw;
    }
}
@-webkit-keyframes whirly-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
@keyframes whirly-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
.select2-container {
    min-width: 100% !important;
    width: auto !important;
    z-index: 99;
    padding: 0 !important;
}
.select2-container .select2-selection--single {
    height: 43px;
}

.select2-container--default .select2-selection--single {
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 5px;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #3f4254;
    line-height: 41px;
    border-radius: 5px;
    padding-right: 30px;
    padding-left: 15px;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 40px;
    right: 6px;
}

.select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    position: absolute;
    top: 50%;
    left: 50%;
    border-color: #878a99;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    margin-left: -10px;
    margin-top: -3px;
    width: 0;
    transform: rotate(45deg) translateY(-50%);
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
}

.select2-container--default.select2-container--open
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: #000;
    border-width: 2px 0 0 2px;
    margin-top: 3px;
    padding: 3px;
}

.select2-container--default
    .select2-results__option--highlighted[aria-selected] {
    background: #7539ff;
    color: #fff;
}

.select2-container--focus .select2-selection--single {
    background: #fff !important;
    border-color: #7539ff;
}

span.select2-container.select2-container--default.select2-container--open {
    z-index: 9999;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background: #7539ff;
}

.sidebar {
    background-color: #fff;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 60px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 247px;
    z-index: 995;
    /*box-shadow: 0 0 13px 0 $__light__slate__grey;*/
    overflow-y: scroll;
}
.custom-content-wrapper {
    margin-left: 240px;
}
@media (max-width: 1199.98px) {
    .sidebar {
        width: 200px;
    }
}
@media (max-width: 991.98px) {
    .sidebar {
        top: 60px;
        margin-left: -575px;
        -webkit-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        transition: all 0.4s ease;
        z-index: 1041;
        background: #fff;
    }
}
@media (max-width: 575.98px) {
    .sidebar {
        width: 225px;
    }
}
.sidebar .nav-tabs {
    border: 0;
}
.sidebar .sidebar-header {
    height: 60px;
    float: left;
    width: 100%;
    padding: 0 24px;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}
.sidebar .sidebar-header .logo,
.sidebar .sidebar-header .dark-logo {
    line-height: 60px;
}
.sidebar .sidebar-header .logo img,
.sidebar .sidebar-header .dark-logo img {
    max-height: 45px;
    width: auto;
}
.sidebar .sidebar-header .main-logo.hide-logo {
    display: none;
}
.sidebar .sidebar-header .white-logo {
    display: none;
    line-height: 60px;
}
.sidebar .sidebar-header .white-logo.show-logo {
    display: block;
}
.sidebar .sidebar-header .logo-small {
    display: none;
}
@media (max-width: 768px) {
    .sidebar .sidebar-header {
        display: none;
    }
}
.sidebar .sidebar-menu .menu-title {
    color: #bdbdbd;
    font-size: 12px;
    opacity: 1;
    padding: 5px 25px;
    white-space: nowrap;
    margin-bottom: 0;
}
.sidebar .sidebar-menu ul {
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 15px 0;
    position: relative;
}
.sidebar .sidebar-menu ul + ul {
    padding-top: 0;
}
.sidebar .sidebar-menu ul li {
    margin: 0;
    position: relative;
}
.sidebar .sidebar-menu ul li ul {
    padding: 0;
}
.sidebar .sidebar-menu ul li ul li .menu-title {
    padding: 0 0 0 50px;
    background: #fff;
}
.sidebar .sidebar-menu ul li ul li a {
    background: #fff;
}
.sidebar .sidebar-menu ul li ul li a:before {
    height: 0 !important;
}
.sidebar .sidebar-menu ul li.active a {
    color: #7539ff;
    position: relative;
}
.sidebar .sidebar-menu ul li.active a::before {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #7539ff;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-radius: 0 10px 10px 0px;
}
.sidebar .sidebar-menu ul li.active a::after {
    width: 8px;
    content: "";
    height: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #7638ff;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 0 10px 10px 0px;
}
.sidebar .sidebar-menu ul li.active a svg {
    width: 18px;
}
.sidebar .sidebar-menu ul li.active a .feather {
    width: 18px;
    height: 18px;
}

.dropdown-item span {
    padding: 0 10px;
}
.sidebar .sidebar-menu ul li.active a span {
    color: #7539ff;
}
.sidebar .sidebar-menu ul li a {
    padding: 8px 25px;
    position: relative;
    color: #3f4254;
}
@media (max-width: 1199.98px) {
    .sidebar .sidebar-menu ul li a {
        padding: 8px 12px;
    }
}
.sidebar .sidebar-menu ul li a .badge {
    background: #7539ff;
    padding-left: 5px;
    padding-right: 5px;
}
.sidebar .sidebar-menu ul li a i {
    line-height: 18px;
}
.sidebar .sidebar-menu ul li a svg {
    width: 18px;
}
.sidebar .sidebar-menu ul li a img {
    width: 18px;
    color: #9e9e9e;
}
.sidebar .sidebar-menu ul li a:hover {
    background-color: rgba(38, 17, 85, 0.05);
    color: #7539ff;
}
.sidebar .sidebar-menu ul li a:hover img {
    filter: brightness(0) invert(1);
}
.sidebar .sidebar-menu ul li a:hover span {
    color: #7539ff;
}
.sidebar .sidebar-menu ul li a:hover svg {
    color: #7539ff;
}
.sidebar .sidebar-menu ul li a:hover i {
    color: #7539ff;
}
.sidebar .sidebar-menu ul li a:hover .badge {
    color: #fff;
}
.sidebar .sidebar-menu ul li a.active {
    color: #7539ff;
    border-radius: 0px;
}
.sidebar .sidebar-menu ul li a.active svg {
    color: #7539ff;
}
.sidebar .sidebar-menu ul li a.active img {
    filter: brightness(0) invert(1);
}
.sidebar .sidebar-menu ul li a.active span {
    color: #7539ff;
}
.sidebar .sidebar-menu ul li a.active::before {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #7638ff;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    /*border-radius: 0 10px 10px 0px;*/
}
.sidebar .sidebar-menu ul li a.active::after {
    width: 8px;
    content: "";
    height: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #7638ff;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 0 10px 10px 0px;
    /*display: $__none;*/
}
.sidebar .sidebar-menu ul li a span {
    padding: 0 10px 0;
    font-size: 14px;
    font-weight: normal;
    /*color: $__light__gray__light;*/
    line-height: 18px;
}
.sidebar .sidebar-menu ul li a .menu-arrow {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    text-rendering: auto;
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    top: 8px;
    right: 10px;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.sidebar .sidebar-menu ul li a .menu-arrow::before {
    content: "\f105";
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
}
.sidebar .sidebar-menu ul li a.subdrop {
    background: none;
}
.sidebar .sidebar-menu ul li a.subdrop .menu-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}
.sidebar .sidebar-menu ul li.submenu {
    /*padding-bottom: 20px;*/
}
.sidebar .sidebar-menu ul li.submenu ul {
    /*background: $__light__vampires;*/
    /*@include rounded(5px);*/
    width: 100%;
    padding: 0;
    display: none;
}
.sidebar .sidebar-menu ul li.submenu ul.level2 li a {
    padding-left: 60px;
}
.sidebar .sidebar-menu ul li.submenu ul.level3 li a {
    padding-left: 80px;
}
.sidebar .sidebar-menu ul li.submenu ul li a {
    font-weight: normal;
    font-size: 13px;
    color: #878a99;
    position: relative;
    display: block;
    padding: 8px 8px 8px 50px;
}
.sidebar .sidebar-menu ul li.submenu ul li a.active {
    color: #7539ff;
    font-weight: normal;
    position: relative;
}
.sidebar .sidebar-menu ul li.submenu ul li a.active ::before {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #7638ff;
    transition: all 0.5s;
    /* border-radius: 0 10px 10px 0px; */
}
.sidebar .sidebar-menu ul li.submenu ul li a.active:after {
    background: #7539ff;
    border: 1px solid #7539ff;
    display: none;
}
.sidebar .sidebar-menu ul li.submenu ul li a:hover {
    color: #7539ff;
}
.sidebar .sidebar-menu ul li.submenu ul li a:hover:after {
    background: #7539ff;
    border: 1px solid #7539ff;
}
.sidebar .nav-link {
    padding: 10px;
    color: #28084b;
    position: relative;
}
.sidebar .nav-link.active {
    color: #7539ff;
    background: transparent;
    position: relative;
}
.sidebar .nav-link.active:after {
    content: "";
    border-width: 10px;
    border-color: transparent #7539ff transparent transparent;
    border-style: solid;
    position: absolute;
    top: 36px;
    right: 0;
}
.sidebar .sidebar-left {
    width: 100px;
    -webkit-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
}
.sidebar .sidebar-right {
    width: 250px;
    height: 100%;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: none;
}
@media (max-width: 991.98px) {
    .sidebar .sidebar-right {
        width: calc(100% - 70px);
    }
}
.sidebar .sidebar-right .slimScrollDiv {
    width: 250px !important;
    overflow: visible !important;
}
@media (max-width: 991.98px) {
    .sidebar .sidebar-right .slimScrollDiv {
        width: 100% !important;
    }
}
.sidebar .sidebar-right .tab-content {
    padding: 20px 20px 40px;
}
@media (max-width: 991.98px) {
    .sidebar .sidebar-right .tab-content {
        padding: 10px;
    }
}
.sidebar .sidebar-right p {
    font-size: 10px;
    color: #f2ecff;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
}
.sidebar .sidebar-right ul li.active a {
    background: linear-gradient(46.62deg, #7539ff 0%, #3e45eb 93.64%);
    color: #fff;
}
.sidebar .sidebar-right ul li a {
    font-weight: 400;
    font-size: 14px;
    color: #28084b;
}
.sidebar .sidebar-right ul li a.active {
    color: #7539ff;
}
.sidebar .sidebar-right .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform 0.15s;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    text-rendering: auto;
    line-height: 40px;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 18px;
    top: 0px;
    position: relative;
    font-weight: 700;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
}
.sidebar .sidebar-right .menu-arrow:before {
    content: "\f105";
}
.sidebar li a.subdrop .menu-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}
.sidebar .nav-items .nav-link {
    border-radius: 0;
    border-bottom: 1px solid #d8d8d8;
    padding: 20px 0;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.sidebar .nav-items .nav-link:hover {
    box-shadow: 0 0 0 150px #3e45eb inset;
    color: #fff;
}
.sidebar .nav-items .nav-link span {
    margin-top: 15px;
    font-weight: 500;
}
.sidebar .sidemenu a {
    width: 100%;
    font-weight: 500;
    display: block;
    border-radius: 5px;
    margin: 0 0 10px;
    padding: 10px;
}
.sidebar .sidemenu a:hover {
    background: linear-gradient(46.62deg, #7539ff 0%, #3e45eb 93.64%);
    color: #fff;
}
.sidebar .sidemenu a:hover svg {
    color: #fff;
}
.sidebar .sidemenu a svg {
    stroke-width: 1px;
}

@media (min-width: 991.98px) {
    .mini-sidebar .page-wrapper {
        margin-left: 80px;
    }
    .mini-sidebar .header-left #toggle_btn {
        opacity: 0;
    }
    .mini-sidebar .header-left #toggle_btn:after {
        border: 0;
    }
    .mini-sidebar.expand-menu .header-left #toggle_btn {
        opacity: 1;
    }
    .mini-sidebar .header-left-two #toggle_btn {
        opacity: 0 !important;
    }
    .mini-sidebar .sidebar-right {
        display: none;
    }
    .mini-sidebar .sidebar .sidebar-menu ul li a {
        padding: 10px;
    }
    .mini-sidebar .sidebar .sidebar-menu ul li a span {
        display: none;
    }
    .mini-sidebar .sidebar {
        width: 80px;
    }
    .css-13cymwt-control {
        border-color: #e0e0e0 !important;
    }
    .expand-menu .sidebar-right {
        display: block;
        -webkit-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    .expand-menu .sidebar {
        width: 240px;
    }
    .expand-menu .sidebar .sidebar-menu ul > li > a {
        padding: 8px 25px;
    }
    .expand-menu .sidebar .sidebar-menu ul > li > a span {
        display: block;
    }
    .expand-menu .header-left {
        width: 240px;
    }
}
.sidebarrightmenu .sidebar-right {
    display: block;
}

.css-hlgwow {
    padding: 5px 12px !important;
}

.slide-nav .sidebar {
    margin-left: 0;
}

.sidebar-overlay {
    display: none;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 60px;
    left: 0;
}
.sidebar-overlay.opened {
    display: block;
}

#toggle_btn i {
    font-size: 22px;
}

.mini-sidebar .active.subdrop ~ ul {
    display: none !important;
}
.mini-sidebar .sidebar .sidebar-menu .menu-title {
    display: none;
}

.mini-sidebar.expand-menu .active.subdrop ~ ul {
    display: block !important;
}
.mini-sidebar.expand-menu .sidebar .sidebar-menu .menu-title {
    display: block;
}

.mini-sidebar .header .header-left.header-left-two .logo.logo-small {
    padding: 10px 0 0;
    margin: 0;
}

.active.subdrop ~ ul {
    display: block !important;
}

.sidebar-four {
    background-color: #101924;
    top: 60px;
    border-top-right-radius: 0px;
}

@media (max-width: 991.98px) {
    .sidebar-five {
        display: none;
    }
    .page-wrapper-three {
        margin-left: 0px;
        padding-top: 80px;
        position: relative;
        -webkit-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
}
.sidebar .sidebar-menu.sidebar-menu-ten li.active > a {
    background-color: #eff0f7;
    color: #b5b5c3;
}
.sidebar .sidebar-menu.sidebar-menu-eleven li.active > a {
    background-color: #eff0f7;
    color: #b5b5c3;
}
.sidebar .sidebar-menu.sidebar-menu-twelve li.active > a {
    background-color: rgb(16, 25, 36);
    color: #fff;
}

.mini-sidebar .sidebar .sidebar-menu > ul > li > a i {
    margin-left: 20px;
}
.mini-sidebar.expand-menu .sidebar .sidebar-menu > ul > li > a i {
    margin-left: 0;
}

.reset-icon {
    margin: 0 10px 0 0;
}

.sidebar-six {
    background-color: #fff;
}

.sidebar-seven {
    background-color: #f0f1f5;
}

.sidebar-eight {
    background-color: #101924;
}

.sidebar-nine {
    background-color: #757575;
}

.sidebar-menu-ten li.active {
    background-color: #757575 !important;
    color: #b5b5c3 !important;
}
.sidebar-menu-ten li a.active {
    background-color: #757575 !important;
    color: #b5b5c3 !important;
}
.sidebar-menu-ten li a.active span {
    color: #b5b5c3 !important;
}

.sidebar-menu-eleven li.active {
    background-color: #757575 !important;
    color: #b5b5c3 !important;
}
.sidebar-menu-eleven li a.active {
    background-color: #757575 !important;
    color: #b5b5c3 !important;
}
.sidebar-menu-eleven li a.active span {
    color: #b5b5c3 !important;
}

.sidebar-menu-eleven li.active {
    background-color: #757575 !important;
    color: #b5b5c3 !important;
}
.sidebar-menu-eleven li a.active {
    background-color: #757575 !important;
    color: #b5b5c3 !important;
}
.sidebar-menu-eleven li a.active span {
    color: #b5b5c3 !important;
}

.sidebar-menu-twelve li.active {
    background-color: #101924 !important;
    color: #b5b5c3 !important;
}
.sidebar-menu-twelve li a.active {
    background-color: #101924 !important;
    color: #b5b5c3 !important;
}
.sidebar-menu-twelve li a.active span {
    color: #b5b5c3 !important;
}

.sidebar.sidebar-two ul > li > a {
    color: #95979b;
}
.sidebar.sidebar-two ul > li > a span {
    color: #95979b;
}
.sidebar.sidebar-two li a.active {
    background-color: transparent !important;
}

.greedys {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    height: 60px;
}
.greedys .viewmoremenu {
    background: transparent;
    color: #3f4254;
    border: 0;
    min-width: 150px;
    text-align: right;
}
.greedys .hidden-links li a:hover {
    color: #333;
}

.sidebar .sidebar-horizantal {
    display: none !important;
}

.mini-sidebar .sidebar .sidebar-menu ul li a span {
    display: none;
}
.mini-sidebar.expand-menu .sidebar .sidebar-menu ul li a span {
    display: block;
}

.sidebar .sidebar-menu .greedys ul {
    padding: 0;
}
.sidebar .sidebar-menu .greedys ul li.submenu {
    padding-bottom: 0;
}
.sidebar .sidebar-menu .greedys ul li a .menu-arrow {
    top: 11px;
}
.sidebar .sidebar-menu .greedys ul li ul li a {
    padding: 8px 15px;
    /*font-weight: $__semibold;*/
}

/*.sidebar .sidebar-menu ul li, .two-col-bar .sidebar-menu ul li {
    position: relative;
}
.sidebar .sidebar-inner, .two-col-bar .sidebar-inner {
    height: 100%;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
} */
.tabs-set .nav-tabs {
    border-bottom: 0;
    margin-bottom: 20px;
}
.tabs-set .nav-tabs .nav-link {
    color: #28084b;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 700;
    border: 0;
    min-width: 120px;
    margin-right: 10px;
    background: #f0f1f5;
    border-radius: 5px !important;
}
.tabs-set .nav-tabs .nav-link:focus {
    border-color: transparent;
}
.tabs-set .nav-tabs .nav-link:hover {
    border-color: transparent;
}
.tabs-set .nav-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid #7539ff;
    background: #7539ff;
    color: #fff;
}

.tab-content {
    padding-top: 32px;
}

.navtab-bg .nav-link {
    background-color: #edeff1;
    color: #455560;
}

.nav-bordered li a {
    border: 0 !important;
}

.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #333 #333 #fff;
}
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.navtab-bg .nav-item {
    margin: 0 5px;
}

.nav-bordered a.active {
    border-bottom: 2px solid #7539ff !important;
}

.tabs-sets .nav-tabs {
    border-bottom: 0;
    margin: 0 0 30px;
}
.tabs-sets .nav-tabs .nav-link {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    border: 0;
    background: #e9ecef;
    min-width: 112px;
    margin-right: 15px;
    padding: 15px;
    border-radius: 5px;
}
.tabs-sets .nav-tabs .nav-link:focus {
    border-color: transparent;
}
.tabs-sets .nav-tabs .nav-link:hover {
    border-color: transparent;
}
.tabs-sets .nav-tabs .nav-link.active {
    background: #7539ff;
    color: #fff;
}

.nav-pills .nav-link {
    color: #455560;
    border-radius: 4px;
}

.nav-link {
    color: #888;
}

.card-buttons p {
    margin-bottom: 20px;
}
.card-buttons p:last-child {
    margin-bottom: 0;
}
.card-buttons .card-title {
    margin-bottom: 10px;
}
@media (max-width: 768px) {
    .card-buttons .btn {
        margin-bottom: 10px;
    }
}
.card-buttons .card-text {
    margin-bottom: 10px;
}
.card-buttons .header-title {
    margin-bottom: 10px;
}

.topnav-dropdown-header {
    text-align: center;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    padding: 0 12px;
}
.topnav-dropdown-header .notification-title {
    display: block;
    float: left;
    font-weight: 500;
    font-size: 14px;
}
.topnav-dropdown-header .notification-title a {
    font-size: 12px;
    margin-left: 5px;
}
.topnav-dropdown-header .clear-noti {
    color: #3f4254;
    float: right;
}
.topnav-dropdown-header .clear-noti:hover {
    color: #7539ff;
}
.topnav-dropdown-header .clear-noti i {
    margin-left: 4px;
    font-size: 14px;
}

.notifications .noti-content {
    height: 290px;
    width: 440px;
    overflow-y: auto;
    position: relative;
}
.notifications .notification-list li {
    margin-top: 0;
    box-shadow: 0px 1px 0px 0px #e4e8ee;
    font-size: 14px;
}
.notifications .notification-list li a {
    display: block;
    padding: 16px;
    border-radius: 2px;
}
.notifications .notification-list li a:hover {
    background-color: rgba(38, 17, 85, 0.05);
}
.notifications .notification-list li .avatar:before {
    content: "";
    position: absolute;
    left: -6px;
    top: -6px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #19a855;
    opacity: 0;
}
.notifications .notification-list li .avatar.active:before {
    opacity: 1;
}
.notifications .notification-list li .media-body {
    width: calc(100% - 44px);
}
.notifications .notification-list li .noti-title {
    color: #3f4254;
    font-weight: 600;
}
.notifications .notification-list li .noti-details {
    color: #3f4254;
    margin: 0;
}
.notifications .notification-list li p.noti-time {
    margin: 0;
}
.notifications .notification-list li .notification-time {
    font-size: 12px;
    line-height: 1.35;
    color: #9e9e9e;
}
.notifications .notification-list li blockquote {
    color: #3f4254;
    margin: 10px 0;
    padding-left: 12px;
    position: relative;
}
.notifications .notification-list li blockquote:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px;
    background-color: #dddee1;
    width: 4px;
    height: 100%;
}
.notifications .notification-list li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}
.notifications .notification-list li .list-item .list-left {
    height: 48px;
    position: absolute;
    width: 48px;
}
.notifications .notification-list li .list-item .list-body {
    padding: 0 0 0 50px;
}
.notifications .notification-list li .list-item .list-body .message-author {
    color: #333;
    float: left;
    font-weight: 500;
    width: 175px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.notifications .notification-list li .list-item .list-body .message-time {
    color: #888888;
    float: right;
    font-size: 11px;
}
.notifications .notification-list li .list-item .list-body .message-content {
    color: #333;
    font-size: 13px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.notifications .notification-list li .notification-btn {
    padding: 10px 0;
}
.notifications .notification-list li .notification-btn .btn {
    padding: 8px 20px;
    font-size: 12px;
    font-weight: 600;
    margin-right: 8px;
    border-radius: 6px;
}
.notifications .notification-list li .notification-btn .btn:last-child {
    margin-right: 0px;
}

.topnav-dropdown-footer {
    border-top: 1px solid #eeeeee;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    padding: 0 12px;
}
.topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #ed3a3a;
    font-weight: 500;
}
.topnav-dropdown-footer a:hover {
    color: #7539ff;
}

.list-left {
    height: 48px;
    position: absolute;
    width: 48px;
}

.list-body {
    padding: 0 0 0 50px;
}
.list-body .message-author {
    color: #333;
    float: left;
    font-weight: 500;
    width: 175px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list-body .message-time {
    color: #888888;
    float: right;
    font-size: 11px;
}
.list-body .message-content {
    color: #333;
    font-size: 13px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.activity {
    width: 100%;
}
.activity .activity-box {
    position: relative;
}
.activity .activity-list {
    position: relative;
}
.activity .activity-list li {
    background-color: #fff;
    position: relative;
    border: 1px solid #d9d9d9;
    margin: 0 0 10px;
    padding: 20px 10px 10px;
    font-size: 15px;
}
.activity .activity-list li .activity-user {
    height: 50px;
    margin: 0;
    padding: 0;
    width: 50px;
    position: absolute;
    top: 12px;
    left: 8px;
}
.activity .activity-list li .activity-content {
    background-color: #fff;
    position: relative;
    margin: 0 0 0 60px;
    padding: 0;
}
.activity .activity-list li .time {
    color: #c4c4c4;
    display: block;
    font-size: 12px;
    line-height: 1.35;
}
.activity .activity-list li a.name {
    color: #000;
}
.activity .activity-list li a {
    color: #28084b;
    font-weight: 600;
    font-size: 15px;
}
.activity .activity-list li a img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.edit-options .status-toggle .check:checked + .checktoggle {
    background-color: #7539ff;
    border: 1px solid #7539ff;
    left: 0;
    transform: translate(calc(0% - 5px), 0%);
}
.edit-options .status-toggle .checktoggle {
    transform: translate(calc(0% - 5px), 0%);
}

.modal-dialog .modal-md {
    max-width: 600px;
}

.form-header {
    text-align: center;
    margin-bottom: 30px;
}
.form-header h4 {
    color: #1b2559;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
}

.modal-header-title h4 {
    font-weight: 600;
    font-size: 20px;
    color: #0f0033;
}

.bank-details .bank-inner-details label {
    font-size: 16px;
    font-weight: 600;
    color: #1b2559;
    margin-bottom: 10px;
}
.bank-details .bank-inner-details .form-control {
    height: 50px;
}

.status-toggle .checktoggle {
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
    top: 0px;
    border-radius: 12px;
    transform: translate(calc(-30% - 5px), -60%);
}
.status-toggle .checktoggle:after {
    height: 15px;
    width: 15px;
}
.status-toggle .check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
.status-toggle .check:checked + .checktoggle {
    background-color: #7539ff;
    border: 1px solid #7539ff;
}
.status-toggle .check:checked + .checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 2px), -50%);
    height: 20px;
    width: 20px;
}
.status-toggle .checktoggle:after {
    content: " ";
    display: block;
    background-color: #fff;
    height: 20px;
    width: 20px;
    transform: translate(0px, -50%);
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    position: absolute;
    left: 0;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 2px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.status-toggle .checktoggle .checkbox-bg::after {
    background: rgba(0, 0, 0, 0.25);
}

.close-btn {
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 18px;
}
.close-btn i {
    font-size: 20px;
    color: #f0142f;
}

.del-icon {
    margin-bottom: 23px;
}
.del-icon i {
    font-size: 60px;
    color: #f0142f;
    margin-bottom: 23px;
}

.submit-section .btn {
    padding: 7px 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    min-width: 150px;
    margin-top: 5px;
}

.custom-modal .modal-header {
    padding: 24px;
}
.custom-modal .modal-body {
    padding: 24px;
}
.custom-modal .modal-body .form-header h3 {
    font-size: 22px;
    margin: 0 0 10px;
}

.bank-details .modal-header {
    border: 0;
    padding: 20px;
}
.bank-details .modal-body {
    padding: 0 20px 0;
}
.bank-details .modal-footer {
    justify-content: flex-end;
    padding: 30px;
}

.modal-content {
    background: #fff;
    box-shadow: 0px 20px 13px rgba(0, 0, 0, 0.03),
        0px 8px 5px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.modal-backdrop.show {
    opacity: 0.4;
}

.add-tax-btns .add-btn-cancel-btn {
    padding: 8px 15px;
    margin: 0 10px 0 0;
    padding: 8px 15px;
    min-width: 81px;
    color: #3f4254;
    background: #fff;
    border: 1px solid #bdbdbd;
    box-shadow: inset 0 0 0 0 #3f4254;
    border-radius: 6px;
}
.add-tax-btns .add-btn-cancel-btn:hover {
    color: #fff;
    background: #3f4254;
    border-color: #3f4254;
    box-shadow: inset 0 50px 0 0 #3f4254;
}
.add-tax-btns .add-tax-save-btn {
    margin: 0 0 0 0;
    padding: 8px 15px;
    min-width: 69px;
}

.table {
    color: #333;
    max-width: 100%;
    margin-bottom: 0;
    width: 100%;
    --bs-table-bg: transparent;
}
.table .table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 500;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
    min-width: 150px;
    font-size: 14px;
}
.table .thead-light th {
    font-size: 13px;
    color: #28084b;
    font-weight: 500;
}
.table thead {
    background: linear-gradient(320deg, #ddceff 0%, #dbecff 100%);
    border-bottom: 0;
    vertical-align: middle;
    white-space: nowrap;
    width: 100% !important;
}
@media (max-width: 767px) {
    .table thead {
        background: #dee2e6;
    }
}
.table thead tr th {
    font-weight: 500;
    border-color: #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
.table tbody {
    vertical-align: middle;
    white-space: nowrap;
}
.table tbody tr td {
    border-color: #dee2e6;
}
.table tbody td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.table tbody td h2 span {
    color: #808080;
    display: block;
    font-size: 14px;
    margin-top: 2px;
    font-weight: 400;
}
.table tbody td a {
    color: #3f4254;
}
.table tbody td a.btn-action-icon {
    background: #f3f3f3;
    color: #3f4254;
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 50px;
}
.table tbody td a.btn-action-icon:active {
    color: #7539ff;
}
.table tbody td a + a {
    display: inline-block;
}
.table tbody td a.add-btn {
    display: inline-block !important;
}
.table tbody td a:hover {
    color: #7539ff;
}
.table tbody td a:active {
    color: #fff;
}
.table tbody td a.btn {
    display: inline-block;
    padding: 5px 10px;
}
.table tbody td .items-links {
    color: #1b2559;
    display: inline-block;
}
.table tbody td .items-links:hover {
    color: #7539ff;
}
.table .invoice-link {
    color: #878a99;
    display: inline-block;
}
.table .invoice-link:hover {
    color: #6b36de;
}
.table .invoice-link .table-center th,
.table .invoice-link .table-center td {
    vertical-align: middle;
}
.table .invoice-link .dropdown-menu {
    width: 200px;
    background: #fff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 4px rgba(231, 234, 252, 0.75);
    margin-top: 10px !important;
    border-radius: 6px;
}
.table .invoice-link .dropdown-item {
    font-weight: 500;
    font-size: 13px;
    color: #1b2559;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
}
.table .invoice-link .dropdown-item:last-child {
    margin-bottom: 0;
}
.table .invoice-link .dropdown-item:focus,
.table .invoice-link .dropdown-item:hover {
    color: #7539ff;
    background: transparent;
}
.table .invoice-link .dropdown-item.active {
    color: #fff;
    text-decoration: none;
    background-color: #7539ff;
}
.table .invoice-link .dropdown-item i {
    font-size: 16px;
}

.table .dropdown-menu {
    width: 160px;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 16px 32px rgba(190, 190, 190, 0.2);
    border-radius: 12px;
    padding: 0;
    margin-top: 10px !important;
    height: auto !important;
}
.table .dropdown-item {
    font-weight: 500;
    font-size: 13px;
    color: #878a99;
    padding: 10px 20px 10px;
}

.table-inbox {
    font-size: 15px;
    margin-bottom: 0;
}

.table-nowrap th,
.table-nowrap td {
    white-space: nowrap;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) {
    background-color: #f8f9fa;
}
.table-striped .dropdown-action {
    margin-bottom: 0;
}
.table-striped .table-bordered {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
    padding: 10px 8px;
}

.table-hover tbody tr:hover {
    background-color: #f6f6f7;
}

.table-bordered th {
    border-color: rgba(0, 0, 0, 0.05);
}
.table-bordered td {
    border-color: rgba(0, 0, 0, 0.05);
}
.table-bordered td:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.table-bordered td:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.card-table .card-body {
    padding: 0 !important;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .card-body .table td,
.custom-table {
    color: #1f0066;
    font-weight: 400;
    border-top: 0;
    font-size: 14px;
    height: 46px;
    border-bottom: 1px solid #f5f5f5;
    padding: 15px 25px;
    white-space: nowrap;
    box-shadow: none;
    vertical-align: middle;
}
.custom-table td {
    padding: 15px 20px;
}
td .css-b62m3t-container .css-13cymwt-control {
    border-color: #dee2e6;
}
.card-table .card-body .table td:first-child {
    padding-left: 25px;
}
.card-table .card-body .table th {
    white-space: nowrap;
    padding: 10px 25px;
    border-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    color: #0f0033;
}
.card-table .card-body .table th:first-child {
    padding-left: 25px;
}

.table > :not(:first-child) {
    border: 0;
}

.dataTables_length select {
    appearance: auto;
}

.table-nosearch .dataTables_length,
.table-nosearch .dataTables_filter {
    display: none;
}

.card-table
    div.table-responsive
    > div.dataTables_wrapper
    > div.row:first-child {
    padding: 1.5rem 1.5rem 0 !important;
    align-items: center;
}
.card-table div.table-responsive > div.dataTables_wrapper > div.row:last-child {
    padding: 0 1.5rem 1.5rem !important;
    align-items: center;
}

.dataTables_length select {
    appearance: auto;
}

table .badge {
    border-radius: 4px;
    display: inline-block;
    font-size: 14px;
    min-width: auto;
    padding: 4px 10px;
    font-weight: 400;
    text-align: center;
}

.ant-table-content th {
    text-align: center;
}

.radioHeading {
    font-size: 1rem;
}
.table tbody td a {
    color: #212121;
    display: inline-block;
}
.table tbody td a.action-icon {
    color: #333;
}
.table tbody td h2.table-avatar a {
    color: #3f4254;
}
.table tbody td h2.table-avatar a:hover {
    color: #7539ff;
}

.mini-sidebar .header .header-left.header-left-two .logo.logo-small {
    padding: 10px 0 0;
    margin: 0;
}

.categories-table .table tr:last-child td {
    padding-bottom: 0;
    border: none;
}
.categories-table .table td {
    padding: 8px;
    font-size: 14px;
}

.card-table .table {
    background: #fff;
    /* margin-bottom: 24px  !important; */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.dataTables_length label {
    font-weight: 500 !important;
    font-size: 14px;
    color: #3f4254;
}

.dataTables_length label:before {
    content: "Show";
    margin-right: 5px;
    font-size: 14px;
}

.dataTables_length label:after {
    content: "Entries";
    margin-left: 5px;
    font-size: 14px;
}

.dataTables_scroll {
    margin-bottom: 24px;
}
.dataTables_scroll *::-webkit-scrollbar {
    height: 7px;
}
.dataTables_scroll *::-webkit-scrollbar-track {
    background: #e9e9ea;
    border-radius: 0;
}
.dataTables_scroll *::-webkit-scrollbar-thumb {
    /*background-color: #DBECFF; */
    background-color: #ddceff;
    border-radius: 0;
}
.dataTables_scroll * {
    scrollbar-color: #d3d3d3 #e9e9ea;
    scrollbar-width: thin;
}

@media (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_length {
        width: 100%;
    }
}

@media (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_paginate {
        width: 100%;
    }
}

@media (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        justify-content: center;
        margin-top: 14px;
    }
}

.dataTables_length .form-select {
    --bs-form-select-bg-img: none;
}

.header .header-left-two {
    background-color: #101924;
    border-bottom: 1px solid #203247;
    border-radius: 0px 16px 0px 0px;
}
@media (max-width: 991.98px) {
    .header .header-left-two {
        background-color: transparent;
        border-bottom: 0;
    }
}
.header .header-left-two #toggle_btn {
    padding: 0 0px;
    margin-left: 0px;
    color: #fff;
}
.header .header-left-two a {
    margin-left: 15px;
}
.header .header-left-two .logo img {
    width: 155px;
}
@media (max-width: 991.98px) {
    .header .header-left-two .logo img {
        display: none;
    }
}
.header .header-left-two .dark-logo {
    display: none;
}
.header.header-four {
    border-radius: 0px;
    background-color: #101924;
}
.header.header-four a {
    padding: 0 10px;
    color: white;
}

@media (min-width: 991.98px) {
    .sidebar-hide {
        display: none;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}
.sidebar.sidebar-two .sidebar-menu > ul > li ul li a {
    background: transparent !important;
}

.sidebar.sidebar-two {
    background-color: #101924;
    top: 0;
    border-top-right-radius: 0px;
    padding-top: 0;
}
@media (max-width: 991.98px) {
    .sidebar.sidebar-two {
        left: 0;
        top: 60px;
    }
}
.sidebar.sidebar-two .menu-title-two {
    color: #fff;
}
.sidebar.sidebar-two li.active > a {
    background-color: rgb(246, 247, 249) !important;
}
.sidebar.sidebar-three-three {
    left: unset;
    top: 85px;
    border-radius: 4px;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    overflow-y: hidden;
}
@media (max-width: 991.98px) {
    .sidebar.sidebar-three-three {
        left: 0;
    }
}

.sidebar-five {
    background-color: transparent;
    top: 0px;
    border-top-right-radius: 0px;
    float: left;
    margin: 0;
    position: relative;
    z-index: 99;
    width: auto;
    overflow-y: visible;
    box-shadow: none;
}

.sidebar-menu-five .dropdown-toggle {
    color: #fff !important;
}
.sidebar-menu-five .dropdown-toggle span {
    color: #fff !important;
}
.sidebar-menu-five ul {
    padding: 10px 0;
    position: relative;
}
.sidebar-menu-five ul .dropdown-menu-right {
    position: absolute;
    width: 220px;
    height: auto;
    border: none;
}
.sidebar-menu-five ul ul a span {
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
    float: unset;
}
.sidebar-menu-five ul ul a:hover {
    background-color: rgba(118, 56, 255, 0.05);
    color: #7539ff;
}
.sidebar-menu-five > ul > li {
    margin-bottom: 0px;
    position: relative;
}
.sidebar-menu-five > ul > li:last-child {
    margin-bottom: 0px;
}
.sidebar-menu-five > ul > li > a {
    color: #fff !important;
    z-index: 9999;
    border-bottom: 3px solid transparent;
}
.sidebar-menu-five > ul > li > a:hover {
    background-color: rgb(16, 25, 36);
    color: #fff;
    border-bottom: 3px solid #7539ff;
}
.sidebar-menu-five li.active > a {
    background-color: rgb(247, 248, 249);
    color: #7539ff;
    position: relative;
}
.sidebar-menu-five li a:hover {
    color: #7539ff;
    background-color: #fff;
}
.sidebar-menu-five li a.subdrop .menu-arrow {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.sidebar-menu-five .menu-arrow {
    transform: rotate(90deg);
    position: initial;
}

.sidebar.sidebar-five .sidebar-menu > ul > li > a .menu-arrow {
    right: -2px;
    position: relative;
    top: 0px;
    transform: rotate(90deg);
}

.sidebar .sidebar-menu .sidebar-menu-five > ul {
    padding: 15px 0 0;
}

.page-wrapper.page-wrapper-four {
    margin-left: 0px;
    padding-top: 60px;
    position: relative;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.page-wrapper-three {
    margin-left: 315px;
    padding-top: 80px;
    position: relative;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
@media (max-width: 991.98px) {
    .page-wrapper-three {
        margin-left: 0;
    }
}

.sidebar.sidebar-three .nav-link.active:after {
    content: "";
    border-width: 10px;
    border-color: transparent;
    border-style: solid;
    position: absolute;
    top: 36px;
    right: 0;
}

.ui-aside {
    float: left;
    width: 66px;
    margin-left: 0px;
    color: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    padding: 0px 0px;
    /*margin-right: 11px;*/
}

.tab .tablinks {
    display: block;
    background-color: inherit;
    color: #6e82a5;
    padding: 22px 15px !important;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 0px 0px;
    font-size: 17px;
    border: 0 !important;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 0;
}

.sidebar-menu-three {
    border-bottom: none;
}
.sidebar-menu-three > ul {
    border-bottom: none;
}
.sidebar-menu-three > ul > li > a:hover {
    background: rgba(118, 56, 255, 0.12);
    color: #7539ff;
}
.sidebar-menu-three li.active > a {
    background: rgba(118, 56, 255, 0.12);
}
.sidebar-menu-three li.active > a::before {
    right: 0;
    left: auto;
    background: #7539ff;
}
.sidebar-menu-three li > a {
    color: #3f4254;
    display: block;
    font-size: 14px;
    height: auto;
    padding: 9px 7px;
}
.sidebar-menu-three li > a.active {
    color: #7539ff;
}
.sidebar-menu-three ul ul {
    padding: 0;
    display: none;
}
.sidebar-menu-three ul ul li a {
    padding-left: 25px;
    display: inline-block;
    color: #878a99;
}
.sidebar-menu-three ul ul li a.active {
    color: #7539ff;
}

.sidebar-three .tab-content svg {
    width: 20px;
}

.ui-aside-compact .ui-aside {
    margin-left: 0;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.tab .tablinks.active {
    color: #fff;
    background-color: #001621;
    border-color: #182b3e #182b3e #182b3e;
    border: 1px solid #203247 !important;
}

.ui-aside::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: inherit;
    background-color: inherit;
    border: inherit;
    /*z-index: -1;*/
    background: #001621 no-repeat center top;
    background-size: cover;
}

.top-nav-search-three {
    margin-left: 11px;
}

.header-left-four .dark-logo {
    display: none !important;
}

.header.header-five a {
    margin-left: 0;
}

.user-menu-four.nav > li > a {
    color: #fff;
}

.sidebar.sidebar-menu-five ul {
    padding: 10px 0;
    position: relative;
}
.sidebar.sidebar-menu-five ul .dropdown-menu-right {
    position: absolute;
    width: 220px;
    height: auto;
    border: none;
}
.sidebar.sidebar-menu-five ul ul a span {
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
    float: unset;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.sidebar.sidebar-menu-five ul ul a:hover {
    background-color: rgba(118, 56, 255, 0.05);
    color: #7539ff;
}
.sidebar.sidebar-menu-five > ul > li {
    margin-bottom: 0px;
    position: relative;
}
.sidebar.sidebar-menu-five > ul > li:last-child {
    margin-bottom: 0px;
}
.sidebar.sidebar-menu-five > ul > li > a {
    color: #fff;
    z-index: 9999;
}
.sidebar.sidebar-menu-five > ul > li > a:hover {
    background-color: rgb(16, 25, 36);
    color: #fff;
    border-bottom: 3px solid #7539ff;
}
.sidebar.sidebar-menu-five li.active > a {
    background-color: rgb(247, 248, 249);
    color: #7539ff;
    position: relative;
}
.sidebar.sidebar-menu-five li a:hover {
    color: #7539ff;
    background-color: #fff;
}
.sidebar.sidebar-menu-five li a.subdrop .menu-arrow {
    transform: rotate(-90deg);
}
.sidebar.sidebar-menu-five .menu-arrow {
    transform: rotate(90deg);
    position: initial;
}

.sidebar.sidebar-five .sidebar-menu > ul > li.submenu ul {
    background: transparent;
    border-radius: 5px;
    padding: 0;
    display: none;
}

.header.header-three .header-left .logo img {
    max-height: 35px;
    width: auto;
}
@media (max-width: 575.98px) {
    .header.header-three .header-left .logo img {
        display: none !important;
    }
}

.header-left-three {
    width: 66px !important;
    padding: 0 5px !important;
    background: #101924;
}
@media (max-width: 991.98px) {
    .header-left-three {
        width: 100% !important;
    }
}

.header-three .mobile_btn {
    color: #fff;
}

.sidebar-menu ul {
    font-size: 15px;
    list-style-type: none;
    margin: 0;
    padding: 15px 0;
    position: relative;
}

.tab-content-three {
    padding-top: 0;
    margin-left: 65px;
}
.tab-content-three .menu-title-three {
    color: #757575;
}

.sidebar-menu-three li.active > a {
    background: rgba(118, 56, 255, 0.12);
}
.sidebar-menu-three li.active > a::before {
    right: 0;
    left: auto;
    background: #7539ff;
}

.sidebar-three {
    background-color: #fff;
    top: 60px;
    border-top-right-radius: 0px;
    width: 306px;
}

.expand-menu .sidebar-three {
    width: 306px;
}

.expand-menu .sidebar-right {
    display: block;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.expand-menu .sidebar-three {
    width: 306px;
}
.expand-menu .sidebar-three .sidebar-menu ul > li > a {
    padding: 9px 20px;
}
.expand-menu .sidebar-three .sidebar-menu ul > li > a span {
    display: inline-block;
}
.expand-menu .sidebar-three .sidebar-menu ul ul a {
    padding-left: 25px !important;
}
.expand-menu .header-left {
    width: 240px;
}

.sidebar-three .nav-tabs {
    border-bottom: 0;
    align-items: center;
    justify-content: center;
}

.profile-cover-avatar input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 33, 68, 0.25);
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 50%;
}

.avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: #677788;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.avatar-edit svg {
    width: 18px;
}

.header-seven {
    background-color: #f0f1f5;
    border-radius: 0;
}

.header-eight {
    border-radius: 0;
    background-color: #101924;
}
.header-eight .header-left .white-logo {
    display: flex;
}
.header-eight .header-left .logo {
    display: none;
}
.header-eight #toggle_btn {
    color: #fff;
}
.header-eight .user-menu-eight .user-link:hover .user-content .user-name {
    color: #000;
}
.header-eight .user-menu-eight .user-content .user-name {
    color: #fff;
}

.header-nine {
    border-radius: 0;
    background-color: #757575;
}

.header-four .nav-tabs .nav-link:hover {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}
.header-four .nav-tabs .nav-link:focus {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}

.sidebar-menu-five ul .dropdown-menu-right {
    position: absolute;
    width: 220px;
    max-height: 300px;
    border: none;
    overflow: auto;
}
.sidebar-menu-five ul .dropdown-menu-right::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 50px !important;
}
.sidebar-menu-five ul .dropdown-menu-right::-webkit-scrollbar-thumb {
    background: #7539ff;
}
.sidebar-menu-five ul .dropdown-menu-right::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.sidebar-menu-five .nav .submenu-five ul a {
    padding: 17px 15px;
}

.sidebar.sidebar-five .sidebar-menu > ul > li.submenu ul li a {
    font-weight: 500;
    font-size: 14px;
    color: #95979b;
    position: relative;
    display: block;
    padding: 7px 15px;
    margin: 0;
    border-bottom: 1px solid #e9e9e9;
}

.header.header-four .has-arrow .dropdown-toggle:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}

.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul {
    background: #101924;
}

.sidebar-menu-three .menu-arrow {
    top: auto;
    transition: transform 0.15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    transform: translate(0, 0);
    line-height: 18px;
}
.sidebar-menu-three .menu-arrow:before {
    content: "\f105";
}

.submenu-five .menu-arrow {
    top: auto;
    transition: transform 0.15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    transform: translate(0, 0);
    line-height: 18px;
}
.submenu-five .menu-arrow:before {
    content: "\f105";
}

.form-control:focus {
    box-shadow: none;
}

.sidebar.sidebar-three .tab .tablinks.active:after {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: -25px;
    top: 0;
    background: #7638ff;
    transition: all 0.5s;
    display: none;
}

.feather {
    width: 18px;
    height: 18px;
}

.sidebar-menu-five .dropdown-toggle::after {
    display: none;
}

[data-layout-width="boxed"] body,
[data-layout-width="boxed"] .header {
    max-width: 1300px;
    margin: auto;
}
[data-layout-width="boxed"] .sidebar ul .menu-title {
    display: none !important;
}
[data-layout-width="boxed"] .sidebar ul li span {
    display: none !important;
}
[data-layout-width="boxed"] .sidebar ul ul li {
    display: none !important;
}
[data-layout-width="boxed"] .page-wrapper {
    margin: 0 0 0 230px;
}
[data-layout-width="boxed"] .page-wrapper .content {
    padding-right: 0;
}
[data-layout-width="boxed"] .list-btn .filter-list li:last-child {
    padding-right: 0;
}
[data-layout-width="boxed"] .main-wrapper {
    position: relative;
}
[data-layout-width="boxed"] .main-wrapper #toggle_btn {
    display: none;
}
[data-layout-width="boxed"] .sidebar {
    position: absolute;
    width: 230px;
}
[data-layout-width="boxed"] .sidebar .noti-dot {
    display: none !important;
}
[data-layout-width="boxed"] .sidebar ul .menu-title {
    display: block !important;
}
[data-layout-width="boxed"] .sidebar ul li span {
    display: block !important;
}
[data-layout-width="boxed"] .sidebar ul ul li {
    display: block !important;
}

[data-sidebar-size="sm-hover"] #toggle_btn {
    display: none;
}
[data-sidebar-size="sm-hover"] .sidebar ul .menu-title {
    display: none !important;
}
[data-sidebar-size="sm-hover"] .sidebar ul li span {
    display: none !important;
}
[data-sidebar-size="sm-hover"] .sidebar ul ul li {
    display: none !important;
    width: 100%;
}
[data-sidebar-size="sm-hover"] .two-col-bar:hover .sidebar-right {
    display: block;
}
[data-sidebar-size="sm-hover"] .two-col-bar .sidebar-left {
    display: block;
}
[data-sidebar-size="sm-hover"] .two-col-bar .sidebar-right {
    display: none;
}
[data-sidebar-size="sm-hover"] .page-wrapper {
    margin: 0 0 0 60px;
}
[data-sidebar-size="sm-hover"] .sidebar {
    width: 60px;
}
[data-sidebar-size="sm-hover"] .sidebar .noti-dot {
    display: none !important;
}
[data-sidebar-size="sm-hover"] .sidebar .sidebar-menu ul li span {
    display: none !important;
}
[data-sidebar-size="sm-hover"] .sidebar:hover {
    width: 247px;
}
[data-sidebar-size="sm-hover"] .sidebar:hover ul .menu-title {
    display: inline-block !important;
}
[data-sidebar-size="sm-hover"] .sidebar:hover ul li span {
    display: inline-block !important;
}
[data-sidebar-size="sm-hover"] .sidebar:hover ul ul li {
    display: inline-block !important;
}

[data-sidebar-size="md"] .page-wrapper {
    margin: 0 0 0 200px;
}
[data-sidebar-size="md"] .main-logo {
    width: 200px;
}
[data-sidebar-size="md"] .sidebar {
    width: 200px;
}
[data-sidebar-size="md"] .sidebar .menu-title {
    justify-content: center;
}
[data-sidebar-size="md"] .sidebar .sidebar-menu ul li a {
    flex-direction: column;
    text-align: center;
    padding: 10px;
}
[data-sidebar-size="md"] #toggle_btn {
    display: none;
}

[data-layout-position="scrollable"] .main-wrapper {
    position: relative;
}
[data-layout-position="scrollable"] .main-wrapper .sidebar {
    position: absolute;
}
[data-layout-position="scrollable"] .slimScrollDiv,
[data-layout-position="scrollable"] .slimscroll {
    overflow: auto !important;
    height: auto !important;
}

[data-layout="twocolumn"] .page-wrapper {
    margin-left: 306px;
}
[data-layout="twocolumn"] .mini-sidebar .page-wrapper {
    margin-left: 66px;
}
[data-layout="twocolumn"] .mini-sidebar.expand-menu .main-logo {
    width: 306px;
}
[data-layout="twocolumn"] .mini-sidebar.expand-menu .main-logo .logo-blue {
    display: block;
}
[data-layout="twocolumn"] .mini-sidebar .main-logo {
    width: 66px;
    padding: 0 13px;
}
[data-layout="twocolumn"] .mini-sidebar .main-logo .logo-blue {
    display: none;
}
[data-layout="twocolumn"] .mini-sidebar.expand-menu .sidebar {
    width: 306px;
}
[data-layout="twocolumn"] .mini-sidebar .sidebar {
    width: 66px;
}
[data-layout="twocolumn"] .two-col-bar {
    display: block;
}
[data-layout="twocolumn"] .main-logo {
    width: 306px;
}
[data-layout="twocolumn"] .sidebar .sidebar-menu {
    padding: 0;
}
[data-layout="twocolumn"] .sidebar .sidebar-menu aside {
    background: #34444c;
    height: 100%;
}
[data-layout="twocolumn"] .sidebar .sidebar-menu ul {
    padding-top: 0;
}
[data-layout="twocolumn"] .sidebar .sidebar-menu ul li.submenu a {
    padding: 9px 7px;
    /*padding-left: 0;
  padding-right: 0;*/
}
[data-layout="twocolumn"] .sidebar .sidebar-menu ul li.submenu ul li a.active {
    background: none;
}
[data-layout="twocolumn"]
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a.active:after {
    /*display: $__none;*/
}
[data-layout="twocolumn"] .sidebar .sidebar-menu ul li a.active:before,
[data-layout="twocolumn"] .sidebar .sidebar-menu ul li a.active:after {
    /*display: $__none;*/
}
[data-layout="twocolumn"] .sidebar .sidebar-menu .menu-title {
    /*padding-left: 5px;*/
}
[data-layout="twocolumn"] #sidebar {
    display: none;
}
@media (max-width: 991.98px) {
    [data-layout="twocolumn"] #sidebar {
        display: block;
    }
}
[data-layout="twocolumn"] #sidebar-view,
[data-layout="twocolumn"] #sidebar-size,
[data-layout="twocolumn"] #layout-width,
[data-layout="twocolumn"] #layout-position {
    display: none;
}
[data-layout="twocolumn"] .mini-sidebar .sidebar .sidebar-right {
    display: none;
}
[data-layout="twocolumn"] .mini-sidebar.expand-menu .sidebar .sidebar-right {
    display: block;
}

[data-layout-width="boxed"][data-sidebar-size="md"] .sidebar:hover {
    width: 200px;
}
[data-layout-width="boxed"][data-sidebar-size="md"] .sidebar ul .menu-title {
    display: block !important;
    text-align: center;
}
[data-layout-width="boxed"][data-sidebar-size="md"] .sidebar ul li span {
    display: block !important;
}
[data-layout-width="boxed"][data-sidebar-size="md"] .sidebar ul ul li {
    display: block !important;
}

.logo-color {
    display: none;
}

[data-layout-mode="light"] .header {
    background: #fff;
    border-color: transparent;
}
[data-layout-mode="light"] .header .page-title-box h3 {
    color: #333;
}
[data-layout-mode="light"] .header .logo-color {
    display: block;
}
[data-layout-mode="light"] .header .logo-white {
    display: none;
}
[data-layout-mode="light"] .header .toggle-bars .bar-icons {
    background: #878a99;
}
[data-layout-mode="light"] .header .logo {
    display: none;
}
[data-layout-mode="light"] .header .logo2 {
    display: inline-block;
    line-height: 60px;
}
[data-layout-mode="light"] .header #toggle_btn .bar-icon span {
    background-color: #333;
}
[data-layout-mode="light"] .header .top-nav-search form .form-control {
    border: 1px solid #d5d5d5;
    color: #333;
    height: 40px;
    /*padding: 10px 50px 10px 15px;*/
    /*border-radius: 50px;*/
}
[data-layout-mode="light"]
    .header
    .top-nav-search
    form
    .form-control::-webkit-input-placeholder {
    color: #333;
}
[data-layout-mode="light"]
    .header
    .top-nav-search
    form
    .form-control:-ms-input-placeholder {
    color: #333;
}
[data-layout-mode="light"]
    .header
    .top-nav-search
    form
    .form-control::placeholder {
    color: #333;
}
[data-layout-mode="light"] .header .top-nav-search form .btn {
    color: #333;
}
[data-layout-mode="light"] nav.greedy button {
    color: #333 !important;
}
[data-layout-mode="light"] .sidebar-twocol.sidebar .sidebar-left {
    background: #fff;
    /*border-right:1px solid #d5d5d5;*/
}
[data-layout-mode="light"] .sidebar-twocol.sidebar .sidebar-left .nav-link {
    color: #333;
}
[data-layout-mode="light"]
    .sidebar-twocol.sidebar
    .sidebar-left
    .nav-link.active {
    background: #34444c;
    color: #fff;
}
[data-layout-mode="light"] .sidebar {
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
[data-layout-mode="light"] .sidebar .sidebar-menu ul ul {
    display: none;
    /*width: 200px;*/
}
[data-layout-mode="light"] .sidebar .sidebar-menu ul li a:hover {
    color: #34444c;
}
[data-layout-mode="light"] .sidebar .sidebar-menu .greedy ul li a:hover {
    color: #34444c;
}
[data-layout-mode="light"] .user-menu.nav > li > a {
    color: #333;
}
[data-layout-mode="light"] .mini-sidebar .header-left .logo2 img {
    height: auto;
    max-height: 30px;
    width: auto;
}
[data-layout-mode="light"] .header .header-left .logo {
    display: none;
    line-height: 60px;
}
[data-layout-mode="light"] .sidebar-menu ul li a:hover,
[data-layout-mode="light"] .two-col-bar .sidebar-menu ul li a:hover {
    color: #333;
}

[data-layout-mode="blue"] body .header .user-menu.nav > li > a,
[data-layout-mode="blue"] body .header .user-menu.nav > li > a span,
[data-layout-mode="blue"] body .header .user-content .user-name,
[data-layout-mode="blue"] body .header .user-content .user-details {
    color: #fff;
}
[data-layout-mode="blue"] body .header .has-arrow .dropdown-toggle:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}
[data-layout-mode="blue"] body .header .toggle-bars .bar-icons {
    background: #fff;
}
[data-layout-mode="blue"] body .header .logo-color {
    display: none;
}
[data-layout-mode="blue"] body .header .logo-white {
    display: block;
}

[data-layout-mode="blue"] .nav-pills .nav-link.active,
[data-layout-mode="blue"] .nav-pills .show > .nav-link {
    background: #7539ff;
}
[data-layout-mode="blue"] .btn-primary {
    background-color: #7539ff;
    border: 1px solid #7539ff;
}
[data-layout-mode="blue"] .header {
    background: #7539ff;
    border-color: transparent;
}
[data-layout-mode="blue"] .header .header-left .logo {
    display: block;
    line-height: 60px;
}
[data-layout-mode="blue"] .header .main-logo {
    background: #7539ff;
}
[data-layout-mode="blue"] .header .user-menu.nav > li > a {
    color: #fff;
}
[data-layout-mode="blue"] .header #toggle_btn .bar-icon span {
    background-color: #fff;
}
[data-layout-mode="blue"] .header .page-title-box h3 {
    color: #fff;
}
[data-layout-mode="blue"] .header .logo2 {
    display: none;
}
[data-layout-mode="blue"] .page-item.active .page-link {
    background-color: #7539ff;
    border-color: #7539ff;
}
[data-layout-mode="blue"] .nav-tabs.nav-tabs-solid li a.active {
    background-color: #7539ff;
    border-color: #7539ff;
}
[data-layout-mode="blue"] .sidebar-twocol.sidebar .sidebar-left {
    background: #34444c;
}
[data-layout-mode="blue"]
    .sidebar-twocol.sidebar
    .sidebar-right
    ul
    li
    a.active {
    color: #7539ff;
}
[data-layout-mode="blue"] #layout-position .radio input:checked + label {
    background: #7539ff;
    color: #fff;
}
[data-layout-mode="blue"] .dash-widget .card-body .dash-widget-icon {
    background-color: rgba(0, 197, 251, 0.2);
    color: #7539ff;
}
[data-layout-mode="blue"] .bg-primary,
[data-layout-mode="blue"] .badge-primary {
    background-color: #7539ff !important;
}
[data-layout-mode="blue"] .sidebar .sidebar-menu ul ul a.active {
    color: #7539ff;
    /*text-decoration: $__underline;*/
}
[data-layout-mode="blue"] .sidebar ul li.submenu .noti-dot:before {
    border: 5px solid #7539ff;
}
[data-layout-mode="blue"] .btn-primary {
    background-color: #7539ff;
    border: 1px solid #7539ff;
}
[data-layout-mode="blue"] .roles-menu ul li.active a {
    border-color: #7539ff;
    color: #7539ff;
}
[data-layout-mode="blue"] .settings-icon span {
    background-color: #7539ff;
}

[data-sidebar="gradient"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a:hover
    span,
[data-sidebar="gradient"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a:hover
    i,
[data-sidebar="gradient"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active
    span,
[data-sidebar="gradient"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active
    i,
[data-sidebar="gradient"][data-layout="horizontal"]
    body
    .greedys
    .viewmoremenu:hover {
    color: #fff;
}
[data-sidebar="gradient"][data-layout="horizontal"]
    body
    .greedys
    .viewmoremenu {
    color: #b7c0cd;
}
[data-sidebar="gradient"][data-layout="horizontal"]
    body
    .hidden-links
    ul
    li
    a:hover {
    color: #333;
}

[data-layout-style="detached"][data-layout="horizontal"] .main-wrapper {
    max-width: 100%;
    padding: 0;
}
[data-layout-style="detached"][data-layout="horizontal"]
    .main-wrapper
    .sidebar {
    top: 60px;
    border-radius: 0;
    left: 0;
    position: relative;
}

[data-layout-style="detached"] .main-wrapper {
    /*max-width: 95%;*/
    margin: 0 auto;
    padding-left: 1.5rem;
    position: relative;
}
[data-layout-style="detached"] .main-wrapper .sidebar {
    position: absolute;
    top: 80px;
    border-radius: 10px;
    /*left: 30px;*/
}
[data-layout-style="detached"] .main-wrapper .sidebar.sidebar-twocol {
    top: 30px;
    left: 50px;
}
[data-layout-style="detached"] .sidebar .sidebar-menu,
[data-layout-style="detached"] .two-col-bar .sidebar-menu {
    padding: 0;
}
[data-layout-style="detached"] #toggle_btn {
    display: none;
}

.dark-white-logo {
    display: none !important;
}

[data-layout-mode="dark"] body {
    background-color: #2a2b2f;
    color: #aaaaaa;
}
[data-layout-mode="dark"] body ul.hidden-links {
    background-color: #1c1d22 !important;
}
[data-layout-mode="dark"] body ul.hidden-links .submenu ul {
    background: #2a2b2f;
}
[data-layout-mode="dark"] body .submenu ul {
    /*background: #34444c;*/
}
[data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a:hover,
[data-layout-mode="dark"]
    body
    .notifications
    .notification-list
    li
    .noti-details {
    color: #7539ff;
}
[data-layout-mode="dark"] body .stats-box {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .w-sidebar ul a {
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .card .card-title {
    color: #fff;
}
[data-layout-mode="dark"] body .text-dark {
    color: #aaaaaa !important;
}
[data-layout-mode="dark"] body.mini-sidebar .sidebar {
    background-color: transparent;
}
[data-layout-mode="dark"] body .header .logo {
    display: block;
}
[data-layout-mode="dark"] body .header .main-logo,
[data-layout-mode="dark"] body .header .card.timeline-card {
    background: #1c1d22;
}
[data-layout-mode="dark"] body .header .user-menu.nav > li > a span {
    color: #aaaaaa;
}
[data-layout-mode="dark"]
    body
    .header
    .header
    .has-arrow
    .dropdown-toggle:after {
    border-color: #aaaaaa;
}
[data-layout-mode="dark"] body .btn.btn-greys,
[data-layout-mode="dark"] body .table tbody td a.btn-action-icon {
    background: #37383d;
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .bg-success-light {
    background: rgba(40, 178, 77, 0.1) !important;
}
[data-layout-mode="dark"] body .bg-danger-light,
[data-layout-mode="dark"] body .bg-inactive,
[data-layout-mode="dark"] body .profile-picture .img-upload .btn-remove {
    background: rgba(230, 33, 33, 0.1) !important;
}
[data-layout-mode="dark"] body .bg-info-light {
    background: rgba(221, 243, 255, 0.12);
}
[data-layout-mode="dark"] body .bg-green-light {
    background: rgba(225, 255, 237, 0.12);
}
[data-layout-mode="dark"] body .bg-pending-light,
[data-layout-mode="dark"] body .bg-warning-light {
    background: rgba(237, 153, 29, 0.1) !important;
}
[data-layout-mode="dark"] body .bg-light-gray {
    background: rgba(243, 243, 243, 0.1);
}
[data-layout-mode="dark"] body .ribbon-secondary {
    background: rgba(247, 49, 100, 0.3);
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .ribbon-success {
    background: rgba(81, 187, 37, 0.3);
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .ribbon-primary {
    background: rgba(115, 102, 255, 0.3);
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .settings-icon span {
    background: rgba(117, 57, 255, 0.7);
}
[data-layout-mode="dark"] body h1,
[data-layout-mode="dark"] body h2,
[data-layout-mode="dark"] body h3,
[data-layout-mode="dark"] body h4,
[data-layout-mode="dark"] body h5,
[data-layout-mode="dark"] body h6,
[data-layout-mode="dark"] body .customer-additional-form .input-block label {
    color: #fff;
}
[data-layout-mode="dark"]
    body
    .profile-view
    .profile-basic
    .profile-info-left
    .user-name {
    color: #bbc4cc;
}
[data-layout-mode="dark"] body .personal-info li .title {
    color: #bbc4cc;
}
[data-layout-mode="dark"]
    body
    .experience-box
    .experience-list
    li
    .experience-content {
    /*background-color:#2e3840;*/
    padding: 10px;
}
[data-layout-mode="dark"]
    body
    .experience-box
    .experience-list
    li
    .experience-user {
    border-radius: 50px;
}
[data-layout-mode="dark"] body .sidebar-twocol.sidebar .sidebar-left {
    background: #16191c;
}
[data-layout-mode="dark"] body .sidebar-twocol.sidebar .sidebar-left .nav-link {
    color: #6e82a5;
    /*background: #2e3840;*/
}
[data-layout-mode="dark"]
    body
    .sidebar-twocol.sidebar
    .sidebar-left
    .nav-link.active {
    color: #fff;
    /*background: #fff;*/
}
[data-layout-mode="dark"] body .sidebar-twocol.sidebar .sidebar-right {
    background: #2e3840;
}
[data-layout-mode="dark"] body .sidebar-twocol.sidebar .sidebar-right ul li a {
    color: #aaaaaa;
}
[data-layout-mode="dark"]
    body
    .sidebar-twocol.sidebar
    .sidebar-right
    ul
    li
    a.active {
    color: #fff;
}
[data-layout-mode="dark"] body .dash-statistics .stats-info {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .table {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .table th {
    color: #aaaaaa;
    border-top: 1px solid #3b3c3e;
    background: #1e1f25;
    border-bottom: 1px solid #3b3c3e;
}
[data-layout-mode="dark"] body .table td {
    border-top: 1px solid transparent;
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .table .btn-white {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"]
    body
    .chat-main-row
    .chat-main-wrapper
    .chat-window
    .fixed-header,
[data-layout-mode="dark"] body .chat-footer {
    background-color: #16191c;
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .video-window .fixed-header .nav li a,
[data-layout-mode="dark"]
    body
    .chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block
    .media-body
    > div:first-child
    .user-name,
[data-layout-mode="dark"]
    body
    .chat-window
    .chat-cont-right
    .chat-header
    .chat-block
    .media-body
    .user-name,
[data-layout-mode="dark"] body .invoice-total-box p span,
[data-layout-mode="dark"] body .invoice-total-inner .status-toggle span,
[data-layout-mode="dark"] body .ticket-details-group .ticket-details-cont h6,
[data-layout-mode="dark"] body .ticket-information .customer-details-cont h6,
[data-layout-mode="dark"] body .text-gray-dark,
[data-layout-mode="dark"] body .comments .comments-details-cont h6,
[data-layout-mode="dark"] body .comments .reply-comment {
    color: #fff;
}
[data-layout-mode="dark"] body .sidebar .sidebar-menu ul li a {
    color: #aaaaaa;
    background: transparent;
}
[data-layout-mode="dark"] body .two-col-bar .sidebar-menu ul li a,
[data-layout-mode="dark"] body .dataTables_length label:before,
[data-layout-mode="dark"] body .dataTables_length label:after,
[data-layout-mode="dark"] body .settings-menu ul li a,
[data-layout-mode="dark"] body table tr td h2.tax-name,
[data-layout-mode="dark"] body .notifications .notification-list li .noti-title,
[data-layout-mode="dark"] body .dropdown-item,
[data-layout-mode="dark"] body .badge-outline-dark {
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .dropdown-item:focus,
[data-layout-mode="dark"] body .dropdown-item:hover {
    background: none;
}
[data-layout-mode="dark"] body .sidebar ul li span,
[data-layout-mode="dark"] body .form-group label,
[data-layout-mode="dark"] body .input-block label,
[data-layout-mode="dark"]
    body
    .custom-modal.signature-add-modal
    .modal-body
    .custom_check,
[data-layout-mode="dark"] body .dropdown-item:focus,
[data-layout-mode="dark"] body .dropdown-item:hover,
[data-layout-mode="dark"] body .invoice-item-two .invoice-details {
    color: #fff;
}
[data-layout-mode="dark"] body .contact-list > li {
    background-color: #16191c;
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .btn-white {
    background-color: #16191c;
    border: 1px solid #2e3840;
    color: #fff;
}
[data-layout-mode="dark"] body .page-item .page-link,
[data-layout-mode="dark"] body input[type="file"]::file-selector-button,
[data-layout-mode="dark"] body .popover-body,
[data-layout-mode="dark"] body .login-wrapper .loginbox .login-right,
[data-layout-mode="dark"] body .list-btn .filter-list .short-filter,
[data-layout-mode="dark"] body .price-table-main .price-selected,
[data-layout-mode="dark"] body #add_package .modal-body ul {
    background: #1c1d22;
    border-color: #3b3c3e;
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .login-wrapper .loginbox .span-or,
[data-layout-mode="dark"] body .fc-unthemed th,
[data-layout-mode="dark"] body .fc-unthemed td,
[data-layout-mode="dark"] body .fc-unthemed thead,
[data-layout-mode="dark"] body .fc-unthemed tbody,
[data-layout-mode="dark"] body .fc-unthemed .fc-divider,
[data-layout-mode="dark"] body .fc-unthemed .fc-row,
[data-layout-mode="dark"] body .fc-unthemed .fc-popover {
    background: #1c1d22;
}
[data-layout-mode="dark"] body .login-wrapper .loginbox .or-line {
    background: #3b3c3e;
}
[data-layout-mode="dark"]
    body
    .bs-popover-auto[data-popper-placement^="top"]
    > .popover-arrow::before,
[data-layout-mode="dark"] body .bs-popover-top > .popover-arrow::before,
[data-layout-mode="dark"]
    body
    .bs-popover-auto[data-popper-placement^="top"]
    > .popover-arrow::after,
[data-layout-mode="dark"] body .bs-popover-top > .popover-arrow::after {
    border-top-color: #1c1d22;
    border-right-color: #1c1d22;
}
[data-layout-mode="dark"] body input[type="file"]:hover::file-selector-button {
    background: none !important;
}
[data-layout-mode="dark"] body .dataTables_length .form-control {
    background-color: #16191c;
    color: #fff;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #2c3034;
}
[data-layout-mode="dark"] body .nav-tabs.nav-tabs-solid {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"]
    body
    .select2-container--default
    .select2-selection--single,
[data-layout-mode="dark"] body .signature-invoice .form-group-bank {
    border: 1px solid #3b3c3e;
    background: rgba(193, 193, 193, 0.07);
}
[data-layout-mode="dark"]
    body
    .select2-container--focus
    .select2-selection--single {
    border: 1px solid #3b3c3e;
    background: #1c1d22 !important;
}
[data-layout-mode="dark"]
    body
    .select2-container--default.select2-container--open
    .select2-selection--single
    .select2-selection__arrow
    b,
[data-layout-mode="dark"] body .card-body.two-factor .two-factor.icon h5,
[data-layout-mode="dark"] body .note-editor.note-frame,
[data-layout-mode="dark"] body .scroll-demo,
[data-layout-mode="dark"] body .fc-unthemed th,
[data-layout-mode="dark"] body .fc-unthemed td,
[data-layout-mode="dark"] body .fc-unthemed thead,
[data-layout-mode="dark"] body .fc-unthemed tbody,
[data-layout-mode="dark"] body .fc-unthemed .fc-divider,
[data-layout-mode="dark"] body .fc-unthemed .fc-row,
[data-layout-mode="dark"] body .fc-unthemed .fc-popover {
    border-color: rgba(193, 193, 193, 0.07);
}
[data-layout-mode="dark"] body form label,
[data-layout-mode="dark"] body .form-group-item label {
    color: #fff;
}
[data-layout-mode="dark"] body .select2-dropdown,
[data-layout-mode="dark"] body .card-footer {
    background: #1c1d22;
    border-color: #3b3c3e;
}
[data-layout-mode="dark"] body .recurring-tab ul {
    border: 1px solid #3b3c3e;
}
[data-layout-mode="dark"]
    body
    .modal-body
    .select2-container--default
    .select2-selection--single {
    background-color: transparent;
    border: 1px solid #e3e3e3;
}
[data-layout-mode="dark"] body .activity-box .activity-list li,
[data-layout-mode="dark"] body .inbox-menu li a:hover,
[data-layout-mode="dark"] body .inbox-menu li.active a,
[data-layout-mode="dark"] body .inbox-menu li a:focus {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .inbox-menu li a:hover,
[data-layout-mode="dark"] body .inbox-menu li.active a,
[data-layout-mode="dark"] body .inbox-menu li a:focus {
    border: none;
}
[data-layout-mode="dark"] body .breadcrumb .breadcrumb-item a,
[data-layout-mode="dark"]
    body
    .card-table
    .card-body
    .inventory-table
    .table
    td,
[data-layout-mode="dark"] body page-wrapper .content .page-header .page-title,
[data-layout-mode="dark"] body .toggle-sidebar .sidebar-header h5,
[data-layout-mode="dark"] body .toggle-sidebar .sidebar-header a,
[data-layout-mode="dark"]
    body
    .toggle-sidebar
    .sidebar-body
    .accordion
    .filter-title
    a,
[data-layout-mode="dark"] body .invoice-content-title a,
[data-layout-mode="dark"] body .card-body,
[data-layout-mode="dark"] body .note textarea,
[data-layout-mode="dark"] body .nav-pills .nav-link,
[data-layout-mode="dark"] body #add_package .modal-body ul li h6 {
    color: #aaaaaa;
}
[data-layout-mode="dark"] body #add_package .modal-body ul li h6:hover,
[data-layout-mode="dark"] body #add_package .modal-body ul li h6.active {
    color: #fff;
}
[data-layout-mode="dark"] body .bg-subscribe {
    background: rgba(237, 58, 58, 0.3);
    color: rgba(237, 58, 58, 0.9);
}
[data-layout-mode="dark"] body .bg-payment {
    background: rgba(36, 101, 188, 0.3);
    color: rgba(36, 101, 188, 0.9);
}
[data-layout-mode="dark"] body .leave-info-box {
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .card-header {
    background-color: #16191c;
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"] body .faq-card .card .card-header {
    background-color: #2e3840;
}
[data-layout-mode="dark"] body .faq-card .card .card-header a {
    color: #aaaaaa;
}
[data-layout-mode="dark"] body .custom-table td {
    color: #fff !important;
}
[data-layout-mode="dark"] body .table td a {
    color: #777;
}
[data-layout-mode="dark"] body .sidebar,
[data-layout-mode="dark"] body .wizard .nav-item .nav-link {
    background-color: #16191c;
}
[data-layout-mode="dark"] body .form-group .form-control,
[data-layout-mode="dark"] body .input-block .form-control,
[data-layout-mode="dark"] body .form-group-item,
[data-layout-mode="dark"] body .form-group-customer,
[data-layout-mode="dark"] body .toggle-sidebar .sidebar-header,
[data-layout-mode="dark"] body .toggle-sidebar .sidebar-body .accordion,
[data-layout-mode="dark"] body .card.company-settings-new .content-page-header,
[data-layout-mode="dark"] body .card-footer {
    border-color: #3b3c3e;
}
[data-layout-mode="dark"] body .popover-header {
    background-color: #2a2b2f;
    color: #aaaaaa;
    border-color: #2a2b2f;
}
[data-layout-mode="dark"] body .add-customer-btns .customer-btn-cancel,
[data-layout-mode="dark"] body .modal-footer .btn-back {
    border-color: #ff1717;
    background: rgba(230, 33, 33, 0.1);
    color: #ff1717;
}
[data-layout-mode="dark"] body .btn-path .btn-cancel.bg-primary-light {
    background: rgba(230, 33, 33, 0.1) !important;
    color: #ff1717 !important;
}
[data-layout-mode="dark"] body .btn-primary,
[data-layout-mode="dark"] body .pagination li.active a.page-link,
[data-layout-mode="dark"] body .nav-pills .nav-link.active,
[data-layout-mode="dark"] body .nav-pills .show > .nav-link,
[data-layout-mode="dark"] body .custom_radio .checkmark:after,
[data-layout-mode="dark"] body .custom_radio_one .checkmark:after,
[data-layout-mode="dark"] body #add_package .modal-body ul li h6.active,
[data-layout-mode="dark"] body .file-link .print-link:hover,
[data-layout-mode="dark"] body .file-link .download-link:hover {
    border-color: rgba(117, 57, 255, 0.3);
    background: rgba(117, 57, 255, 0.3);
    color: #7539ff;
}
[data-layout-mode="dark"] body .btn-primary:hover,
[data-layout-mode="dark"] body .pagination li.active a.page-link:hover,
[data-layout-mode="dark"] body .nav-pills .nav-link.active:hover,
[data-layout-mode="dark"] body .nav-pills .show > .nav-link:hover,
[data-layout-mode="dark"] body .custom_radio .checkmark:after:hover,
[data-layout-mode="dark"] body .custom_radio_one .checkmark:after:hover,
[data-layout-mode="dark"] body #add_package .modal-body ul li h6.active:hover,
[data-layout-mode="dark"] body .file-link .print-link:hover:hover,
[data-layout-mode="dark"] body .file-link .download-link:hover:hover {
    box-shadow: inset 0 50px 0 0 #aaaaaa;
    border-color: #aaaaaa;
    color: #000;
}
[data-layout-mode="dark"] body #add_package .modal-body ul li h6:hover {
    border-color: rgba(117, 57, 255, 0.3);
    background: rgba(117, 57, 255, 0.3);
    color: #7539ff;
}
[data-layout-mode="dark"] body .bg-white {
    background: #1c1d22 !important;
}
[data-layout-mode="dark"] body .nav-bordered a.active {
    border-bottom: 2px solid rgba(117, 57, 255, 0.3);
}
[data-layout-mode="dark"] body .btn-warning {
    border-color: rgba(255, 193, 7, 0.3);
    background: rgba(255, 193, 7, 0.3);
    color: #ffc107;
}
[data-layout-mode="dark"] body .btn-light,
[data-layout-mode="dark"] body .navtab-bg .nav-link {
    border-color: rgba(166, 166, 166, 0.3);
    background: rgba(166, 166, 166, 0.3);
    color: #a6a6a6;
}
[data-layout-mode="dark"] body .toggle-sidebar .sidebar-layout-filter {
    background: #16191c;
}
[data-layout-mode="dark"] .view-icons .btn {
    background-color: #16191c;
    border: 1px solid #2e3840;
    color: #aaaaaa;
}
[data-layout-mode="dark"] .header {
    background: #1c1d22;
    border-color: #1c1d22;
}
[data-layout-mode="dark"] .header .user-menu.nav > li > a {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .header #toggle_btn .bar-icon span {
    background-color: #aaaaaa;
}
[data-layout-mode="dark"] .header .page-title-box h3 {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .header .logo2 {
    display: none;
}
[data-layout-mode="dark"] .due-info,
[data-layout-mode="dark"] .assigned-info,
[data-layout-mode="dark"] .activity-feed .feed-item .feed-text a,
[data-layout-mode="dark"] .login-wrapper .loginbox .login-right .dont-have a,
[data-layout-mode="dark"] .invoice-one .add-details,
[data-layout-mode="dark"] .invoice-one .invoice-table table tr td,
[data-layout-mode="dark"]
    .invoice-one
    .invoice-table-footer
    .table-footer-right
    table
    td,
[data-layout-mode="dark"] .invoice-one .customer-name,
[data-layout-mode="dark"] .invoice-one .customer-name,
[data-layout-mode="dark"] .invoice-one .bank-details .terms-condition span,
[data-layout-mode="dark"]
    .invoice-one
    .bank-details
    .payment-info
    .payment-title,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address
    .company-add,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address
    .invoice-date
    h6,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-address-details
    .invoice-to
    .inv-to-address,
[data-layout-mode="dark"] .inv-content .bank-details .company-sign span,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .bank-details
    .account-details
    span,
[data-layout-mode="dark"] .inv-content .bank-details .bank-title,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-left
    span {
    color: #fff;
}
[data-layout-mode="dark"]
    .task-wrapper
    .task-list-body
    #task-list
    li
    .task-container {
    background: #1c1d22;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"]
    .task-wrapper
    .task-list-body
    #task-list
    li
    .task-container
    .task-label {
    color: #aaaaaa;
}
[data-layout-mode="dark"]
    .task-wrapper
    .task-list-body
    #task-list
    li.completed
    .task-container {
    background: #16191c;
}
[data-layout-mode="dark"] .task-chat-contents {
    background-color: #1c1d22;
}
[data-layout-mode="dark"] .welcome-box {
    background-color: #16191c;
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"]
    .chat-contents
    .chat-content-wrap
    .chats
    .chat-right
    .chat-body
    .chat-content {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"]
    .chat-contents
    .chat-content-wrap
    .chats
    .chat-left
    .chat-content {
    background-color: #16191c;
    border: 1px solid #2e3840;
    padding: 10px !important;
}
[data-layout-mode="dark"]
    .chat-contents
    .chat-content-wrap
    .chats
    .chat-left
    .chat-content
    .chat-time {
    color: #fff;
}
[data-layout-mode="dark"] .chat-sidebar .chat-contents {
    background-color: #1c1d22;
}
[data-layout-mode="dark"]
    .chat-footer
    .message-bar
    .message-area
    .input-group
    .form-control {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] .chat-line .chat-date {
    background-color: #16191c;
    top: 9px;
    left: -15px;
}
[data-layout-mode="dark"] .search-box .input-group {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] .chat-main-row .chat-main-wrapper .chat-window {
    background-color: transparent;
}
[data-layout-mode="dark"] .dash-section .dash-info-list .dash-card {
    background-color: #16191c;
    border: 1px solid #2e3840;
    color: #575757;
}
[data-layout-mode="dark"] .card,
[data-layout-mode="dark"] .invoice-one .invoice-wrapper,
[data-layout-mode="dark"] .invoice-two .invoice-wrapper,
[data-layout-mode="dark"] .invoice-wrapper,
[data-layout-mode="dark"] .invoice-five .inv-content,
[data-layout-mode="dark"] .receipt-wrap,
[data-layout-mode="dark"] .receipt-wrap .receipt-top .company-logo,
[data-layout-mode="dark"] .receipt-wrap .receipt-heading span {
    border: 1px solid #16191c;
    background: #16191c;
}
[data-layout-mode="dark"] .file-link .download-link,
[data-layout-mode="dark"] .file-link .print-link {
    background: #16191c;
}
[data-layout-mode="dark"]
    .invoice-one
    .inv-content
    .invoice-header
    .company-details,
[data-layout-mode="dark"] .invoice-two .inv-content .invoice-header,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-address-details
    .invoice-to-payment,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-notes,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-date-no,
[data-layout-mode="dark"]
    .invoice-five
    .inv-content
    .invoice-five-details
    .gradient-block {
    background: rgba(225, 255, 237, 0.3);
}
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-notes {
    border-color: rgba(225, 255, 237, 0.3);
}
[data-layout-mode="dark"] .invoice-one .inv-content .invoice-header {
    background-color: #2a2b2f;
    color: #aaaaaa;
}
[data-layout-mode="dark"] .time-list .dash-stats-list h4 {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .topics,
[data-layout-mode="dark"] .w-sidebar {
    background-color: #16191c;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] .topics .topic-title a,
[data-layout-mode="dark"] .w-sidebar .topic-title a {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .topics .topics .topics-list li a,
[data-layout-mode="dark"] .w-sidebar .topics .topics-list li a {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .roles-menu ul {
    border: 1px solid #16191c;
    background: #16191c;
}
[data-layout-mode="dark"] .roles-menu ul li a {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .list-group-item,
[data-layout-mode="dark"] .activity-box,
[data-layout-mode="dark"] .punch-info .punch-hours,
[data-layout-mode="dark"] .punch-det,
[data-layout-mode="dark"] .att-statistics .stats-info,
[data-layout-mode="dark"] .stats-info {
    border: 1px solid #2e3840;
    background: #16191c;
    color: #aaaaaa;
}
[data-layout-mode="dark"] .form-control,
[data-layout-mode="dark"] .signature-invoice .form-group-bank .nav-pills,
[data-layout-mode="dark"] .service-upload {
    border: 1px solid #3b3c3e;
    background: rgba(193, 193, 193, 0.07);
    color: #aaaaaa;
}
[data-layout-mode="dark"] .form-control {
    font-size: 14px;
}
[data-layout-mode="dark"] .form-control::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #aaaaaa;
    opacity: 1;
}
[data-layout-mode="dark"] .form-control:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaaaaa;
    opacity: 1;
}
[data-layout-mode="dark"] .form-control::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #aaaaaa;
    opacity: 1;
}
[data-layout-mode="dark"] .form-control:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #aaaaaa;
    opacity: 1;
}
[data-layout-mode="dark"] .project-title a {
    color: #fff;
}
[data-layout-mode="dark"]
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #aaaaaa;
    background: #1c1d22;
}
[data-layout-mode="dark"] .profile-widget {
    border: 1px solid #2e3840;
    background: #16191c;
}
[data-layout-mode="dark"] .profile-widget .user-name a {
    color: #fff;
}
[data-layout-mode="dark"] .stats-info h6 {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .modal-body .form-control {
    border-color: #e3e3e3;
    box-shadow: none;
    background-color: transparent;
    color: #aaaaaa;
}
[data-layout-mode="dark"] .chat-main-row .chat-main-wrapper .chat-sidebar {
    border-left: 1px solid #2e3840;
}
[data-layout-mode="dark"] .nav-tabs {
    border-bottom: none;
}
[data-layout-mode="dark"] .chat-line {
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"] .file-wrap .file-sidebar .file-header {
    background-color: #16191c;
    border-bottom: 1px solid #2e3840;
    color: #aaaaaa;
}
[data-layout-mode="dark"] .file-wrap .file-sidebar .file-pro-list {
    background-color: #16191c;
}
[data-layout-mode="dark"]
    .file-wrap
    .file-sidebar
    .file-pro-list
    .file-scroll
    .file-menu
    li
    a {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .file-cont-wrap .file-cont-inner .file-cont-header {
    background-color: #16191c;
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"]
    .file-cont-wrap
    .file-cont-inner
    .file-cont-header
    span {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .file-content .file-body {
    background-color: #16191c;
}
[data-layout-mode="dark"]
    .file-content
    .file-body
    .file-scroll
    .file-content-inner {
    padding: 15px;
    width: 100%;
}
[data-layout-mode="dark"]
    .file-content
    .file-body
    .file-scroll
    .file-content-inner
    .card-file
    .card-file-thumb {
    background-color: #1c1d22;
}
[data-layout-mode="dark"] .file-content .file-search {
    background-color: #1c1d22;
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"] .file-content .file-search .form-control {
    background-color: #1c1d22;
    color: #aaaaaa;
}
[data-layout-mode="dark"] .file-wrap .file-sidebar .file-search {
    background-color: #1c1d22;
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"] .file-wrap .file-sidebar .file-search .form-control {
    background-color: #16191c;
}
[data-layout-mode="dark"]
    .file-cont-wrap
    .file-cont-inner
    .file-cont-header
    .file-options
    a {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .file-wrap {
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] .file-wrap .file-sidebar {
    border-right: 1px solid #2e3840;
}
[data-layout-mode="dark"]
    .file-wrap
    .file-sidebar
    .file-search
    .input-group
    .form-control {
    color: #aaaaaa;
    background-color: #1c1d22;
}
[data-layout-mode="dark"] .table-bordered td {
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] .nav-tabs.nav-tabs-bottom li a.active {
    color: #fff;
}
[data-layout-mode="dark"] .offcanvas {
    background-color: #1c1d22;
}
[data-layout-mode="dark"] .offcanvas .sidebar-headerset {
    border-bottom: 1px solid #2e3840;
}
[data-layout-mode="dark"] .offcanvas h5 {
    color: #ced4da;
}
[data-layout-mode="dark"] .offcanvas .settings-mains .layout-head h5 {
    color: #ced4da;
}
[data-layout-mode="dark"] .offcanvas .settings-mains .layout-head h6 {
    color: #ced4da;
}
[data-layout-mode="dark"] .offcanvas .sidebar-headerset h2,
[data-layout-mode="dark"] .offcanvas .sidebar-headerset h3 {
    color: #ced4da;
}
[data-layout-mode="dark"] .offcanvas .card-radio .form-check-label {
    border-color: #ced4da;
}
[data-layout-mode="dark"] .offcanvas .offcanvas-footer {
    border-top: 1px solid #2e3840 !important;
}
[data-layout-mode="dark"] .review-section .review-header {
    background-color: #1c1d22;
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] .table-bordered th,
[data-layout-mode="dark"] .dropdown-menu {
    border: 1px solid #2e3840;
}
[data-layout-mode="dark"] .chat-window,
[data-layout-mode="dark"] .chat-window .chat-cont-left,
[data-layout-mode="dark"] .chat-cont-right,
[data-layout-mode="dark"] .chat-window .chat-cont-left .chat-header,
[data-layout-mode="dark"] .chat-window .chat-cont-right .chat-header,
[data-layout-mode="dark"] .chat-window .chat-cont-right .chat-body,
[data-layout-mode="dark"] .chat-window .chat-cont-left .chat-search,
[data-layout-mode="dark"]
    .chat-window
    .chat-cont-left
    .chat-users-list
    a.chat-block,
[data-layout-mode="dark"] .dropdown-menu,
[data-layout-mode="dark"] .mail-provider,
[data-layout-mode="dark"] #plan-billing-slider .owl-item .packages.active,
[data-layout-mode="dark"] .form-select,
[data-layout-mode="dark"] .form-group .form-control,
[data-layout-mode="dark"] .input-block .form-control,
[data-layout-mode="dark"] .input-group-text,
[data-layout-mode="dark"] .blog,
[data-layout-mode="dark"] .bootstrap-tagsinput,
[data-layout-mode="dark"] .custom_check .checkmark,
[data-layout-mode="dark"] .checkmark,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .thanks-msg,
[data-layout-mode="dark"] .chat-cont-left .chat-scroll {
    background: #1c1d22;
    border-color: #3b3c3e;
    color: #ced4da;
}
[data-layout-mode="dark"]
    body
    .modal-body
    .select2-container--default
    .select2-selection--single,
[data-layout-mode="dark"]
    .card.company-settings-new
    .input-block.service-upload.logo-upload
    .sites-logo,
[data-layout-mode="dark"] .template-invoice-card .invoice-card-title h6,
[data-layout-mode="dark"] .invoice-template-tab.invoices-main-tabs,
[data-layout-mode="dark"] .invoices-main-tabs,
[data-layout-mode="dark"] .blog,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-address,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-address
    .invoice-details-content
    .invoice-status-details {
    border-color: #3b3c3e;
}
[data-layout-mode="dark"] .notifications .notification-list li {
    box-shadow: 0px 1px 0px 0px #3b3c3e;
}
[data-layout-mode="dark"] #plan-billing-slider .owl-item .packages,
[data-layout-mode="dark"] .grid-blog .post-widget,
[data-layout-mode="dark"] .nav-tabs .nav-link.active {
    background: #2a2b2f;
    border-color: #3b3c3e;
}
[data-layout-mode="dark"]
    .chat-window
    .chat-cont-right
    .chat-header
    .chat-block
    .media-body
    .user-status,
[data-layout-mode="dark"] .dash-widget-header .dash-count .dash-counts p,
[data-layout-mode="dark"] .user-menu .dropdown-menu .dropdown-item,
[data-layout-mode="dark"] .user-content .user-name {
    color: #ced4da;
    background: #1c1d22;
}
[data-layout-mode="dark"] .card-table .card-body .table td,
[data-layout-mode="dark"] .table tbody td h2.table-avatar a:hover,
[data-layout-mode="dark"] .card-table .companies-table .table td,
[data-layout-mode="dark"] quotations.html,
[data-layout-mode="dark"] .notifications .notification-list li blockquote,
[data-layout-mode="dark"] .service-upload .drop-browse,
[data-layout-mode="dark"] .grid-blog .blog-title a,
[data-layout-mode="dark"] .grid-blog .post-widget,
[data-layout-mode="dark"] .card {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .post-title {
    color: #7539ff;
}
[data-layout-mode="dark"] .companies-table .table tbody td h2.table-avatar a,
[data-layout-mode="dark"] .card-table .card-body .table th,
[data-layout-mode="dark"] table.dataTable > thead .sorting:before,
[data-layout-mode="dark"] table.dataTable > thead .sorting_asc:before,
[data-layout-mode="dark"] table.dataTable > thead .sorting_desc:before,
[data-layout-mode="dark"] table.dataTable > thead .sorting_asc_disabled:before,
[data-layout-mode="dark"] table.dataTable > thead .sorting_desc_disabled:before,
[data-layout-mode="dark"] .table tbody td h2.table-avatar a,
[data-layout-mode="dark"] .nav-tabs .nav-link.active,
[data-layout-mode="dark"] .customer-text-one,
[data-layout-mode="dark"]
    .activity-feed
    .feed-item.timeline-item
    span.timeline-user
    a,
[data-layout-mode="dark"] .invoice-sign span,
[data-layout-mode="dark"] .ticket-information .support-details-cont h6,
[data-layout-mode="dark"] .ticket-history .card-inform .ticket-info h6,
[data-layout-mode="dark"]
    .kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .kanban-ticket
    .kanban-head
    h6,
[data-layout-mode="dark"]
    .kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    h6,
[data-layout-mode="dark"] .inv-content .invoice-table table tr td,
[data-layout-mode="dark"] .inv-content .invoice-table table tr th,
[data-layout-mode="dark"] .inv-content .total-amountdetails span,
[data-layout-mode="dark"] .invoice-one .bank-details .payment-info span,
[data-layout-mode="dark"] .invoice-one .bank-details .payment-info div,
[data-layout-mode="dark"] .invoice-one .thanks-msg,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-header
    .inv-header-left
    h2,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-table-details
    table
    thead
    tr
    th,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-table-details
    table
    tr
    .table-description,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-table-details
    table
    tr
    td,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    td,
[data-layout-mode="dark"] .invoice-two .inv-content .thanks-msg,
[data-layout-mode="dark"] .invoice-two .inv-content .two-invoice-details h5,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .bank-details
    .payment-info
    .debitcard,
[data-layout-mode="dark"] .invoice-two .inv-content .terms-condition span,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .bank-details
    .payment-info
    .payment-title,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .invoice-title,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details
    span,
[data-layout-mode="dark"]
    .inv-content
    .invoice-address
    .invoice-to
    .inv-to-address,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table
    table
    thead
    tr
    th,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table
    table
    td,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    tr
    td,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-header-left
    .tax-invoice,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-date-no,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .invoice-title,
[data-layout-mode="dark"]
    .inv-content
    .invoice-header
    .inv-header-right
    .inv-date
    span,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to
    .inv-to-address,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to-payment
    span,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    tr
    .table-description,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    thead
    tr
    th,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    td,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .total-amountdetails
    span,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    .payment-title,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    span,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-header-left
    .tax-invoice,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .terms-condition
    span,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    .debitcard,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .thanks-msg,
[data-layout-mode="dark"] .invoice-five .terms-condition ol li,
[data-layout-mode="dark"]
    .invoice-five
    .inv-content
    .invoice-header
    .inv-header-right
    h2,
[data-layout-mode="dark"]
    .invoice-five
    .inv-content
    .invoice-five-details
    .invoice-date
    .invoice-date-details,
[data-layout-mode="dark"]
    .company-booking-address.company-five-address
    .company-add
    h4,
[data-layout-mode="dark"]
    .company-booking-address.company-five-address
    .invoice-date
    p,
[data-layout-mode="dark"]
    .invoice-address-details.invoice-five-group
    .invoice-to
    .inv-to-address,
[data-layout-mode="dark"]
    .invoice-five
    .invoice-table-details
    table
    tr
    .table-description,
[data-layout-mode="dark"] .invoice-five .invoice-table-details table tr td,
[data-layout-mode="dark"]
    .invoice-five
    .invoice-table-footer
    .table-footer-right
    table
    td,
[data-layout-mode="dark"] .total-amt-word tr td span,
[data-layout-mode="dark"] .inv-content .bank-details .account-details span,
[data-layout-mode="dark"]
    .invoice-five
    .invoice-table-details
    table
    thead
    tr
    th,
[data-layout-mode="dark"] .receipt-wrap .receipt-top .company-name,
[data-layout-mode="dark"] .receipt-wrap .receipt-heading,
[data-layout-mode="dark"] .receipt-wrap .customer-list li .desc,
[data-layout-mode="dark"] .receipt-wrap .receipt-table thead th,
[data-layout-mode="dark"] .receipt-wrap .total-payable,
[data-layout-mode="dark"] .login-wrapper .loginbox .account-subtitle,
[data-layout-mode="dark"] .receipt-three .receipt-top .receipt-title,
[data-layout-mode="dark"] .receipt-four .receipt-top .receipt-title {
    color: #fff;
}
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .triangle-right {
    border-left: 58px solid #16191c;
}
[data-layout-mode="dark"] .invoice-one .gst-details h6,
[data-layout-mode="dark"] .invoice-one .inv-header-right h6,
[data-layout-mode="dark"]
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-right
    p
    span {
    color: #878a99;
}
[data-layout-mode="dark"]
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-right
    p
    span {
    background: rgba(225, 255, 237, 0.3);
    color: #fff;
}
[data-layout-mode="dark"] .invoice-one .inv-content span.line {
    background: rgba(225, 255, 237, 0.3);
}
[data-layout-mode="dark"] .inv-content .invoice-header {
    border-bottom: rgba(225, 255, 237, 0.3);
}
[data-layout-mode="dark"] .list-btn .btn-filters {
    background: #36373d;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
    border-color: transparent;
}
[data-layout-mode="dark"] .list-btn .filter-list li a.active,
[data-layout-mode="dark"] .btn-import {
    border-color: #7539ff;
}
[data-layout-mode="dark"] .btn-import {
    color: #7539ff;
    background: transparent;
}
[data-layout-mode="dark"] .btn-import:hover {
    background: #7539ff;
}
[data-layout-mode="dark"] .card-table .card-body .table td,
[data-layout-mode="dark"] .invoice-one .invoice-table table tr td,
[data-layout-mode="dark"] .invoice-one .bank-details,
[data-layout-mode="dark"] .invoice-one .thanks-msg,
[data-layout-mode="dark"] .invoice-one .total-amountdetails,
[data-layout-mode="dark"] .invoice-two .inv-content .invoice-header,
[data-layout-mode="dark"] .invoice-two .inv-content .invoice-header,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-table-details
    table
    tr,
[data-layout-mode="dark"] .invoice-two .inv-content .thanks-msg,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    tr
    td,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table
    table
    tr,
[data-layout-mode="dark"] .inv-content .bank-details,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    tr,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .total-amountdetails,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details,
[data-layout-mode="dark"] .invoice-five .invoice-table-details table tr {
    border-bottom: 1px solid #3b3c3e;
}
[data-layout-mode="dark"] .invoice-two .inv-content .thanks-msg {
    border-top: 1px solid #3b3c3e;
}
[data-layout-mode="dark"] .invoice-one .totalamount-footer,
[data-layout-mode="dark"] .invoice-two .inv-content .totalamount-footer,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .totalamount-footer {
    border-color: #3b3c3e;
}
[data-layout-mode="dark"] .invoice-one .inv-content,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-table-details
    table,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table
    table,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four,
[data-layout-mode="dark"] .invoice-five .invoice-table-details table {
    border: 1px solid #3b3c3e;
}
[data-layout-mode="dark"] .invoice-one h5,
[data-layout-mode="dark"] .inv-content .invoice-table table thead tr,
[data-layout-mode="dark"] .invoice-one .thanks-msg,
[data-layout-mode="dark"]
    .invoice-two
    .inv-content
    .invoice-table-details
    table
    thead
    tr,
[data-layout-mode="dark"] .invoice-two .inv-content .thanks-msg,
[data-layout-mode="dark"] .invoice-two .file-link .download-link,
[data-layout-mode="dark"] .invoice-two .file-link .print-link,
[data-layout-mode="dark"]
    .invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table
    table
    thead
    tr,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    thead
    tr,
[data-layout-mode="dark"]
    .invoice-five
    .invoice-table-details
    table
    .ecommercetable {
    background: #2a2b2f;
}
[data-layout-mode="dark"] .table-hover tbody tr:hover {
    background: none;
}
[data-layout-mode="dark"] .user-menu .dropdown-menu .dropdown-item:hover {
    color: #fff;
    background: none;
}
[data-layout-mode="dark"] .status-toggle .check:checked + .checktoggle {
    background-color: rgba(85, 206, 99, 0.3);
    border: 1px solid rgba(85, 206, 99, 0.3);
}
[data-layout-mode="dark"]
    .chat-window
    .chat-cont-left
    .chat-search
    .input-group
    .form-control,
[data-layout-mode="dark"] .modal-content,
[data-layout-mode="dark"] .card .card-body,
[data-layout-mode="dark"] .top-action-left .dropdown-toggle,
[data-layout-mode="dark"] .btn-white-outline,
[data-layout-mode="dark"] .card.timeline-card {
    background: #1e1f25;
}
[data-layout-mode="dark"] .inbox-menu li a,
[data-layout-mode="dark"] .product-list-item-img span {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .top-action-left .dropdown-toggle,
[data-layout-mode="dark"] .btn-white-outline {
    border-color: #3b3c3e;
    color: #aaaaaa;
}
[data-layout-mode="dark"] .pagination .paginate_button.next .page-link,
[data-layout-mode="dark"] .pagination .paginate_button.previous .page-link {
    background: rgba(193, 193, 193, 0.07);
    border: 1px solid #3b3c3e;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
    color: #aaaaaa;
}
[data-layout-mode="dark"] .setting-menu {
    color: #fff !important;
}
[data-layout-mode="dark"] .card.timeline-card,
[data-layout-mode="dark"] .invoice-item-date,
[data-layout-mode="dark"] .invoice-item-two,
[data-layout-mode="dark"] .activity-feed .feed-item,
[data-layout-mode="dark"]
    .kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban,
[data-layout-mode="dark"]
    .kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .ticket-due,
[data-layout-mode="dark"] .blog,
[data-layout-mode="dark"]
    .invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to-payment {
    border-color: #3b3c3e;
}
[data-layout-mode="dark"] .blog-image img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
[data-layout-mode="dark"] .invoice-terms .invoice-terms-icon {
    background: rgba(239, 239, 239, 0.12);
}
[data-layout-mode="dark"] .dark-white-logo {
    display: inline-block !important;
}
[data-layout-mode="dark"] .light-color-logo {
    display: none !important;
}
[data-layout-mode="dark"] .invoice-item .invoice-details strong {
    color: #878a99;
}
[data-layout-mode="dark"] .packages.package-selected {
    background: rgba(115, 102, 255, 0.3);
}
[data-layout-mode="dark"]
    .chat-cont-right
    ul.list-unstyled
    .chat-block.received
    .media-body
    > div,
[data-layout-mode="dark"]
    .chat-window
    .chat-cont-right
    .chat-body
    .chat-block.received
    .media-body
    .msg-box
    > div,
[data-layout-mode="dark"] .chat-window .chat-cont-right .chat-footer,
[data-layout-mode="dark"]
    .chat-window
    .chat-cont-right
    .chat-footer
    .input-group
    .form-control,
[data-layout-mode="dark"] .form-group-bank,
[data-layout-mode="dark"] .description-box .note-frame,
[data-layout-mode="dark"] .form-group .note-editable,
[data-layout-mode="dark"] .input-block .note-editable,
[data-layout-mode="dark"] .note-toolbar,
[data-layout-mode="dark"] .form-group .note-toolbar,
[data-layout-mode="dark"] .input-block .note-toolbar,
[data-layout-mode="dark"] .note-btn,
[data-layout-mode="dark"] .ticket-information .support-details,
[data-layout-mode="dark"]
    .kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban {
    background: #2a2b2f;
}
[data-layout-mode="dark"]
    .chat-window
    .chat-cont-right
    .chat-body
    .chat-block
    .media-body
    .msg-box
    > div
    p,
[data-layout-mode="dark"] .chat-time span,
[data-layout-mode="dark"] .filter-buttons button.btn-secondary,
[data-layout-mode="dark"] .note-btn {
    color: #8a8a8a;
}
[data-layout-mode="dark"]
    .chat-window
    .chat-cont-right
    .chat-body
    .chat-block.received
    .media-body
    .msg-box:first-child:before {
    border-right: 6px solid #2a2b2f;
}
[data-layout-mode="dark"] .chat-window .chat-cont-right .chat-footer,
[data-layout-mode="dark"] .form-group-bank,
[data-layout-mode="dark"] .note-btn,
[data-layout-mode="dark"] .note-toolbar,
[data-layout-mode="dark"] .ticket-information .support-details {
    border-color: #3b3c3e;
}
[data-layout-mode="dark"] .card-support {
    border: none;
}
[data-layout-mode="dark"] .invoices-main-tabs {
    padding-top: 12px;
    padding-left: 12px;
}
[data-layout-mode="dark"] .recurring-tab ul li button.active {
    background: rgba(115, 102, 255, 0.3) !important;
}
[data-layout-mode="dark"] .badge-gray-outline {
    background: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
    color: #fff;
}
[data-layout-mode="dark"] .filter-buttons button.btn-secondary {
    /*background: $__white__rgba; */
    /*border-color: $__white__rgba; */
}
[data-layout-mode="dark"] .filter-buttons button.btn-secondary:hover {
    box-shadow: inset 0 50px 0 0 rgba(117, 57, 255, 0.3);
}
[data-layout-mode="dark"] .ticket-information .support-widget-icon,
[data-layout-mode="dark"] .ticket-information .customer-widget-icon,
[data-layout-mode="dark"] .ticket-information .support-details .btn-action-icon,
[data-layout-mode="dark"] .activity-feed .feed-item:before,
[data-layout-mode="dark"]
    .ticket-history
    .card-inform
    .ticket-info
    .ticket-upload {
    background: rgba(255, 255, 255, 0.3);
}
[data-layout-mode="dark"]
    .ticket-history
    .card-inform
    .ticket-info
    .ticket-upload {
    border-color: rgba(255, 255, 255, 0.3);
}
[data-layout-mode="dark"]
    .ticket-history
    .card-inform
    .ticket-info
    .ticket-upload:hover {
    background: rgba(117, 57, 255, 0.3);
    border-color: rgba(117, 57, 255, 0.3);
}

.status-toggle .checktoggle {
    transform: none;
}

[data-topbar="light"][data-layout-mode="dark"] body .logo-dark,
[data-topbar="light"][data-layout-mode="dark"] body .logo-lightmode {
    display: none;
}
[data-topbar="light"][data-layout-mode="dark"] body .logo-light,
[data-topbar="light"][data-layout-mode="dark"] body .logo-darkmode {
    display: inline-block;
}
[data-topbar="light"][data-layout-mode="dark"] body .header .main-logo {
    background: #1c1d22;
}
[data-topbar="light"][data-layout-mode="dark"] body .header .logo-color {
    display: none;
}
[data-topbar="light"][data-layout-mode="dark"] body .header .logo-white {
    display: block;
}
[data-topbar="light"][data-layout-mode="dark"]
    body
    .header
    .toggle-bars
    .bar-icons {
    background: #aaaaaa;
}
[data-topbar="light"][data-layout-mode="dark"] body .header .logo {
    display: none;
}
[data-topbar="light"][data-layout-mode="dark"] body .header .logo2 {
    display: inline-block;
    line-height: 60px;
}
[data-topbar="light"][data-layout-mode="dark"]
    body
    .header
    .user-menu.nav
    > li
    > a
    span {
    color: #aaaaaa;
}
[data-topbar="light"][data-layout-mode="dark"]
    body
    .header
    .has-arrow
    .dropdown-toggle:after {
    border-color: #aaaaaa;
}
[data-topbar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul.sidebar-vertical
    .menu-title
    span {
    color: #fff;
}
[data-topbar="light"][data-layout-mode="dark"]
    body
    .user-menu.nav
    .dropdown-heads
    > a,
[data-topbar="light"][data-layout-mode="dark"]
    body
    .customer-details-group
    .customer-details
    .customer-widget-icon
    i {
    background: #28292d;
}
[data-topbar="light"][data-layout-mode="dark"]
    body
    .user-menu.nav
    .dropdown-heads
    > a
    i {
    color: #aaaaaa;
}
[data-topbar="light"][data-layout-mode="dark"] body .signature-invoice {
    background: #1e1f25;
}

[data-topbar="light"][data-layout-mode="dark"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active
    i,
[data-topbar="light"][data-layout-mode="dark"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a:hover
    span.menu-arrow,
[data-topbar="light"][data-layout-mode="dark"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a:hover
    i {
    /*color: $__primarycolor;  */
}
[data-topbar="light"][data-layout-mode="dark"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul {
    background: #1c1d22;
    border-color: #3b3c3e;
}
[data-topbar="light"][data-layout-mode="dark"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a:hover {
    background: #1c1d22;
}

[data-sidebar="light"][data-layout-mode="dark"] body #toggle_btn {
    color: #aaaaaa;
}
[data-sidebar="light"][data-layout-mode="dark"] body .header {
    background: #1c1d22;
    border-color: #1c1d22;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .header
    .top-nav-search
    form
    .form-control {
    background: rgba(193, 193, 193, 0.07);
    border: 1px solid #3b3c3e;
    color: #aaaaaa;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .header
    .top-nav-search
    form
    .form-control::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #aaaaaa;
    opacity: 1;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .header
    .top-nav-search
    form
    .form-control:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaaaaa;
    opacity: 1;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .header
    .top-nav-search
    form
    .form-control::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #aaaaaa;
    opacity: 1;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .header
    .top-nav-search
    form
    .form-control:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #aaaaaa;
    opacity: 1;
}
[data-sidebar="light"][data-layout-mode="dark"] body .sidebar {
    background-color: #1c1d22;
}
[data-sidebar="light"][data-layout-mode="dark"] body .sidebar .sidebar-menu {
    background-color: #1c1d22;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    a.active
    i {
    color: #7539ff;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    a.active
    span {
    color: #7539ff;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    a.active
    span.menu-arrow {
    color: #7539ff;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a
    span {
    color: #aaaaaa;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    ul
    li
    a {
    background: #1c1d22;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a:hover {
    background: transparent;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    .greedy
    ul
    li
    a:hover,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    > li
    > a:hover,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a:hover
    span,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a.active,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a:hover
    i,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active
    i {
    color: #7539ff;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a.active:after {
    background: #fff;
    border: 1px solid #fff;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active::before,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active::after,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active::before,
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active::before {
    background: #7539ff;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    .greedys
    .viewmoremenu {
    color: #b7c0cd;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    .greedys
    .viewmoremenu:hover {
    color: #fff;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    .greedys
    .hidden-links
    li
    a:hover {
    color: #7539ff;
}
[data-sidebar="light"][data-layout-mode="dark"]
    body
    .sidebar
    .sidebar-menu
    .greedys
    .hidden-links
    li
    a:hover
    span {
    color: #7539ff;
}

[data-layout-mode="light"][data-sidebar="dark"] nav.greedy button {
    color: #fff !important;
}

[data-sidebar="gradient"] .sidebar {
    background: linear-gradient(92.08deg, #ff0000 0%, #764ba2 100%);
}
[data-sidebar="gradient"] .sidebar .sidebar-menu ul li ul li a {
    background: none;
}
[data-sidebar="gradient"] .sidebar .sidebar-menu ul li a.active::before {
    display: none;
}
[data-sidebar="gradient"]
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a.active:after,
[data-sidebar="gradient"] .sidebar .sidebar-menu ul li a.active::after {
    background: #fff;
}
[data-sidebar="gradient"]
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a.active:after {
    border: 1px solid #fff;
}
[data-sidebar="gradient"] .sidebar .sidebar-menu ul li a,
[data-sidebar="gradient"] .sidebar .sidebar-menu ul li a span {
    color: #b7c0cd;
}
[data-sidebar="gradient"] .sidebar .sidebar-menu ul li.submenu ul li a.active,
[data-sidebar="gradient"] .sidebar .sidebar-menu ul li a:hover,
[data-sidebar="gradient"] .sidebar .sidebar-menu ul li a:hover span {
    color: #fff;
}
[data-sidebar="gradient"] .sidebar-twocol.sidebar .sidebar-left {
    background: linear-gradient(92.08deg, #ff0000 0%, #764ba2 100%);
}
[data-sidebar="gradient"]
    .sidebar-twocol.sidebar
    .sidebar-right
    ul
    li
    a.active {
    color: #405189;
}

[data-sidebar="gradient-2"] .sidebar {
    background: linear-gradient(to right, #7539ff 0%, #0253cc 100%);
}
[data-sidebar="gradient-2"] .sidebar-twocol.sidebar .sidebar-left {
    background: linear-gradient(to right, #7539ff 0%, #0253cc 100%);
}
[data-sidebar="gradient-2"]
    .sidebar-twocol.sidebar
    .sidebar-right
    ul
    li
    a.active {
    color: #7539ff;
}
[data-sidebar="gradient-2"] #layout-position .radio input:checked + label {
    background: #7539ff;
    color: #fff;
}

[data-sidebar="gradient-3"] .sidebar {
    background: linear-gradient(to right, #ff737b 0%, #453a94 100%);
}
[data-sidebar="gradient-3"] .sidebar-twocol.sidebar .sidebar-left {
    background: linear-gradient(to right, #ff737b 0%, #453a94 100%);
}
[data-sidebar="gradient-3"]
    .sidebar-twocol.sidebar
    .sidebar-right
    ul
    li
    a.active {
    color: #ff737b;
}
[data-sidebar="gradient-3"] #layout-position .radio input:checked + label {
    background: #ff737b;
    color: #fff;
}

[data-sidebar="gradient-4"] .sidebar {
    background: linear-gradient(to right, #1ec1b0 0%, #764ba2 100%);
}
[data-sidebar="gradient-4"] .sidebar-twocol.sidebar .sidebar-left {
    background: linear-gradient(to right, #1ec1b0 0%, #764ba2 100%);
}
[data-sidebar="gradient-4"]
    .sidebar-twocol.sidebar
    .sidebar-right
    ul
    li
    a.active {
    color: #1ec1b0;
}
[data-sidebar="gradient-4"] #layout-position .radio input:checked + label {
    background: #1ec1b0;
    color: #fff;
}

[data-sidebar-size="sm-hover"] [data-layout="twocolumn"] .page-wrapper {
    margin: 0;
}
[data-sidebar-size="sm-hover"]
    [data-layout="twocolumn"]
    .page-wrapper
    .sidebar
    .sidebar-left {
    display: block;
}
[data-sidebar-size="sm-hover"]
    [data-layout="twocolumn"]
    .page-wrapper
    .sidebar
    .sidebar-right {
    display: none;
}

/*[data-layout=horizontal] {
    body .sidebar .sidebar-menu ul li a:hover span, body .sidebar .sidebar-menu ul li a.subdrop span, body .sidebar .sidebar-menu ul li a.active span {
        color: $__white;
    }
}*/
[data-layout="horizontal"] body .header {
    box-shadow: none;
}
[data-layout="horizontal"] body .sidebar {
    width: 100% !important;
    bottom: inherit;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a:hover {
    background: none;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li span,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li i,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a .menu-arrow {
    display: inline-block !important;
    color: #878a99;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a span.badge {
    color: #fff;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.subdrop span,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.subdrop i,
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.subdrop
    .menu-arrow {
    color: #7539ff;
}
[data-layout="horizontal"] body .sidebar .slimScrollDiv,
[data-layout="horizontal"] body .sidebar .sidebar-inner {
    height: auto !important;
    overflow: inherit !important;
}
[data-layout="horizontal"] body .sidebar .hidden {
    display: none !important;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu {
    padding: 0 !important;
    height: 60px;
    display: flex;
    align-items: center;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.active::before {
    display: none;
}
[data-layout="horizontal"] body .sidebar .sidebar-vertical {
    display: none !important;
}
[data-layout="horizontal"] body .sidebar .sidebar-horizantal {
    display: flex !important;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a {
    flex-direction: inherit;
    text-align: left;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a:hover,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.subdrop,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.active {
    color: #7539ff;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a:hover i,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.subdrop i,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.active i {
    color: #7539ff;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a:hover span,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.subdrop span,
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a.active span {
    color: #7539ff;
}
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a:hover
    span.menu-arrow,
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.subdrop
    span.menu-arrow,
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active
    span.menu-arrow {
    color: #7539ff;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a:hover span.badge,
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.subdrop
    span.badge,
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active
    span.badge {
    color: #fff;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu .menu-title {
    display: none !important;
}
[data-layout="horizontal"] body .sidebar ul li span {
    display: inline-block !important;
}
[data-layout="horizontal"] body .sidebar .hidden-links .submenu ul {
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid #d5d5d5;
    max-height: 200px;
    /*overflow-y: auto; */
    overflow-y: inherit;
}
[data-layout="horizontal"] body .sidebar .hidden-links li a:hover {
    color: #333;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul.links > li > a {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
}
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul.links
    > li
    > a
    .menu-arrow {
    top: 21px;
}
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    .noti-dot:before {
    display: none;
}
[data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a:hover {
    color: #7539ff;
    background: #fff;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a .menu-arrow {
    right: 0;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul ul {
    display: none;
    width: 200px;
    background-color: #1c1d22;
    position: absolute;
    top: 60px;
    left: 0;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul ul li a .menu-arrow {
    right: 15px;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul ul a {
    padding: 8px 15px;
}
[data-layout="horizontal"] body .sidebar .list-inline-item li a:hover {
    color: #fff;
}
[data-layout="horizontal"] body #sidebar-view,
[data-layout="horizontal"] body #sidebar-size,
[data-layout="horizontal"] body #layout-width,
[data-layout="horizontal"] body #layout-position {
    display: none;
}
[data-layout="horizontal"] body #toggle_btn {
    display: none;
}
[data-layout="horizontal"] body ul.hidden-links {
    position: absolute !important;
    right: 0;
    background: #fff;
    width: 100%;
    top: 60px;
    justify-content: flex-start;
    align-items: start !important;
    flex-wrap: wrap;
    border-top: 1px dashed #e3e3e3;
    padding: 12px 3px;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
}
[data-layout="horizontal"] body ul.hidden-links ul {
    left: 0 !important;
    position: absolute !important;
    top: 40px !important;
    background-color: #fff !important;
    width: 100% !important;
    z-index: 9999;
}
[data-layout="horizontal"] body ul.hidden-links ul li a {
    width: 100%;
}
[data-layout="horizontal"] body ul.hidden-links ul li a:hover {
    color: #333;
}
[data-layout="horizontal"] body ul.hidden-links > li {
    width: 20%;
}
[data-layout="horizontal"] body ul.hidden-links > li a {
    display: block;
    padding: 5px 15px;
}
[data-layout="horizontal"] body ul.hidden-links > li a:hover {
    background: none;
}
[data-layout="horizontal"] body ul.hidden-links li a .menu-arrow {
    right: 10px !important;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul li a {
    padding: 10px 15px;
}
[data-layout="horizontal"]
    body
    .greedy
    ul.list-inline-item.list-unstyled.links {
    width: 95%;
}
[data-layout="horizontal"] body .greedy > button {
    display: block;
}
[data-layout="horizontal"] body nav.greedy button {
    align-self: stretch;
    transition: all 0.4s ease-out;
    padding: 5px 0 0 5px;
    outline: 0;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    background-color: transparent;
    color: #fff;
    min-width: 100px;
    margin-right: 20px;
}
[data-layout="horizontal"] body nav.greedy {
    position: relative;
    height: 45px;
    justify-content: flex-start;
}
[data-layout="horizontal"] body nav.greedy ul.hidden-links.hidden {
    display: none;
}
[data-layout="horizontal"] body .page-wrapper {
    margin: 0;
    padding-top: 120px;
}
[data-layout="horizontal"] body .sidebar .sidebar-menu ul ul ul {
    position: absolute;
    right: -200px;
    top: 0;
    left: auto;
}
[data-layout="horizontal"] body .sidebar ul ul li {
    display: inline-block !important;
    width: 100%;
}
[data-layout="horizontal"] body .hidden-links .menu-title {
    display: inline-block !important;
    color: #333 !important;
}

[data-sidebar="dark"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a:hover
    span.menu-arrow,
[data-sidebar="dark"][data-layout="horizontal"]
    body
    .sidebar
    .sidebar-menu
    ul
    li
    a.active
    span.menu-arrow {
    color: #7539ff;
}

[data-sidebar="dark"] body .sidebar-twocol.sidebar .sidebar-left {
    background-color: #34444c;
}
[data-sidebar="dark"] body .sidebar-twocol.sidebar .sidebar-left .nav-link {
    color: #333;
    /*background: #34444c;*/
}
[data-sidebar="dark"]
    body
    .sidebar-twocol.sidebar
    .sidebar-left
    .nav-link.active {
    color: #fff;
    /*background: #333;*/
}
[data-sidebar="dark"] body .sidebar {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    background-color: #1c1d22;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li.submenu ul li a:hover {
    color: #fff;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a span {
    color: #b7c0cd;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a:hover i,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a:hover span,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a.active span,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a.active i {
    color: #7539ff;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li ul li a {
    background: transparent;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu .greedy ul li a:hover,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul > li > a:hover,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a:hover span,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li.submenu ul li a.active {
    color: #7539ff;
}
[data-sidebar="dark"]
    body
    .sidebar
    .sidebar-menu
    ul
    li.submenu
    ul
    li
    a.active:after {
    background: #fff;
    border: 1px solid #fff;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a.active::before {
    display: none;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a.active::after,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a.active::before,
[data-sidebar="dark"] body .sidebar .sidebar-menu ul li a.active::before {
    background: #fff;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu .greedys .viewmoremenu {
    color: #b7c0cd;
}
[data-sidebar="dark"] body .sidebar .sidebar-menu .greedys .viewmoremenu:hover {
    color: #fff;
}
[data-sidebar="dark"]
    body
    .sidebar
    .sidebar-menu
    .greedys
    .hidden-links
    li
    a:hover {
    color: #7539ff;
}
[data-sidebar="dark"]
    body
    .sidebar
    .sidebar-menu
    .greedys
    .hidden-links
    li
    a:hover
    span {
    color: #7539ff;
}
[data-sidebar="dark"] body .main-logo {
    background: transparent;
}
[data-sidebar="dark"] body .two-col-bar .sidebar .sidebar-left .nav-link {
    padding: 5px;
    /*margin-bottom: 15px;*/
    color: #fff;
}
[data-sidebar="dark"]
    body
    .two-col-bar
    .sidebar
    .sidebar-left
    .nav-link.active {
    /*background: #fff;*/
    color: #fff;
}

[data-topbar="light"] body .header {
    background: #fff;
    border-color: transparent;
}
[data-topbar="light"] body .header .main-logo {
    background: #fff;
}
[data-topbar="light"] body .header .page-title-box h3 {
    color: #333;
}
[data-topbar="light"] body .header .logo-color {
    display: block;
}
[data-topbar="light"] body .header .logo-white {
    display: none;
}
[data-topbar="light"] body .header .toggle-bars .bar-icons {
    background: #878a99;
}
[data-topbar="light"] body .header .top-nav-search form .form-control {
    background: #fff;
    border: 1px solid #e0e0e0;
    color: #333;
}
[data-topbar="light"]
    body
    .header
    .top-nav-search
    form
    .form-control::-webkit-input-placeholder {
    color: #333;
}
[data-topbar="light"]
    body
    .header
    .top-nav-search
    form
    .form-control:-ms-input-placeholder {
    color: #333;
}
[data-topbar="light"]
    body
    .header
    .top-nav-search
    form
    .form-control::placeholder {
    color: #333;
}
[data-topbar="light"] body .header .top-nav-search form .btn {
    color: #333;
}
[data-topbar="light"] body .header .header-left .logo2 {
    display: inline-block;
    line-height: 60px;
}
[data-topbar="light"] body .header .header-left .logo {
    display: none;
    line-height: 60px;
}
[data-topbar="light"] body .header #toggle_btn .bar-icon span {
    background-color: #333;
}
[data-topbar="light"] body .header .user-menu.nav > li > a,
[data-topbar="light"] body .header .user-menu.nav > li > a span {
    color: #333;
}
[data-topbar="light"] body .header .has-arrow .dropdown-toggle:after {
    border-bottom: 2px solid #333;
    border-right: 2px solid #333;
}

[data-sidebar="light"] body .sidebar-twocol.sidebar .sidebar-right {
    background: #fff;
}
[data-sidebar="light"]
    body
    .sidebar-twocol.sidebar
    .sidebar-right
    ul
    li
    a.active {
    color: #333;
}
[data-sidebar="light"] body .sidebar-twocol.sidebar .sidebar-left .nav-link {
    color: #34444c;
    background: transparent;
}
[data-sidebar="light"]
    body
    .sidebar-twocol.sidebar
    .sidebar-left
    .nav-link.active {
    color: #333333;
    background: #fff;
}
[data-sidebar="light"] body .sidebar {
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
[data-sidebar="light"] body .sidebar ul li span {
    display: inline-block;
    /*color: $__sandstone;*/
}
[data-sidebar="light"] body .sidebar ul li span:hover {
    display: inline-block !important;
}
[data-sidebar="light"] body .sidebar .sidebar-menu {
    padding: 10px 0;
    background: #fff;
}
[data-sidebar="light"] body .sidebar .list-inline-item li a:hover i {
    color: #333;
}
[data-sidebar="light"] body .greedys .viewmoremenu {
    color: #878a99;
}
[data-sidebar="light"] body .greedys .viewmoremenu:hover {
    color: #7539ff;
}
[data-sidebar="light"] body nav.greedy button {
    color: #333 !important;
}
[data-sidebar="light"] body .sidebar-twocol.sidebar .sidebar-left {
    background: #fafafa;
}
[data-sidebar="light"]
    body
    .sidebar-twocol.sidebar
    .sidebar-left
    .nav-link.active {
    background: #34444c;
    color: #fff;
}
[data-sidebar="light"] body .mini-sidebar.expand-menu .sidebar ul li span {
    display: block !important;
}
[data-sidebar="light"] body .mini-sidebar .sidebar {
    background-color: #fff;
}
[data-sidebar="light"] body .mini-sidebar .sidebar ul li span {
    display: none !important;
}
[data-sidebar="light"] body nav.greedy button {
    color: #333;
}
[data-sidebar="light"] body .sidebar-menu ul li a:hover,
[data-sidebar="light"] body .two-col-bar .sidebar-menu ul li a:hover {
    color: #333;
}
[data-sidebar="light"] body .sidebar .sidebar-menu ul li.active a,
[data-sidebar="light"] body .two-col-bar .sidebar-menu ul li.active a {
    color: #333;
    background-color: transparent;
}

[data-topbar="dark"] .header {
    background: #1c1d22;
    border-color: #2e3840;
}
[data-topbar="dark"] .header .main-logo {
    background: #1c1d22;
}
[data-topbar="dark"] .header .toggle-bars .bar-icons {
    background: #aaaaaa;
}
[data-topbar="dark"] .header .logo-color {
    display: none;
}
[data-topbar="dark"] .header .logo-white {
    display: block;
}
[data-topbar="dark"] .header .user-content .user-name {
    color: #9e9e9e;
}
[data-topbar="dark"] .header .has-arrow .dropdown-toggle:after {
    border-bottom: 2px solid #aaaaaa;
    border-right: 2px solid #aaaaaa;
}
[data-topbar="dark"] .header .header-left .logo {
    display: block;
    line-height: 60px;
}
[data-topbar="dark"] .header .top-nav-search form .form-control {
    border: 1px solid #bbc4cc;
    color: #bbc4cc;
}
[data-topbar="dark"]
    .header
    .top-nav-search
    form
    .form-control::-webkit-input-placeholder {
    color: #bbc4cc;
}
[data-topbar="dark"]
    .header
    .top-nav-search
    form
    .form-control:-ms-input-placeholder {
    color: #bbc4cc;
}
[data-topbar="dark"] .header .top-nav-search form .form-control::placeholder {
    color: #bbc4cc;
}
[data-topbar="dark"] .header .top-nav-search form .btn {
    color: #bbc4cc;
}
[data-topbar="dark"] .header .user-menu.nav > li > a {
    color: #bbc4cc;
}
[data-topbar="dark"] .header #toggle_btn .bar-icon span {
    background-color: #bbc4cc;
}
[data-topbar="dark"] .header .page-title-box h3 {
    color: #bbc4cc;
}
[data-topbar="dark"] .header .logo2 {
    display: none;
}

.two-col-bar {
    display: none;
}

.sidebar-headerset {
    width: 100%;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
}
.sidebar-headerset h2 {
    font-size: 24px;
    font-weight: 600;
}
.sidebar-headerset h3 {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}

.settings-mains {
    padding: 15px;
}
.settings-mains h5 {
    font-size: 14px;
}
.settings-mains .layout-head {
    margin-bottom: 15px;
}
.settings-mains .layout-head h5 {
    font-size: 16px;
    font-weight: 600;
    color: #272b41;
    margin-bottom: 10px;
}
.settings-mains .layout-head h6 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #272b41;
}

#layout-position .radio input + label {
    min-width: 110px;
    border: 0;
}

.bor-rad-50 {
    border-radius: 50px;
}

.bg-sidebarcolor {
    background: #f9f9f9;
    height: 45px;
    display: block;
    border-radius: 5px;
}
.bg-sidebarcolor.bg-darks {
    background: #34444c;
}
.bg-sidebarcolor.bg-gradients {
    background: linear-gradient(92.08deg, #ff0000 0%, #764ba2 100%);
}
.bg-sidebarcolor.bg-oranges {
    background: #ff9b44;
}
.bg-sidebarcolor.bg-darkset {
    background: #16191c;
}

.bg-blue {
    background-color: #00c5fb;
}

.card-radio .form-check-input {
    display: none;
}

.form-check .form-check-input {
    cursor: pointer;
}
.form-check .form-check-input:focus {
    box-shadow: none;
}

.form-check-input:checked {
    background-color: #7539ff;
    border-color: #7539ff;
}

.card-radio .form-check-input:checked + .form-check-label {
    border-color: #3bb800 !important;
}

.card-radio .form-check-label {
    background-color: var(--vz-card-bg);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    position: relative;
    padding-right: 32px;
    padding: 10px;
}

.form-check label {
    cursor: pointer;
}

.card-radio .form-check-input:checked + .form-check-label:before {
    content: "✓";
    font-family: "Line Awesome Free";
    position: absolute;
    top: 40%;
    left: 40%;
    font-size: 10px;
    background: #3bb800;
    font-weight: 900;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #f4f4f9;
    transform: translate(0%);
}

[data-layout-mode="dark"] .super-admin-list-head {
    border-bottom-color: #3b3c3e;
}
[data-layout-mode="dark"] .super-admin-list-head .grid-info-item span {
    color: #aaaaaa;
}
[data-layout-mode="dark"] .subscription-plane-head ul {
    border-bottom-color: #3b3c3e;
}

/****** PAGES ******/
.invoices-links {
    color: #1b2559;
    margin-right: 20px;
}
.invoices-links:last-child {
    margin-right: 0px;
}
.invoices-links:hover {
    color: #7539ff;
}
.invoices-links.active {
    color: #7539ff;
}
.invoices-links .feather {
    width: 25px;
    height: 25px;
}

.report-card {
    border-radius: 5px;
}

.app-listing {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
    align-items: flex-start;
}
.app-listing li {
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
}

.multipleSelection .selectbox,
.sortby .selectboxes {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 13px 10px;
    font-weight: 400;
    background: #fff;
    border: 1px solid #f7f8f9;
    border-radius: 7px;
    cursor: pointer;
}

.selectbox-cont {
    height: 220px;
    overflow-y: auto;
    padding-right: 5px;
}
.selectbox-cont::-webkit-scrollbar {
    width: 4px;
    background: #d8d8d8;
    height: 10px;
}
.selectbox-cont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(231, 231, 231);
    border-radius: 4px;
}
.selectbox-cont::-webkit-scrollbar-thumb {
    background: rgba(231, 234, 252, 0.75);
    border-radius: 4px;
}
.selectbox-cont::-webkit-scrollbar-thumb:hover {
    background: rgba(231, 234, 252, 0.75);
}

.selectbox-cont-one {
    overflow-y: inherit;
}

.selectboxes.order-by {
    min-width: 154px;
    background: #d8d8d8;
    padding: 6px 10px;
}

.multipleSelection {
    position: relative;
    margin-bottom: 20px;
}
.multipleSelection #checkboxes-one {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}
.multipleSelection #checkboxes-one::before {
    border: 7px solid #f2ecff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    left: 30px;
    position: absolute;
    top: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.multipleSelection #checkboxes.form-custom .form-control {
    padding-left: 25px;
    background: #f5f6fa;
    border: 1px solid #e6e9f4;
    border-radius: 5px;
    height: 33px;
}
.multipleSelection #checkboxes .form-custom i {
    position: absolute;
    top: 35%;
    left: 6px;
    color: #7e84a3;
    font-size: 12px;
}

.multi-filter,
.sortby {
    position: relative;
}

.multi-filter:before {
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    right: 0;
    position: absolute;
    top: 2px;
    transform-origin: 0 0;
    transform: rotate(135deg);
}

.multipleSelection #checkboxes,
.sortby #checkbox {
    display: none;
    position: absolute;
    width: 300px;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}

#checkboxes-one .form-custom {
    margin-bottom: 14px;
}
#checkboxes-one .form-custom .form-control {
    padding-left: 14px;
    border: 1px solid rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}
#checkboxes-one .btn-grey {
    background: rgba(231, 234, 252, 0.75);
    color: #1b2559;
    margin-top: 10px;
}
#checkboxes-one .btn-grey:hover {
    background: #1b2559;
    color: #fff;
}

#checkboxes-one .btn,
#checkbox .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;
}

.multipleSelection #checkboxes::before,
.sortby #checkbox::before {
    border: 7px solid #f2ecff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    left: 30px;
    position: absolute;
    top: 0;
    transform-origin: 0 0;
    transform: rotate(135deg);
}

.check-list {
    position: relative;
}

.date-picker {
    width: 50%;
    float: left;
    padding-right: 10px;
}

#checkboxes .date-picker .bootstrap-datetimepicker-widget table td {
    padding-left: 10px;
    padding-right: 10px;
}
#checkboxes .date-picker .form-custom .form-control {
    padding-left: 10px;
}
#checkboxes .form-custom {
    margin-bottom: 14px;
}
#checkboxes .form-custom .form-control {
    padding-left: 14px;
    border: 1px solid rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}
#checkboxes .form-custom .form-control::placeholder {
    color: #8f9bba;
}
#checkboxes .btn-grey {
    background: rgba(231, 234, 252, 0.75);
    color: #1b2559;
    margin-top: 10px;
}
#checkboxes .btn-grey:hover {
    background: #1b2559;
    color: #fff;
}

.date-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.date-list ul li {
    width: 100%;
    padding: 0;
    padding-bottom: 5px;
}
.date-list ul li:last-child {
    padding-bottom: 0;
}
.date-list .date-btn {
    background: #fff;
    color: #1b2559;
    border: 1px solid #e7eafc;
    border-radius: 4px;
    padding: 10px 0 !important;
    width: 100%;
}
.date-list .date-btn:hover {
    background: #7539ff;
    color: #fff;
    border: 1px solid #e7eafc;
}

.form-custom {
    position: relative;
}
.form-custom.cal-icon:after {
    font-size: 14px;
}
.form-custom i {
    color: #7e84a3;
    position: absolute;
    top: 10px;
    left: 10px;
}

.card-header .form-custom .form-control {
    padding-left: 30px;
    height: 35px;
}

#checkboxes .btn,
#checkbox .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;
}

.checkbox-title {
    font-weight: 600;
    font-size: 16px;
    color: #7539ff;
    margin-bottom: 14px;
}

.custom_check {
    color: #1b2559;
    display: inline-block;
    position: relative;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;
    font-weight: 400;
    user-select: none;
}
.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
    background-color: #7539ff;
    border-color: #7539ff;
}
.custom_check input:checked ~ .checkmark:after {
    display: block;
}
.custom_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 21px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    border-radius: 6px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    color: #fff;
    font-size: 9px;
}

.selectbox p {
    color: #1b2559;
    font-weight: 500;
}
.selectbox p .select-icon {
    color: #7539ff;
    width: 20px;
    height: 20px;
}

.report-btn {
    margin-bottom: 20px;
}
.report-btn .btn {
    font-weight: 600;
    color: #7539ff;
    background: #fff;
    border: 2px solid #7539ff;
    border-radius: 8px;
    width: 100%;
    padding: 11px 0;
}
.report-btn .btn:hover {
    color: #fff;
    background: #7539ff;
    border: 2px solid #7539ff;
}
.report-btn .btn:hover img {
    filter: invert(0) brightness(100);
}

.invoice-total-inner .status-toggle .checktoggle {
    transform: translate(calc(0% - 0px), -30%);
    height: 20px;
}
.invoice-total-inner .status-toggle span {
    float: right;
    color: #3f4254;
    font-weight: 600;
    margin: -10px 0 0;
}

.invoices-tabs-card {
    background: transparent;
    box-shadow: unset;
}

.invoices-tabs ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.invoices-tabs ul li:last-child {
    margin-right: 0;
}
.invoices-tabs ul li a:hover {
    color: #7539ff;
}
.invoices-tabs ul li a.active {
    color: #7539ff;
    border-bottom: 2px solid #7539ff;
}

.invoices-settings-btn {
    justify-content: flex-end;
}
.invoices-settings-btn .btn {
    font-weight: 600;
    color: #fff;
    padding: 12px 10px;
    min-width: 167px;
    background: #1b2559;
    border: 1px solid #1b2559;
    border-radius: 6px;
}
.invoices-settings-btn .btn:hover {
    background: #7539ff;
    border: 1px solid #7539ff;
}
.invoices-settings-btn .btn .feather {
    margin-right: 6px;
}

.invoices-settings-icon {
    color: #7539ff;
    margin-right: 20px;
}
.invoices-settings-icon .feather {
    width: 24px;
    height: 24px;
}

.inovices-card {
    border-radius: 5px;
}
.inovices-card .card-body {
    padding: 15px;
}

.inovices-widget-header .inovices-amount {
    font-weight: 600;
    font-size: 24px;
    color: #7539ff;
}

.inovices-widget-icon {
    padding: 10px;
    border-radius: 5px;
}
@media (max-width: 1399.98px) {
    .inovices-widget-icon {
        padding: 5px;
    }
}
.inovices-widget-icon img {
    width: 20px;
}

.inovices-all span {
    margin: 0 0 0 5px;
    padding: 0px;
    font-size: 10px;
    font-weight: 500;
    color: #28084b;
}

.invoices-grid-card {
    background: #fff;
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    border-radius: 10px;
    color: #1b2559;
}
.invoices-grid-card .card-header {
    padding: 20px;
    border: 0;
}
.invoices-grid-card .card-header .dropdown-action {
    margin-bottom: 0;
}
.invoices-grid-card .card-header .action-icon {
    color: #1b2559;
    display: initial;
}
.invoices-grid-card .card-body {
    padding: 20px;
}
.invoices-grid-card .card-body span {
    font-size: 14px;
}
.invoices-grid-card .card-body h6 {
    font-size: 16px;
    font-weight: 500;
    color: #1b2559;
    margin-top: 4px;
}
.invoices-grid-card .card-footer {
    padding: 20px;
    border: 0;
}
.invoices-grid-card .card-footer .badge {
    border-radius: 6px;
    font-size: 13px;
    font-weight: 400;
    padding: 7px 18px;
}
.invoices-grid-card .bg-success-dark {
    background: #008f64;
}
.invoices-grid-card .bg-danger-dark {
    background: #ff0000;
}
.invoices-grid-card .bg-secondary-dark {
    background: #7539ff;
}
.invoices-grid-card .bg-primary-dark {
    background: #2196f3;
}
.invoices-grid-card .text-sm {
    font-size: 14px;
}

.invoice-grid-link {
    color: #1b2559;
}
.invoice-grid-link:hover {
    color: #7539ff;
}

.card-middle {
    padding: 20px;
    background: #f7f7ff;
}

.card-middle-avatar {
    display: inline-flex;
    font-size: 16px;
    margin: 0;
}
.card-middle-avatar a {
    color: #1b2559;
}
.card-middle-avatar a:hover {
    color: #7539ff;
}

.invoice-load-btn {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.invoice-load-btn .btn {
    font-weight: 700;
    font-size: 16px;
    color: #3e45eb;
    min-width: 170px;
    padding: 12px 15px;
    background: #fff;
    border: 2px solid #3e45eb;
    border-radius: 6px;
    display: inline-flex;
}
.invoice-load-btn .btn:hover {
    color: #fff;
    background: #3e45eb;
    border: 2px solid #3e45eb;
}
.invoice-load-btn .btn:hover span {
    border: 3px solid #fff;
    border-right-color: #fff;
}
.invoice-load-btn .btn span {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 8px;
    vertical-align: 0;
    border: 3px solid #3e45eb;
    border-right-color: #3e45eb;
    border-radius: 50%;
    border: 3px solid rgba(62, 69, 235, 0.4901960784);
    border-right-color: #3e45eb;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
}

.invoices-page-header {
    background: #fff;
    border-radius: 10px;
    padding: 12px;
}
.invoices-page-header .invoices-breadcrumb-item a {
    font-weight: 600;
    color: #7539ff;
}
.invoices-page-header .invoices-breadcrumb-item a:hover {
    color: #377dff;
}
.invoices-page-header .invoices-breadcrumb-item a:hover i {
    color: #fff;
    background: #377dff;
}
.invoices-page-header .invoices-breadcrumb-item i {
    color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background: #7539ff;
}

.invoices-create-btn .btn {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    border-radius: 8px;
    padding: 10px 0px;
    min-width: 160px;
}
.invoices-create-btn .invoices-preview-link {
    font-weight: 600;
    color: #7539ff;
    margin-right: 30px;
}
.invoices-create-btn .invoices-preview-link:hover {
    color: #377dff;
}
.invoices-create-btn .delete-invoice-btn {
    background: #1b2559;
    margin-right: 10px;
}
.invoices-create-btn .delete-invoice-btn:hover {
    background: #7539ff;
}
.invoices-create-btn .save-invoice-btn {
    background: #7539ff;
}
.invoices-create-btn .save-invoice-btn:hover {
    background: #1b2559;
}

.invoices-add-card {
    background: #fff;
    border-radius: 10px;
}

.invoices-main-form {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
}

.invoices-form label {
    font-weight: 600;
    color: #1b2559;
}
.invoices-form .multipleSelection .selectbox {
    border: 2px solid #7539ff;
    border-radius: 6px;
}
.invoices-form .form-control {
    height: 50px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
}
.invoices-form .form-control::placeholder {
    color: #8f9bba;
    font-weight: 400;
}

.invoice-details-title {
    font-weight: 500;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 0.5rem;
}

.invoice-details-box {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
}

.invoice-inner-head {
    padding: 26px;
    border-bottom: 1px solid #e5e5e5;
}
.invoice-inner-head span {
    color: #1b2559;
}
.invoice-inner-head span a {
    font-weight: 500;
    color: #7539ff;
}
.invoice-inner-head span a:hover {
    font-weight: 500;
    color: #377dff;
}

.invoice-inner-footer {
    padding: 0;
}
.invoice-inner-footer span {
    font-weight: 500;
    color: #1b2559;
    position: relative;
}
.invoice-inner-footer .form-control {
    position: absolute;
    color: #7539ff;
    background: transparent;
    border: 0;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0 10px;
}
.invoice-inner-footer .form-control::placeholder {
    color: #7539ff;
    font-weight: 500;
}

.invoice-inner-date {
    padding: 26px;
    border-right: 1px solid #e5e5e5;
}

.invoice-inner-datepic {
    border: 0;
    padding-left: 0;
}

.inovices-month-info {
    padding-top: 50px;
}
.inovices-month-info label {
    font-weight: 400;
}
.inovices-month-info
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #8f9bba;
    border-radius: 6px;
}
.inovices-month-info .form-control {
    height: 42px;
}

#show-invoices {
    display: none;
}

.invoice-add-table {
    padding-top: 20px;
    padding-bottom: 30px;
}
.invoice-add-table h4 {
    font-weight: 600;
    font-size: 20px;
    color: #7539ff;
    margin-bottom: 30px;
}
.invoice-add-table th {
    font-size: 16px;
    color: #1b2559;
    background: #f8f9fa;
}
.invoice-add-table .no-border td {
    border: 0;
}
.invoice-add-table .table-form-control td .form-control::placeholder {
    color: #1b2559;
}
.invoice-add-table .add-product {
    border-bottom: 1px solid #e5e5e5;
}
.invoice-add-table .add-product td {
    border: 0;
}
.invoice-add-table .add-product td a {
    color: #7539ff;
}
.invoice-add-table .add-btn {
    color: #7539ff;
}
.invoice-add-table .remove-btn {
    color: #ff0000;
}
.invoice-add-table .copy-btn {
    color: #c4c4c4;
}

.invoice-add-table td,
.invoice-add-table th {
    border-color: #e5e5e5;
    padding: 20px;
}

.invoice-fields .field-title {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 15px;
}

.field-box {
    padding: 15px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}
.field-box p {
    font-weight: 500;
    font-size: 14px;
    color: #1b2559;
    margin-bottom: 10px;
}
.field-box .btn {
    min-width: 266px;
    border-radius: 4px;
    padding: 30px 0;
}

.payment-details {
    width: 266px;
    border-radius: 4px;
    padding: 15px;
    background: #7539ff;
}
.payment-details p {
    color: #fff;
    max-width: 140px;
    font-weight: 400;
    margin-bottom: 0;
}
.payment-details a {
    font-size: 26px;
    color: #fff;
}

.invoice-faq .faq-tab {
    padding-top: 10px;
}

.faq-tab .panel-title {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin-bottom: 0px;
    padding: 10px 20px;
    position: relative;
}
.faq-tab .panel-title a {
    color: #c4c4c4;
}
.faq-tab .panel-title a:hover {
    color: #1b2559;
}
.faq-tab .panel-title a:not(.collapsed) {
    color: #1b2559;
}
.faq-tab .panel-title a.collapsed::after {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    color: #f09e9e;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    top: 10px;
    right: 20px;
}
.faq-tab .panel-title a::after {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    color: #f09e9e;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    top: 10px;
    right: 20px;
}
.faq-tab .panel-title a:not(.collapsed)::after {
    color: #ff0000;
}
.faq-tab .panel-body {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
}
.faq-tab .panel-body textarea {
    min-height: 100px;
    border: 0;
    border-radius: 0 0 4px 4px;
}

.invoice-total-card .invoice-total-title {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 15px;
}

.invoice-total-box p {
    position: relative;
    margin: 0 0 11px 0;
    position: relative;
    font-weight: 500;
    color: #878a99;
}
.invoice-total-box p span {
    float: right;
    color: #3f4254;
    font-weight: 600;
}
.invoice-total-box .add-links {
    font-weight: 500;
    color: #7539ff;
    margin-bottom: 20px;
    display: block;
}
.invoice-total-box .service-trash {
    margin-bottom: 10px;
    color: #1b2559;
}
.invoice-total-box .service-trash i {
    color: #ec1361;
}
.invoice-total-box .add-links-one {
    font-weight: 500;
    color: #7539ff;
}
.invoice-total-box .service-trash-one {
    margin-top: 10px;
    color: #1b2559;
}
.invoice-total-box .service-trash-one i {
    color: #ec1361;
}

.invoice-total-box .status-toggle .checktoggle::after {
    height: 14px;
    width: 14px;
}
.invoice-total-box .check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
.invoice-total-box .check:checked + .checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 2px), -50%);
    height: 14px;
    width: 14px;
}

.checktoggle {
    background-color: #cfcfcf;
    border: 1px solid #cfcfcf;
    cursor: pointer;
    font-size: 0;
    height: 15px;
    margin-left: 10px;
    top: 3px;
    margin-bottom: 0;
    position: absolute;
    width: 30px;
    display: inline-block;
    border-radius: 12px;
}
.checktoggle:after {
    content: " ";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: translate(3px, -50%);
    -webkit-transform: translate(3px, -50%);
    -ms-transform: translate(3px, -50%);
}

.onoffswitch {
    margin-left: auto;
    position: relative;
    width: 73px;
}

.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 0;
}

.onoffswitch-inner {
    display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}
.onoffswitch-inner:before {
    background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}

.onoffswitch-switch {
    background: #fff;
    bottom: 0;
    display: block;
    height: 20px;
    margin: 5px;
    width: 20px;
    border-radius: 20px;
    position: absolute;
    top: 0;
    right: 43px;
    bottom: 0;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.invoice-total-footer {
    margin: 0 0 26px 0;
}
.invoice-total-footer h4 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    color: #3f4254;
}
.invoice-total-footer h4 span {
    float: right;
}

.upload-sign {
    float: right;
    margin-top: 20px;
}
.upload-sign .btn {
    min-width: 198px;
    border-radius: 8px;
    padding: 10px 0;
}

.service-upload {
    border: 2px dashed #e5e5e5;
    text-align: center;
    background-color: #fff;
    position: relative;
    padding: 16px 0;
    min-height: 170px;
    border-radius: 5px;
    transition-duration: 0.3s;
    cursor: pointer;
}
.service-upload:hover {
    border-color: #4096ff;
}
.service-upload .drop-browse {
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;
    margin-bottom: 5px;
}
.service-upload .drop-browse p {
    font-weight: 500;
    font-size: 14px;
    color: #b5b5c3 !important;
}
.service-upload span {
    font-size: 13px;
    color: #858585;
    display: block;
}
.service-upload input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.service-upload-info {
    min-height: 58px;
}

.invoice-info-card {
    border-radius: 14px;
}

.invoice-item-one {
    margin: 0 0 30px;
}
@media (max-width: 768px) {
    .invoice-item-one {
        margin: 0 0 20px;
    }
}
.invoice-item-one .invoice-info {
    text-align: right;
}
.invoice-item-one .invoice-info h1 {
    font-weight: 600;
    font-size: 24px;
    color: #4a3aff;
}
@media (max-width: 768px) {
    .invoice-item-one .invoice-info h1 {
        font-size: 26px;
    }
}
.invoice-item-one .invoice-details {
    color: #1b2559;
    font-weight: 400;
}

.customer-text-one {
    font-size: 20px;
    color: #1b2559;
    font-weight: 600;
    display: block;
    margin: 0 0 8px;
}
@media (max-width: 768px) {
    .customer-text-one {
        font-size: 18px;
    }
}

.invoice-name {
    color: #1b2559;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.invoice-head {
    margin-bottom: 30px;
}
.invoice-head h2 {
    font-weight: 600;
    font-size: 28px;
    color: #1b2559;
    text-transform: uppercase;
    margin-bottom: 0;
}
.invoice-head p {
    font-weight: 400;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 0;
}

.invoice-item-two {
    border-bottom: 1px solid #efefef;
    margin: 0 0 15px;
    padding: 15px 0;
}
.invoice-item-two .invoice-details {
    color: #1b2559;
    font-weight: 400;
}

.invoice-item-box {
    background: #f7f7ff;
    border-radius: 5px;
    padding: 15px;
    width: 200px;
    float: right;
    margin-top: 20px;
}
.invoice-item-box p {
    color: #1b2559;
    font-weight: 400;
    margin-bottom: 10px;
}

.invoice-issues-box {
    background: #7539ff;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    margin-bottom: 30px;
}

.invoice-issues-date {
    padding-top: 20px;
    padding-bottom: 20px;
}
.invoice-issues-date p {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
}

.invoice-table-wrap {
    margin: 0 0 30px;
}
.invoice-table-wrap .invoice-table-head {
    margin: 0 0 30px;
}

.invoice-table > thead > tr > th {
    background: #f5f7ff;
    border-top: 0;
    font-weight: 500;
    font-size: 16px;
    color: #1b2559;
}
.invoice-table > tbody > tr > td {
    font-weight: 400;
    color: #1b2559;
    padding: 15px 20px;
}

.terms-conditions {
    margin: 0 0 20px;
}

.invoice-terms {
    margin: 0 0 30px;
}
.invoice-terms h6 {
    font-weight: 600;
    margin: 0 0 5px;
    font-size: 16px;
    color: #3f4254;
}
.invoice-terms p {
    font-weight: 400;
    font-size: 14px;
    color: #8f9bba;
}
.invoice-terms .invoice-terms-icon {
    background: #efefef;
    border-radius: 10px;
    padding: 10px;
    display: inline-flex;
}
.invoice-terms .invoice-terms-icon i {
    color: #7539ff;
    font-size: 30px;
}

.invoice-sign span {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    margin: 0 0 11px;
}

.invoice-item-bg {
    background: #7539ff;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.invoice-item-bg .invoice-info {
    border-right: 1px solid #fff;
    margin: 20px 0 0;
    padding: 30px;
}
.invoice-item-bg .customer-text-one {
    color: #fff;
}
.invoice-item-bg .invoice-name {
    color: #fff;
}
.invoice-item-bg .invoice-details-two {
    color: #fff !important;
}
.invoice-item-bg .invoice-info-one p {
    color: #fff;
    margin-bottom: 10px;
}

.invoice-circle-img {
    position: relative;
}
.invoice-circle-img .invoice-circle1 {
    opacity: 0.1;
    position: absolute;
    left: 0;
}
.invoice-circle-img .invoice-circle2 {
    opacity: 0.1;
    position: absolute;
    right: 0;
}

.invoice-sign-box {
    margin-top: 30px;
}

.invoice-payment-box h4 {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 16px;
}
.invoice-payment-box .payment-details {
    background: transparent;
    border: 2px dashed #7539ff;
}
.invoice-payment-box .payment-details p {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
}

.invoices-preview .modal-body {
    padding: 0;
}

.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px;
}
.invoice-item .invoice-text h2 {
    color: #272b41;
    font-size: 36px;
    font-weight: 600;
    font-weight: 400;
}
.invoice-item .invoice-details {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
}
@media (max-width: 768px) {
    .invoice-item .invoice-details {
        text-align: left;
        margin-bottom: 10px;
    }
}
.invoice-item .invoice-details strong {
    color: #3f4254;
}
.invoice-item .invoice-details span.text-danger {
    margin-left: 20px;
    position: relative;
}
.invoice-item .invoice-details span.text-danger::before {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: "";
    background: #ff0000;
    position: absolute;
    bottom: 5px;
    left: -12px;
}
.invoice-item .invoice-details-two {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #878a99;
}
.invoice-item .customer-text {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;
}
.invoice-item .customer-text a {
    font-size: 14px;
    color: #7539ff;
    margin-left: 12px;
}

.invoice-item-date {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    margin: 0 0 15px;
    padding: 15px 0;
}

.invoice-item-bg .invoice-item .invoice-details-two {
    text-align: left;
    color: #fff !important;
}

.invoice-info p {
    margin-bottom: 0;
}
.invoice-info.invoice-info2 {
    text-align: left;
}
.invoice-info h5 {
    font-size: 16px;
    font-weight: 500;
}

.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
    font-weight: 600;
    padding: 15px 20px;
    line-height: inherit;
}

.invoice-table tr td,
.invoice-table-two tr td {
    font-weight: 500;
}

.invoice-table-two {
    margin-bottom: 0;
}
.invoice-table-two tr td {
    text-align: right;
}

.invoice-table-two tr th,
.invoice-table-two tr td {
    border-top: 0;
}

.other-info {
    margin-top: 10px;
}

.file {
    visibility: hidden;
    position: absolute;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.font-size-14 {
    font-size: 14px;
}

.form-group-bank {
    border: 2px solid #efefef;
    padding: 20px;
    border-radius: 10px;
    background-color: #f7f8f9;
}
.form-group-bank .select2-container .select2-selection--single {
    height: 43px;
}
.form-group-bank
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #878a99;
    line-height: 43px;
    border-radius: 5px;
    padding-right: 30px;
    padding-left: 15px;
}
.form-group-bank .input-block .form-control {
    padding: 10px;
}
.form-group-bank .input-block textarea {
    height: 91px !important;
}
.form-group-bank .form-groups {
    margin-top: 8px;
}

.recurring-tab ul {
    border: 1px solid #e5e7eb;
    border-radius: 10px;
}
.recurring-tab ul li button {
    padding: 10px;
    width: 45px;
}
.recurring-tab ul li button.active {
    padding: 10px;
    background-color: #7539ff !important;
}
.recurring-tab ul li button.active.yes {
    border-radius: 10px 0 0 10px;
}
.recurring-tab ul li button.active.no {
    border-radius: 0 10px 10px 0;
}

.sort-by-ticket .select2-container--default .select2-selection--single {
    border: none;
    background: transparent !important;
}
.sort-by-ticket .select2-container--default .select2-selection__rendered {
    padding-left: 0;
    padding-right: 26px;
}
.sort-by-ticket .select2-container--default .select2-selection__arrow {
    right: 0;
}

.invoice-info2 .due-date span.text-danger {
    position: relative;
    margin-left: 20px;
    display: inline-block;
}
.invoice-info2 .due-date span.text-danger::before {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: "";
    background: #ff0000;
    position: absolute;
    bottom: 7px;
    left: -12px;
}

.activity-feed .feed-item.timeline-item::after {
    background: #7539ff;
    border-color: #7539ff;
    width: 8px;
    height: 8px;
}
.activity-feed .feed-item.timeline-item::before {
    display: none;
}
.activity-feed .feed-item.timeline-item span.timeline-user {
    font-size: 14px;
    margin-bottom: 2px;
    display: inline-block;
}
.activity-feed .feed-item.timeline-item span.timeline-user a {
    font-size: 13px;
    color: #3f4254;
}
.activity-feed .feed-item.timeline-item .invoice-date span {
    display: inline-block;
    font-size: 12px;
    color: #bebebe;
}
.activity-feed .feed-item.timeline-item .invoice-date span.start-date {
    margin-right: 10px;
}

.card.timeline-card {
    background: #f7f8f9;
    border: 1px solid #efefef;
    border-radius: 10px;
}

.modal-body .payment-heading h5 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 13px;
}

.pay-modal .modal-header h4.modal-title {
    font-size: 18px;
}
.pay-modal .modal-header h4.modal-title span {
    color: #7539ff;
}
.pay-modal .modal-header h5 {
    color: #3f4254;
    font-size: 14px;
}
.pay-modal .modal-header h5 span {
    color: #878a99;
}

@media (max-width: 991.98px) {
    .paynow-tab ul {
        flex-direction: column;
    }
    .paynow-tab ul li {
        width: 100%;
        margin-bottom: 20px;
    }
    .paynow-tab ul li:last-child {
        margin-bottom: 0;
    }
}
.paynow-tab .nav-pills .nav-item button {
    width: 100%;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #878a99;
    text-align: left;
    position: relative;
    padding: 10px 20px 10px 50px;
}
.paynow-tab .nav-pills .nav-item button i {
    position: absolute;
    top: 13px;
    right: 20px;
}
.paynow-tab .nav-pills .nav-item button::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: absolute;
    top: 11px;
    left: 22px;
}
.paynow-tab .nav-pills .nav-item button.active {
    color: #3f4254;
}
.paynow-tab .nav-pills .nav-item button.active::before {
    border-color: #2da7ff;
}
.paynow-tab .nav-pills .nav-item button.active::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #2da7ff;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 26px;
}

@media print {
    .filter-list {
        display: none !important;
    }
    .header-one {
        display: none !important;
    }
    html,
    body {
        height: 100vh;
        margin: 0 !important;
        padding: 0 !important;
    }
    .right-side-views {
        display: none;
    }
    .sidebar-layout {
        display: none;
    }
    .sidebar-settings {
        display: none;
    }
}
.profile-picture {
    margin: 0 0 30px 0;
}
@media (max-width: 575.98px) {
    .profile-picture {
        display: block;
        margin: 0 0 25px 0;
    }
}
.profile-picture .upload-profile {
    margin-right: 50px;
}
@media (max-width: 575.98px) {
    .profile-picture .upload-profile {
        margin: 0 0 20px 0;
    }
}
.profile-picture .upload-profile .profile-img .avatar {
    width: 61px;
    height: 61px;
}
@media (max-width: 575.98px) {
    .profile-picture .upload-profile .profile-img .avatar {
        margin: 0 20px 0 0;
    }
}
.profile-picture .upload-profile .add-profile h5 {
    font-size: 14px;
    margin: 0 0 4px;
}
.profile-picture .upload-profile .add-profile span {
    font-size: 14px;
}
.profile-picture .img-upload {
    position: relative;
}
@media (max-width: 768px) {
    .profile-picture .img-upload .btn {
        max-width: 120px;
    }
}
.profile-picture .img-upload .btn {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    min-width: 82px;
    margin: 0 15px 0 0;
    padding: 8px 20px;
}
.profile-picture .img-upload .btn input {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: #fff;
    cursor: inherit;
    display: block;
}
.profile-picture .img-upload .upload {
    background-color: #7539ff;
    color: #fff;
    margin: 0 15px 0 0;
    padding: 8px 20px;
    border-radius: 6px;
}
.profile-picture .img-upload .btn-remove {
    background-color: #fff;
    color: #ed3a3a;
    border: 1px solid #ed3a3a;
    min-width: 87px;
    padding: 8px 20px;
    box-shadow: inset 0 0 0 0 #ed3a3a;
    border-radius: 6px;
}
.profile-picture .img-upload .btn-remove:hover {
    color: #fff;
    background-color: #ed3a3a;
    box-shadow: inset 0 50px 0 0 #ed3a3a;
}

.billing-btn {
    margin: 0 0 15px 0;
}
.billing-btn button {
    background-color: #7539ff;
    color: #fff;
}
.billing-btn .btn-primary {
    min-width: 139px;
    border-radius: 6px;
    padding: 8px 20px;
}

.card-title.title-form {
    margin-bottom: 36px;
}

.custom_radio,
.custom_radio_one {
    color: #878a99;
    position: relative;
    font-size: 14px;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
}
.custom_radio input,
.custom_radio_one input {
    position: absolute;
    opacity: 0;
    padding: 0;
}
.custom_radio input:checked ~ .checkmark,
.custom_radio_one input:checked ~ .checkmark {
    border: 1px solid #7539ff;
}
.custom_radio input:checked ~ .checkmark:after,
.custom_radio_one input:checked ~ .checkmark:after {
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.custom_radio .checkmark,
.custom_radio_one .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid #cfcfcf;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.custom_radio .checkmark:after,
.custom_radio_one .checkmark:after {
    display: block;
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    opacity: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #7539ff;
    visibility: visible;
    transform: scale(0.1);
    -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card-support {
    background: #fff;
    border: 1px solid #efefef;
    box-shadow: 0px 2px 4px rgba(190, 190, 190, 0.2);
}

.customer-details-group .customer-details {
    margin: 0 0 25px 0;
}
.customer-details-group .customer-details .customer-widget-img {
    margin: 0 15px 0 0;
    width: 60px;
    height: 60px;
}
@media (max-width: 768px) {
    .customer-details-group .customer-details .customer-widget-img {
        width: 40px;
        height: 40px;
    }
}
.customer-details-group .customer-details .customer-widget-img i {
    font-size: 24px;
}
.customer-details-group .customer-details .customer-widget-icon {
    margin: 0 15px 0 0;
}
.customer-details-group .customer-details .customer-widget-icon i {
    background: #f3f3f9;
    padding: 18px;
    border-radius: 50px;
    font-size: 24px;
}
@media (max-width: 768px) {
    .customer-details-group .customer-details .customer-widget-icon i {
        padding: 10px;
        font-size: 20px;
    }
}
.customer-details-group .customer-details .customer-details-cont h6 {
    font-weight: 600;
    margin-bottom: 4px;
}
.customer-details-group .customer-details .customer-details-cont p {
    color: #878a99;
    margin: 0;
}
.customer-details-group
    .customer-details
    .customer-details-cont
    .customer-mail {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    white-space: nowrap;
}

.ticket-details-group {
    margin: 0 0 40px 0;
}
.ticket-details-group .ticket-widget-img {
    width: 61px;
    height: 61px;
    background: #fff;
    margin: 0 15px 0 0;
    padding: 16px;
}
.ticket-details-group .ticket-details-cont p {
    font-weight: 600;
    color: #7539ff;
    margin: 0 0 4px 0;
}
.ticket-details-group .ticket-details-cont h6 {
    font-weight: 600;
    font-size: 18px;
    color: #3f4254;
    margin: 0;
}
@media (max-width: 768px) {
    .ticket-details-group .ticket-details {
        margin: 30px 0 0;
        padding: 0;
    }
}
.ticket-details-group .ticket-details .badge {
    padding: 8px;
}

.ticket-description-group {
    margin: 0 0 24px;
}
.ticket-description-group h6 {
    font-weight: 600;
    font-size: 14px;
    color: #28084b;
    margin: 0 0 18px;
}
.ticket-description-group p {
    margin: 0 0 10px;
}

.ticket-information {
    margin: 0 0 28px;
    padding: 0 0 28px;
    border-bottom: 1px solid #efefef;
}
.ticket-information .customer-details {
    border-right: 1px solid #efefef;
}
@media (max-width: 768px) {
    .ticket-information .customer-details {
        margin: 0 0 30px;
        padding: 0;
    }
}
.ticket-information .customer-widget-img {
    width: 50px;
    height: 50px;
    margin: 0 10px;
}
.ticket-information .customer-widget-icon {
    margin: 0 10px 0;
    padding: 15px;
    background: #fff;
}
.ticket-information .customer-widget-icon i {
    font-size: 20px;
}
.ticket-information .customer-details-cont h6 {
    font-weight: 400;
    color: #3f4254;
    margin: 0 0 4px;
}
.ticket-information .support-widget-icon {
    margin: 0 10px 0 0;
    padding: 12px;
    background: #f3f3f3;
}
.ticket-information .support-widget-icon i {
    font-size: 18px;
    font-weight: 600;
    color: #3f4254;
}
.ticket-information .support-details-cont h6 {
    font-weight: 600;
    color: #3f4254;
    margin: 0 0 4px;
}
@media (max-width: 575.98px) {
    .ticket-information .support-details-cont h6 {
        font-size: 12px;
    }
}
.ticket-information .support-details {
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 8px;
    margin: 0 0 13px;
    padding: 9px;
    line-height: 20px;
}
.ticket-information .support-details .btn-action-icon {
    background: #f3f3f3;
    color: #878a99;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 50px;
}

.ticket-history .card-inform {
    margin: 0 0 31px;
}
@media (max-width: 575.98px) {
    .ticket-history .card-inform {
        margin: 15px 0 31px;
    }
}
.ticket-history .card-inform #image_personal {
    display: none;
}
.ticket-history .card-inform .ticket-info h6 {
    font-weight: 600;
    font-size: 14px;
    color: #28084b;
}
.ticket-history .card-inform .ticket-info .ticket-upload {
    background-color: #fff;
    border: 1px solid #7539ff;
    color: #7539ff;
    box-shadow: inset 0 0 0 #7539ff;
    padding: 6px 15px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.ticket-sidebar .action-sets {
    border: 1px solid rgba(145, 158, 171, 0.32);
    padding: 4px 10px;
    color: #878a99;
    line-height: 34px;
    border-radius: 5px;
}
.ticket-sidebar .dropdown-menu {
    min-width: 220px;
    border-radius: 6px;
    margin-top: 0 !important;
}
.ticket-sidebar .dropdown-menu .dropdown-item {
    font-size: 14px;
    justify-content: flex-start;
}
.ticket-sidebar .dropdown-menu .dropdown-item i {
    line-height: 20px !important;
}
.ticket-sidebar
    .multiselect-native-select
    .multiselect-container.dropdown-menu {
    transform: translate3d(0px, 40px, 0px) !important;
    right: 0 !important;
    left: 0 !important;
    padding: 10px;
    border: 1px solid #f4f3ff;
    box-shadow: 0px 6px 13px rgba(213, 228, 235, 0.11);
    border-radius: 5px;
    margin-top: 7px !important;
    max-height: 464px;
    min-width: 270px !important;
    width: 100%;
}

.multi-search-select-image {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px -1px -1px -3px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    left: 50%;
    top: 30px;
}

.multiselect {
    border: 1px solid #e3e2ff;
    border-radius: 5px;
    background: #fbfbff;
    height: 40px;
    padding: 8px 20px;
    text-align: left !important;
    line-height: 15px;
}

.dropdown-item img {
    margin-right: 5px;
}

.multi-drop-img {
    height: 25px;
    width: 25px !important;
    border-radius: 50%;
    margin-right: 5px;
}

.comments .comments-head h5 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 24px;
}
.comments .comments-widget-img {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
}
.comments .comments-widget-icon {
    margin: 0 10px 0;
    padding: 15px;
    background: #fff;
}
.comments .comments-widget-icon i {
    font-size: 20px;
}
.comments .comments-details-cont h6 {
    font-weight: 600;
    color: #3f4254;
    margin: 0 0 4px;
}
.comments .card-describe {
    margin: 16px 0 0;
}
.comments .card-describe p {
    font-weight: 400;
    line-height: 26px;
}
.comments .card-describe .describe-btn .badge {
    margin: 7px;
}
.comments .describe-btn {
    margin: 22px 0 0;
}
.comments .describe-btn .badge {
    padding: 7px;
    border-radius: 4px;
    font-weight: 400;
    min-width: 66px;
    justify-content: center;
}
.comments .describe-btn span {
    font-size: 12px;
    align-items: center;
}
.comments .reply-comment {
    font-weight: 600;
    font-size: 14px;
    line-height: 0;
    color: #3f4254;
}
.comments .reply-comment i {
    font-weight: 600;
    line-height: 0;
}

.radio_input .custom_radio + .custom_radio {
    margin-left: 15px;
}

.input-block .label-text {
    font-size: 14px;
    color: #455560;
    font-weight: 600;
    margin: 0 0 5px 0;
}
.check-mark-status .status-toggle .checktoggle {
    transform: translate(calc(-30% - 5px), 0%);
}
.check-mark-status .status-toggle .check:checked + .checktoggle {
    transform: translate(calc(-30% - 5px), 0%);
}

.add-customer-btns .btn {
    font-size: 14px;
}
@media (max-width: 575.98px) {
    .add-customer-btns .btn {
        max-width: inherit;
        margin: 10px;
    }
}
.add-customer-btns .customer-btn-cancel {
    color: #3f4254;
    border: 1px solid #bdbdbd;
    background: #fff;
    border-radius: 6px;
    margin: 0 12px 0 0;
    padding: 11px 22px;
}
@media (max-width: 575.98px) {
    .add-customer-btns .customer-btn-cancel {
        margin: 0 10px 0 0;
    }
}
.add-customer-btns .customer-btn-cancel:hover {
    color: #fff;
    background: #3f4254;
    border-color: #3f4254;
    box-shadow: inset 0 50px 0 0 #3f4254;
}
.add-customer-btns .customer-btn-save {
    color: #fff;
    border: 1px solid #7539ff;
    background: #7539ff;
    box-shadow: inset 0 0 0 0 #fff;
    border-radius: 6px;
    padding: 11px 22px;
}
@media (max-width: 320px) {
    .add-customer-btns .customer-btn-save {
        margin: 0 0 0 0;
        padding: 8px 15px;
    }
}
.add-customer-btns .customer-btn-save:hover {
    color: #7539ff;
    background: #fff;
    border-color: #7539ff;
    box-shadow: inset 0 50px 0 0 #fff;
}

.add-vendor-btns .btn {
    font-size: 14px;
}
@media (max-width: 320px) {
    .add-vendor-btns .btn {
        max-width: inherit;
    }
}
.add-vendor-btns .vendor-btn-cancel {
    color: #2da7ff;
    border: 1px solid #2da7ff;
    background: #fff;
    box-shadow: inset 0 0 0 0 #2da7ff;
    border-radius: 6px;
    min-width: 98px;
    margin: 0 12px 0 0;
    padding: 10px 20px;
}
@media (max-width: 320px) {
    .add-vendor-btns .vendor-btn-cancel {
        margin: 0 10px 0 0;
        padding: 8px 15px;
    }
}
.add-vendor-btns .vendor-btn-cancel:hover {
    color: #fff;
    background: #2da7ff;
    border-color: #2da7ff;
    box-shadow: inset 0 50px 0 0 #2da7ff;
}
.add-vendor-btns .vendor-btn-add {
    color: #fff;
    border: 1px solid #7539ff;
    background: #7539ff;
    box-shadow: inset 0 0 0 0 #fff;
    border-radius: 6px;
    min-width: 136px;
    margin: 0 0 0 0;
    padding: 10px 15px;
}
@media (max-width: 320px) {
    .add-vendor-btns .vendor-btn-add {
        margin: 0 0 0 0;
        padding: 8px 15px;
    }
}
.add-vendor-btns .vendor-btn-add:hover {
    color: #7539ff;
    background: #fff;
    border-color: #7539ff;
    box-shadow: inset 0 50px 0 0 #fff;
}

.iti--separate-dial-code .iti__selected-flag {
    background-color: transparent;
}

.customer-additional-form .input-block label {
    color: #282828;
}
.customer-additional-form .custom_radio .checkmark {
    top: 2px;
    border-color: #878a99;
}
.customer-additional-form .status-toggle .checktoggle:after {
    height: 18px;
    width: 18px;
    transform: translate(3px, -50%);
    -webkit-transform: translate(3px, -50%);
}
.customer-additional-form .status-toggle .check:checked + .checktoggle:after {
    transform: translate(calc(-100% - 3px), -50%);
    -webkit-transform: translate(calc(-100% - 3px), -50%);
}

.product-list-item-img {
    display: flex;
    align-items: center;
}
.product-list-item-img img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: 8px;
}
.product-list-item-img span {
    color: #1f0066;
}

.table tbody td h2.table-avatar a.product-list-item-bg {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    background: #e0e0e0;
    border-radius: 6px;
}

.input-block.add-products {
    position: relative;
}
.input-block.add-products button {
    position: absolute;
    top: 34px;
    right: 5px;
    padding: 5px;
}

.dropdown-menu.dropdown-menu-right.quatation-dropdown {
    width: 200px;
    height: 200px;
    overflow: auto;
}

.input-block input.form-control {
    color: #3f4254;
    font-size: 14px;
}

.description-box .note-fullscreen,
.description-box .note-fontname,
.description-box .note-undo,
.description-box .note-redo,
.description-box .note-fontsize,
.description-box .note-para {
    display: none;
}
.description-box .note-resizebar {
    visibility: hidden;
}
.description-box .note-frame {
    height: 180px;
    background: #fff;
}

.price-table-main .card {
    margin: 30px 0 0 0;
}
.price-table-main .price-selected {
    margin: 0;
    background: #7539ff;
    border: 1px solid #eff0f7;
    border-radius: 24px;
    color: #fff;
}
.price-table-main .plan-selected {
    margin: 0 0 30px 0;
}
.price-table-main h4 {
    font-weight: 600;
    font-size: 18px;
}
.price-table-main .status-toggle .checktoggle {
    transform: translate(calc(0% - 5px), 0%);
    width: 74px;
    height: 33px;
    border-radius: 20px;
}
.price-table-main .status-toggle .checktoggle:after {
    height: 20px;
    width: 20px;
    left: 5px;
}
.price-table-main .status-toggle .check:checked + .checktoggle {
    transform: translate(calc(0% - 5px), 0%);
}
.price-table-main .plan-header {
    margin: 0 0 16px 0;
}
.price-table-main .plan-header .plan-widget-icon {
    background: #ecebff;
    padding: 10px;
    border-radius: 16px;
}
.price-table-main .plan-header .plan-title {
    margin: 0 0 0 18px;
}
.price-table-main .plan-header .plan-title h6 {
    color: #878a99;
}
.price-table-main .plan-header .plan-name {
    font-weight: 700;
}
.price-table-main .price-popular {
    margin: 0 0 0 30px;
    padding: 12px 24px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
}
@media (max-width: 1199.98px) {
    .price-table-main .price-popular {
        margin: 0 0 0 30px;
        padding: 8px 10px;
    }
}
@media (max-width: 992px) {
    .price-table-main .price-popular {
        display: none;
    }
}
.price-table-main .description-content p {
    margin: 0 0 10px 0;
}
.price-table-main .price-dollar h1 {
    font-size: 48px;
    font-weight: 700;
    margin: 0 0 10px 0;
}
.price-table-main .price-dollar h1 span {
    font-weight: 500;
    color: #878a99;
    font-size: 13px;
}
@media (max-width: 1199.98px) {
    .price-table-main .price-dollar h1 {
        font-size: 40px;
    }
}
.price-table-main .plan-description {
    margin: 0 0 34px 0;
}
.price-table-main .plan-description h6 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 18px 0;
}
.price-table-main .plan-description ul li {
    margin: 0 0 14px 0;
}
.price-table-main .plan-description ul li:last-child {
    margin: 0;
}
.price-table-main .plan-description ul li span {
    background: #4a3aff;
    color: #fff;
    display: inline-flex;
    margin: 0;
    padding: 3px;
    line-height: 0;
}
.price-table-main .plan-description ul li span i {
    font-weight: 500;
}
.price-table-main .plan-button .btn {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
}
.price-table-main .plan-button .btn span {
    line-height: 0;
}
.price-table-main .plan-header-selected {
    margin: 30px 0 30px 0;
}

.price-selected .card-body {
    color: #fff;
}

.price-table-main .status-toggle .check:checked + .checktoggle:after {
    transform: translate(calc(-100% - 5px), -50%);
}

.kanban-ticket-main {
    overflow-x: auto;
}
.kanban-ticket-main .kanban-ticket-list {
    min-width: 240px;
    width: 240px;
}
.kanban-ticket-main .kanban-ticket-list li {
    margin: 0 8px;
}
.kanban-ticket-main .kanban-ticket-list li:last-child {
    margin-right: 0;
    margin-left: 0;
}
.kanban-ticket-main .kanban-ticket-list .kanban-ticket-grid {
    -ms-flex: 100%;
    flex: auto;
    max-width: 100%;
    margin: 0 0 16px;
    padding: 0 16px 0px 0;
}
@media (max-width: 1199.98px) {
    .kanban-ticket-main .kanban-ticket-list .kanban-ticket-grid {
        -ms-flex: 100%;
        flex: auto;
        max-width: 100%;
    }
}
.kanban-ticket-main .kanban-ticket-list .kanban-ticket-grid .kanban-ticket {
    margin-bottom: 20px;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .kanban-ticket
    .kanban-head
    h6 {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .kanban-ticket
    .kanban-head
    span {
    line-height: 0;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #ebbc2e;
}
.kanban-ticket-main .kanban-ticket-list .kanban-ticket-grid .kanban-ticket a i {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #989c9e;
}
.kanban-ticket-main .kanban-ticket-list .kanban-ticket-grid .card-kanban {
    background: #fff;
    border: 1px solid #efefef;
    box-shadow: 0px 2px 4px rgba(190, 190, 190, 0.2);
    cursor: move;
    border-radius: 5px;
    padding: 12px;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-name {
    margin: 0 0 10px;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-name
    .badge {
    padding: 10px;
    font-size: 11px;
    min-width: 66px;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-name
    i {
    font-weight: 600 !important;
    color: #989c9e;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    h6 {
    margin: 0 0 12px;
    font-size: 13px !important;
    font-weight: 600 !important;
    color: #000000;
    margin: 0 0 12px;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .ticket-due {
    margin: 0 0 12px;
    padding: 0 0 12px;
    border-bottom: 1px solid #efefef;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .ticket-due
    .badge {
    padding: 8px;
    font-size: 11px;
    font-weight: 400;
    border-radius: 3px;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-ticket-img
    li {
    margin-left: -10px;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-ticket-img
    li:first-child {
    margin: 0;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-ticket-img
    li
    span {
    border: 2px solid #fff;
    width: 26px;
    height: 26px;
    background: #eff2f5;
    border-radius: 50px;
    color: #4b4b9f;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .kanban-ticket-img
    li
    img {
    border: 2px solid #fff;
    width: 26px;
    height: 26px;
    color: #4b4b9f;
}
.kanban-ticket-main
    .kanban-ticket-list
    .kanban-ticket-grid
    .card-kanban
    .kanban-box
    .progressset
    p {
    font-size: 11px;
}

.greentext {
    background-color: #33b469;
}

.bluetext {
    background-color: #377dff;
}

.orangetext {
    background-color: #ff9900;
}

.yellowtext {
    background-color: #ff9900;
}

.redtext {
    background-color: #ff0000;
}

.selecttext {
    background: #fff;
    color: #878a99 !important;
}

.select-status select {
    border: 1px solid rgba(145, 158, 171, 0.32);
    padding: 4px 10px;
    color: #fff;
    line-height: 34px;
    border-radius: 5px;
}

.select-status select option {
    background: #fff;
    color: #333;
}

.custom-select-trigger {
    position: relative;
    display: block;
    width: 130px;
    padding: 0 84px 0 22px;
    font-size: 22px;
    font-weight: 400;
    color: #fff;
    line-height: 60px;
    background: #fff;
    border-radius: 54px;
    cursor: pointer;
}

.ticket-assigny {
    position: relative;
}
.ticket-assigny .ticket-assigned {
    position: absolute;
    left: 10px;
    top: 40px;
    z-index: 999;
}
.ticket-assigny .ticket-assigned img {
    width: 25px;
    height: 25px;
}
.ticket-assigny
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    padding-left: 40px;
}

.alertify .ajs-footer {
    border-top: 1px solid #eee;
}
.alertify .ajs-footer .ajs-buttons .ajs-button {
    font-weight: 500;
    background-color: transparent;
    color: #000;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #5156be;
}
.alertify .ajs-footer .ajs-buttons.ajs-primary {
    text-align: right;
}
.alertify .ajs-body .ajs-content .ajs-input {
    display: block;
    width: 100%;
    padding: 8px;
    margin: 4px;
    border-radius: 2px;
    border: 1px solid #ccc;
}
.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
    outline: 0;
}
.alertify .ajs-commands {
    right: 4px;
    left: auto;
    margin: -14px 0 0 24px;
}
.alertify .ajs-header {
    border-bottom: 1px solid #eee;
}

.alertify-notifier .ajs-message {
    background-color: #5156be;
    border-color: #5156be;
    color: #fff;
    text-shadow: none !important;
}
.alertify-notifier .ajs-message.ajs-success {
    background-color: #33b469;
    border-color: #33b469;
}
.alertify-notifier .ajs-message.ajs-error {
    background-color: #fd625e;
    border-color: #fd625e;
}
.alertify-notifier .ajs-message.ajs-warning {
    background-color: #ffbf53;
    border-color: #ffbf53;
}
.alertify-notifier.ajs-right {
    right: 10px;
    left: auto;
}
.alertify-notifier.ajs-right .ajs-message {
    right: -320px;
    left: auto;
}
.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 290px;
    left: auto;
}
.alertify-notifier.ajs-left {
    left: 10px;
    right: auto;
}
.alertify-notifier.ajs-left .ajs-message {
    left: -300px;
    right: auto;
}
.alertify-notifier.ajs-left .ajs-message.ajs-visible {
    left: 0;
    right: auto;
}

body[data-layout-mode="dark"] .alertify .ajs-dialog {
    background-color: #313533;
}
body[data-layout-mode="dark"] .alertify .ajs-body {
    color: #adb5bd;
}
body[data-layout-mode="dark"] .alertify .ajs-body .ajs-content .ajs-input {
    color: #adb5bd;
    background-color: #363a38;
    border: 1px solid #3b403d;
}
body[data-layout-mode="dark"] .alertify .ajs-footer .ajs-buttons .ajs-button {
    color: #adb5bd;
}
body[data-layout-mode="dark"]
    .alertify
    .ajs-footer
    .ajs-buttons
    .ajs-button.ajs-ok {
    color: #5156be;
}

body[data-layout-mode="dark"] .alertify .ajs-footer,
body[data-layout-mode="dark"] .alertify .ajs-header {
    color: #ced4da;
    background-color: #313533;
    border-color: #3b403d;
}

.swal2-actions button {
    margin-right: 10px;
}

.swal2-title {
    font-size: 22px !important;
}

.blog {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 25px;
    border: 2px solid #efefef;
    position: relative;
}

.grid-blog .blog-image {
    border-radius: 10px;
}
.grid-blog .entry-meta {
    flex-wrap: wrap;
    margin-bottom: 0;
    padding: 0;
}
.grid-blog .entry-meta li {
    margin-bottom: 15px;
}
.grid-blog .post-widget {
    background: #f3f3f9;
    font-size: 12px;
    border-radius: 6px;
    line-height: 17px;
    padding: 5px;
    color: #3f4254;
    margin-right: 10px;
}
.grid-blog .post-author {
    width: 189px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.grid-blog .blog-title {
    font-size: 16px;
    margin: 0 0 6px;
}
.grid-blog .blog-title a {
    color: #333;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.grid-blog .blog-title a:hover {
    color: #7539ff;
}
.grid-blog .blog-content p {
    margin: 0 0 14px 0;
    color: #878a99;
}

.blog-image {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
}
.blog-image > a {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
}
.blog-image img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 10px;
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.blog-content {
    position: relative;
    padding: 20px;
}

.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}

.back-btn {
    font-weight: 600;
    font-size: 16px;
    color: #7539ff;
    display: inline-block;
    margin-bottom: 18px;
}

.post-author span:nth-child(1):hover {
    color: #7539ff;
}
.post-author img {
    border-radius: 50px;
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.post-title {
    display: block;
    color: #092c4c;
    font-weight: 600;
    font-size: 14px;
}

.post-date {
    font-size: 12px;
    color: #7e84a3;
}

.blog-views {
    position: absolute;
    top: 20px;
    right: 12px;
    background: #fff;
    border-radius: 6px;
    padding: 2px 5px;
    font-size: 12px;
    text-align: center;
    display: block;
    min-width: 47px;
}
.blog-views img {
    width: auto;
    margin-right: 5px;
}

.blog-catagories {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #fc8543;
    border-radius: 6px;
    min-width: 92px;
    padding: 6px 10px;
}
.blog-catagories p {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
}

.edit-delete-btn a {
    margin-right: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #9e9e9e;
}
.edit-delete-btn a:nth-child(1) {
    margin-right: 6px;
}
.edit-delete-btn a:nth-child(2) {
    color: #9e9e9e;
}

.btn-blog {
    padding: 10px 22px;
}

.edit-options {
    flex-wrap: wrap;
    line-height: 0;
}

.active-text {
    color: #1b5a90;
}

.modal.contentmodal .modal-content .submit-section .btn.btn-download {
    min-width: 200px;
}

.list-links {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.list-links li {
    margin: 0 15px 10px 0;
    list-style: none;
}
.list-links li a {
    font-size: 14px;
    color: #092c4c;
    border: 1px solid #f5f6fa;
    display: inline-block;
    border-radius: 5px;
    padding: 12px 30px;
}
.list-links li a:hover {
    background: #e5e5e8;
}
.list-links li a span {
    font-size: 14px;
}
.list-links li.active a {
    color: #7539ff;
}

.inactive-style {
    font-size: 14px;
}

.select-by
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: #092c4c;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 4px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 45%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.select-by
    .select2-container--default.select2-container--open
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: #092c4c;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -2px;
}

.modal-select-box
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: #092c4c;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 4px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 45%;
    width: 0;
    transform: rotate(224deg);
    -webkit-transform: rotate(224deg);
    border-color: #092c4c;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -2px;
}

.pagination-tab {
    padding: 30px;
}

.header.fixed-header .user-menu.nav > li.view-btn > a {
    background-color: #fff;
    color: #000000;
}
.header.fixed-header .user-menu.nav > li.view-btn > a:hover {
    background-color: #d19eb7;
    color: #fff;
}

.status-toggle {
    display: flex;
    align-items: center;
}
.status-toggle span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
}

.change-photo-btn {
    color: #7e84a3;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    padding: 15px;
    position: relative;
    text-align: center;
    height: 120px;
    background: #fafafa;
    border: 1px dashed #e1e1e1;
    vertical-align: middle;
    margin: auto;
    margin-bottom: 10px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.change-photo-btn input.upload {
    bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100px;
    width: 100%;
}

.blog-categories-btn {
    padding: 20px;
}

.page-header {
    margin-bottom: 1.875rem;
}
@media (max-width: 768px) {
    .page-header {
        margin-bottom: 0.9375rem;
    }
}
.page-header .page-title {
    color: #333;
    margin-bottom: 5px;
}

.bootstrap-tagsinput {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 0;
    box-shadow: unset;
    display: flex;
    align-items: center;
    overflow-x: auto;
    min-height: 44px;
    padding: 10px;
}
.bootstrap-tagsinput .bg-info {
    background-color: #7539ff !important;
    color: #fff;
}
.bootstrap-tagsinput .badge-info {
    color: #878a99;
    background: #f6f2ff;
    border-radius: 50px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
    color: #ed3a3a;
    border-radius: 50px;
    padding: 3px 5px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
    content: "\f057";
    padding: 0px 2px;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    font-size: 18px;
}
.bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
}
.bootstrap-tagsinput .badge {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0px;
    padding: 5px 2px 5px 10px;
    display: flex;
    align-items: center;
    width: max-content;
}

.chat-window {
    position: relative;
}
.chat-window .card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #e8ebed;
}
.chat-window .badge-success {
    background-color: #33b469 !important;
}

.chat-page .footer {
    display: none;
}

.chat-search .input-group .form-control {
    margin: 0;
    padding: 0 0 0 36px;
    height: 46px;
}
.chat-search .input-group .input-group-prefix {
    color: #5e5873;
    pointer-events: none;
    z-index: 4;
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
}

.chat-cont-left .chat-scroll {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    padding: 0;
}
.chat-cont-left .chat-scroll .chat-block {
    border-bottom: 1px solid #e8ebed;
    margin: 0;
    padding: 10px;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}
.chat-cont-left .chat-scroll .chat-block.active {
    background-color: #f5f5f5;
}
.chat-cont-left .chat-scroll .chat-block:hover {
    background-color: #f5f5f5;
}
.chat-cont-left .chat-scroll .chat-block .media-img-wrap {
    margin: 0 15px 0 0;
    position: relative;
}
.chat-cont-left .chat-scroll .chat-block .media-img-wrap .avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.chat-cont-left .chat-scroll .chat-block .media-body .user-name {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #28084b;
    font-size: 15px;
    font-weight: 500;
}
.chat-cont-left .chat-scroll .chat-block .media-body .user-last-chat {
    color: #333;
    font-size: 14px;
    line-height: 24px;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-cont-left .chat-scroll .chat-block .media-body .last-chat-time {
    color: #9e9e9e;
    font-size: 13px;
}
.chat-cont-left .chat-scroll .chat-block .media-body div:last-child {
    text-align: left;
}

.chat-cont-right {
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
}
.chat-cont-right .chat-scroll {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}
.chat-cont-right .msg_head .back-user-list {
    display: none;
    margin: 0 0 0 -10px;
    padding: 0 10px;
}
@media (max-width: 991.98px) {
    .chat-cont-right .msg_head .back-user-list {
        display: block;
    }
}
.chat-cont-right .img_cont {
    position: relative;
    height: 45px;
    width: 45px;
}
.chat-cont-right .img_cont .user_img {
    height: 45px;
    min-width: 45px;
    width: 45px;
    border: 1.5px solid #7539ff;
}
.chat-cont-right .user_info {
    margin: 0 auto 0 15px;
    padding: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-cont-right ul.list-unstyled {
    margin: 0 auto;
    padding: 0;
    width: 100%;
}
.chat-cont-right ul.list-unstyled .chat-block.sent {
    margin-bottom: 20px;
}
.chat-cont-right ul.list-unstyled .chat-block.sent .avatar {
    order: 3;
    height: 30px;
    width: 30px;
}
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body {
    margin: 0 20px 0 0;
    padding: 0;
}
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .msg-box {
    margin: 0;
    padding: 0 0 0 50px;
    position: relative;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .msg-box
    + .msg-box {
    margin: 5px 0 0;
    padding: 0;
}
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .msg-box p {
    margin: 0;
    padding: 0;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .msg-box:first-child::before {
    border-bottom: 6px solid transparent;
    border-left: 6px solid #e3e3e3;
    border-top: 6px solid #e3e3e3;
    border-right: 6px solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    right: -12px;
    left: auto;
}
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .msg-box > div {
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background-color: #e3e3e3;
    display: inline-block;
    position: relative;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .msg-box:first-child
    > div {
    border-radius: 10px 0 10px 10px;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments {
    margin: 0 -1px;
    padding: 4px 0;
    width: 100%;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments
    .chat-attachment {
    position: relative;
    max-width: 130px;
    overflow: hidden;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments
    .chat-attachment:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    content: "";
    opacity: 0.4;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments
    .chat-attachment:hover
    .chat-attach-download {
    opacity: 1;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments
    > div {
    margin: 0 1px;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments
    img {
    max-width: 100%;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-attachments
    .chat-attach-download {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    color: #fff;
    width: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
}
.chat-cont-right ul.list-unstyled .chat-block.sent .media-body .chat-msg-info {
    margin: 5px 0 0;
    padding: 0;
    clear: both;
    flex-direction: row-reverse;
    list-style: none;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-info
    li {
    margin: 0;
    padding: 0 0 0 16px;
    position: relative;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-info
    li:not(:last-child)::after {
    position: absolute;
    top: 50%;
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #7539ff;
    content: "";
    height: 4px;
    width: 4px;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-info
    .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.sent
    .media-body
    .chat-msg-info
    a {
    color: #333;
}
.chat-cont-right ul.list-unstyled .chat-block.received {
    position: relative;
    margin-bottom: 20px;
}
.chat-cont-right ul.list-unstyled .chat-block.received .media-body {
    position: relative;
    margin: 0 0 0 10px;
    padding: 0;
}
.chat-cont-right ul.list-unstyled .chat-block.received .media-body::before {
    border-bottom: 6px solid transparent;
    border-right: 0;
    border-top: 6px solid #fff;
    border-left: 0;
    content: "";
    height: 0;
    position: absolute;
    top: 0px;
    right: auto;
    left: -6px;
    width: 0;
}
.chat-cont-right ul.list-unstyled .chat-block.received .media-body > div {
    margin: 0;
    padding: 10px 15px;
    border-radius: 10px;
    display: table;
    background: #fff;
    position: relative;
}
.chat-cont-right ul.list-unstyled .chat-block.received .media-body > div p {
    color: #fff;
    margin: 0;
    padding: 0;
}
.chat-cont-right ul.list-unstyled .chat-block.received .msg-box {
    display: block;
}
.chat-cont-right ul.list-unstyled .chat-block.received .msg-box + .msg-box {
    margin-top: 5px;
}
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-info {
    margin: 5px 0 0;
    padding: 0;
    clear: both;
    list-style: none;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.received
    .chat-msg-info
    .chat-time {
    color: rgba(255, 255, 255, 0.8);
}
.chat-cont-right ul.list-unstyled .chat-block.received .chat-msg-attachments {
    margin: 0 -1px;
    padding: 4px 0;
    width: 100%;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.received
    .chat-msg-attachments
    .chat-attachment {
    position: relative;
    max-width: 130px;
    overflow: hidden;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.received
    .chat-msg-attachments
    .chat-attachment:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    content: "";
    opacity: 0.4;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.received
    .chat-msg-attachments
    .chat-attachment:hover
    .chat-attach-download {
    opacity: 1;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.received
    .chat-msg-attachments
    > div {
    margin: 0 1px;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.received
    .chat-msg-attachments
    img {
    max-width: 100%;
}
.chat-cont-right
    ul.list-unstyled
    .chat-block.received
    .chat-msg-attachments
    .chat-attach-download {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    opacity: 0;
    color: #fff;
    width: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
}
.chat-cont-right ul.list-unstyled .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-cont-right ul.list-unstyled .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    top: 50%;
    right: 50%;
    width: 100%;
}
.chat-cont-right ul.list-unstyled .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin: 0 0 0 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
}

.card-footer {
    background-color: #fff;
    border-top: 1px solid #e8ebed;
    margin: 0;
    padding: 1rem 1.5rem;
}
.card-footer .type_msg {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border: 0 !important;
    overflow-y: auto;
}

@media (max-width: 992px) {
    .chat-window.chat-slide .chat-cont-right {
        right: 0;
        opacity: 1;
        visibility: visible;
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
    }
    .chat-cont-right {
        position: absolute;
        right: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
    }
    .chat-cont-right .msg_head .back-user-list {
        display: flex;
        align-items: center;
    }
    .chat-window
        .chat-cont-right
        .chat-header
        .chat-block
        .media-body
        .user-name {
        font-size: 14px;
    }
}
.msg-typing {
    width: auto;
    height: 24px;
    padding-top: 8px;
}
.msg-typing span {
    height: 8px;
    width: 8px;
    float: left;
    background-color: #d9d9d9;
    display: block;
    opacity: 0.4;
    margin: 0 1px;
    border-radius: 50%;
}
.msg-typing span:nth-of-type(1) {
    animation: 1s blink infinite 0.33333s;
}
.msg-typing span:nth-of-type(2) {
    animation: 1s blink infinite 0.66666s;
}
.msg-typing span :nth-of-type(3) {
    animation: 1s blink infinite 0.99999s;
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}
.page-wrapper {
    margin-left: 247px;
    padding-top: 60px;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
@media (max-width: 1199.98px) {
    .page-wrapper {
        margin-left: 200px;
    }
}
.page-wrapper .content {
    padding: 25px;
}
@media (max-width: 1199.98px) {
    .page-wrapper .content {
        padding: 20px;
    }
}
@media (max-width: 991.98px) {
    .page-wrapper .content {
        padding: 15px;
    }
}
@media (max-width: 991.98px) {
    .page-wrapper {
        margin: 0;
        padding-top: 60px;
    }
    .custom-content-wrapper {
        margin: 0;
    }
}

.content-page-header {
    align-items: center;
    -webkit-align-items: center;
    margin: 0 0 30px 0;
}
@media (max-width: 575.98px) {
    .content-page-header {
        display: block;
    }
}
@media (max-width: 991.98px) {
    .content-page-header {
        flex-wrap: wrap;
        margin: 0 0 30px 0;
    }
}
.content-page-header h5 {
    font-size: 24px;
    color: #0f0033;
    font-weight: 600;
    margin: 0;
    line-height: 32px;
}
@media (max-width: 768px) {
    .content-page-header h5 {
        font-size: 18px;
    }
}
.content-page-header h6 {
    font-size: 20px;
    color: #28084b;
    font-weight: 600;
    margin: 0;
}

.role-testing h6 {
    font-size: 18px;
    font-weight: 600;
}
.role-testing h6 span {
    color: #7539ff;
}

.role-data {
    font-weight: 600 !important;
    color: #3f4254 !important;
}

.content-invoice-header {
    margin: 0 0 41px 0;
}
@media (max-width: 768px) {
    .content-invoice-header {
        margin: 0 0 25px 0;
    }
}
.content-invoice-header h5 {
    font-size: 24px;
    color: #28084b;
    font-weight: 600;
    margin: 0;
}
@media (max-width: 768px) {
    .content-invoice-header h5 {
        font-size: 18px;
    }
}

.btn-import {
    border: 1px solid #7539ff;
    color: #7539ff;
    background: #fff;
    font-weight: 600;
    transition: all 0.5s;
}
@media (max-width: 575.98px) {
    .btn-import {
        padding: 6px 8px;
    }
}
.btn-import:hover {
    background: #7539ff;
    color: #fff;
}

.card {
    background: #fff;
    -webkit-box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    margin: 0 0 24px 0;
    display: inline-block;
    width: 100%;
    border: 0;
    border-radius: 8px;
}
.card .card-header {
    border-color: #f0f1f5;
    background-color: #fff;
    padding: 1.5rem;
}
.card .card-header:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
@media (max-width: 1199.98px) {
    .card .card-header {
        padding: 15px;
    }
}
.card .card-body {
    position: relative;
    padding: 1.5rem;
}
@media (max-width: 1199.98px) {
    .card .card-body {
        padding: 15px;
    }
}
.card .card {
    position: relative;
    padding: 20px;
}
@media (max-width: 1199.98px) {
    .card .card {
        padding: 15px;
    }
}

.accord-btn {
    margin: 10px 0 0;
}

.dash-widget-header .dash-widget-icon {
    color: #fff;
    display: inline-flex;
    font-size: 1.875rem;
    height: 4rem;
    text-align: center;
    width: 4rem;
    border-radius: 10px;
}
.dash-widget-header .dash-count {
    margin-left: 15px;
}
@media (max-width: 1399.98px) {
    .dash-widget-header .dash-count {
        margin-left: 12px;
    }
}
.dash-widget-header .dash-count .dash-title {
    font-weight: 400;
    font-size: 13px;
    color: #878a99;
}
.dash-widget-header .dash-count .dash-counts p {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin: 0 0 0px 0;
}
@media (max-width: 1399.98px) {
    .dash-widget-header .dash-count .dash-counts p {
        font-size: 14px;
    }
}

.bg-1 {
    background-color: #ffeec3;
}
.bg-1 i {
    color: #ffb800;
}

.bg-2 {
    background-color: #d1f3ff;
}
.bg-2 i {
    color: #42cdff;
}

.bg-3 {
    background-color: #ceffd5;
}
.bg-3 i {
    color: #52fb6a;
}

.bg-4 {
    background-color: #fccaff;
}
.bg-4 i {
    color: #f555ff;
}

.bg-5 {
    background-color: #ffb800;
}

.bg-6 {
    background-color: #42cdff;
}

.bg-7 {
    background-color: #52fb6a;
}

.bg-8 {
    background-color: #f555ff;
}

.progress.progress-sm {
    height: 6px;
}
.progress.progress-md {
    height: 8px;
}

.text-success,
.dropdown-menu > li > a.text-success {
    color: #22cc62 !important;
}

.bg-success {
    background-color: #22cc62 !important;
}

.text-danger,
.dropdown-menu > li > a.text-danger {
    color: #ff0000 !important;
}

.text-success-light {
    color: #33b469 !important;
}

.text-warning-light {
    color: #ebbc2e;
}

.warning-border {
    border: 1px solid #ebbc2e;
    padding: 0 3px;
    border-radius: 50px;
}

.danger-border {
    border: 1px solid #ff0000;
    padding: 0 3px;
    border-radius: 50px;
}

.info-border {
    border: 1px solid #377dff;
    padding: 0 3px;
    border-radius: 50px;
}

.text-primary {
    color: #7539ff !important;
}

.text-gray-light {
    color: #878a99;
}

.text-gray-dark {
    color: #3f4254;
}

.text-danger-light {
    color: #ed3a3a;
}

.text-muted {
    color: #b5b5c3 !important;
}

.bg-light-gray {
    background: #f3f3f3;
}

.bg-info-light {
    background: #ddf3ff;
}

.bg-info-blue {
    background: #e9f2ff;
}

.bg-primary-light {
    background: #eaddff;
}

.bg-warning-light {
    background: #fff6dc;
}

.bg-blue-light {
    background: #e9f2ff;
}

.bg-green-light {
    background: #e1ffed;
}

.bg-danger-light {
    background: #ffecec;
}

.bg-white-smoke {
    background: #efefef;
}

.bg-ash-gray {
    background: #f6f2ff;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}

.dropdown-item {
    font-size: 12px;
    padding: 8px 10px;
}

.input-block {
    font-weight: 500;
    font-size: 14px;
    color: #282828;
}
.input-block.status-grid {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
}
.input-block label {
    margin: 0 0 8px 0;
    font-weight: 500;
    font-size: 14px;
    color: #282828;
}
.input-block .optional {
    font-weight: 400;
    font-size: 12px;
    color: #878a99;
}
.input-block .form-control {
    border: 1px solid #e0e0e0;
    box-shadow: none;
    border-radius: 4px;
    margin: 0;
    padding: 9px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #3f4254;
    transition-duration: 0.3s;
}
.form-control:hover {
    border-color: #4096ff;
}
.input-block .form-control ::placeholder,
.input-block .form-control ::-webkit-input-placeholder,
.input-block .form-control :-ms-input-placeholder {
    color: #878a99;
    font-size: 14px;
    font-weight: 500;
}
.input-block .form-control .form-title {
    font-weight: 600;
    font-size: 16px;
    color: #28084b;
    margin: 0 0 25px 0;
}
.input-block textarea.form-control {
    height: auto;
}
.input-block .intl-tel-input,
.input-block .iti {
    width: 100%;
}
.input-block .note-toolbar {
    background: #fff;
}
.input-block .note-editable {
    background: #fff;
}
.input-block .note-resizebar {
    height: 0 !important;
}
.input-block .toggle-password {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, 50%);
}
.input-block .feather-eye-off {
    color: #878a99;
}
.input-block .feather-eye {
    margin-right: 1px;
    color: #878a99;
}

.notes-form-group textarea.form-control {
    height: 80px;
    resize: none;
}

.notes-form-group-info textarea.form-control {
    height: 117px;
    resize: none;
}

.pass-group {
    position: relative;
}

.form-upload-file {
    position: relative;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: none;
    border-radius: 4px;
    margin: 0;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #878a99;
    text-align: left;
}
.form-upload-file input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.form-upload-label {
    margin: 0 0 8px 0;
    font-weight: 500;
    font-size: 14px;
    color: #282828;
}

@media (max-width: 1199.98px) {
    .form-group-bank {
        margin: 0 0 20px 0;
    }
}
.form-group-bank .bank-details {
    background: #fff;
    border: 3px dashed #e5e7eb;
    border-radius: 8px;
    padding: 25px;
    text-align: center;
}

.form-group-item {
    border-bottom: 1px solid #efefef;
    margin: 0 0 30px 0;
    padding: 0 0 20px 0;
}
@media (max-width: 768px) {
    .form-group-item {
        margin: 0 0 20px 0;
        padding: 0 0 15px 0;
    }
}
.form-group-item .form-group-plus li {
    -ms-flex: 100%;
    flex: auto;
    max-width: 100%;
}
.form-group-item .form-group-plus li:last-child {
    -ms-flex: 0%;
    flex: auto;
    max-width: 0%;
    margin: 0 0 0 10px;
}
.form-group-item .form-group-plus li:last-child span {
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}
.form-group-item .form-group-plus .form-plus-btn {
    padding: 8px 12px;
    width: 42px;
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.form-group-item .css-equal-heights {
    width: calc(100% - 40px);
}

.form-group-customer {
    border-bottom: 1px solid #efefef;
    margin: 0 0 30px 0;
    padding: 0 0 8px 0;
}

.form-title {
    font-weight: 600;
    font-size: 16px;
    color: #1f0066;
    margin: 0 0 20px 0;
}

.form-group-add {
    margin: 0 0 10px 0;
}
.form-group-add .input-block .form-control {
    margin: 0 0 8px 0;
}
.form-group-add .input-block span {
    color: #b5b5c3;
    font-size: 12px;
    font-weight: 400;
}
.form-group-add .booking-option {
    margin: 40px 0 0 0;
}
@media (max-width: 575.98px) {
    .form-group-add .booking-option {
        margin: 0;
    }
}
.form-group-add .status-toggle .checktoggle {
    transform: translate(calc(30% - 5px), -15%);
    width: 50px;
    height: 26px;
    border-radius: 20px;
}
.form-group-add .status-toggle .checktoggle:after {
    width: 22px;
    height: 22px;
}
.form-group-add .status-toggle .check:checked + .checktoggle {
    transform: translate(calc(30% - 5px), -15%);
    background-color: #7539ff;
    border: 1px solid #7539ff;
}

.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    border-radius: 10px;
    padding: 0;
}
.page-header .breadcrumb a {
    color: #333;
    font-size: 1rem;
}

.customer-details-group .card-body {
    padding-bottom: 0px;
}

.invoices-items-main-tabs {
    border-bottom: 3px solid #f3f3f3;
    padding-bottom: 10px;
}
.invoices-items-main-tabs ul li {
    display: inline-block;
    padding-left: 36px;
    padding-right: 36px;
    position: relative;
}
.invoices-items-main-tabs ul li:last-child {
    padding-right: 0;
}
.invoices-items-main-tabs ul li a {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    padding-bottom: 24px;
    position: relative;
}
.invoices-items-main-tabs ul li a.active:after {
    content: "";
    width: 130px;
    height: 6px;
    background-color: #7539ff;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    position: absolute;
    bottom: 4px;
    left: 50%;
}

.btn-close {
    background-color: #f3f3f9;
    color: #1b2559;
    border-radius: 50px;
    font-size: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0 !important;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}

.bank-details-btn .btn {
    min-width: 160px;
    border-radius: 8px;
    padding: 10px 0;
    color: #fff;
}
.bank-details-btn .bank-cancel-btn {
    background: #1b2559;
}
.bank-details-btn .bank-cancel-btn:hover {
    background: #7539ff;
}
.bank-details-btn .bank-save-btn {
    background: #7539ff;
}
.bank-details-btn .bank-save-btn:hover {
    background: #1b2559;
}

.bank-details .bank-inner-details textarea {
    min-height: 100px;
}

.filter-card {
    display: none;
}
.filter-card.open {
    display: block;
}

label {
    margin-bottom: 0.5rem;
}

.contact-details {
    display: flex;
    flex-flow: row wrap;
    padding-left: 0;
    list-style: none;
    margin: 0 0 42px;
}
@media (max-width: 991.98px) {
    .contact-details {
        margin: 0 0 42px;
    }
}
.contact-details li {
    -ms-flex: 16.66%;
    flex: 16.66%;
    max-width: 16.66%;
}
@media (max-width: 1299.98px) {
    .contact-details li {
        -ms-flex: 33.33%;
        flex: 33.33%;
        max-width: 33.33%;
        margin: 0 0 25px;
    }
}
@media (max-width: 575.98px) {
    .contact-details li {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
        margin: 0 0 25px;
    }
}
.contact-details li .contact-square {
    background: #f7f7ff;
    min-width: 38px;
    height: 38px;
    margin: 0 10px 0 0;
}
.contact-details li p {
    color: #3f4254;
}
.contact-details li span i {
    font-size: 14px;
}

.note-editor.note-frame {
    border-color: #e0e0e0;
}

.note-editor.note-frame .note-statusbar {
    border-top: 0;
}

.bootstrap-datetimepicker-widget {
    font-size: 13px;
}

.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #7638ff;
}

.bg-primary-light {
    background-color: rgba(17, 148, 247, 0.12) !important;
    color: #2196f3 !important;
}

.bg-danger-light {
    background-color: rgba(255, 218, 218, 0.49) !important;
    color: #ff0000 !important;
}

.bg-purple-light {
    background-color: rgba(197, 128, 255, 0.12) !important;
    color: #c580ff !important;
}

.bg-default-light {
    background-color: rgba(40, 52, 71, 0.12) !important;
    color: #283447 !important;
}

.bg-success-light {
    background-color: #e1ffed !important;
    color: #33b469 !important;
}

.card-two {
    border: 1px solid #dbdfea;
    border-radius: 5px;
}

.badge-soft-primary {
    color: #405189;
    background-color: rgba(64, 81, 137, 0.1);
}

.badge-soft-secondary {
    color: #3577f1;
    background-color: rgba(53, 119, 241, 0.1);
}

.badge-soft-success {
    color: #0ab39c;
    background-color: rgba(10, 179, 156, 0.1);
}

.badge-soft-info {
    color: #299cdb;
    background-color: rgba(41, 156, 219, 0.1);
}

.badge-soft-warning {
    color: #f7b84b;
    background-color: rgba(247, 184, 75, 0.1);
}

.badge-soft-danger {
    color: #f06548;
    background-color: rgba(240, 101, 72, 0.1);
}

.badge-soft-dark {
    color: #212529;
    background-color: rgba(33, 37, 41, 0.1);
}

.badge-soft-light {
    color: #f3f6f9;
    background-color: rgba(243, 246, 249, 0.1);
}

.badge-outline-primary {
    color: #405189;
    border: 1px solid #405189;
    background-color: transparent;
}

.badge-outline-secondary {
    color: #3577f1;
    border: 1px solid #3577f1;
    background-color: transparent;
}

.badge-outline-success {
    color: #0ab39c;
    border: 1px solid #0ab39c;
    background-color: transparent;
}

.badge-outline-info {
    color: #299cdb;
    border: 1px solid #299cdb;
    background-color: transparent;
}

.badge-outline-warning {
    color: #f7b84b;
    border: 1px solid #f7b84b;
    background-color: transparent;
}

.badge-outline-danger {
    color: #f06548;
    border: 1px solid #f06548;
    background-color: transparent;
}

.badge-outline-dark {
    color: #212529;
    border: 1px solid #212529;
    background-color: transparent;
}

.badge-outline-light {
    color: #f3f6f9;
    border: 1px solid #f3f6f9;
    background-color: transparent;
}

.badge-gray-outline {
    background-color: #fff;
    border: 1px solid #eff2f5;
    color: #878a99;
}

.badge-gradient-primary {
    background: linear-gradient(135deg, #405189 0, #0ab39c 100%);
}

.badge-gradient-secondary {
    background: linear-gradient(135deg, #3577f1 0, #299cdb 100%);
}

.badge-gradient-success {
    background: linear-gradient(135deg, #0ab39c 0, #f7b84b 100%);
}

.badge-gradient-danger {
    background: linear-gradient(135deg, #f06548 0, #3577f1 100%);
}

.badge-gradient-warning {
    background: linear-gradient(135deg, #f7b84b 0, #eb990a 100%);
}

.badge-gradient-info {
    background: linear-gradient(135deg, #299cdb 0, #0ab39c 100%);
}

.badge-gradient-dark {
    background: linear-gradient(135deg, #212529 0, #405189 100%);
}

li.previous {
    margin-right: 10px;
}

.card-title {
    margin-bottom: 0;
    border-radius: 10px;
}

.cal-icon {
    position: relative;
    width: 100%;
}
.cal-icon:after {
    color: #979797;
    content: "\f073";
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}

.cal-icon-info:after {
    font-family: "Feather";
    content: "\e926";
    color: #878a99;
    font-weight: 400;
    font-size: 18px;
    top: 8px;
}

.btn-back {
    background: #fff;
    border: 1px solid #bdbdbd;
    box-shadow: inset 0 0 0 0 #3f4254;
    border-radius: 6px;
    color: #3f4254;
    min-width: 69px;
}
.btn-back:hover {
    background: #3f4254;
    border: 1px solid #3f4254;
    box-shadow: inset 0 0 0 0 #fff;
    color: #fff;
}

body.filter-opened {
    overflow: hidden;
}

.page-header .btn {
    border-radius: 8px;
}

.input-block .form-control-sm {
    padding: 0.25rem 0.5rem;
}
.input-block .form-control-lg {
    padding: 0.75rem 1.25rem;
}

.toggle-sidebar {
    width: 400px;
    position: fixed;
    top: 0;
    right: -400px;
    height: 100vh;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    transition: all 0.8s ease;
    z-index: 1002;
}
@media (max-width: 575.98px) {
    .toggle-sidebar {
        width: 250px;
        right: -250px;
    }
}
.toggle-sidebar.open-filter {
    right: 0;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    transition: all 0.8s ease;
}
.toggle-sidebar .sidebar-layout-filter {
    background: #fff;
    box-shadow: 0px 16px 32px rgba(65, 152, 255, 0.2);
    border-radius: 10px 0px 0px 10px;
    max-width: 400px;
    width: 100%;
    position: absolute;
    right: 0;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}
@media (max-width: 575.98px) {
    .toggle-sidebar .sidebar-layout-filter {
        width: 250px;
    }
}
.toggle-sidebar .sidebar-header {
    padding: 20px;
    border-bottom: 1px solid #eff2f5;
    align-items: center;
}
.toggle-sidebar .sidebar-header h5 {
    font-weight: 600;
    font-size: 18px;
    color: #28084b;
    margin: 0;
}
.toggle-sidebar .sidebar-header a {
    color: #292d32;
    font-size: 20px;
}
.toggle-sidebar .sidebar-header .dropdown-item {
    font-size: 16px;
}
.toggle-sidebar .sidebar-body {
    padding: 20px;
}
.toggle-sidebar .sidebar-body .accordion {
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 15px;
}
.toggle-sidebar .sidebar-body .accordion .filter-title {
    margin-bottom: 15px;
}
.toggle-sidebar .sidebar-body .accordion .filter-title a {
    font-weight: 500;
    font-size: 14px;
    color: #28084b;
}
.toggle-sidebar .sidebar-body .accordion .view-all {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 12px;
    color: #7539ff;
}
.toggle-sidebar .sidebar-body .accordion-last {
    border: 0;
}
.toggle-sidebar .form-custom .form-control {
    padding: 8px 30px;
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
}
.toggle-sidebar .form-custom .form-control:focus {
    border-color: #000;
}
.toggle-sidebar .form-custom span img {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.open-filter .custom_check {
    color: #808080;
}
.open-filter i {
    transition: 0.2s;
}

.accordion .collapsed i {
    color: #878a99;
    transition: 0.2s;
    transform: rotate(-90deg);
}
.accordion .float-end {
    color: #878a99;
}

@media (max-width: 767px) {
    .list-btn {
        margin-top: 15px;
    }
}
.list-btn .filter-list .short-filter {
    border: 1px solid #e6e9e8;
    border-radius: 8px;
    color: #878a99;
    font-size: 14px;
    height: 39px;
    padding: 10px;
    background: #fff;
}
.list-btn .filter-list .short-filter .sort {
    background: transparent;
    border: none;
    outline: 0;
    font-size: 12px;
    font-weight: 600;
    color: #3f4254;
}
.list-btn .filter-list li {
    padding: 0 5px 0 0;
}
@media (max-width: 767px) {
    .list-btn .filter-list li {
        padding: 0 5px 5px 0;
    }
}
.list-btn .filter-list li a.active {
    color: #7539ff !important;
}
.list-btn .filter-list li ul li {
    padding: 0;
    margin: 0 0 10px;
}
.list-btn .filter-list li ul li .download-item {
    color: #808080;
    font-size: 14px;
    margin: auto;
}
.list-btn .filter-list li ul li .download-item:hover {
    color: #7539ff;
}
.list-btn .filter-list li ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}
.list-btn .btn-filters {
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: #878a99;
    min-width: 39px;
    height: 39px;
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.list-btn .btn-filters span {
    margin-bottom: 0;
    line-height: 0;
    font-size: 18px;
}
.list-btn .btn-filters:hover {
    box-shadow: 0 40px 0 0 #eaddff inset;
    border-color: #eaddff;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    color: #7539ff;
}
.list-btn .btn-filters:hover i {
    color: #7539ff;
}
.list-btn .btn-filters:hover img {
    /*filter: invert(1) brightness(100);*/
    filter: invert(44%) sepia(93%) saturate(5806%) hue-rotate(247deg)
        brightness(95%) contrast(113%);
}
.list-btn .dropdown-menu {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 16px 32px rgba(190, 190, 190, 0.2);
    border-radius: 12px;
    padding: 10px;
    margin-top: 10px !important;
}
.list-btn .dropdown-menu .dropdown-item {
    font-weight: 500;
    font-size: 14px;
    color: #878a99;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 767px) {
    .filter-list {
        flex-wrap: wrap;
    }
}

.filter-buttons {
    display: flex;
    align-items: center;
}
.filter-buttons button.btn-secondary {
    background: #efefef;
    color: #3f4254;
    border: 1px solid #efefef;
    transition: 0.5s all;
    box-shadow: inset 0 0 0 0 #7539ff;
    margin: 0 0 0 15px;
}
.filter-buttons button.btn-secondary:hover {
    background-color: #7539ff;
    border-color: #7539ff;
    color: #fff;
    box-shadow: inset 0 50px 0 0 #7539ff;
}

.filter-list li a:hover img {
    filter: invert(0) brightness(100);
}

.dash-widget-icon {
    align-items: center;
    border-radius: 10px;
    color: #fff;
    display: inline-flex;
    font-size: 1.875rem;
    height: 4rem;
    justify-content: center;
    text-align: center;
    width: 4rem;
}

.dash-widget-info h3 {
    margin-bottom: 10px;
}

.dash-widget-header {
    margin: 0 0 20px 0;
}

.activity-feed {
    list-style: none;
    margin-bottom: 0;
    margin-left: 5px;
    padding: 0;
}
.activity-feed .feed-item {
    border-left: 2px solid #e4e8eb;
    padding-bottom: 19px;
    padding-left: 20px;
    position: relative;
}
.activity-feed .feed-item:last-child {
    border-color: transparent;
}
.activity-feed .feed-item:after {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    padding: 6px;
    left: -9px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #f3f3f3;
    background-color: transparent;
}
.activity-feed .feed-item:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
    background: #e3e3e3;
}
.activity-feed .feed-item .feed-date {
    display: block;
    position: relative;
    color: #777;
    text-transform: uppercase;
    font-size: 13px;
}
.activity-feed .feed-item .feed-text {
    color: #777;
    position: relative;
    margin: 0 0 5px;
}
.activity-feed .feed-item .feed-text a {
    color: #333;
    font-weight: 600;
}

.modal-icon i {
    font-size: 42px;
    color: #6c757d;
}

.modal-footer {
    border: 0;
    padding: 0 0 24px;
}
.modal-footer .btn-back {
    background: #eaddff;
    border: 1px solid #eaddff;
    box-shadow: inset 0 0 0 0 #3f4254;
    padding: 8px 20px;
    border-radius: 6px;
    color: #7539ff;
    min-width: 69px;
}
.modal-footer .btn-back:hover {
    background: #7539ff;
    border: 1px solid #7539ff;
    box-shadow: inset 0 50px 0 0 #7539ff;
    color: #fff;
}
.modal-footer .btn-primary {
    min-width: 69px;
    margin: 0;
}

.add-remove i {
    font-size: 18px;
    cursor: pointer;
}

.profile-cover {
    position: relative;
    padding: 1.75rem 2rem;
    border-radius: 0.75rem;
    height: 10rem;
}

.profile-cover-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10rem;
    background-color: #e7eaf3;
    border-radius: 0.75rem;
}

.profile-cover-img {
    width: 100%;
    height: 10rem;
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: top;
    border-radius: 0.75rem;
}

.cover-content {
    position: relative;
    z-index: 1;
    padding: 1rem 2rem;
    position: absolute;
    bottom: 0;
    right: 0;
}

.custom-file-btn {
    position: relative;
    overflow: hidden;
}

.custom-file-btn-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.profile-cover-avatar {
    display: -ms-flexbox;
    display: flex;
    margin: -6.3rem auto 0.5rem auto;
    border: 3px solid #fff;
    border-radius: 50%;
}
@media (max-width: 568px) {
    .profile-cover-avatar {
        margin: 0 auto;
    }
}
.profile-cover-avatar input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 33, 68, 0.25);
    border-radius: 50%;
    transition: 0.2s;
}

.avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: #677788;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.2s;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}
.avatar-edit svg {
    width: 18px;
}

.error-page {
    align-items: center;
    color: #1f1f1f;
    height: 100vh;
}
.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
    min-height: unset;
}

.error-box {
    margin: 20dvh auto;
    max-width: 480px;
    padding: 1.875rem 0;
    text-align: center;
    width: 100%;
}
.error-box h1 {
    color: #7638ff;
    font-size: 10em;
}
.error-box p {
    margin-bottom: 1.875rem;
}
.error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

.custom-file-container {
    box-sizing: border-box;
    position: relative;
    display: block;
}
.custom-file-container label {
    color: #4361ee;
}

.custom-file-container__custom-file {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    margin-top: 5px;
}
.custom-file-container__custom-file:hover {
    cursor: pointer;
}

.custom-file-container__custom-file__custom-file-input {
    box-sizing: border-box;
    min-width: 14rem;
    max-width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
}
.custom-file-container__custom-file__custom-file-input:focus ~ span {
    outline: 1px dotted #dddde0;
    outline: 0px auto -webkit-focus-ring-color;
}

.custom-file-container__custom-file__custom-file-control {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: auto;
    overflow: hidden;
    line-height: 1.5;
    user-select: none;
    background-clip: padding-box;
    border-radius: 0.25rem;
    height: auto;
    border: 1px solid #f1f2f3;
    color: #3b3f5c;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #f1f2f3;
}

.custom-file-container__custom-file__custom-file-control__button {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    display: block;
    height: auto;
    padding: 10px 16px;
    line-height: 1.25;
    background-color: #9e9e9e;
    color: #fff;
    border-left: 1px solid #e0e6ed;
    box-sizing: border-box;
}

.custom-file-container__image-preview {
    box-sizing: border-box;
    margin-top: 54px;
    margin-bottom: 40px;
    height: 250px;
    width: 100%;
    border-radius: 4px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: auto;
    padding: 15px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom-file-container__image-multi-preview {
    position: relative;
    box-sizing: border-box;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
    margin: 1.858736%;
    width: 29.615861214%;
    height: 90px;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom-file-container__image-multi-preview__single-image-clear {
    background: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-top: -6px;
    border-radius: 50%;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
    position: absolute;
    left: -6px;
}
.custom-file-container__image-multi-preview__single-image-clear:hover {
    background: #cbcbbd;
    cursor: pointer;
}

.custom-file-container__image-multi-preview__single-image-clear__icon {
    color: #4361ee;
    display: block;
    margin-top: -2px;
}

.note-editor.card {
    padding: 0.5rem;
    margin-bottom: 0;
}

.note-toolbar.card-header {
    padding: 0;
    padding-bottom: 0.5rem;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
    background-color: #7638ff;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #7638ff;
}

.note-editor.note-frame .note-statusbar .note-resizebar {
    padding-top: 0;
    height: 0;
    width: 100%;
    cursor: ns-resize;
    background: transparent;
    border: 0;
}

.app-listing {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
    flex-wrap: wrap;
}
.app-listing li {
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
}
@media (max-width: 991.98px) {
    .app-listing li {
        width: 50%;
    }
}
@media (max-width: 568px) {
    .app-listing li {
        width: 100%;
    }
}
.app-listing .checkbox-title {
    font-weight: 600;
    font-size: 16px;
    color: #7539ff;
    margin-bottom: 14px;
}
.app-listing .form-custom {
    margin-bottom: 14px;
}
.app-listing .form-custom .form-control {
    padding-left: 14px;
    border: 1px solid rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}

.selectbox-cont {
    height: 220px;
    overflow-y: auto;
    padding-right: 5px;
}

.multipleSelection {
    position: relative;
    margin-bottom: 20px;
}
.multipleSelection .selectbox,
.multipleSelection .selectboxes {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 13px 10px;
    font-weight: 400;
    background: #fff;
    border: 1px solid #f7f8f9;
    border-radius: 7px;
    cursor: pointer;
}
.multipleSelection .selectbox p,
.multipleSelection .selectboxes p {
    color: #1b2559;
    font-weight: 500;
}
.multipleSelection .selectbox p .select-icon,
.multipleSelection .selectboxes p .select-icon {
    color: #7539ff;
    width: 20px;
    height: 20px;
}

.custom_check {
    color: #1b2559;
    display: inline-block;
    position: relative;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;
    font-weight: 400;
    user-select: none;
}
.custom_check input:checked ~ .checkmark {
    background-color: #7539ff;
    border-color: #7539ff;
}
.custom_check input:checked ~ .checkmark:after {
    display: block;
}
.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.custom_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 4px;
    top: 2px;
    color: #fff;
    font-size: 9px;
}

.input-block .custom_check {
    margin-bottom: 20px;
}

.table tbody td a.btn-white {
    background-color: #fff;
    border-color: #e7eaf3;
    color: #212529;
}

.date-list .date-btn {
    background: #fff;
    color: #1b2559;
    border: 1px solid #e7eafc;
    border-radius: 4px;
    padding: 10px 0 !important;
    width: 100%;
}

.form-custom {
    font-size: 14px;
}
.form-custom.cal-icon {
    position: relative;
}
.form-custom.cal-icon:after {
    color: #979797;
    content: "\f073";
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}

.btn-grey {
    background: rgba(231, 234, 252, 0.75);
    color: #1b2559;
    margin-top: 10px;
}
.btn-grey:hover {
    background: #1b2559;
    color: #fff;
}

.date-picker {
    width: 50%;
    float: left;
    padding-right: 10px;
}

.date-list ul {
    text-align: center;
}
.date-list ul li {
    width: 100%;
    padding: 0;
    padding-bottom: 5px;
}

.sortby #checkbox {
    display: none;
    position: absolute;
    width: 300px;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}
.sortby #checkbox:before {
    border: 7px solid #f2ecff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    left: 30px;
    position: absolute;
    top: 0;
    transform: rotate(135deg);
}

.multipleSelection #checkboxes {
    display: none;
    position: absolute;
    width: 300px;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}

.report-card {
    border-radius: 5px;
}

.report-btn .btn {
    font-weight: 600;
    color: #7539ff;
    background: #fff;
    border: 2px solid #7539ff;
    border-radius: 8px;
    width: 100%;
    padding: 11px 0;
}
.report-btn .btn:hover {
    color: #fff;
    background: #7539ff;
    border: 2px solid #7539ff;
}
.report-btn .btn:hover img {
    filter: invert(0) brightness(100);
}

.invoices-settings-btn {
    justify-content: flex-end;
}

.invoices-main-tabs {
    border-bottom: 2px solid #e0e0e0;
    padding: 0 0 12px 0;
}
.invoices-main-tabs .invoices-tabs ul li {
    position: relative;
    display: inline-block;
    margin: 0 24px 0 0;
}
@media (max-width: 575.98px) {
    .invoices-main-tabs .invoices-tabs ul li {
        display: block;
        margin: 0 0 20px 0;
    }
}
.invoices-main-tabs .invoices-tabs ul li a {
    font-weight: 500;
    font-size: 14px;
    color: #878a99;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 0 0 14px 0;
}
.invoices-main-tabs .invoices-tabs ul li a.active {
    color: #7539ff;
    border-bottom: 2px solid #7539ff;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
@media (max-width: 991.98px) {
    .invoices-main-tabs .invoices-tabs ul li a.active {
        border-bottom: 0;
    }
}
.invoices-main-tabs .invoices-settings-icon {
    color: #7539ff;
    margin-right: 20px;
}
.invoices-main-tabs .btn {
    font-weight: 600;
    color: #fff;
    padding: 12px 10px;
    min-width: 167px;
    background: #1b2559;
    border: 1px solid #1b2559;
    border-radius: 6px;
}

.paid-continue-btn {
    background-color: #7539ff;
    border: 1px solid #7539ff;
    border-radius: 5px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 20px;
    text-align: center;
}

.paid-continue-btn:hover,
.paid-continue-btn:focus,
.paid-continue-btn:active {
    background-color: #fff;
    border: 1px solid #7539ff;
    color: #7539ff;
}

.paid-cancel-btn {
    background-color: #fff;
    border: 1px solid #7539ff;
    border-radius: 5px;
    color: #7539ff;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 20px;
    text-align: center;
}

.paid-cancel-btn:hover,
.paid-cancel-btn:active {
    background-color: #7539ff;
    border: 1px solid #7539ff;
    color: #7539ff;
}

.paid-cancel-btn:focus {
    color: #7539ff;
}

.inovices-card {
    border-radius: 5px;
    margin: 0 0 32px 0;
}
.inovices-card .inovices-widget-header .inovices-amount {
    font-weight: 600;
    font-size: 24px;
    color: #7539ff;
}
.inovices-card .inovices-all {
    font-weight: 500;
    font-size: 10px;
    color: #878a99;
}
.inovices-card .inovice-trending {
    font-weight: 500;
    font-size: 10px;
}
.inovices-card .inovice-trending span {
    margin: 0;
    line-height: 0;
}

.invoices-tabs-card {
    background: transparent;
    box-shadow: unset;
}

.invoices-grid-card {
    background: #fff;
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    border-radius: 10px;
    color: #1b2559;
}
.invoices-grid-card .card-header {
    padding: 20px;
    border: 0;
}
.invoices-grid-card .card-header .action-icon {
    color: #1b2559;
    display: initial;
}
.invoices-grid-card .card-header .invoice-grid-link {
    color: #1b2559;
}
.invoices-grid-card .card-header .dropdown-action {
    margin-bottom: 0;
}
.invoices-grid-card .card-middle {
    padding: 20px;
    background: #f7f7ff;
}
.invoices-grid-card .card-middle .avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
}
.invoices-grid-card .card-middle .card-middle-avatar {
    align-items: center;
    display: inline-flex;
    font-size: 16px;
    margin: 0;
}
.invoices-grid-card .card-body {
    padding: 20px;
}
.invoices-grid-card .card-body span {
    font-size: 14px;
}
.invoices-grid-card .card-body h6 {
    font-size: 16px;
    font-weight: 500;
    color: #1b2559;
    margin-top: 4px;
}
.invoices-grid-card .card-footer {
    padding: 20px;
    border: 0;
}

.inovices-card .card-body {
    padding: 10px;
}

.badge.bg-info-lights {
    background: #efe9ff;
    color: #7539ff;
}

.admin-invoice p {
    display: flex;
    flex-direction: column;
}

.dropdown-menu.dropdown-menu-end.customer-dropdown {
    width: 230px;
    height: 200px;
    overflow-y: auto;
}

.dropdown-menu.dropdown-menu-right.credit-note-dropdown {
    width: 235px;
    height: 200px;
    overflow-y: auto;
}

.card.company-settings-new .content-page-header {
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #e0e0e0;
}
.card.company-settings-new .input-block.service-upload.logo-upload {
    min-height: 130px;
    justify-content: space-between;
    padding: 15px 30px;
}
.card.company-settings-new .input-block.service-upload.logo-upload .text-info {
    line-height: 1;
}
.card.company-settings-new .input-block.service-upload.logo-upload .sites-logo {
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 10px;
    margin-left: 20px;
}
@media (max-width: 991.98px) {
    .card.company-settings-new
        .input-block.service-upload.logo-upload
        .sites-logo {
        margin-left: 0;
    }
}
.card.company-settings-new
    .input-block.service-upload.logo-upload
    .sites-logo
    img {
    margin-right: 0;
    max-width: 116px;
    max-height: 30px;
}

.inbox-menu {
    display: inline-block;
    width: 100%;
}
.inbox-menu li {
    display: inline-block;
    width: 100%;
}
.inbox-menu li a {
    color: #333;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    font-weight: 600;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.inbox-menu li a:hover {
    background: rgba(33, 33, 33, 0.05);
}
.inbox-menu li a i {
    font-size: 14px;
    padding-right: 10px;
    color: #878787;
}

@media (max-width: 768px) {
    .user-menu.nav > li > a > span:not(.user-img) {
        display: none;
    }
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-menu {
    border: 1px solid #eff2f7;
    border-radius: 0.8rem;
    transform-origin: left top 0;
    background-color: #fff;
    padding: 0;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
    overflow: hidden;
}

.flag-nav .dropdown-menu.notifications {
    transform: translateY(49px) !important;
}

.flag-nav .dropdown-menu {
    transform: translateY(59px) !important;
}

.table-inbox {
    font-size: 15px;
    margin-bottom: 0;
}

.inbox-menu li a:hover,
.inbox-menu li.active a,
.inbox-menu li a:focus {
    background: #eff2f7;
}

.compose-btn {
    margin-bottom: 25px;
}

.top-action-left .dropdown-toggle {
    height: 40px;
}
.top-action-left .dropdown-toggle::after {
    display: none;
}

.table-inbox .starred.fas.fa-star {
    color: #ffd200;
}
.table-inbox .checked {
    background-color: #ffffcc;
}
.table-inbox tbody tr td {
    font-size: 14px;
    color: #28084b;
}

.unread .name,
.unread .subject,
.unread .mail-date {
    color: #000;
    font-weight: 700;
}

.ribbon-primary {
    background: #7366ff;
}
.ribbon-primary.ribbon-corner:before {
    border-top-color: #7366ff;
    border-left-color: #7366ff;
}
.ribbon-primary.ribbon-corner.ribbon-right:before {
    border-right-color: #7366ff;
    border-left-color: transparent;
}
.ribbon-primary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #7366ff;
}
.ribbon-primary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #43f;
    border-right-color: #43f;
}
.ribbon-primary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #43f;
    border-left-color: #43f;
}
.ribbon-primary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #43f;
    border-right-color: #43f;
}
.ribbon-primary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #43f;
    border-right-color: transparent;
    border-left-color: #43f;
}

.ribbon-bookmark.ribbon-primary:before {
    border-color: #7366ff;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-secondary:before {
    border-color: #f73164;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-success:before {
    border-color: #51bb25;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-danger:before {
    border-color: #dc3545;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-info:before {
    border-color: #a927f9;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-light:before {
    border-color: #f4f4f4;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-dark:before {
    border-color: #2c323f;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-warning:before {
    border-color: #f8d62b;
    border-right-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-bottom-color: transparent;
}

.ribbon-secondary {
    background: #f73164;
    background: #f73164;
}
.ribbon-secondary.ribbon-corner:before {
    border-top-color: #f73164;
    border-left-color: #f73164;
}
.ribbon-secondary.ribbon-corner.ribbon-right:before {
    border-right-color: #f73164;
    border-left-color: transparent;
}
.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f73164;
}
.ribbon-secondary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #ec0944;
    border-right-color: #ec0944;
}
.ribbon-secondary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #ec0944;
    border-left-color: #ec0944;
}
.ribbon-secondary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: #ec0944;
}
.ribbon-secondary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: transparent;
    border-left-color: #ec0944;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-bottom-color: transparent;
}

.ribbon-success {
    background: #51bb25;
    background: #51bb25;
}
.ribbon-success.ribbon-corner:before {
    border-top-color: #51bb25;
    border-left-color: #51bb25;
}
.ribbon-success.ribbon-corner.ribbon-right:before {
    border-right-color: #51bb25;
    border-left-color: transparent;
}
.ribbon-success.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #51bb25;
}
.ribbon-success.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #3f901d;
    border-right-color: #3f901d;
}
.ribbon-success.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #3f901d;
    border-left-color: #3f901d;
}
.ribbon-success.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: #3f901d;
}
.ribbon-success.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: transparent;
    border-left-color: #3f901d;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-bottom-color: transparent;
}

.ribbon-danger {
    background: #dc3545;
    background: #dc3545;
}
.ribbon-danger.ribbon-corner:before {
    border-top-color: #dc3545;
    border-left-color: #dc3545;
}
.ribbon-danger.ribbon-corner.ribbon-right:before {
    border-right-color: #dc3545;
    border-left-color: transparent;
}
.ribbon-danger.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dc3545;
}
.ribbon-danger.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #bd2130;
    border-right-color: #bd2130;
}
.ribbon-danger.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #bd2130;
    border-left-color: #bd2130;
}
.ribbon-danger.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: #bd2130;
}
.ribbon-danger.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: transparent;
    border-left-color: #bd2130;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-bottom-color: transparent;
}

.ribbon-info {
    background: #a927f9;
    background: #a927f9;
}
.ribbon-info.ribbon-corner:before {
    border-top-color: #a927f9;
    border-left-color: #a927f9;
}
.ribbon-info.ribbon-corner.ribbon-right:before {
    border-right-color: #a927f9;
    border-left-color: transparent;
}
.ribbon-info.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #a927f9;
}
.ribbon-info.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #9106e7;
    border-right-color: #9106e7;
}
.ribbon-info.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #9106e7;
    border-left-color: #9106e7;
}
.ribbon-info.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: #9106e7;
}
.ribbon-info.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: transparent;
    border-left-color: #9106e7;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-bottom-color: transparent;
}

.ribbon-light {
    background: #f4f4f4;
    background: #f4f4f4;
}
.ribbon-light.ribbon-corner:before {
    border-top-color: #f4f4f4;
    border-left-color: #f4f4f4;
}
.ribbon-light.ribbon-corner.ribbon-right:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent;
}
.ribbon-light.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f4f4f4;
}
.ribbon-light.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #dbdbdb;
    border-right-color: #dbdbdb;
}
.ribbon-light.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #dbdbdb;
    border-left-color: #dbdbdb;
}
.ribbon-light.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: #dbdbdb;
}
.ribbon-light.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: transparent;
    border-left-color: #dbdbdb;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-bottom-color: transparent;
}

.ribbon-dark {
    background: #2c323f;
    background: #2c323f;
}
.ribbon-dark.ribbon-corner:before {
    border-top-color: #2c323f;
    border-left-color: #2c323f;
}
.ribbon-dark.ribbon-corner.ribbon-right:before {
    border-right-color: #2c323f;
    border-left-color: transparent;
}
.ribbon-dark.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #2c323f;
}
.ribbon-dark.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #171a21;
    border-right-color: #171a21;
}
.ribbon-dark.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #171a21;
    border-left-color: #171a21;
}
.ribbon-dark.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: #171a21;
}
.ribbon-dark.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: transparent;
    border-left-color: #171a21;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-bottom-color: transparent;
}

.ribbon-warning {
    background: #f8d62b;
    background: #f8d62b;
}
.ribbon-warning.ribbon-corner:before {
    border-top-color: #f8d62b;
    border-left-color: #f8d62b;
}
.ribbon-warning.ribbon-corner.ribbon-right:before {
    border-right-color: #f8d62b;
    border-left-color: transparent;
}
.ribbon-warning.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f8d62b;
}
.ribbon-warning.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #e8c308;
    border-right-color: #e8c308;
}
.ribbon-warning.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #e8c308;
    border-left-color: #e8c308;
}
.ribbon-warning.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: #e8c308;
}
.ribbon-warning.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: transparent;
    border-left-color: #e8c308;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-bottom-color: transparent;
}

.ribbon-wrapper {
    position: relative;
    padding-top: 40px;
}

.ribbon-wrapper .card-body,
.ribbon-vertical-left-wrapper .card-body,
.ribbon-vertical-right-wrapper .card-body {
    position: unset;
}

.ribbon {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    clear: left;
    position: absolute;
    top: 12px;
    left: 0px;
    color: #fff;
    z-index: 1;
}

.ribbon-right {
    left: auto;
    right: -2px;
}

.ribbon-vertical-left-wrapper {
    padding-left: 40px;
    position: relative;
}

.ribbon-vertical-left {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    left: 12px;
    top: -2px;
}

.ribbon-vertical-right-wrapper {
    padding-right: 40px;
}

.ribbon-vertical-right {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    top: -2px;
    left: auto;
    right: 12px;
}

.timeline {
    position: relative;
    padding: 20px 0 20px;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;
}
.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    margin-left: -1.5px;
    background-color: #e9ecef;
}
.timeline > li {
    position: relative;
    margin-bottom: 20px;
}
.timeline > li:after {
    clear: both;
    clear: both;
}
.timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}
.timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 26px;
    right: -8px;
    border-top: 8px solid transparent;
    border-right: 0 solid #e9ecef;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #e9ecef;
}
.timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -7px;
    border-top: 7px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #fff;
}
.timeline > li > .timeline-badge {
    z-index: 1;
    position: absolute;
    top: 16px;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-radius: 50% 50% 50% 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    overflow: hidden;
}
.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -8px;
    border-right-width: 8px;
    border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -7px;
    border-right-width: 7px;
    border-left-width: 0;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
    content: " ";
    display: table;
}

.timeline-badge.primary {
    background-color: #fb9678;
}
.timeline-badge.success {
    background-color: #00c292;
}
.timeline-badge.warning {
    background-color: #fec107;
}
.timeline-badge.danger {
    background-color: #e46a76;
}
.timeline-badge.info {
    background-color: #03a9f3;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
    font-weight: 400;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}
.cd-horizontal-timeline .events {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 30px;
    height: 2px;
    background: #dfdfdf;
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}
.cd-horizontal-timeline .events a {
    padding-bottom: 6px;
    color: #621aff;
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 14px;
    padding-bottom: 15px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}
.cd-horizontal-timeline .events a.selected {
    pointer-events: none;
}
.cd-horizontal-timeline .events a.selected::after {
    border-color: #621aff;
    background-color: #621aff;
    border-color: #621aff;
}
.cd-horizontal-timeline .events a::after {
    content: "";
    position: absolute;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    background-color: #f8f8f8;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
}
.cd-horizontal-timeline .events a.older-event::after {
    border-color: #621aff;
}
.cd-horizontal-timeline .m-t-40 {
    margin-top: 25px !important;
}
.cd-horizontal-timeline table {
    border-collapse: collapse;
    border-spacing: 0;
}
.cd-horizontal-timeline::before {
    content: "mobile";
    display: none;
}
.cd-horizontal-timeline.loaded {
    opacity: 1;
}
.cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
}
.cd-horizontal-timeline .timeline:before {
    content: " ";
    display: none;
    bottom: 0;
    left: 0%;
    width: 0px;
    margin-left: -1.5px;
    background-color: #eeeeee;
}
.cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}
.cd-horizontal-timeline .events-wrapper::before {
    left: 0;
}
.cd-horizontal-timeline .events-wrapper::after {
    right: 0;
}
.cd-horizontal-timeline .filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #621aff;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}
.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    -webkit-transition: height 0.4s;
    -moz-transition: height 0.4s;
    transition: height 0.4s;
}
.cd-horizontal-timeline .events-content li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 0 5%;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
.cd-horizontal-timeline .events-content li.selected {
    position: relative;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
.cd-horizontal-timeline .events-content li > * {
    max-width: 800px;
    margin: 0 auto;
}
.cd-horizontal-timeline .events-content h2 {
    font-weight: 600;
    margin-bottom: 0px;
}
.cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto;
}
.cd-horizontal-timeline .events-content em::before {
    content: "- ";
}
.cd-horizontal-timeline .events-content p {
    font-size: 16px;
}

.cd-horizontal-timeline .filling-line,
.cd-horizontal-timeline .events a.selected::after {
    background: #621aff;
}

.cd-horizontal-timeline ol,
.cd-horizontal-timeline ul {
    list-style: none;
}

.cd-timeline-navigation a:hover,
.cd-timeline-navigation a:focus {
    border-color: #621aff;
}

.cd-horizontal-timeline a,
.cd-horizontal-timeline a:hover,
.cd-horizontal-timeline a:focus {
    color: #621aff;
}

.cd-horizontal-timeline blockquote,
.cd-horizontal-timeline q {
    quotes: none;
}

.cd-horizontal-timeline blockquote:before,
.cd-horizontal-timeline blockquote:after,
.cd-horizontal-timeline q:before,
.cd-horizontal-timeline q:after {
    content: "";
    content: none;
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px;
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: #621aff;
    border-color: #621aff;
}
.no-touch .cd-timeline-navigation a:hover {
    border-color: #7b9d6f;
}
.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
}

.cd-timeline-navigation a {
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    transition: border-color 0.3s;
}
.cd-timeline-navigation a::after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../img/arrow.svg) no-repeat 0 0;
}
.cd-timeline-navigation a.prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
    right: 0;
}
.cd-timeline-navigation a.inactive {
    cursor: not-allowed;
}
.cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px;
}

.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
}

.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
    line-height: 30px;
}

.h-250 {
    height: 250px;
}

.icons-list {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.icons-list li {
    display: block;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 18px;
    border-radius: 50px;
    box-shadow: 0px 2px 3px rgb(215, 197, 255);
    border: 1px solid #e6ebf1;
    color: #7638ff;
    margin: 5px;
}

.login-body {
    display: table;
    height: 100vh;
    min-height: 100vh;
    margin: auto;
}

.logo-light {
    max-width: 180px;
    margin: auto;
    display: none;
}

.logo-dark {
    max-width: 180px;
    margin: auto;
    display: block;
}

.login-wrapper {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-top: 1.875rem;
}
.login-wrapper .loginbox {
    background-color: #fff;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    display: flex;
    margin: 1.875rem auto;
    max-width: 450px;
    width: 100%;
    border-radius: 20px;
}
.login-wrapper .loginbox .login-left {
    align-items: center;
    background: linear-gradient(180deg, #8bc34a, #00bcd4);
    flex-direction: column;
    justify-content: center;
    padding: 80px;
    width: 450px;
    display: flex;
    border-radius: 6px 0 0 6px;
}
.login-wrapper .loginbox .login-right {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2rem;
    width: 450px;
}
@media (max-width: 320px) {
    .login-wrapper .loginbox .login-right {
        width: 100%;
    }
}
.login-wrapper .loginbox .login-right .login-right-wrap {
    max-width: 100%;
    flex: 0 0 100%;
}
.login-wrapper .loginbox .login-right .login-right-wrap h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
}
.login-wrapper .loginbox .login-right .forgotpass a {
    color: #a0a0a0;
}
.login-wrapper .loginbox .login-right .forgotpass a:hover {
    color: #333;
    text-decoration: underline;
}
.login-wrapper .loginbox .login-right .dont-have {
    color: #a0a0a0;
    margin-top: 1.875rem;
}
.login-wrapper .loginbox .login-right .dont-have a {
    color: #333;
}
.login-wrapper .loginbox .login-right .dont-have a:hover {
    text-decoration: underline;
}
.login-wrapper .loginbox .login-right .social-login {
    text-align: center;
}
.login-wrapper .loginbox .login-right .social-login span {
    color: #757575;
    margin-right: 8px;
}
.login-wrapper .loginbox .login-right .social-login a {
    background-color: #ccc;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    margin-right: 6px;
    text-align: center;
    width: 32px;
    border-radius: 4px;
}
.login-wrapper .loginbox .login-right .social-login a:last-child {
    margin-right: 0;
}
.login-wrapper .loginbox .login-right .social-login a.facebook {
    background-color: #4b75bd;
}
.login-wrapper .loginbox .login-right .social-login a.google {
    background-color: #fe5240;
}
.login-wrapper .loginbox .account-subtitle {
    color: #4c4c4c;
    font-size: 17px;
    margin-bottom: 1.875rem;
    text-align: center;
}
.login-wrapper .loginbox .login-or {
    color: #757575;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}
.login-wrapper .loginbox .or-line {
    background-color: #e5e5e5;
    height: 1px;
    margin-bottom: 0;
    margin-top: 0;
    display: block;
}
.login-wrapper .loginbox .span-or {
    background-color: #fff;
    display: block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 42px;
}
.login-wrapper .loginbox .lock-user {
    margin-bottom: 20px;
    text-align: center;
}
.login-wrapper .loginbox .lock-user img {
    margin-bottom: 15px;
    width: 100px;
}
.login-wrapper .loginbox .toggle-password {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    color: #757575;
    cursor: pointer;
}
.login-wrapper .loginbox .toggle-password.fa-eye {
    margin-right: 1px;
}
.login-wrapper .loginbox .pass-group {
    position: relative;
}

.settings-menu {
    margin-bottom: 30px;
}
.settings-menu ul {
    display: block;
    padding: 0;
}
.settings-menu ul li + li {
    margin-top: 15px;
}
.settings-menu ul li a {
    color: #3f4254;
    padding: 0;
    border: 0 !important;
    display: flex;
}
.settings-menu ul li a.active {
    color: #7539ff !important;
    border: 0;
}
.settings-menu ul li i {
    margin-right: 10px;
    font-size: 20px;
    min-width: 25px;
}

.settings-menu ul li a:hover {
    background-color: transparent !important;
    border: 0 !important;
    color: #7539ff;
}
.settings-menu ul li a:focus {
    background-color: transparent !important;
    border: 0 !important;
}

.invoices-settings-card {
    border-radius: 5px;
}
.invoices-settings-card .card-title {
    color: #1b2559;
}

.invoices-settings-form label {
    color: #1b2559;
}
.invoices-settings-form .form-control {
    height: 50px;
    border-radius: 6px;
}

.invoices-upload-btn {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    color: #000000;
    cursor: pointer;
    font-weight: 400;
    padding: 15px 15px;
    position: relative;
    width: 100%;
    height: 50px;
}
.invoices-upload-btn .hide-input {
    position: relative;
    z-index: 1;
    cursor: pointer;
    min-height: auto;
    padding-left: 4px;
    padding-top: 0;
    line-height: 10px;
    width: 100%;
    opacity: 0;
}
.invoices-upload-btn .upload {
    position: absolute;
    background: #7638ff;
    border-radius: 4px;
    right: 6px;
    top: 8px;
    width: 136px;
    height: 32px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.invoice-setting-btn .btn {
    min-width: 160px;
    padding: 10px 0;
    color: #fff;
    border-radius: 8px;
}
.invoice-setting-btn .cancel-btn {
    background: #1b2559;
}
.invoice-setting-btn .cancel-btn:hover {
    background: #7638ff;
}
.invoice-setting-btn .btn-primary:hover {
    background: #1b2559;
    border: 1px solid #1b2559;
}

.invoices-items-main-tabs {
    border-bottom: 3px solid #f3f3f3;
    padding-bottom: 10px;
}

.invoices-items-tabs ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.invoices-items-tabs ul li {
    display: inline-block;
    padding-left: 36px;
    padding-right: 36px;
    position: relative;
}
.invoices-items-tabs ul li:last-child {
    padding-right: 0;
}
.invoices-items-tabs ul li a {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    padding-bottom: 24px;
    position: relative;
}
.invoices-items-tabs ul li a.active {
    color: #7638ff;
}
.invoices-items-tabs ul li a.active:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 4px;
    width: 130px;
    height: 6px;
    background-color: #7638ff;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}
.invoices-items-tabs ul li a:hover {
    color: #7638ff;
}

.circle-btn {
    width: 33px;
    height: 33px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.input-block.service-upload.logo-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: auto;
    flex-wrap: wrap;
}
.input-block.service-upload.logo-upload span {
    line-height: 0;
}
.input-block.service-upload.logo-upload span img {
    margin-right: 10px;
}

.payment-toggle {
    display: flex;
    align-items: center;
}
.payment-toggle .status-toggle .checktoggle {
    transform: none;
    margin-bottom: 25px;
}

.no-pagination .dataTables_length {
    display: none;
}
.no-pagination .pagination {
    display: none;
}

.mail-title {
    color: #1f2937;
    font-size: 14px;
    margin-bottom: 10px;
}

.mail-provider {
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
}
.mail-provider h4 {
    font-size: 15px;
}
.mail-provider .status-toggle .checktoggle {
    transform: none;
}
.mail-provider .mail-setting {
    display: flex;
    align-items: center;
}
.mail-provider .mail-setting a i {
    color: #95979b;
    margin-right: 10px;
}

.php-mail-modal {
    display: flex;
    align-items: center;
}
.php-mail-modal .status-toggle .checktoggle {
    transform: none;
}

table tr td h2.tax-name {
    color: #3f4254;
}
table tr td .status-toggle .checktoggle {
    transform: none;
    margin: 0;
}

.btn-path .btn-cancel.bg-primary-light {
    color: #3f3f3f !important;
    background: #eaddff !important;
}

.setting-menu {
    font-size: 15px;
    color: #28283c !important;
}

.form-title .h5 {
    font-size: 18px;
    color: #1f2937;
}

.email-template-card .card {
    padding: 15px;
}
.email-template-card .card .card-body {
    padding: 0;
}
.email-template-card h5 {
    font-size: 16px;
    margin-bottom: 10px;
}

.sticky-sidebar {
    position: fixed;
    border: 3px solid #555a64;
    top: 50%;
    background: #555a64;
    z-index: 600;
    right: 0px;
    padding: 0px 0px;
    width: 50px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
    border-radius: 5px 0px 0px 5px;
    transform: translateY(-50%);
    flex-wrap: wrap;
    transition: all 0.8s;
}
.sticky-sidebar > li > a {
    height: 30px;
    width: 30px;
    color: #fff;
    position: relative;
}

.tooltip-five {
    position: relative;
    text-align: center;
    margin: auto;
    display: flex;
}
.tooltip-five:hover .tooltiptext {
    visibility: visible;
}
.tooltip-five .tooltiptext {
    visibility: hidden;
    width: 126px;
    background-color: #7539ff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 111111;
    top: 0px;
    right: 41px;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
}
.tooltip-five .tooltiptext:after {
    content: "";
    position: absolute;
    top: 49%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #7539ff;
}

.sidebar-logo img {
    max-height: 45px;
    width: auto;
}

.sticky-sidebar.siderbar-view.show-sidebar {
    right: 617px;
    z-index: 10000;
    transition: all 0.8s;
}

.sidebar-layout {
    width: 600px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    right: -600px;
    top: 0;
    height: 100vh;
    z-index: 9999;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    transition: ease all 0.8s;
}
@media (max-width: 575.98px) {
    .sidebar-layout {
        width: 100%;
    }
}
.sidebar-layout.show-layout {
    right: 0;
    transition: ease all 0.8s;
    scrollbar-width: none;
    overflow-y: scroll;
    width: 100%;
}
.sidebar-layout .sidebar-content {
    width: 600px;
    position: absolute;
    right: 0px;
    background: #fff;
}
@media (max-width: 575.98px) {
    .sidebar-layout .sidebar-content {
        width: 100%;
    }
}
.sidebar-layout .sidebar-content .container-fluid {
    padding: 20px;
}
.sidebar-layout .sidebar-content .sidebar-top {
    padding: 0;
    border-bottom: 1px solid #f0f1f5;
}
.sidebar-layout .sidebar-content .sidebar-top .btn-closed {
    position: relative;
    z-index: 1;
    color: #ff0000;
    border: 1px solid transparent;
    text-align: center;
    float: right;
    padding: 2px;
    top: 0px;
    width: 25px;
    height: 25px;
    border-radius: 50px;
}
@media (max-width: 991.98px) {
    .sidebar-layout .sidebar-content .sidebar-top .btn-closed {
        margin-top: -45px;
    }
}
.sidebar-layout .sidebar-content .sidebar-top .sidebar-logo img {
    max-height: 45px;
    width: auto;
}
.sidebar-layout .sidebar-content .sidebar-title {
    font-size: 18px;
    color: #212529;
    margin: 0px 11px 15px 11px;
    padding: 0;
}
.sidebar-layout .sidebar-content .sidebar-image {
    flex-wrap: wrap;
}
.sidebar-layout .sidebar-content .layout {
    padding: 30px 11px;
    justify-content: left;
}
.sidebar-layout .sidebar-content .layout .layout-title {
    font-size: 15px;
    color: #212529;
    margin: 0px;
    text-align: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
    margin-right: 0px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.switch .layout-link {
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: inline-block;
}
.switch .layout-link.checked + .slider {
    background-color: #7539ff;
    border: 2px solid #7539ff;
}
.switch .layout-link.checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(16px);
    background: #fff;
}
.switch .round {
    border-radius: 45px;
}
.switch .slider {
    cursor: pointer;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid #6e82a5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.switch .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: #6e82a5;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.switch .slider.round:before {
    border-radius: 50%;
}

.sidebar-icons {
    padding: 7px 0px;
}

.sidebar-settings {
    width: 600px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    right: -600px;
    top: 0;
    height: 100vh;
    z-index: 9999;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    transition: ease all 0.8s;
    -webkit-transition: ease all 0.8s;
    -ms-transition: ease all 0.8s;
}
.sidebar-settings.show-settings {
    right: 0;
    transition: ease all 0.8s;
    scrollbar-width: none;
    overflow-y: scroll;
    width: 100%;
}
.sidebar-settings .sidebar-content {
    width: 600px;
    position: absolute;
    right: 0px;
    background: #fff;
}
@media (max-width: 991.98px) {
    .sidebar-settings .sidebar-content {
        width: 100%;
    }
}
.sidebar-settings .sidebar-top {
    padding: 0;
    border-bottom: 1px solid #f0f1f5;
}
.sidebar-settings .sidebar-top .container-fluid {
    padding: 20px;
}
.sidebar-settings .sidebar-top .btn-closed {
    position: relative;
    z-index: 1;
    color: #ff0000;
    border: 1px solid transparent;
    text-align: center;
    float: right;
    padding: 2px;
    top: 0px;
    width: 25px;
    height: 25px;
    border-radius: 50px;
}
.sidebar-settings .sidebar-content .container-fluid {
    padding: 20px;
}
.sidebar-settings .sidebar-content .sidebar-title {
    font-size: 18px;
    color: #212529;
    margin: 0px 11px 15px 11px;
    padding: 0;
}
.sidebar-settings .sidebar-content .setting-title {
    font-size: 12px;
    color: #212529;
    margin: 0;
}

.setting {
    padding: 15px 11px;
    justify-content: left;
}
.setting:nth-child(2) {
    justify-content: flex-end;
}

.sidebar-color {
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 100%;
    margin: 10px 0px;
}
.sidebar-color span {
    border-radius: 1px;
    width: 100%;
    height: 75px;
    display: block;
    margin: 1px;
}

.color-one {
    background: #fff;
    border: 1px solid #dbdfea;
}

.color-two {
    background: #f0f1f5;
    border: 1px solid #dbdfea;
}

.color-three {
    background: #101924;
    border: 1px solid #dbdfea;
}

.color-four {
    background: #7539ff;
    border: 1px solid #dbdfea;
}

.color-eight {
    background: #757575;
    border: 1px solid #dbdfea;
}

.sidebar-sub-title {
    font-size: 15px;
    color: #6e82a5;
    margin: 10px 0px 10px 11px;
    padding: 0;
}

.sidebar-color-one {
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 53px;
    margin: 10px 0px;
    display: inline-block;
}
.sidebar-color-one span {
    border-radius: 1px;
    width: 54px;
    height: 30px;
    display: inline-block;
    margin: 0px 3px;
}
.sidebar-color-one .color-five {
    background: #757575;
    border: 1px solid #dbdfea;
}
.sidebar-color-one .color-six {
    background: #dbdfea;
    border: 1px solid #dbdfea;
}
.sidebar-color-one .color-seven {
    background: #6e82a5;
    border: 1px solid #dbdfea;
}

.sidebar-but {
    padding: 6px 23px;
    background: #7539ff;
    border-radius: 5px;
    display: inline-block;
    border: 1px solid #7539ff;
    width: auto;
    margin: 20px auto;
    color: #fff;
    border: 1px solid #7539ff;
    display: flex;
    align-items: center;
}
.sidebar-but:hover {
    background: #621aff;
    color: #fff;
}

@media (max-width: 991.98px) {
    .layout,
    .setting {
        flex: 1 0 0%;
    }
    .layout:nth-child(2) {
        justify-content: flex-end !important;
    }
}
.circle-btn {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-light {
    border-color: #e6e6e6;
    color: #a6a6a6;
}

.user-menu-eight.nav > li > a {
    color: #fff;
}
.user-menu-eight.nav > li > a.dropdown-toggle-eight:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}

.sidebar-three .nav-tabs .nav-link:hover {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}

.wizard {
    position: relative;
}
.wizard .nav-tabs {
    position: relative;
    margin-top: 20px;
    border: 0;
}
.wizard .nav-tabs li:after {
    content: "";
    width: 75%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    background: #e9e9ef;
    height: 2px;
    left: -30%;
    top: 38%;
    z-index: 1;
}
@media (max-width: 992px) {
    .wizard .nav-tabs li:after {
        width: 60%;
        left: -22%;
    }
}
@media (max-width: 575.98px) {
    .wizard .nav-tabs li:after {
        background: transparent;
    }
}
.wizard .nav-tabs li:first-child:after {
    background: transparent;
}
.wizard .nav-item {
    position: relative;
}
.wizard .nav-item .nav-link {
    width: 56px;
    height: 56px;
    content: "";
    position: absolute;
    background: #fff;
    left: 50%;
    color: #5156be;
    top: 50%;
    font-size: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 1px solid rgba(81, 86, 190, 0.2);
}
.wizard .nav-item .nav-link.active {
    background: #e9e9ef;
    color: #5156be;
    border: 1px solid rgba(81, 86, 190, 0.2);
}
@media (max-width: 320px) {
    .wizard .nav-item .nav-link {
        left: 20%;
    }
}
@media (max-width: 768px) {
    .wizard .nav-item .nav-link {
        background: transparent;
    }
}

.wizard .tab-content {
    padding-top: 50px;
}
.wizard .form-control {
    border-radius: 4px;
}
.wizard .form-select {
    border: 1px solid #e0e0e0;
    box-shadow: none;
    border-radius: 4px;
    margin: 0;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #878a99;
}
.wizard .form-select:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #878a99;
    border-radius: 4px;
}

.bus-ticket .inv-content {
    border: 1px solid #0c0c20;
}
.bus-ticket .inv-content .invoice-header {
    background: #f2f2f2;
    margin: 20px 20px 0;
    padding: 30px;
}
.bus-ticket .inv-content .invoice-header h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #2c3038;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .invoice-header h2 {
        font-size: 30px;
    }
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .invoice-header .inv-header-right {
        margin-top: 10px;
    }
}
.bus-ticket .inv-content .flight-invoice-details {
    margin: 0;
    padding: 0 20px;
}
.bus-ticket .inv-content .flight-invoice-details .bus-booking-address {
    display: flex;
    align-items: center;
}
.bus-ticket
    .inv-content
    .flight-invoice-details
    .bus-booking-address
    .booked-add {
    color: #000;
    font-weight: 450;
    font-size: 16px;
}
.bus-ticket
    .inv-content
    .flight-invoice-details
    .bus-booking-address
    .booked-add
    span {
    display: block;
    color: #878a99;
    font-weight: 450;
    font-size: 16px;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .flight-invoice-details .bus-booking-address {
        display: block;
        margin-top: 20px;
    }
}
.bus-ticket .inv-content .flight-invoice-details h3 {
    color: #28084b;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0;
    padding: 0;
    line-height: normal;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .flight-invoice-details h3 {
        font-size: 30px;
        margin: 20px 0;
    }
}
.bus-ticket .inv-content .flight-invoice-details .invoice-infomation {
    display: flex;
}
.bus-ticket .inv-content .flight-invoice-details .invoice-infomation div {
    background: #f2f2f2;
    padding: 10px;
    width: 32.8%;
    text-align: center;
    color: #000;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .flight-invoice-details .invoice-infomation div {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .flight-invoice-details .invoice-infomation {
        display: block;
    }
}
.bus-ticket .inv-content .invoice-address {
    margin: 0;
    padding: 30px 20px;
    justify-content: normal;
}
.bus-ticket .inv-content .invoice-address div {
    width: 50%;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .invoice-address div {
        width: 100%;
    }
}
.bus-ticket .inv-content .passenger-info {
    display: flex;
    margin: 0;
    padding: 0;
}
.bus-ticket .inv-content .passenger-info .passenger-details,
.bus-ticket .inv-content .passenger-info .payment-info {
    background: #f2f2f2;
    margin: 0 10px 0 0;
    padding: 15px 25px;
    width: 50%;
}
.bus-ticket .inv-content .passenger-info .passenger-details h5,
.bus-ticket .inv-content .passenger-info .payment-info h5 {
    color: #0c0c20;
    margin-bottom: 15px;
    font-size: 18px;
}
.bus-ticket .inv-content .passenger-info .passenger-details div,
.bus-ticket .inv-content .passenger-info .payment-info div {
    display: flex;
    justify-content: space-between;
}
.bus-ticket .inv-content .passenger-info .passenger-details div span,
.bus-ticket .inv-content .passenger-info .payment-info div span {
    color: #2c3038;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .passenger-info .passenger-details div,
    .bus-ticket .inv-content .passenger-info .payment-info div {
        font-size: 15px;
    }
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .passenger-info .passenger-details,
    .bus-ticket .inv-content .passenger-info .payment-info {
        width: 100%;
        margin-bottom: 20px;
        margin: 0 10px 20px 0;
        padding: 15px;
    }
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .passenger-info {
        display: block;
    }
}
.bus-ticket .inv-content .passenger-ticket-details {
    margin: 0;
    padding: 30px 20px;
}
.bus-ticket .inv-content .passenger-ticket-details .invoice-title {
    margin: 0;
    padding: 0px 0 30px;
}
.bus-ticket .inv-content .invoice-table {
    margin: 0;
    padding: 0px 20px;
}
.bus-ticket .inv-content .invoice-table h6 {
    padding: 0 0 30px;
    line-height: normal;
}
.bus-ticket .inv-content .invoice-table table {
    color: #95979b;
}
.bus-ticket .inv-content .invoice-table table thead tr {
    color: #2c3038;
}
.bus-ticket .inv-content .invoice-table table .table_width_2 {
    width: 38%;
}
.bus-ticket .inv-content .invoice-table table tr {
    border-bottom: 1px solid #f2f2f2;
}
.bus-ticket .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.bus-ticket .inv-content .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
@media (max-width: 992px) {
    .bus-ticket .inv-content .invoice-table table tr td,
    .bus-ticket .inv-content .invoice-table table tr th {
        white-space: nowrap;
    }
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .invoice-table table {
        margin: 0;
        padding: 15px 20px 0;
    }
}
.bus-ticket
    .inv-content
    .invoice-table
    .invoice-table-footer
    .table-footer-right {
    color: #2c3038;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .invoice-table .invoice-table-footer .total-item {
        display: none;
    }
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .invoice-table .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .bus-ticket .inv-content .invoice-table .invoice-table-footer .total-item {
        display: none;
    }
}
.bus-ticket .inv-content .invoice-table .total-amountdetails span {
    display: block;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .invoice-table .total-amountdetails {
        margin: 0;
        padding: 15px 20px;
    }
}
.bus-ticket .inv-content .totalamount-footer {
    background: #f2f2f2;
    border: 0;
    margin: 10px 20px;
    padding: 10px 10px 10px 15px;
}
.bus-ticket .inv-content .invoice-table-footer {
    border: 0;
}
.bus-ticket .inv-content .invoice-table-footer .notes span {
    display: block;
    color: #2c3038;
}
.bus-ticket .inv-content .invoice-table-footer .amount-payable {
    width: 35%;
}
.bus-ticket .inv-content .invoice-table-footer .table-footer-right table td {
    padding: 5px;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .bus-ticket .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
.bus-ticket .inv-content .payable-table {
    margin: 0;
    padding: 15px 20px;
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .payable-table {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .payable-table .table-footer-left {
        width: 100%;
    }
}
.bus-ticket .inv-content .payable-table .amount-payable {
    width: 35%;
}
@media (max-width: 992px) {
    .bus-ticket .inv-content .payable-table .amount-payable {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .bus-ticket .inv-content .payable-table .amount-payable {
        width: 100%;
    }
}
.bus-ticket .inv-content .bank-details {
    margin: 0 20px;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2;
}
.bus-ticket .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.bus-ticket .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.bus-ticket .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.bus-ticket .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.bus-ticket .inv-content .terms-condition {
    margin: 0 20px;
    padding: 30px 0;
}
.bus-ticket .inv-content .terms-condition p {
    color: #000;
    font-weight: 450;
    font-size: 16px;
}
.bus-ticket .inv-content .thanks-msg {
    padding: 30px 0;
}

@media print {
    .file-link {
        display: none !important;
    }
}
.car-booking .inv-content {
    border: 1px solid #0c0c20;
}
.car-booking .inv-content .invoice-header {
    background: linear-gradient(320deg, #ddceff 0%, #dbecff 100%);
    margin: 20px 20px 0;
    padding: 30px;
}
.car-booking .inv-content .invoice-header h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #fff;
    margin-top: 10px;
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-header h2 {
        font-size: 30px;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-header .inv-header-right {
        margin-top: 10px;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
    }
}
.car-booking .inv-content .flight-invoice-details {
    margin: 0;
    padding: 0 20px;
}
.car-booking .inv-content .flight-invoice-details .bus-booking-address {
    display: flex;
    align-items: center;
    margin: 30px 0;
    padding: 0;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .bus-booking-address
    .booked-add {
    color: #2c3038;
    font-weight: 450;
    width: 50%;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .bus-booking-address
    .booked-add
    span {
    display: block;
    color: #95979b;
}
@media (max-width: 767px) {
    .car-booking
        .inv-content
        .flight-invoice-details
        .bus-booking-address
        .booked-add {
        width: 100%;
    }
}
.car-booking .inv-content .flight-invoice-details .bus-booking-address .info {
    text-align: right;
}
.car-booking .inv-content .flight-invoice-details .bus-booking-address .info p {
    color: #95979b;
}
@media (max-width: 767px) {
    .car-booking
        .inv-content
        .flight-invoice-details
        .bus-booking-address
        .info {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .flight-invoice-details .bus-booking-address {
        display: block;
        margin-top: 20px;
    }
}
.car-booking .inv-content .flight-invoice-details h3 {
    color: #2c3038;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0;
    padding: 0;
    line-height: normal;
}
@media (max-width: 767px) {
    .car-booking .inv-content .flight-invoice-details h3 {
        font-size: 30px;
        margin: 20px 0;
    }
}
.car-booking .inv-content .flight-invoice-details .invoice-infomation {
    display: flex;
    margin: 0 0 15px;
    padding: 0;
}
.car-booking .inv-content .flight-invoice-details .invoice-infomation div {
    background: #f2f2f2;
    padding: 10px;
    width: 32.8%;
    text-align: center;
    color: #2c3038;
    margin: 0 15px 0px 0;
    padding: 10px;
}
@media (max-width: 767px) {
    .car-booking .inv-content .flight-invoice-details .invoice-infomation div {
        width: 100%;
        margin-bottom: 10px;
    }
}
.car-booking .inv-content .flight-invoice-details .invoice-infomation div span {
    color: #000;
    margin-left: 10px;
}
.car-booking .inv-content .flight-invoice-details .invoice-infomation .station {
    margin: 0 15px 0 0;
    padding: 25px;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    div {
    margin: 0;
    padding: 0px;
    color: #95979b;
    text-align: left;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    div
    span {
    display: block;
    margin: 0 0 5px;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info {
    display: flex;
    width: 100%;
    align-items: center;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info
    .point-img {
    width: 5%;
    margin-right: 15px;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info
    .station-details {
    width: 95%;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info
    .station-details
    div {
    width: auto;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info
    .station-details
    div
    .from-add {
    color: #2c3038;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info
    .station-details
    span {
    display: inline;
}
@media (max-width: 767px) {
    .car-booking
        .inv-content
        .flight-invoice-details
        .invoice-infomation
        .station {
        margin-bottom: 15px;
    }
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .days-detail {
    background: none;
    padding: 0px;
    display: flex;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .days-detail
    div {
    width: 50%;
    padding: 15px;
    margin-right: 15px;
    text-align: center;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .days-detail
    div
    span:first-child {
    margin: 0 0 10px;
}
.car-booking
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .days-detail
    .invoice-title {
    font-size: 18px;
    color: #7638ff;
}
@media (max-width: 767px) {
    .car-booking .inv-content .flight-invoice-details .invoice-infomation {
        display: block;
    }
}
.car-booking .inv-content .invoice-address {
    margin: 0;
    padding: 30px 20px;
    justify-content: normal;
}
.car-booking .inv-content .invoice-address div {
    width: 50%;
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-address div {
        width: 100%;
    }
}
.car-booking .inv-content .passenger-info {
    display: flex;
    margin: 0;
    padding: 0;
}
.car-booking .inv-content .passenger-info .passenger-details,
.car-booking .inv-content .passenger-info .payment-info {
    background: none;
    margin: 0 15px 0 0;
    padding: 0px;
    width: 50%;
}
.car-booking .inv-content .passenger-info .passenger-details h5,
.car-booking .inv-content .passenger-info .payment-info h5 {
    color: #0c0c20;
    margin-bottom: 15px;
    font-size: 18px;
}
.car-booking .inv-content .passenger-info .passenger-details div,
.car-booking .inv-content .passenger-info .payment-info div {
    display: flex;
    justify-content: space-between;
}
.car-booking .inv-content .passenger-info .passenger-details div span,
.car-booking .inv-content .passenger-info .payment-info div span {
    color: #2c3038;
    width: 65%;
    text-align: right;
}
@media (max-width: 992px) {
    .car-booking .inv-content .passenger-info .passenger-details div,
    .car-booking .inv-content .passenger-info .payment-info div {
        font-size: 12px;
        display: block;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .passenger-info .passenger-details div,
    .car-booking .inv-content .passenger-info .payment-info div {
        font-size: 15px;
    }
}
.car-booking
    .inv-content
    .passenger-info
    .passenger-details
    .passenger-details-display,
.car-booking
    .inv-content
    .passenger-info
    .payment-info
    .passenger-details-display {
    display: block;
    background: #f2f2f2;
    margin: 0;
    padding: 20px;
    min-height: 180px;
}
@media (max-width: 767px) {
    .car-booking .inv-content .passenger-info .passenger-details,
    .car-booking .inv-content .passenger-info .payment-info {
        width: 100%;
        margin-bottom: 20px;
        margin: 0 10px 20px 0;
        padding: 15px;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .passenger-info {
        display: block;
    }
}
.car-booking .inv-content .passenger-ticket-details {
    margin: 0;
    padding: 15px 20px 30px;
}
.car-booking .inv-content .passenger-ticket-details .invoice-title {
    margin: 0 0 15px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    font-size: 18px;
    font-weight: normal;
}
.car-booking
    .inv-content
    .passenger-ticket-details
    .invoice-title
    span:nth-child(1) {
    color: #fff;
    z-index: 2;
    position: relative;
}
.car-booking .inv-content .passenger-ticket-details .ticket-title-bg {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    -webkit-transform: skewX(35deg);
    transform: skewX(35deg);
    top: 0px;
    left: -22px;
    background: linear-gradient(320deg, #ddceff 0%, #dbecff 100%);
}
.car-booking .inv-content .passenger-ticket-details .ticket-title-bg:hover {
    background: linear-gradient(270.14deg, #7638ff -0.04%, #3698ff 100%);
}
.car-booking .inv-content .invoice-table {
    margin: 0;
    padding: 0px 20px;
}
.car-booking .inv-content .invoice-table h6 {
    padding: 0 0 30px;
    line-height: normal;
}
.car-booking .inv-content .invoice-table table {
    color: #95979b;
}
.car-booking .inv-content .invoice-table table thead tr {
    color: #2c3038;
}
.car-booking .inv-content .invoice-table table .table_width_2 {
    width: 56%;
}
.car-booking .inv-content .invoice-table table tr {
    border-bottom: 1px solid #f2f2f2;
}
.car-booking .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.car-booking .inv-content .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
@media (max-width: 992px) {
    .car-booking .inv-content .invoice-table table tr td,
    .car-booking .inv-content .invoice-table table tr th {
        white-space: nowrap;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-table table {
        margin: 0;
        padding: 15px 20px 0;
    }
}
.car-booking
    .inv-content
    .invoice-table
    .invoice-table-footer
    .table-footer-right {
    color: #2c3038;
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-table .invoice-table-footer .total-item {
        display: none;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-table .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .car-booking .inv-content .invoice-table .invoice-table-footer .total-item {
        display: none;
    }
}
.car-booking .inv-content .invoice-table .total-amountdetails span {
    display: block;
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-table .total-amountdetails {
        margin: 0;
        padding: 15px 20px;
    }
}
.car-booking .inv-content .totalamount-footer {
    background: #f2f2f2;
    border: 0;
    margin: 10px 20px;
    padding: 10px 10px 10px 15px;
}
.car-booking .inv-content .invoice-table-footer {
    border: 0;
}
.car-booking .inv-content .invoice-table-footer .notes span {
    display: block;
    color: #2c3038;
}
.car-booking .inv-content .invoice-table-footer .amount-payable {
    width: 35%;
}
.car-booking .inv-content .invoice-table-footer .table-footer-right table td {
    padding: 5px;
}
@media (max-width: 767px) {
    .car-booking .inv-content .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .car-booking .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
.car-booking .inv-content .payable-table {
    margin: 0 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #f2f2f2;
}
@media (max-width: 767px) {
    .car-booking .inv-content .payable-table {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .payable-table .table-footer-left {
        width: 100%;
    }
}
.car-booking .inv-content .payable-table .amount-payable {
    width: 35%;
}
@media (max-width: 992px) {
    .car-booking .inv-content .payable-table .amount-payable {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .car-booking .inv-content .payable-table .amount-payable {
        width: 100%;
    }
}
.car-booking .inv-content .bank-details {
    margin: 0 20px;
    padding: 30px 0px 20px;
    border-bottom: 1px solid #f2f2f2;
}
.car-booking .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.car-booking .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.car-booking .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.car-booking .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.car-booking .inv-content .terms-condition {
    margin: 0 20px;
    padding: 30px 0;
}
.car-booking .inv-content .terms-condition p {
    color: #000;
    font-weight: 450;
    font-size: 16px;
}
.car-booking .inv-content .thanks-msg {
    padding: 30px 0;
}

.receipt-wrap {
    max-width: 340px;
    margin: 30px auto;
    padding: 30px;
    background-color: #fff;
}
.receipt-wrap .receipt-top {
    text-align: center;
}
.receipt-wrap .receipt-top .company-logo {
    background: #f4f4f4;
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
}
.receipt-wrap .receipt-top .company-name {
    color: #000;
    margin-bottom: 10px;
}
.receipt-wrap .receipt-top .company-address,
.receipt-wrap .receipt-top .company-email {
    font-size: 10px;
}
.receipt-wrap .receipt-heading {
    display: flex;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    margin: 10px 0;
    color: #000;
}
.receipt-wrap .receipt-heading span {
    display: inline-flex;
    padding: 0 5px;
    background-color: #fff;
    z-index: 1;
    font-weight: 700;
    font-size: 10px;
}
.receipt-wrap .receipt-heading:before {
    content: "";
    position: absolute;
    height: 0;
    width: 100%;
    left: 0;
    top: 46%;
    border-top: 1px dashed #95979b;
}
.receipt-wrap .customer-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}
.receipt-wrap .customer-list li {
    display: flex;
    width: 48%;
    font-size: 12px;
    line-height: 1.2em;
    margin-bottom: 10px;
    margin-right: 10px;
    justify-content: space-between;
}
.receipt-wrap .customer-list li .desc {
    color: #000;
}
.receipt-wrap .receipt-table {
    width: 100%;
    line-height: 1.3em;
    font-size: 12px;
}
.receipt-wrap .receipt-table thead th {
    color: #000;
    text-align: left;
    padding: 10px 3px;
    border-top: 1px dashed #95979b;
    border-bottom: 1px dashed #95979b;
}
.receipt-wrap .receipt-table td {
    padding: 6px;
}
.receipt-wrap .receipt-table td:first-child,
.receipt-wrap .receipt-table th:first-child {
    padding-left: 0;
}
.receipt-wrap .receipt-table tr:first-child td {
    padding-top: 10px;
}
.receipt-wrap .bill-list {
    margin: 0;
    padding: 5px 0;
    font-size: 10px;
}
.receipt-wrap .bill-list .bill_list_in {
    display: flex;
    text-align: right;
    justify-content: space-between;
    padding: 4px 0;
}
.receipt-wrap .bill-list .bill_list_in .bill_title {
    padding-right: 20px;
}
.receipt-wrap .bill-list .bill_list_in .bill_value {
    width: 90px;
}
.receipt-wrap .total-payable {
    color: #000;
}
.receipt-wrap .receipt-seperator {
    width: 100%;
    border-top: 1px dashed #95979b;
    margin: 6px 0;
    margin-left: auto;
}
.receipt-wrap .sample_text {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px dashed #95979b;
    line-height: 1.6em;
    color: #95979b;
    font-size: 10px;
}
.receipt-wrap .receipt-footer {
    text-align: center;
    margin-top: 10px;
    font-size: 10px;
}

.receipt-two .bill-list {
    margin: 0;
    padding: 5px 0;
    font-size: 10px;
    border-bottom: 1px dashed #95979b;
}
.receipt-two .bill-list .bill_list_in {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    padding: 4px 0;
}
.receipt-two .bill-list .bill_list_in .bill_title {
    padding-right: 20px;
}
.receipt-two .bill-list .bill_list_in .bill_value {
    width: 90px;
}
.receipt-two .receipt-table {
    margin-top: 0;
}
.receipt-two .receipt-seperator {
    width: 150px;
}
.receipt-two .sample_text img {
    margin-top: 10px;
}

.receipt-three .receipt-top .receipt-title {
    font-size: 18px;
    color: #000;
    margin-bottom: 5px;
}
.receipt-three .receipt-top .company-name {
    color: #95979b;
    text-transform: uppercase;
    font-size: 10px;
}
.receipt-three .receipt-seperator {
    margin: 1px 0;
}
.receipt-three .customer-list {
    margin: 10px 0 0;
}
.receipt-three .receipt-table thead th {
    border-top: 0;
    padding-top: 10px;
}
.receipt-three .receipt-table td {
    padding-bottom: 10px;
}
.receipt-three .bill-list {
    margin: 0;
    padding: 5px 0;
    font-size: 10px;
    border-bottom: 1px dashed #95979b;
}
.receipt-three .bill-list .bill_list_in {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    padding: 4px 0;
}
.receipt-three .bill-list .bill_list_in .bill_title {
    padding-right: 20px;
}
.receipt-three .bill-list .bill_list_in .bill_value {
    width: 90px;
}
.receipt-three .bill-list .receipt-seperator {
    width: 150px;
    margin-left: auto;
    margin-top: 6px;
    margin-bottom: 6px;
}
.receipt-three .sample_text img {
    margin-top: 10px;
}

.receipt-four .receipt-top .receipt-title {
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
}
.receipt-four .receipt-top .company-name {
    color: #95979b;
    text-transform: uppercase;
    font-size: 10px;
}
.receipt-four .receipt-top .company-logo {
    background: none;
    margin-bottom: 0;
}
.receipt-four .receipt-top .company-logo img {
    vertical-align: middle;
}
.receipt-four .receipt-top .receipt-title {
    margin-top: 10px;
}
.receipt-four .receipt-top .company-email {
    padding-bottom: 10px;
}
.receipt-four .receipt-seperator {
    margin: 1px 0;
}
.receipt-four .bill-list .receipt-seperator {
    margin: 6px 0;
}
.receipt-four .customer-list {
    margin-top: 10px;
}
.receipt-four .receipt-table {
    margin-top: 0;
}
.receipt-four .receipt-table thead th {
    border-top: 0;
}
.receipt-four .sample_text img {
    margin-top: 10px;
}

.coffee-shop .inv-content {
    border: 5px solid #000;
    margin: 0;
    padding: 40px;
}
.coffee-shop .inv-content .invoice-header {
    margin: 0;
    padding: 0 0 30px;
    border-bottom: 5px solid #000;
}
.coffee-shop .inv-content .invoice-header a {
    margin: 0 0 10px;
    padding: 0px;
}
.coffee-shop .inv-content .invoice-header .inv-header-left {
    width: 30%;
}
@media (max-width: 992px) {
    .coffee-shop .inv-content .invoice-header .inv-header-left {
        width: 40%;
    }
}
@media (max-width: 767px) {
    .coffee-shop .inv-content .invoice-header .inv-header-left {
        width: auto;
    }
}
@media (max-width: 767px) {
    .coffee-shop .inv-content .invoice-header .inv-header-right {
        margin: 15px 0 0;
    }
}
.coffee-shop .inv-content .invoice-header .inv-header-right .tax-invoice {
    margin: 15px 0 10px;
    padding: 0px;
}
.coffee-shop .inv-content .invoice-header .inv-header-right .gst-details {
    margin: 5px 0 0px;
    padding: 0px;
}
.coffee-shop .inv-content .invoice-header .inv-details {
    display: block;
}
.coffee-shop .inv-content .invoice-header .inv-details span {
    font-size: 24px;
    color: #2c3038;
    font-weight: 700;
}
.coffee-shop .inv-content .invoice-header .inv-details .inv-date {
    margin: 0 0 10px;
    padding: 0px;
}
.coffee-shop .inv-content .invoice-header .inv-details .inv-no {
    background: url("../img/inv-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0;
    padding: 19px;
    color: #fff;
    font-size: 16px;
    color: #fff;
}
.coffee-shop .inv-content .invoice-header .inv-details .inv-no span {
    color: #fff;
    font-size: 18px;
}
@media (max-width: 992px) {
    .coffee-shop .inv-content .invoice-header .inv-details .inv-no {
        margin: 0;
        padding: 10px;
        background: #000;
    }
}
@media (max-width: 767px) {
    .coffee-shop .inv-content .invoice-header .inv-details .inv-no {
        width: 275px;
    }
}
@media (max-width: 575.98px) {
    .coffee-shop .inv-content .invoice-header .inv-details .inv-no {
        width: 245px;
    }
}
.coffee-shop .inv-content .invoice-address {
    margin: 0;
    padding: 40px 0;
}
.coffee-shop .inv-content .invoice-address .invoice-to .invoice-title {
    margin: 0;
    padding: 0;
}
@media (max-width: 767px) {
    .coffee-shop .inv-content .invoice-address .invoice-to {
        margin: 0;
    }
}
.coffee-shop .inv-content .invoice-table {
    margin: 0;
    padding: 0 0 25px;
}
.coffee-shop .inv-content .invoice-table table {
    color: #2c3038;
    margin: 0;
    padding: 0px;
}
.coffee-shop .inv-content .invoice-table table thead .coffee-shop {
    background: #7638ff;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #000;
}
.coffee-shop .inv-content .invoice-table table .table_width_2 {
    width: 50%;
}
.coffee-shop .inv-content .invoice-table table tr {
    border-bottom: 1px solid #000;
}
.coffee-shop .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.coffee-shop .inv-content .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
.coffee-shop .inv-content .invoice-table table tr td {
    margin: 0;
    padding: 25px 15px;
}
@media (max-width: 992px) {
    .coffee-shop .inv-content .invoice-table table tr td,
    .coffee-shop .inv-content .invoice-table table tr th {
        white-space: nowrap;
    }
}
.coffee-shop .inv-content .invoice-table-footer {
    margin: 0;
    padding: 25px 0px;
    border-bottom: 1px solid #000;
}
.coffee-shop .inv-content .invoice-table-footer .table-footer-right {
    color: #2c3038;
}
.coffee-shop .inv-content .invoice-table-footer .table-footer-right table td {
    margin: 0;
    padding: 5px 15px;
}
@media (max-width: 767px) {
    .coffee-shop .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
.coffee-shop .inv-content .table-total-item {
    margin: 0;
    padding: 15px 0px;
}
.coffee-shop .inv-content .total-amountdetails {
    margin: 0;
    padding: 25px 0;
    border-bottom: 1px solid #000;
    text-align: right;
}
@media (max-width: 992px) {
    .coffee-shop .inv-content .total-amountdetails {
        text-align: center;
    }
}
.coffee-shop .inv-content .bank-details {
    margin: 0;
    padding: 25px 15px;
    border-bottom: 1px solid #000;
}
.coffee-shop .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.coffee-shop .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.coffee-shop .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.coffee-shop .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.coffee-shop .inv-content .bank-details .terms-condition {
    margin: 0;
    padding: 0;
    color: #2c3038;
}
.coffee-shop .inv-content .bank-details .terms-condition span {
    font-size: 18px;
    margin: 0 0 10px;
}
.coffee-shop .inv-content .bank-details .terms-condition ol {
    color: #95979b;
}
@media (max-width: 767px) {
    .coffee-shop .inv-content .bank-details .terms-condition {
        margin: 10px 0;
        padding: 0px 0px;
    }
}
@media (max-width: 767px) {
    .coffee-shop .inv-content .bank-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.coffee-shop .inv-content .thanks-msg {
    background: #7638ff;
    border-bottom: 1px solid #000;
    color: #000;
}
@media (max-width: 767px) {
    .coffee-shop .inv-content {
        margin: 0;
        padding: 20px;
    }
}

.domain-hosting .inv-content .invoice-header {
    background: #f2f2f2;
    margin: 20px;
    padding: 30px;
}
.domain-hosting .inv-content .invoice-header h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #2c3038;
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .invoice-header h2 {
        font-size: 28px;
    }
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .invoice-header .inv-header-right {
        margin-top: 10px;
    }
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .invoice-header {
        display: flex;
        flex-direction: column-reverse;
    }
}
.domain-hosting .inv-content .flight-invoice-details {
    margin: 0;
    padding: 0 20px;
}
.domain-hosting .inv-content .flight-invoice-details h3 {
    color: #2c3038;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0 22px 0;
    padding: 0;
    line-height: normal;
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .flight-invoice-details h3 {
        font-size: 30px;
        margin: 20px 0;
    }
}
.domain-hosting .inv-content .flight-invoice-details .invoice-infomation {
    display: flex;
}
.domain-hosting .inv-content .flight-invoice-details .invoice-infomation div {
    background: #f2f2f2;
    padding: 10px;
    width: 32.8%;
    display: flex;
    justify-content: space-between;
    color: #2c3038;
}
.domain-hosting
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    div
    span {
    color: #000;
}
@media (max-width: 767px) {
    .domain-hosting
        .inv-content
        .flight-invoice-details
        .invoice-infomation
        div {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .flight-invoice-details .invoice-infomation {
        display: block;
    }
}
.domain-hosting .inv-content .invoice-address {
    margin: 0;
    padding: 30px 20px;
    justify-content: normal;
}
.domain-hosting .inv-content .invoice-address div {
    width: 50%;
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .invoice-address div {
        width: 100%;
    }
}
.domain-hosting .inv-content .service-details {
    margin: 0;
    padding: 0 20px;
}
.domain-hosting .inv-content .service-details h4 {
    color: #2c3038;
    font-size: 24px;
    margin: 0;
    padding: 20px 0 10px 10px;
    font-weight: 700;
}
.domain-hosting .inv-content .service-details table {
    border-collapse: separate;
    border-spacing: 10px;
    color: #000;
    width: 100%;
}
.domain-hosting .inv-content .service-details table thead th {
    background: #f2f2f2;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 13px;
    color: #000;
    text-align: center;
    font-size: 18px;
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .service-details table thead th {
        font-size: 14px;
    }
}
.domain-hosting .inv-content .service-details table .services {
    width: 50%;
}
.domain-hosting .inv-content .service-details table .dates {
    width: 10%;
}
.domain-hosting .inv-content .service-details table .rooms {
    width: 15%;
}
.domain-hosting .inv-content .service-details table .description {
    width: 50%;
}
.domain-hosting .inv-content .service-details table .charged-amount {
    width: 15%;
}
.domain-hosting .inv-content .service-details table .discount {
    width: 15%;
}
.domain-hosting .inv-content .service-details table .total {
    width: 15%;
}
.domain-hosting .inv-content .service-details table tr:nth-child(odd) {
    background: #f2f2f2;
}
.domain-hosting .inv-content .service-details table tr td {
    padding: 10px;
}
@media (max-width: 992px) {
    .domain-hosting .inv-content .service-details table tr td {
        white-space: nowrap;
    }
}
.domain-hosting .inv-content .service-details .invoice-table-footer {
    margin: 0;
    padding: 0;
}
.domain-hosting
    .inv-content
    .service-details
    .invoice-table-footer
    .table-footer-right {
    width: 34%;
}
.domain-hosting
    .inv-content
    .service-details
    .invoice-table-footer
    .table-footer-right
    table
    td:first-child {
    background: #fff;
}
.domain-hosting
    .inv-content
    .service-details
    .invoice-table-footer
    .table-footer-right
    table
    td:last-child {
    background: #f2f2f2;
}
@media (max-width: 767px) {
    .domain-hosting
        .inv-content
        .service-details
        .invoice-table-footer
        .table-footer-right
        table
        td {
        width: 50%;
    }
}
@media (max-width: 992px) {
    .domain-hosting
        .inv-content
        .service-details
        .invoice-table-footer
        .table-footer-right {
        width: 55%;
    }
}
@media (max-width: 992px) {
    .domain-hosting
        .inv-content
        .service-details
        .invoice-table-footer
        .table-footer-right {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .service-details .invoice-table-footer {
        display: block;
    }
}
.domain-hosting
    .inv-content
    .service-details
    .subtotal
    .table-footer-right
    table
    td:last-child {
    background: #fcfcfc;
}
.domain-hosting
    .inv-content
    .service-details
    .table-total-item
    .table-footer-right
    table
    td:last-child {
    background: #2c3038;
    color: #f2f2f2;
}
.domain-hosting .inv-content .invoice-table-footer {
    border: 0;
}
.domain-hosting .inv-content .invoice-table-footer .notes span {
    display: block;
    color: #2c3038;
}
.domain-hosting
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    td {
    padding: 10px;
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .domain-hosting .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
.domain-hosting .inv-content .totalamount-footer {
    background: #f2f2f2;
    border: 0;
    margin: 10px 20px 0;
    padding: 10px 10px 10px 15px;
}
.domain-hosting .inv-content .totalamount-footer .table-footer-left,
.domain-hosting .inv-content .totalamount-footer .table-footer-right {
    width: 50%;
}
.domain-hosting .inv-content .totalamount-footer .table-footer-left table,
.domain-hosting .inv-content .totalamount-footer .table-footer-right table {
    width: 100%;
}
.domain-hosting .inv-content .totalamount-footer .table-footer-left table td,
.domain-hosting .inv-content .totalamount-footer .table-footer-right table td {
    width: 50%;
}
@media (max-width: 767px) {
    .domain-hosting
        .inv-content
        .totalamount-footer
        .table-footer-left
        table
        td,
    .domain-hosting
        .inv-content
        .totalamount-footer
        .table-footer-right
        table
        td {
        width: auto;
    }
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .totalamount-footer .table-footer-left,
    .domain-hosting .inv-content .totalamount-footer .table-footer-right {
        width: 100%;
    }
}
.domain-hosting
    .inv-content
    .totalamount-footer
    .table-footer-left
    table
    td:last-child
    div {
    background: #fcfcfc;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.domain-hosting .inv-content .totalamount-footer .table-footer-right table td {
    text-align: left;
}
.domain-hosting
    .inv-content
    .totalamount-footer
    .table-footer-right
    table
    td:last-child
    div {
    background: #000;
    padding: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
}
@media (max-width: 767px) {
    .domain-hosting
        .inv-content
        .totalamount-footer
        .table-footer-right
        table
        td {
        padding: 10px 0 0;
    }
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .totalamount-footer {
        display: block;
    }
}
.domain-hosting .inv-content .payable-table {
    margin: 0 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #7638ff;
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .payable-table {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .payable-table .table-footer-left {
        width: 100%;
    }
}
.domain-hosting .inv-content .payable-table .amount-payable {
    width: 35%;
}
@media (max-width: 992px) {
    .domain-hosting .inv-content .payable-table .amount-payable {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .domain-hosting .inv-content .payable-table .amount-payable {
        width: 100%;
    }
}
.domain-hosting .inv-content .bank-details {
    margin: 0 20px;
    padding: 20px 0px 10px;
    border-bottom: 1px solid #f2f2f2;
}
.domain-hosting .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.domain-hosting .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.domain-hosting .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.domain-hosting .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.domain-hosting .inv-content .terms-condition {
    margin: 0 20px;
    padding: 30px 0;
}
.domain-hosting .inv-content .terms-condition p {
    color: #000;
}
.domain-hosting .inv-content .thanks-msg {
    padding: 30px 0;
}

.e-commerce .invoice-header {
    margin: 0;
    padding: 40px 40px 15px;
}
.e-commerce .invoice-header .inv-header-right a {
    display: block;
}
.e-commerce .invoice-header .inv-header-right span {
    margin-bottom: 10px;
    display: block;
}
.e-commerce .invoice-header .inv-header-right .inv-details {
    display: block;
}
.e-commerce .invoice-header .inv-header-right .inv-details .inv-date {
    margin: 0 0 5px;
    padding: 0;
}
.e-commerce .invoice-header .inv-header-right .inv-details .inv-date span {
    display: inline;
}
.e-commerce .invoice-header .company-details .invoice-title {
    color: #2c3038;
    font-size: 24px;
}
.e-commerce .invoice-header .company-details .gst-details {
    margin: 10px 0 0;
    padding: 0;
}
.e-commerce .invoice-header .company-details .gst-details span {
    color: #2c3038;
}
@media (max-width: 767px) {
    .e-commerce .invoice-header .company-details {
        margin: 30px 0 0;
        padding: 0;
    }
}
@media (max-width: 767px) {
    .e-commerce .invoice-header {
        display: flex;
        flex-direction: column-reverse;
        align-items: normal;
        margin: 0;
        padding: 20px 20px;
    }
    .e-commerce .invoice-header .tax-invoice {
        display: none;
    }
}
.e-commerce .invoice-header .inv-header-left .tax-invoice {
    color: #2c3038;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 15px;
}
.e-commerce .invoice-address table {
    margin: 0;
    padding: 25px 40px;
    justify-content: normal;
}
.e-commerce .invoice-address table .invoice-to {
    width: 32%;
}
@media (max-width: 767px) {
    .e-commerce .invoice-address table .invoice-to {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .e-commerce .invoice-address table {
        margin: 0;
        padding: 20px;
    }
}
.e-commerce .invoice-table table {
    color: #95979b;
}
.e-commerce .invoice-table table thead .ecommercetable {
    background: #7638ff;
    color: #95979b;
}
.e-commerce .invoice-table table .table_width_2 {
    width: 38%;
}
.e-commerce .invoice-table table tr {
    border-bottom: 1px solid #f2f2f2;
}
.e-commerce .invoice-table table tr .table-description {
    color: #2c3038;
}
.e-commerce .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
@media (max-width: 992px) {
    .e-commerce .invoice-table table tr td,
    .e-commerce .invoice-table table tr th {
        white-space: nowrap;
    }
}
@media (max-width: 767px) {
    .e-commerce .invoice-table table {
        margin: 0;
        padding: 15px 20px 0;
    }
}
.e-commerce .invoice-table-footer .table-footer-right {
    color: #2c3038;
}
@media (max-width: 767px) {
    .e-commerce .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .e-commerce .invoice-table-footer .total-item {
        display: none;
    }
}
.e-commerce .total-amountdetails span {
    display: block;
}
@media (max-width: 767px) {
    .e-commerce .total-amountdetails {
        margin: 0;
        padding: 15px 20px;
    }
}
@media (max-width: 767px) {
    .e-commerce .bank-details,
    .e-commerce .terms-condition {
        margin: 0;
        padding: 5px 20px;
    }
}

.fitness-center .invoice-header {
    margin: 0 20px;
    padding: 20px 0px 15px;
}
.fitness-center .invoice-header .tax-invoice {
    font-size: 24px;
    font-weight: 700;
}
.fitness-center .invoice-header .inv-header-right a {
    display: block;
}
.fitness-center .invoice-header .inv-header-right span {
    margin-bottom: 5px;
    display: block;
    font-size: 18px;
    font-weight: 400;
}
.fitness-center .invoice-header .inv-header-right .inv-details {
    display: block;
}
.fitness-center .invoice-header .inv-header-right .inv-details .inv-date {
    margin: 0;
    padding: 0;
}
.fitness-center .invoice-header .inv-header-right .inv-details .inv-date span {
    display: inline;
}
.fitness-center .invoice-header .company-details .invoice-title {
    color: #000;
    display: block;
    margin-top: 10px;
}
.fitness-center .invoice-header .company-details .gst-details {
    margin: 10px 0 0;
    padding: 0;
}
.fitness-center .invoice-header .company-details .gst-details span {
    color: #2c3038;
}
@media (max-width: 767px) {
    .fitness-center .invoice-header .company-details {
        margin: 15px 0 0;
        padding: 0;
    }
}
@media (max-width: 767px) {
    .fitness-center .invoice-header {
        display: flex;
        flex-direction: column-reverse;
        align-items: normal;
        margin: 0;
        padding: 20px 20px;
    }
    .fitness-center .invoice-header .tax-invoice {
        display: none;
    }
}
.fitness-center .invoice-address {
    margin: 0;
    padding: 25px 20px;
}
.fitness-center .invoice-address .grand-total {
    background: #f2f2f2;
    margin: 0;
    padding: 15px;
    text-align: center;
    width: 200px;
}
.fitness-center .invoice-address .grand-total span {
    display: block;
}
.fitness-center .invoice-address .grand-total span:last-child {
    color: #000;
    font-size: 24px;
    font-weight: 700;
}
@media (max-width: 767px) {
    .fitness-center .invoice-address {
        padding: 15px 20px;
    }
}
.fitness-center .invoice-table {
    margin: 0;
    padding: 0 20px;
}
.fitness-center .invoice-table table {
    color: #95979b;
}
.fitness-center .invoice-table table thead .fitness-center {
    background: #2c3038;
    color: #95979b;
}
.fitness-center .invoice-table table .table_width_2 {
    width: 52%;
}
.fitness-center .invoice-table table tr {
    border-bottom: 1px solid #f2f2f2;
}
.fitness-center .invoice-table table tr .table-description {
    color: #2c3038;
}
.fitness-center .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
@media (max-width: 992px) {
    .fitness-center .invoice-table table tr td,
    .fitness-center .invoice-table table tr th {
        white-space: nowrap;
    }
}
@media (max-width: 767px) {
    .fitness-center .invoice-table table {
        margin: 0;
        padding: 15px 20px 0;
    }
}
.fitness-center .invoice-table-footer {
    margin: 0 22px;
    padding: 5px 0;
}
.fitness-center .invoice-table-footer .table-footer-left span {
    display: block;
    color: #2c3038;
}
@media (max-width: 767px) {
    .fitness-center .invoice-table-footer .table-footer-left {
        width: auto;
    }
    .fitness-center .invoice-table-footer .table-footer-left .total-item {
        display: none;
    }
}
.fitness-center .invoice-table-footer .table-footer-right {
    color: #2c3038;
    width: 36%;
}
@media (max-width: 992px) {
    .fitness-center .invoice-table-footer .table-footer-right {
        width: 64%;
    }
}
@media (max-width: 767px) {
    .fitness-center .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .fitness-center .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .fitness-center .invoice-table-footer .total-item {
        display: none;
    }
}
.fitness-center .payable-table {
    margin: 0 22px;
    padding: 20px 0;
}
@media (max-width: 767px) {
    .fitness-center .payable-table {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .fitness-center .payable-table .table-footer-left {
        width: 100%;
    }
}
.fitness-center .payable-table .amount-payable {
    width: 35%;
}
@media (max-width: 992px) {
    .fitness-center .payable-table .amount-payable {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .fitness-center .payable-table .amount-payable {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .fitness-center .payable-table {
        margin: 0 20px;
        padding: 10px 0;
    }
}
.fitness-center .total-amountdetails span {
    display: block;
}
@media (max-width: 767px) {
    .fitness-center .total-amountdetails {
        margin: 0;
        padding: 15px 20px;
    }
}
.fitness-center .bank-details {
    margin: 0 20px;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2;
}
.fitness-center .bank-details .payment-info {
    color: #2c3038;
}
.fitness-center .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.fitness-center .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.fitness-center .bank-details .payment-info span {
    color: #95979b;
}
.fitness-center .bank-details .company-sign {
    color: #000;
}
.fitness-center .terms-condition {
    margin: 0 20px;
    padding: 20px 0;
}
.fitness-center .terms-condition p {
    color: #000;
}
@media (max-width: 767px) {
    .fitness-center .bank-details,
    .fitness-center .terms-condition {
        margin: 0;
        padding: 5px 20px;
    }
}

.flight-booking .inv-content .invoice-header {
    background: #f2f2f2;
    margin: 20px;
    padding: 30px;
}
.flight-booking .inv-content .invoice-header h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #2c3038;
}
@media (max-width: 767px) {
    .flight-booking .inv-content .invoice-header h2 {
        font-size: 30px;
    }
}
@media (max-width: 767px) {
    .flight-booking .inv-content .invoice-header .inv-header-right {
        margin-top: 10px;
    }
}
.flight-booking .inv-content .flight-invoice-details {
    margin: 0;
    padding: 0 20px;
}
.flight-booking .inv-content .flight-invoice-details h3 {
    color: #2c3038;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0 22px 0;
    padding: 0;
    line-height: normal;
}
@media (max-width: 767px) {
    .flight-booking .inv-content .flight-invoice-details h3 {
        font-size: 30px;
        margin: 20px 0;
    }
}
.flight-booking .inv-content .flight-invoice-details .invoice-infomation {
    display: flex;
}
.flight-booking .inv-content .flight-invoice-details .invoice-infomation div {
    background: #f2f2f2;
    padding: 10px;
    width: 32.8%;
    text-align: center;
    color: #000;
}
@media (max-width: 767px) {
    .flight-booking
        .inv-content
        .flight-invoice-details
        .invoice-infomation
        div {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {
    .flight-booking .inv-content .flight-invoice-details .invoice-infomation {
        display: block;
    }
}
.flight-booking .inv-content .invoice-address {
    margin: 0;
    padding: 30px 20px;
    justify-content: normal;
}
.flight-booking .inv-content .invoice-address div {
    width: 50%;
}
@media (max-width: 767px) {
    .flight-booking .inv-content .invoice-address div {
        width: 100%;
    }
}
.flight-booking .inv-content .passenger-info {
    display: flex;
    margin: 0 20px 30px;
    padding: 0;
}
.flight-booking .inv-content .passenger-info .passenger-details,
.flight-booking .inv-content .passenger-info .payment-info {
    background: #f2f2f2;
    margin: 0 10px 0 0;
    padding: 15px 25px;
    width: 50%;
}
.flight-booking .inv-content .passenger-info .passenger-details h5,
.flight-booking .inv-content .passenger-info .payment-info h5 {
    color: #0c0c20;
    margin-bottom: 15px;
    font-size: 18px;
}
.flight-booking .inv-content .passenger-info .passenger-details div,
.flight-booking .inv-content .passenger-info .payment-info div {
    display: flex;
    justify-content: space-between;
}
.flight-booking .inv-content .passenger-info .passenger-details div span,
.flight-booking .inv-content .passenger-info .payment-info div span {
    color: #2c3038;
}
@media (max-width: 767px) {
    .flight-booking .inv-content .passenger-info .passenger-details,
    .flight-booking .inv-content .passenger-info .payment-info {
        width: 100%;
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .flight-booking .inv-content .passenger-info {
        display: block;
    }
}
.flight-booking .inv-content .invoice-table {
    margin: 0;
    padding: 0px 20px;
}
.flight-booking .inv-content .invoice-table h6 {
    padding: 0 0 30px;
    line-height: normal;
}
.flight-booking .inv-content .invoice-table table {
    color: #95979b;
}
.flight-booking .inv-content .invoice-table table thead tr {
    background: linear-gradient(270.14deg, #7638ff -0.04%, #3698ff 100%);
    color: #95979b;
}
.flight-booking .inv-content .invoice-table table .table_width_2 {
    width: 38%;
}
.flight-booking .inv-content .invoice-table table tr {
    border-bottom: 1px solid #f2f2f2;
}
.flight-booking .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.flight-booking .inv-content .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
@media (max-width: 992px) {
    .flight-booking .inv-content .invoice-table table tr td,
    .flight-booking .inv-content .invoice-table table tr th {
        white-space: nowrap;
    }
}
@media (max-width: 767px) {
    .flight-booking .inv-content .invoice-table table {
        margin: 0;
        padding: 15px 20px 0;
    }
}
.flight-booking
    .inv-content
    .invoice-table
    .invoice-table-footer
    .table-footer-right {
    color: #2c3038;
}
@media (max-width: 767px) {
    .flight-booking
        .inv-content
        .invoice-table
        .invoice-table-footer
        .total-item {
        display: none;
    }
}
@media (max-width: 767px) {
    .flight-booking .inv-content .invoice-table .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .flight-booking
        .inv-content
        .invoice-table
        .invoice-table-footer
        .total-item {
        display: none;
    }
}
.flight-booking .inv-content .invoice-table .total-amountdetails span {
    display: block;
}
@media (max-width: 767px) {
    .flight-booking .inv-content .invoice-table .total-amountdetails {
        margin: 0;
        padding: 15px 20px;
    }
}
.flight-booking .inv-content .totalamount-footer {
    background: #f2f2f2;
    border: 0;
    margin: 10px 20px;
    padding: 15px 10px 15px 15px;
}
.flight-booking .inv-content .invoice-table-footer {
    border: 0;
}
.flight-booking .inv-content .invoice-table-footer .notes span {
    display: block;
    color: #2c3038;
}
.flight-booking
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    td {
    padding: 5px;
}
@media (max-width: 767px) {
    .flight-booking .inv-content .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .flight-booking .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
.flight-booking .inv-content .payable-table {
    margin: 0;
    padding: 15px 20px;
}
@media (max-width: 767px) {
    .flight-booking .inv-content .payable-table {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .flight-booking .inv-content .payable-table .table-footer-left {
        width: 100%;
    }
}
.flight-booking .inv-content .payable-table .amount-payable {
    width: 35%;
}
@media (max-width: 992px) {
    .flight-booking .inv-content .payable-table .amount-payable {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .flight-booking .inv-content .payable-table .amount-payable {
        width: 100%;
    }
}
.flight-booking .inv-content .bank-details {
    margin: 0 20px;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2;
}
.flight-booking .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.flight-booking .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.flight-booking .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.flight-booking .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.flight-booking .inv-content .terms-condition {
    margin: 0 20px;
    padding: 30px 0;
}
.flight-booking .inv-content .terms-condition p {
    color: #000;
}
.flight-booking .inv-content .thanks-msg {
    padding: 30px 0;
}

.hotel-booking .inv-content .invoice-header .inv-header-left span {
    display: block;
    margin: 10px 0 0;
}
.hotel-booking .inv-content .invoice-header .inv-header-right {
    width: 54%;
    position: relative;
    overflow: hidden;
}
.hotel-booking .inv-content .invoice-header .company-details .gst-details {
    position: relative;
    margin: 0;
    padding: 40px 30px;
    color: #3f4254;
    z-index: 2;
    left: 20px;
}
@media (max-width: 992px) {
    .hotel-booking .inv-content .invoice-header .company-details .gst-details {
        color: #000;
    }
}
@media (max-width: 767px) {
    .hotel-booking .inv-content .invoice-header .company-details .gst-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.hotel-booking .inv-content .invoice-header .company-details .address-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-transform: skewX(35deg);
    transform: skewX(35deg);
    top: 0px;
    right: -46px;
    overflow: hidden;
    background: linear-gradient(320deg, #ddceff 0%, #dbecff 100%);
}
.hotel-booking .inv-content .invoice-header .company-details .address-bg:hover {
    background: linear-gradient(270.14deg, #7638ff -0.04%, #3698ff 100%);
}
@media (max-width: 992px) {
    .hotel-booking .inv-content .invoice-header .company-details .address-bg {
        display: none;
    }
}
.hotel-booking .inv-content .hotel-info {
    display: flex;
    margin: 0;
    padding: 10px 30px 0;
}
.hotel-booking .inv-content .hotel-info .hotel-booking-details {
    width: 70%;
    border: 1px solid #f2f2f2;
    margin: 0 15px 0 0;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}
.hotel-booking .inv-content .hotel-info .hotel-booking-details .booked-info {
    width: 33.33%;
    display: inline-flex;
}
.hotel-booking
    .inv-content
    .hotel-info
    .hotel-booking-details
    .booked-info
    span {
    margin: 0 0px 0 0;
    padding: 15px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    width: 50%;
}
.hotel-booking
    .inv-content
    .hotel-info
    .hotel-booking-details
    .booked-info
    .right-info {
    border-right: 0;
    color: #000000;
}
@media (max-width: 992px) {
    .hotel-booking
        .inv-content
        .hotel-info
        .hotel-booking-details
        .booked-info {
        width: 50%;
    }
}
@media (max-width: 767px) {
    .hotel-booking
        .inv-content
        .hotel-info
        .hotel-booking-details
        .booked-info {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .hotel-booking .inv-content .hotel-info .hotel-booking-details {
        display: block;
        width: 100%;
    }
}
.hotel-booking .inv-content .hotel-info .hotel-booking-address {
    border: 1px solid #f2f2f2;
    margin: 0 0 0;
    padding: 25px;
    width: 30%;
}
.hotel-booking .inv-content .hotel-info .hotel-booking-address .billing-name {
    color: #2c3038;
    display: block;
    margin: 10px 0;
}
@media (max-width: 767px) {
    .hotel-booking .inv-content .hotel-info .hotel-booking-address {
        width: 100%;
        margin: 10px 0;
    }
}
@media (max-width: 767px) {
    .hotel-booking .inv-content .hotel-info {
        display: block;
    }
}
.hotel-booking .inv-content .hotel-name {
    font-size: 40px;
    margin: 0;
    padding: 0px 0 0 30px;
    color: #7539ff;
    font-weight: 700;
}
.hotel-booking .inv-content .service-details {
    margin: 0;
    padding: 0 30px;
}
.hotel-booking .inv-content .service-details h4 {
    color: #2c3038;
    font-size: 24px;
    margin: 0;
    padding: 30px 0 30px 10px;
    font-weight: 700;
}
.hotel-booking .inv-content .service-details table {
    border-collapse: separate;
    border-spacing: 10px;
    color: #000;
    width: 100%;
}
.hotel-booking .inv-content .service-details table thead th {
    background: #2c3038;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 4px;
    color: #f2f2f2;
    text-align: center;
    font-size: 18px;
}
@media (max-width: 767px) {
    .hotel-booking .inv-content .service-details table thead th {
        font-size: 14px;
    }
}
.hotel-booking .inv-content .service-details table .services {
    width: 40%;
}
.hotel-booking .inv-content .service-details table .dates {
    width: 10%;
}
.hotel-booking .inv-content .service-details table .rooms {
    width: 15%;
}
.hotel-booking .inv-content .service-details table .description {
    width: 25%;
}
.hotel-booking .inv-content .service-details table .charged-amount {
    width: 20%;
}
.hotel-booking .inv-content .service-details table .discount {
    width: 15%;
}
.hotel-booking .inv-content .service-details table .total {
    width: 15%;
}
.hotel-booking .inv-content .service-details table tr:nth-child(odd) {
    background: #f2f2f2;
}
.hotel-booking .inv-content .service-details table tr td {
    padding: 10px;
}
@media (max-width: 992px) {
    .hotel-booking .inv-content .service-details table tr td {
        white-space: nowrap;
    }
}
.hotel-booking .inv-content .service-details .invoice-table-footer {
    margin: 0;
    padding: 0;
}
.hotel-booking
    .inv-content
    .service-details
    .invoice-table-footer
    .table-footer-right {
    width: 32%;
}
.hotel-booking
    .inv-content
    .service-details
    .invoice-table-footer
    .table-footer-right
    table
    td:first-child {
    background: #fff;
}
.hotel-booking
    .inv-content
    .service-details
    .invoice-table-footer
    .table-footer-right
    table
    td:last-child {
    background: #f2f2f2;
}
@media (max-width: 767px) {
    .hotel-booking
        .inv-content
        .service-details
        .invoice-table-footer
        .table-footer-right
        table
        td {
        width: 50%;
    }
}
@media (max-width: 992px) {
    .hotel-booking
        .inv-content
        .service-details
        .invoice-table-footer
        .table-footer-right {
        width: 55%;
    }
}
@media (max-width: 992px) {
    .hotel-booking
        .inv-content
        .service-details
        .invoice-table-footer
        .table-footer-right {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .hotel-booking .inv-content .service-details .invoice-table-footer {
        display: block;
    }
}
.hotel-booking
    .inv-content
    .service-details
    .subtotal
    .table-footer-right
    table
    td:last-child {
    background: #fcfcfc;
}
.hotel-booking
    .inv-content
    .service-details
    .table-total-item
    .table-footer-right
    table
    td:last-child {
    background: #2c3038;
    color: #fff;
}
.hotel-booking .inv-content .terms-condition {
    border-bottom: 0;
    margin: 0;
    padding: 30px;
}
.hotel-booking .inv-content .terms-condition span {
    color: #2c3038;
}
.hotel-booking .inv-content .terms-condition ol {
    color: #95979b;
}
.hotel-booking .inv-content .thanks-msg {
    background: #f2f2f2;
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    margin: 0 30px 40px;
    padding: 15px 0;
    color: #000;
}

.booking-date {
    color: #000000;
    font-size: 16px;
    font-weight: 450;
}

.internet-billing .inv-content {
    border: 5px solid #000;
    margin: 0;
    padding: 40px;
}
.internet-billing .inv-content .invoice-header {
    margin: 0;
    padding: 0 0 20px;
    border-bottom: 5px solid #000;
}
.internet-billing .inv-content .invoice-header a {
    margin: 0 0 10px;
    padding: 0px;
}
.internet-billing .inv-content .invoice-header .inv-header-right {
    width: 30%;
}
@media (max-width: 992px) {
    .internet-billing .inv-content .invoice-header .inv-header-right {
        width: 50%;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .invoice-header .inv-header-right {
        width: auto;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .invoice-header .inv-header-left {
        margin: 15px 0 0;
    }
}
.internet-billing .inv-content .invoice-header .inv-header-left .tax-invoice {
    margin: 15px 0 10px;
    padding: 0px;
}
.internet-billing .inv-content .invoice-header .inv-header-left .gst-details {
    margin: 5px 0 0px;
    padding: 0px;
}
.internet-billing
    .inv-content
    .invoice-header
    .inv-header-left
    .company-details
    .company-name {
    color: #000;
}
.internet-billing .inv-content .invoice-header .inv-details {
    display: block;
}
.internet-billing .inv-content .invoice-header .inv-details span {
    font-size: 24px;
    color: #2c3038;
    font-weight: 700;
}
.internet-billing .inv-content .invoice-header .inv-details .inv-date {
    margin: 0 0 10px;
    padding: 0px;
}
.internet-billing .inv-content .invoice-header .inv-details .inv-no {
    background: url("../img/inv-bg-rightblue.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0;
    padding: 20px;
    color: #fff;
    font-size: 16px;
    color: #fff;
}
.internet-billing .inv-content .invoice-header .inv-details .inv-no span {
    color: #fff;
    font-size: 18px;
}
@media (max-width: 1199.98px) {
    .internet-billing .inv-content .invoice-header .inv-details .inv-no {
        margin: 0;
        padding: 10px;
    }
}
@media (max-width: 992px) {
    .internet-billing .inv-content .invoice-header .inv-details .inv-no {
        margin: 0;
        padding: 10px;
        background: #000;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .invoice-header .inv-details .inv-no {
        width: 275px;
        background: #000;
    }
}
@media (max-width: 575.98px) {
    .internet-billing .inv-content .invoice-header .inv-details .inv-no {
        width: 235px;
        background: #000;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .invoice-header {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}
.internet-billing .inv-content .invoice-address {
    margin: 0;
    padding: 20px 0;
}
.internet-billing .inv-content .invoice-address .invoice-to .invoice-title {
    margin: 0;
    padding: 0;
}
.internet-billing .inv-content .invoice-address .invoice-to span {
    margin-bottom: 5px;
}
@media (max-width: 767px) {
    .internet-billing .inv-content .invoice-address .invoice-to {
        margin: 0;
    }
}
.internet-billing .inv-content .billing-info {
    display: flex;
    margin: 0 0 45px;
    padding: 0 0 20px;
    border-bottom: 5px solid #000;
}
.internet-billing .inv-content .billing-info .billing-details {
    background: none;
    margin: 0 20px 0 0;
    padding: 0px;
    width: 50%;
}
.internet-billing .inv-content .billing-info .billing-details h5 {
    color: #0c0c20;
    margin-bottom: 15px;
    font-size: 18px;
}
.internet-billing .inv-content .billing-info .billing-details div {
    display: flex;
    justify-content: space-between;
}
.internet-billing .inv-content .billing-info .billing-details div span {
    color: #2c3038;
    width: 70%;
    text-align: right;
}
@media (max-width: 992px) {
    .internet-billing .inv-content .billing-info .billing-details div {
        font-size: 12px;
        display: block;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .billing-info .billing-details div {
        font-size: 15px;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .billing-info .billing-details {
        width: 100%;
        margin-bottom: 20px;
        margin: 0 10px 20px 0;
        padding: 0px;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .billing-info {
        display: block;
    }
}
.internet-billing .inv-content .invoice-table {
    margin: 0;
    padding: 0 0 25px;
}
.internet-billing .inv-content .invoice-table table {
    color: #2c3038;
    margin: 0;
    padding: 0px;
}
.internet-billing .inv-content .invoice-table table thead tr {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #000;
}
.internet-billing .inv-content .invoice-table table .table_width_2 {
    width: 62%;
}
.internet-billing .inv-content .invoice-table table tr {
    border-bottom: 1px solid #000;
}
.internet-billing .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.internet-billing .inv-content .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
.internet-billing .inv-content .invoice-table table tr td {
    margin: 0;
    padding: 25px 15px;
}
@media (max-width: 992px) {
    .internet-billing .inv-content .invoice-table table tr td,
    .internet-billing .inv-content .invoice-table table tr th {
        white-space: nowrap;
    }
}
.internet-billing .inv-content .invoice-table-footer {
    margin: 0;
    padding: 20px 0px;
    border-bottom: 1px solid #000;
}
.internet-billing .inv-content .invoice-table-footer .table-footer-left {
    width: 69%;
}
@media (max-width: 767px) {
    .internet-billing .inv-content .invoice-table-footer .table-footer-left {
        width: auto;
    }
}
.internet-billing .inv-content .invoice-table-footer .table-footer-right {
    color: #2c3038;
}
.internet-billing
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    td {
    margin: 0;
    padding: 5px 15px;
}
@media (max-width: 767px) {
    .internet-billing
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        margin: 0;
        padding: 15px 0px;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
.internet-billing .inv-content .table-total-item {
    margin: 0;
    padding: 15px 0px 15px 15px;
}
@media (max-width: 767px) {
    .internet-billing .inv-content .table-total-item {
        margin: 0;
        padding: 15px 0px;
    }
}
.internet-billing .inv-content .total-amountdetails {
    margin: 0;
    padding: 25px 0;
    border-bottom: 1px solid #000;
    text-align: center;
}
@media (max-width: 992px) {
    .internet-billing .inv-content .total-amountdetails {
        text-align: center;
    }
}
.internet-billing .inv-content .bank-details {
    margin: 0;
    padding: 25px 15px;
    border-bottom: 1px solid #000;
}
.internet-billing .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.internet-billing .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.internet-billing .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.internet-billing .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.internet-billing .inv-content .bank-details .terms-condition {
    margin: 0;
    padding: 0;
    color: #2c3038;
}
.internet-billing .inv-content .bank-details .terms-condition span {
    font-size: 18px;
    margin: 0 0 10px;
}
.internet-billing .inv-content .bank-details .terms-condition ol {
    color: #95979b;
}
@media (max-width: 767px) {
    .internet-billing .inv-content .bank-details .terms-condition {
        margin: 10px 0;
        padding: 0px 0px;
    }
}
@media (max-width: 767px) {
    .internet-billing .inv-content .bank-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.internet-billing .inv-content .thanks-msg {
    background: #f2f2f2;
    border-bottom: 1px solid #000;
    color: #000;
}
@media (max-width: 767px) {
    .internet-billing .inv-content {
        margin: 0;
        padding: 20px;
    }
}

.medical .inv-content {
    border: 1px solid #000;
    margin: 0;
    padding: 30px;
}
.medical .inv-content .invoice-header {
    border-top: 10px solid #2c3038;
    border-bottom: 10px solid #2c3038;
    margin: 0;
    padding: 20px 20px;
}
.medical .inv-content .invoice-header .inv-header-left {
    width: 54%;
}
@media (max-width: 1199.98px) {
    .medical .inv-content .invoice-header .inv-header-left {
        width: 56%;
    }
}
@media (max-width: 767px) {
    .medical .inv-content .invoice-header .inv-header-left {
        width: 100%;
    }
}
.medical .inv-content .invoice-header .inv-header-left h4 {
    color: #28084b;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 15px;
}
@media (max-width: 767px) {
    .medical .inv-content .invoice-header .inv-header-left h4 {
        display: none;
    }
}
.medical .inv-content .invoice-header .inv-header-right span {
    display: block;
    margin: 15px 0;
}
.medical .inv-content .invoice-header .inv-header-right h3 {
    font-size: 40px;
    margin: 0;
    padding: 0;
    color: #2c3038;
    font-weight: 700;
}
@media (max-width: 992px) {
    .medical .inv-content .invoice-header .inv-header-right h3 {
        font-size: 30px;
    }
}
.medical .inv-content .invoice-header .company-details {
    position: relative;
    overflow: hidden;
}
.medical .inv-content .invoice-header .company-details .gst-details {
    position: relative;
    margin: 0;
    padding: 40px 30px;
    color: #fff;
    z-index: 2;
}
.medical .inv-content .invoice-header .company-details .gst-details span {
    color: #fff;
}
@media (max-width: 992px) {
    .medical .inv-content .invoice-header .company-details .gst-details span {
        color: #000;
    }
}
@media (max-width: 992px) {
    .medical .inv-content .invoice-header .company-details .gst-details {
        color: #000;
        margin: 0;
        padding: 10px 0px;
    }
}
@media (max-width: 767px) {
    .medical .inv-content .invoice-header .company-details .gst-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.medical .inv-content .invoice-header .company-details .address-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-transform: skewX(-35deg);
    transform: skewX(-35deg);
    top: 0px;
    right: 38px;
    overflow: hidden;
    background-color: #7638ff;
}
.medical .inv-content .invoice-header .company-details .address-bg:hover {
    background-color: #7638ff;
}
@media (max-width: 1199.98px) {
    .medical .inv-content .invoice-header .company-details .address-bg {
        right: 58px;
    }
}
@media (max-width: 992px) {
    .medical .inv-content .invoice-header .company-details .address-bg {
        display: none;
    }
}
@media (max-width: 767px) {
    .medical .inv-content .invoice-header {
        flex-direction: column-reverse;
        display: flex;
    }
}
.medical .inv-content .patient-info {
    display: flex;
    margin: 0 0 20px;
    padding: 0;
}
.medical .inv-content .patient-info .patient-details,
.medical .inv-content .patient-info .treatment-info {
    background: #f2f2f2;
    margin: 0 20px 0 0;
    padding: 20px;
    width: 50%;
}
.medical .inv-content .patient-info .patient-details h5,
.medical .inv-content .patient-info .treatment-info h5 {
    color: #0c0c20;
    margin-bottom: 15px;
    font-size: 18px;
}
.medical .inv-content .patient-info .patient-details div,
.medical .inv-content .patient-info .treatment-info div {
    display: flex;
    justify-content: space-between;
}
.medical .inv-content .patient-info .patient-details div span,
.medical .inv-content .patient-info .treatment-info div span {
    color: #2c3038;
    width: 70%;
    text-align: right;
}
@media (max-width: 992px) {
    .medical .inv-content .patient-info .patient-details div,
    .medical .inv-content .patient-info .treatment-info div {
        font-size: 12px;
        display: block;
    }
}
@media (max-width: 767px) {
    .medical .inv-content .patient-info .patient-details div,
    .medical .inv-content .patient-info .treatment-info div {
        font-size: 15px;
    }
}
@media (max-width: 767px) {
    .medical .inv-content .patient-info .patient-details,
    .medical .inv-content .patient-info .treatment-info {
        width: 100%;
        margin-bottom: 20px;
        margin: 0 10px 20px 0;
        padding: 15px;
    }
}
@media (max-width: 767px) {
    .medical .inv-content .patient-info {
        display: block;
    }
}
.medical .inv-content h5 {
    background: #f4f4f4;
    color: #2c3038;
    border-radius: 10px;
    font-size: 18px;
    margin: 20px 0;
    padding: 10px 20px;
}
.medical .inv-content .invoice-table {
    margin: 0;
    padding: 0 0 20px;
}
.medical .inv-content .invoice-table table {
    color: #2c3038;
    margin: 0;
    padding: 0px;
}
.medical .inv-content .invoice-table table thead tr {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #000;
}
.medical .inv-content .invoice-table table .table_width_2 {
    width: 50%;
}
.medical .inv-content .invoice-table table tr {
    border-bottom: 1px solid #000;
}
.medical .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.medical .inv-content .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
.medical .inv-content .invoice-table table tr td {
    margin: 0;
    padding: 20px 15px;
}
.medical .inv-content .invoice-table table tr td:last-child,
.medical .inv-content .invoice-table table tr th:last-child {
    padding-right: 25px;
}
@media (max-width: 992px) {
    .medical .inv-content .invoice-table table tr td,
    .medical .inv-content .invoice-table table tr th {
        white-space: nowrap;
    }
}
.medical .inv-content .invoice-table-footer {
    margin: 0;
    padding: 20px 10px;
    border-bottom: 1px solid #000;
}
.medical .inv-content .invoice-table-footer .table-footer-right {
    color: #2c3038;
}
.medical .inv-content .invoice-table-footer .table-footer-right table td {
    margin: 0;
    padding: 5px 15px;
}
@media (max-width: 767px) {
    .medical .inv-content .invoice-table-footer .table-footer-right table td {
        margin: 0;
        padding: 5px 0px;
    }
}
@media (max-width: 767px) {
    .medical .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
.medical .inv-content .table-total-item {
    margin: 0;
    padding: 15px 10px 15px 20px;
}
@media (max-width: 767px) {
    .medical .inv-content .table-total-item {
        margin: 0;
        padding: 15px 0px;
    }
}
.medical .inv-content .total-amountdetails {
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid #000;
    text-align: center;
}
@media (max-width: 992px) {
    .medical .inv-content .total-amountdetails {
        text-align: center;
    }
}
.medical .inv-content .bank-details {
    margin: 0;
    padding: 25px 15px;
    border-bottom: 1px solid #000;
}
.medical .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.medical .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.medical .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.medical .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.medical .inv-content .bank-details .terms-condition {
    margin: 0;
    padding: 0;
    color: #2c3038;
}
.medical .inv-content .bank-details .terms-condition span {
    font-size: 18px;
    margin: 0 0 10px;
}
.medical .inv-content .bank-details .terms-condition ol {
    color: #95979b;
}
@media (max-width: 767px) {
    .medical .inv-content .bank-details .terms-condition {
        margin: 10px 0;
        padding: 0px 0px;
    }
}
@media (max-width: 767px) {
    .medical .inv-content .bank-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.medical .inv-content .thanks-msg {
    background: #f2f2f2;
    border-bottom: 1px solid #000;
    color: #000;
}
@media (max-width: 767px) {
    .medical .inv-content {
        margin: 0;
        padding: 20px;
    }
}

.money-exchange .inv-content {
    border-radius: 10px;
    border: 1px solid #95979b;
}
.money-exchange .inv-content .invoice-header {
    background: #fcfcfc;
    border-radius: 8px;
    margin: 25px;
    padding: 25px;
}
.money-exchange .inv-content .invoice-header .cineplux-title {
    font-size: 64px;
    line-height: 80px;
}
@media (max-width: 992px) {
    .money-exchange .inv-content .invoice-header .cineplux-title {
        font-size: 40px;
        line-height: 40px;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .invoice-header .cineplux-title {
        font-size: 30px;
        line-height: 50px;
    }
}
.money-exchange .inv-content .invoice-header .inv-header-left a {
    display: block;
}
.money-exchange .inv-content .invoice-header .inv-header-left span {
    margin-bottom: 10px;
    display: block;
}
.money-exchange .inv-content .invoice-header .inv-header-left .inv-details {
    display: block;
}
.money-exchange
    .inv-content
    .invoice-header
    .inv-header-left
    .inv-details
    .inv-date {
    margin: 0 0 5px;
    padding: 0;
}
.money-exchange
    .inv-content
    .invoice-header
    .inv-header-left
    .inv-details
    .inv-date
    span {
    display: inline;
    color: #2c3038;
    font-weight: 700;
    font-size: 24px;
    margin-left: 8px;
}
.money-exchange .inv-content .invoice-header .inv-header-right .company-name {
    margin: 0 0 10px;
    padding: 0;
    display: block;
}
.money-exchange .inv-content .exchange-details {
    margin: 0 25px;
    padding: 0;
}
.money-exchange .inv-content .exchange-details h4 {
    background: #f4f4f4;
    color: #2c3038;
    border-radius: 10px;
    font-size: 18px;
    margin: 0;
    padding: 10px 30px;
}
.money-exchange .inv-content .exchange-details .exchange-info {
    display: flex;
    margin: 20px 0 0;
    padding: 0;
}
.money-exchange .inv-content .exchange-details .exchange-info div {
    background: #fcfcfc;
    border-radius: 10px;
    width: 24%;
    margin: 0;
    padding: 10px;
    display: flex;
    border: 1px solid #d9d9d9;
}
@media (max-width: 992px) {
    .money-exchange .inv-content .exchange-details .exchange-info div {
        width: 49%;
        margin-bottom: 15px;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .exchange-details .exchange-info div {
        width: 100%;
    }
}
.money-exchange .inv-content .exchange-details .exchange-info div span {
    color: #2c3038;
}
@media (max-width: 992px) {
    .money-exchange .inv-content .exchange-details .exchange-info {
        flex-wrap: wrap;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .exchange-details .exchange-info {
        display: block;
    }
}
.money-exchange .inv-content .transfer-overview {
    margin: 0;
    padding: 30px 25px;
}
.money-exchange .inv-content .transfer-overview .exchange-info div {
    width: 32%;
}
@media (max-width: 767px) {
    .money-exchange .inv-content .transfer-overview .exchange-info div {
        width: 100%;
    }
}
.money-exchange .inv-content .transfer-overview .exchange-rate div {
    width: 49%;
}
@media (max-width: 767px) {
    .money-exchange .inv-content .transfer-overview .exchange-rate div {
        width: 100%;
    }
}
.money-exchange .inv-content .sendto-details {
    display: flex;
    margin: 20px 0 30px;
    padding: 0;
}
.money-exchange .inv-content .sendto-details .sendto-left,
.money-exchange .inv-content .sendto-details .sendto-right {
    border: 1px solid #d9d9d9;
    background: #fcfcfc;
    border-radius: 10px;
    margin: 0;
    padding: 20px;
    width: 49%;
}
.money-exchange .inv-content .sendto-details .sendto-left div,
.money-exchange .inv-content .sendto-details .sendto-right div {
    display: flex;
}
.money-exchange .inv-content .sendto-details .sendto-left div span,
.money-exchange .inv-content .sendto-details .sendto-right div span {
    color: #2c3038;
}
@media (max-width: 992px) {
    .money-exchange .inv-content .sendto-details .sendto-left div,
    .money-exchange .inv-content .sendto-details .sendto-right div {
        display: block;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .sendto-details .sendto-left,
    .money-exchange .inv-content .sendto-details .sendto-right {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .sendto-details {
        display: block;
    }
}
.money-exchange .inv-content .exchange-notes p {
    margin: 20px 0 30px 0;
    padding: 0;
}
.money-exchange .inv-content .thanks-msg {
    margin: 0px 25px 30px 25px;
    padding: 15px;
    border: 1px solid #d9d9d9;
    color: #fff;
    background: #7638ff;
    border-radius: 10px;
}

.money-exchange .inv-content {
    border-radius: 10px;
    border: 1px solid #95979b;
}
.money-exchange .inv-content .invoice-header {
    background: #fcfcfc;
    border-radius: 8px;
    margin: 25px;
    padding: 25px;
}
.money-exchange .inv-content .invoice-header .cineplux-title {
    font-size: 64px;
    line-height: 80px;
}
@media (max-width: 992px) {
    .money-exchange .inv-content .invoice-header .cineplux-title {
        font-size: 40px;
        line-height: 40px;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .invoice-header .cineplux-title {
        font-size: 30px;
        line-height: 50px;
    }
}
.money-exchange .inv-content .invoice-header .inv-header-left a {
    display: block;
}
.money-exchange .inv-content .invoice-header .inv-header-left span {
    margin-bottom: 10px;
    display: block;
}
.money-exchange .inv-content .invoice-header .inv-header-left .inv-details {
    display: block;
}
.money-exchange
    .inv-content
    .invoice-header
    .inv-header-left
    .inv-details
    .inv-date {
    margin: 0 0 5px;
    padding: 0;
}
.money-exchange
    .inv-content
    .invoice-header
    .inv-header-left
    .inv-details
    .inv-date
    span {
    display: inline;
    color: #2c3038;
    font-weight: 700;
    font-size: 24px;
    margin-left: 8px;
}
.money-exchange .inv-content .invoice-header .inv-header-right .company-name {
    margin: 0 0 10px;
    padding: 0;
    display: block;
}
.money-exchange .inv-content .exchange-details {
    margin: 0 25px;
    padding: 0;
}
.money-exchange .inv-content .exchange-details h4 {
    background: #f4f4f4;
    color: #2c3038;
    border-radius: 10px;
    font-size: 18px;
    margin: 0;
    padding: 10px 30px;
}
.money-exchange .inv-content .exchange-details .exchange-info {
    display: flex;
    margin: 20px 0 0;
    padding: 0;
}
.money-exchange .inv-content .exchange-details .exchange-info div {
    background: #fcfcfc;
    border-radius: 10px;
    width: 24%;
    margin: 0;
    padding: 10px;
    display: flex;
    border: 1px solid #d9d9d9;
}
@media (max-width: 992px) {
    .money-exchange .inv-content .exchange-details .exchange-info div {
        width: 49%;
        margin-bottom: 15px;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .exchange-details .exchange-info div {
        width: 100%;
    }
}
.money-exchange .inv-content .exchange-details .exchange-info div span {
    color: #2c3038;
}
@media (max-width: 992px) {
    .money-exchange .inv-content .exchange-details .exchange-info {
        flex-wrap: wrap;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .exchange-details .exchange-info {
        display: block;
    }
}
.money-exchange .inv-content .transfer-overview {
    margin: 0;
    padding: 30px 25px;
}
.money-exchange .inv-content .transfer-overview .exchange-info div {
    width: 32%;
}
@media (max-width: 767px) {
    .money-exchange .inv-content .transfer-overview .exchange-info div {
        width: 100%;
    }
}
.money-exchange .inv-content .transfer-overview .exchange-rate div {
    width: 49%;
}
@media (max-width: 767px) {
    .money-exchange .inv-content .transfer-overview .exchange-rate div {
        width: 100%;
    }
}
.money-exchange .inv-content .sendto-details {
    display: flex;
    margin: 20px 0 30px;
    padding: 0;
}
.money-exchange .inv-content .sendto-details .sendto-left,
.money-exchange .inv-content .sendto-details .sendto-right {
    border: 1px solid #d9d9d9;
    background: #fcfcfc;
    border-radius: 10px;
    margin: 0;
    padding: 20px;
    width: 49%;
}
.money-exchange .inv-content .sendto-details .sendto-left div,
.money-exchange .inv-content .sendto-details .sendto-right div {
    display: flex;
}
.money-exchange .inv-content .sendto-details .sendto-left div span,
.money-exchange .inv-content .sendto-details .sendto-right div span {
    color: #2c3038;
}
@media (max-width: 992px) {
    .money-exchange .inv-content .sendto-details .sendto-left div,
    .money-exchange .inv-content .sendto-details .sendto-right div {
        display: block;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .sendto-details .sendto-left,
    .money-exchange .inv-content .sendto-details .sendto-right {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {
    .money-exchange .inv-content .sendto-details {
        display: block;
    }
}
.money-exchange .inv-content .exchange-notes p {
    margin: 20px 0 30px 0;
    padding: 0;
}
.money-exchange .inv-content .thanks-msg {
    margin: 0px 25px 30px 25px;
    padding: 15px;
    border: 1px solid #d9d9d9;
    color: #fff;
    background: #7638ff;
    border-radius: 10px;
}

.movie-booking .inv-content {
    margin: 0;
    padding: 40px;
}
.movie-booking .inv-content .cineplux-title {
    font-size: 80px;
    line-height: 100px;
}
@media (max-width: 767px) {
    .movie-booking .inv-content .cineplux-title {
        font-size: 50px;
        line-height: 70px;
    }
}
.movie-booking .inv-content .invoice-header {
    margin: 0;
    padding: 0 0 15px;
}
.movie-booking .inv-content .invoice-header .inv-header-right a {
    display: block;
}
.movie-booking .inv-content .invoice-header .inv-header-right span {
    margin-bottom: 10px;
    display: block;
}
.movie-booking .inv-content .invoice-header .inv-header-right .inv-details {
    display: block;
}
.movie-booking
    .inv-content
    .invoice-header
    .inv-header-right
    .inv-details
    .inv-date {
    margin: 0 0 5px;
    padding: 0;
}
.movie-booking
    .inv-content
    .invoice-header
    .inv-header-right
    .inv-details
    .inv-date
    span {
    display: inline;
}
.movie-booking .inv-content .invoice-header .inv-header-left .tax-invoice {
    color: #2c3038;
}
.movie-booking .inv-content .invoice-header .company-details .invoice-title {
    color: #2c3038;
}
.movie-booking .inv-content .invoice-header .company-details .gst-details {
    margin: 5px 0 0;
    padding: 0;
}
@media (max-width: 767px) {
    .movie-booking .inv-content .invoice-header .company-details {
        margin: 10px 0 0;
        padding: 0;
    }
}
@media (max-width: 767px) {
    .movie-booking .inv-content .invoice-header {
        display: flex;
        flex-direction: column-reverse;
        align-items: normal;
        margin: 0;
        padding: 15px 0;
    }
    .movie-booking .inv-content .invoice-header .tax-invoice {
        display: none;
    }
}
.movie-booking .inv-content .invoice-address {
    margin: 0;
    padding: 30px 0px;
    border: 0;
}
.movie-booking .inv-content .invoice-address .invoice-to span {
    font-size: 18px;
    margin: 0 0 5px;
}
@media (max-width: 767px) {
    .movie-booking .inv-content .invoice-address .invoice-to {
        margin: 0;
    }
}
@media (max-width: 767px) {
    .movie-booking .inv-content .invoice-address {
        margin: 0;
        padding: 10px 0px 20px;
    }
}
.movie-booking .inv-content .invoice-table {
    margin: 0;
    padding: 0;
}
.movie-booking .inv-content .invoice-table table {
    color: #95979b;
    margin: 0;
    padding: 0px;
}
.movie-booking .inv-content .invoice-table table thead .movie-ticket-table {
    background: #7638ff;
    color: #fff;
}
.movie-booking .inv-content .invoice-table table .table_width_2 {
    width: 50%;
}
.movie-booking .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.movie-booking .inv-content .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
.movie-booking .inv-content .invoice-table table tr td {
    margin: 0;
    padding: 25px 15px;
}
@media (max-width: 992px) {
    .movie-booking .inv-content .invoice-table table tr td,
    .movie-booking .inv-content .invoice-table table tr th {
        white-space: nowrap;
    }
}
.movie-booking .inv-content .invoice-table-footer {
    margin: 0;
    padding: 5px 0;
}
@media (max-width: 767px) {
    .movie-booking .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
@media (max-width: 767px) {
    .movie-booking .inv-content .invoice-table-footer .table-footer-right td {
        margin: 0;
        padding: 5px 0px;
    }
}
.movie-booking .inv-content .total-amountdetails {
    margin: 0;
    padding: 25px 0;
    text-align: center;
}
.movie-booking .inv-content .bank-details {
    margin: 0;
    padding: 25px 0px;
    border-bottom: 1px solid #f2f2f2;
}
.movie-booking .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.movie-booking .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.movie-booking .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.movie-booking .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.movie-booking .inv-content .bank-details .terms-condition {
    margin: 0;
    padding: 0;
    color: #2c3038;
}
.movie-booking .inv-content .bank-details .terms-condition span {
    font-size: 18px;
    margin: 0 0 10px;
}
.movie-booking .inv-content .bank-details .terms-condition ol {
    color: #95979b;
}
@media (max-width: 767px) {
    .movie-booking .inv-content .bank-details .terms-condition {
        margin: 10px 0;
        padding: 0px 0px;
    }
}
@media (max-width: 767px) {
    .movie-booking .inv-content .bank-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.movie-booking .inv-content .thanks-msg {
    background: #7638ff;
    color: #fff;
    border-radius: 5px;
}

.student-billing .inv-content .invoice-header {
    background: #f2f2f2;
    margin: 20px;
    padding: 30px;
}
.student-billing .inv-content .invoice-header h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #2c3038;
    margin-bottom: 10px;
}
@media (max-width: 992px) {
    .student-billing .inv-content .invoice-header h2 {
        font-size: 28px;
    }
}
@media (max-width: 992px) {
    .student-billing .inv-content .invoice-header h2 {
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    .student-billing .inv-content .invoice-header .inv-header-right {
        margin-top: 10px;
    }
}
.student-billing .inv-content .flight-invoice-details {
    margin: 0;
    padding: 0 20px;
}
.student-billing .inv-content .flight-invoice-details h3 {
    color: #2c3038;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0 22px 0;
    padding: 0;
    line-height: normal;
}
@media (max-width: 767px) {
    .student-billing .inv-content .flight-invoice-details h3 {
        font-size: 20px;
        margin: 20px 0;
    }
}
.student-billing .inv-content .flight-invoice-details .invoice-infomation {
    display: flex;
    margin-bottom: 10px;
}
.student-billing .inv-content .flight-invoice-details .invoice-infomation div {
    background: #f2f2f2;
    padding: 10px;
    width: 32.8%;
    display: flex;
    justify-content: space-between;
    color: #2c3038;
}
.student-billing
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    div
    span {
    color: #000;
}
@media (max-width: 767px) {
    .student-billing
        .inv-content
        .flight-invoice-details
        .invoice-infomation
        div {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {
    .student-billing .inv-content .flight-invoice-details .invoice-infomation {
        display: block;
    }
}
.student-billing .inv-content .invoice-address {
    margin: 0;
    padding: 30px 20px;
    justify-content: normal;
}
.student-billing .inv-content .invoice-address div {
    width: 50%;
}
@media (max-width: 767px) {
    .student-billing .inv-content .invoice-address div {
        width: 100%;
    }
}
.student-billing .inv-content .invoice-table {
    margin: 0;
    padding: 0 20px;
}
.student-billing .inv-content .invoice-table table {
    color: #2c3038;
}
.student-billing .inv-content .invoice-table table thead tr {
    border: 1px solid #95979b;
}
.student-billing .inv-content .invoice-table table tr {
    border-bottom: 1px solid #95979b;
}
.student-billing .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.student-billing .inv-content .invoice-table table .table_width_2 {
    width: 70%;
}
.student-billing .inv-content .invoice-table-footer {
    border: 0;
}
.student-billing .inv-content .invoice-table-footer .notes {
    color: #2c3038;
}
.student-billing .inv-content .invoice-table-footer .notes span {
    display: block;
    color: #95979b;
}
.student-billing .inv-content .invoice-table-footer .notes .delivery-notes {
    max-width: 300px;
}
@media (max-width: 992px) {
    .student-billing .inv-content .invoice-table-footer .notes .delivery-notes {
        max-width: none;
    }
}
.student-billing .inv-content .invoice-table-footer .table-footer-right table {
    color: #2c3038;
}
.student-billing
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    td {
    padding: 10px;
}
@media (max-width: 767px) {
    .student-billing
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        padding: 5px;
    }
}
@media (max-width: 767px) {
    .student-billing .inv-content .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .student-billing .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
@media (max-width: 767px) {
    .student-billing .inv-content .discount-table {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .student-billing .inv-content .discount-table .notes {
        width: 100%;
        margin-top: 20px;
    }
}
.student-billing .inv-content .totalamount-footer {
    background: #f2f2f2;
    border: 0;
    margin: 10px 20px 0;
    padding: 10px 10px 10px 15px;
}
.student-billing .inv-content .totalamount-footer .table-footer-left,
.student-billing .inv-content .totalamount-footer .table-footer-right {
    width: 50%;
}
.student-billing .inv-content .totalamount-footer .table-footer-left table,
.student-billing .inv-content .totalamount-footer .table-footer-right table {
    width: 100%;
}
.student-billing .inv-content .totalamount-footer .table-footer-left table td,
.student-billing .inv-content .totalamount-footer .table-footer-right table td {
    width: 50%;
}
@media (max-width: 767px) {
    .student-billing
        .inv-content
        .totalamount-footer
        .table-footer-left
        table
        td,
    .student-billing
        .inv-content
        .totalamount-footer
        .table-footer-right
        table
        td {
        width: auto;
    }
}
@media (max-width: 767px) {
    .student-billing .inv-content .totalamount-footer .table-footer-left,
    .student-billing .inv-content .totalamount-footer .table-footer-right {
        width: 100%;
    }
}
.student-billing
    .inv-content
    .totalamount-footer
    .table-footer-left
    table
    td:last-child
    div {
    background: #fcfcfc;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.student-billing .inv-content .totalamount-footer .table-footer-right table td {
    text-align: left;
}
.student-billing
    .inv-content
    .totalamount-footer
    .table-footer-right
    table
    td:last-child
    div {
    background: #000;
    padding: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
}
@media (max-width: 767px) {
    .student-billing
        .inv-content
        .totalamount-footer
        .table-footer-right
        table
        td {
        padding: 10px 0 0;
    }
}
@media (max-width: 767px) {
    .student-billing .inv-content .totalamount-footer {
        display: block;
    }
}
.student-billing .inv-content .payable-table {
    margin: 0 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #7638ff;
}
@media (max-width: 767px) {
    .student-billing .inv-content .payable-table {
        flex-direction: column-reverse;
    }
}
.student-billing .inv-content .payable-table .table-footer-left .notes {
    color: #95979b;
}
.student-billing .inv-content .payable-table .table-footer-left .notes span {
    color: #2c3038;
}
@media (max-width: 767px) {
    .student-billing .inv-content .payable-table .table-footer-left {
        width: 100%;
    }
}
.student-billing .inv-content .payable-table .amount-payable {
    width: 35%;
}
@media (max-width: 992px) {
    .student-billing .inv-content .payable-table .amount-payable {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .student-billing .inv-content .payable-table .amount-payable {
        width: 100%;
    }
}
.student-billing .inv-content .bank-details {
    margin: 0 20px;
    padding: 20px 0px 10px;
    border-bottom: 1px solid #f2f2f2;
}
.student-billing .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.student-billing .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.student-billing .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.student-billing .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.student-billing .inv-content .terms-condition {
    margin: 0 20px;
    padding: 30px 0;
    text-align: center;
}
.student-billing .inv-content .terms-condition p {
    color: #000;
}
.student-billing .inv-content .thanks-msg {
    padding: 30px 0;
}

.train-ticket .inv-content {
    border: 1px solid #0c0c20;
}
.train-ticket .inv-content .invoice-header {
    background: linear-gradient(270.14deg, #7638ff -0.04%, #3698ff 100%);
    margin: 20px 20px 0;
    padding: 30px;
}
.train-ticket .inv-content .invoice-header h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #fff;
}
@media (max-width: 767px) {
    .train-ticket .inv-content .invoice-header h2 {
        font-size: 30px;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .invoice-header .inv-header-right {
        margin-top: 10px;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .invoice-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
    }
}
.train-ticket .inv-content .flight-invoice-details {
    margin: 0;
    padding: 0 20px;
}
.train-ticket .inv-content .flight-invoice-details .bus-booking-address {
    display: flex;
    align-items: center;
    margin: 30px 0;
    padding: 0;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .bus-booking-address
    .booked-add {
    color: #000;
    width: 50%;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .bus-booking-address
    .booked-add
    span {
    display: block;
    color: #95979b;
}
@media (max-width: 767px) {
    .train-ticket
        .inv-content
        .flight-invoice-details
        .bus-booking-address
        .booked-add {
        width: 100%;
    }
}
.train-ticket .inv-content .flight-invoice-details .bus-booking-address .info {
    text-align: right;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .bus-booking-address
    .info
    p {
    color: #95979b;
}
@media (max-width: 767px) {
    .train-ticket
        .inv-content
        .flight-invoice-details
        .bus-booking-address
        .info {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .flight-invoice-details .bus-booking-address {
        display: block;
        margin-top: 20px;
    }
}
.train-ticket .inv-content .flight-invoice-details h3 {
    color: #2c3038;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0;
    padding: 0;
    line-height: normal;
}
@media (max-width: 767px) {
    .train-ticket .inv-content .flight-invoice-details h3 {
        font-size: 30px;
        margin: 20px 0;
    }
}
.train-ticket .inv-content .flight-invoice-details .invoice-infomation {
    display: flex;
    margin: 0 0 15px;
    padding: 0;
}
.train-ticket .inv-content .flight-invoice-details .invoice-infomation div {
    background: #f2f2f2;
    padding: 10px;
    width: 32.8%;
    text-align: center;
    color: #000;
    margin: 0 15px 0px 0;
    padding: 10px;
}
@media (max-width: 767px) {
    .train-ticket .inv-content .flight-invoice-details .invoice-infomation div {
        width: 100%;
        margin-bottom: 10px;
    }
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station {
    margin: 0;
    padding: 25px;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    div {
    margin: 0;
    padding: 0px;
    color: #95979b;
    text-align: left;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    div
    span {
    display: block;
    margin: 0 0 5px;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info {
    display: flex;
    width: 100%;
    align-items: center;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info
    .point-img {
    width: 5%;
    margin-right: 15px;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info
    .station-details {
    width: 95%;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .station
    .station-info
    .station-details
    .destination {
    color: #2c3038;
}
@media (max-width: 767px) {
    .train-ticket
        .inv-content
        .flight-invoice-details
        .invoice-infomation
        .station {
        margin-bottom: 15px;
    }
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .seat-details {
    text-align: center;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .seat-details
    div {
    text-align: center;
    width: 100%;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .seat-details
    div
    span {
    margin: 0 0 10px;
}
.train-ticket
    .inv-content
    .flight-invoice-details
    .invoice-infomation
    .seat-details
    div
    .invoice-title {
    line-height: 20px;
}
@media (max-width: 992px) {
    .train-ticket
        .inv-content
        .flight-invoice-details
        .invoice-infomation
        .seat-details
        div
        .invoice-title {
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .flight-invoice-details .invoice-infomation {
        display: block;
    }
}
.train-ticket .inv-content .invoice-address {
    margin: 0;
    padding: 30px 20px;
    justify-content: normal;
}
.train-ticket .inv-content .invoice-address div {
    width: 50%;
}
@media (max-width: 767px) {
    .train-ticket .inv-content .invoice-address div {
        width: 100%;
    }
}
.train-ticket .inv-content .passenger-info {
    display: flex;
    margin: 0;
    padding: 0;
}
.train-ticket .inv-content .passenger-info .passenger-details,
.train-ticket .inv-content .passenger-info .payment-info {
    background: #f2f2f2;
    margin: 0 10px 0 0;
    padding: 20px;
    width: 50%;
}
.train-ticket .inv-content .passenger-info .passenger-details h5,
.train-ticket .inv-content .passenger-info .payment-info h5 {
    color: #0c0c20;
    margin-bottom: 15px;
    font-size: 18px;
}
.train-ticket .inv-content .passenger-info .passenger-details div,
.train-ticket .inv-content .passenger-info .payment-info div {
    display: flex;
    justify-content: space-between;
}
.train-ticket .inv-content .passenger-info .passenger-details div span,
.train-ticket .inv-content .passenger-info .payment-info div span {
    color: #2c3038;
}
@media (max-width: 992px) {
    .train-ticket .inv-content .passenger-info .passenger-details div,
    .train-ticket .inv-content .passenger-info .payment-info div {
        font-size: 11px;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .passenger-info .passenger-details div,
    .train-ticket .inv-content .passenger-info .payment-info div {
        font-size: 15px;
        display: block;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .passenger-info .passenger-details,
    .train-ticket .inv-content .passenger-info .payment-info {
        width: 100%;
        margin-bottom: 20px;
        margin: 0 10px 20px 0;
        padding: 15px;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .passenger-info {
        display: block;
    }
}
.train-ticket .inv-content .passenger-ticket-details {
    margin: 0;
    padding: 30px 20px;
}
.train-ticket .inv-content .passenger-ticket-details .invoice-title {
    margin: 0 0 30px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    font-size: 18px;
    font-weight: normal;
}
.train-ticket
    .inv-content
    .passenger-ticket-details
    .invoice-title
    span:nth-child(1) {
    color: #fff;
    z-index: 2;
    position: relative;
}
.train-ticket .inv-content .passenger-ticket-details .ticket-title-bg {
    position: absolute;
    display: block;
    height: 100%;
    width: 27%;
    -webkit-transform: skewX(35deg);
    transform: skewX(35deg);
    top: 0px;
    left: -32px;
    background: linear-gradient(270.14deg, #7638ff -0.04%, #3698ff 100%);
}
.train-ticket .inv-content .passenger-ticket-details .ticket-title-bg:hover {
    background: linear-gradient(270.14deg, #7638ff -0.04%, #3698ff 100%);
}
@media (max-width: 1199.98px) {
    .train-ticket .inv-content .passenger-ticket-details .ticket-title-bg {
        width: 48%;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .passenger-ticket-details .ticket-title-bg {
        width: 100%;
    }
}
.train-ticket .inv-content .invoice-table {
    margin: 0;
    padding: 0px 20px;
}
.train-ticket .inv-content .invoice-table h6 {
    padding: 0 0 30px;
    line-height: normal;
}
.train-ticket .inv-content .invoice-table table {
    color: #95979b;
}
.train-ticket .inv-content .invoice-table table thead tr {
    color: #2c3038;
}
.train-ticket .inv-content .invoice-table table .table_width_2 {
    width: 38%;
}
.train-ticket .inv-content .invoice-table table tr {
    border-bottom: 1px solid #f2f2f2;
}
.train-ticket .inv-content .invoice-table table tr .table-description {
    color: #2c3038;
}
.train-ticket .inv-content .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
@media (max-width: 992px) {
    .train-ticket .inv-content .invoice-table table tr td,
    .train-ticket .inv-content .invoice-table table tr th {
        white-space: nowrap;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .invoice-table table {
        margin: 0;
        padding: 15px 20px 0;
    }
}
.train-ticket
    .inv-content
    .invoice-table
    .invoice-table-footer
    .table-footer-right {
    color: #2c3038;
}
@media (max-width: 767px) {
    .train-ticket
        .inv-content
        .invoice-table
        .invoice-table-footer
        .total-item {
        display: none;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .invoice-table .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .train-ticket
        .inv-content
        .invoice-table
        .invoice-table-footer
        .total-item {
        display: none;
    }
}
.train-ticket .inv-content .invoice-table .total-amountdetails span {
    display: block;
}
@media (max-width: 767px) {
    .train-ticket .inv-content .invoice-table .total-amountdetails {
        margin: 0;
        padding: 15px 20px;
    }
}
.train-ticket .inv-content .totalamount-footer {
    background: #f2f2f2;
    border: 0;
    margin: 10px 20px;
    padding: 10px 10px 10px 15px;
}
.train-ticket .inv-content .invoice-table-footer {
    border: 0;
}
.train-ticket .inv-content .invoice-table-footer .notes span {
    display: block;
    color: #2c3038;
}
.train-ticket .inv-content .invoice-table-footer .amount-payable {
    width: 35%;
}
.train-ticket .inv-content .invoice-table-footer .table-footer-right table td {
    padding: 5px;
}
@media (max-width: 767px) {
    .train-ticket .inv-content .invoice-table-footer {
        margin: 0;
        padding: 5px 10px 0;
    }
    .train-ticket .inv-content .invoice-table-footer .total-item {
        display: none;
    }
}
.train-ticket .inv-content .payable-table {
    margin: 0 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #95979b;
}
@media (max-width: 767px) {
    .train-ticket .inv-content .payable-table {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .payable-table .table-footer-left {
        width: 100%;
    }
}
.train-ticket .inv-content .payable-table .amount-payable {
    width: 35%;
}
@media (max-width: 992px) {
    .train-ticket .inv-content .payable-table .amount-payable {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .train-ticket .inv-content .payable-table .amount-payable {
        width: 100%;
    }
}
.train-ticket .inv-content .bank-details {
    margin: 0 20px;
    padding: 30px 0px 20px;
    border-bottom: 1px solid #f2f2f2;
}
.train-ticket .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.train-ticket .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.train-ticket .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.train-ticket .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.train-ticket .inv-content .terms-condition {
    margin: 0 20px;
    padding: 30px 0;
}
.train-ticket .inv-content .terms-condition p {
    color: #000;
}
.train-ticket .inv-content .thanks-msg {
    padding: 30px 0;
}

.invoice-wrapper {
    background: #fff;
    margin: 60px 0 40px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
    .invoice-wrapper {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .inv-details {
        display: block;
    }
}

.inv-content .invoice-header {
    border-bottom: 1px solid #f2f2f2;
    padding: 30px 30px 15px;
}
.inv-content .invoice-header .inv-header-left a {
    display: block;
}
.inv-content .invoice-header .inv-header-left img {
    max-width: 200px;
}
.inv-content .invoice-header .inv-header-left span {
    color: #95979b;
}
.inv-content .invoice-header .inv-header-right {
    text-align: right;
}
.inv-content .invoice-header .inv-header-right img {
    max-width: 200px;
}
.inv-content .invoice-header .inv-header-right .invoice-title {
    font-size: 24px;
    font-weight: 700;
    color: #2c3038;
}
@media (max-width: 992px) {
    .inv-content .invoice-header .inv-header-right .invoice-title {
        font-size: 20px;
        margin: 10px 0 0 0;
        padding: 0;
    }
}
.inv-content .invoice-header .inv-header-right .inv-date {
    color: #95979b;
    font-size: 18px;
    font-weight: normal;
    margin-right: 20px;
}
.inv-content .invoice-header .inv-header-right .inv-date:last-child {
    margin-right: 0;
}
.inv-content .invoice-header .inv-header-right .inv-date span {
    color: #2c3038;
    font-weight: 700;
    font-size: 24px;
    margin-left: 8px;
}
@media print {
    .inv-content .invoice-header .inv-header-right .inv-date span {
        font-size: 14px;
    }
}
@media (max-width: 992px) {
    .inv-content .invoice-header .inv-header-right .inv-date span {
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .inv-content .invoice-header .inv-header-right {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .inv-content .invoice-header {
        display: block;
    }
}
@media print {
    .inv-content .invoice-header {
        display: flex;
    }
}
.inv-content .invoice-address {
    margin: 0;
    padding: 20px 30px;
    border-bottom: 1px solid #f2f2f2;
}
.inv-content .invoice-address .invoice-to span {
    color: #95979b;
    margin: 0 0 10px;
    display: block;
}
@media (max-width: 767px) {
    .inv-content .invoice-address .invoice-to span {
        margin: 0 0 10px;
    }
}
@media (max-width: 575.98px) {
    .inv-content .invoice-address .invoice-to span {
        margin: 0 40px 10px;
    }
}
.inv-content .invoice-address .invoice-to .inv-to-address {
    color: #2c3038;
}
@media (max-width: 767px) {
    .inv-content .invoice-address .invoice-to {
        margin-bottom: 20px;
    }
}
.inv-content .invoice-address .company-details {
    text-align: right;
}
.inv-content .invoice-address .company-details .company-name {
    font-size: 24px;
    color: #7638ff;
    margin: 0 0 10px;
    font-weight: 700;
    display: block;
}
.inv-content .invoice-address .company-details .gst-details {
    margin: 0 0 10px;
}
.inv-content .invoice-address .company-details .gst-details span {
    color: #95979b;
    display: block;
}
@media print {
    .inv-content .invoice-address .company-details .gst-details {
        margin: 0 0 5px;
    }
}
@media (max-width: 767px) {
    .inv-content .invoice-address .company-details {
        text-align: left;
        margin: 0 0 20px;
    }
}
@media print {
    .inv-content .invoice-address {
        font-size: 10px;
    }
}
@media (max-width: 992px) {
    .inv-content .invoice-address {
        font-size: 14px;
    }
}
@media (max-width: 767px) {
    .inv-content .invoice-address {
        display: block;
        padding: 0px 30px;
    }
}
.inv-content .invoice-table {
    margin: 0;
    padding: 15px 30px 0;
}
.inv-content .invoice-table table {
    width: 100%;
    color: #2c3038;
}
.inv-content .invoice-table table .table_width_1 {
    width: 3%;
}
.inv-content .invoice-table table .table_width_2 {
    width: 20%;
}
.inv-content .invoice-table table .table_width_3 {
    width: 40%;
}
.inv-content .invoice-table table .table_width_4 {
    width: 15%;
}
.inv-content .invoice-table table .table_width_5 {
    width: 15%;
}
.inv-content .invoice-table table thead tr {
    background: #f2f2f2;
}
.inv-content .invoice-table table tr {
    border-bottom: 1px solid #f2f2f2;
}
.inv-content .invoice-table table tr td,
.inv-content .invoice-table table tr th {
    padding: 15px;
}
@media print {
    .inv-content .invoice-table table tr td,
    .inv-content .invoice-table table tr th {
        padding: 8px;
    }
}
.inv-content .invoice-table table tr .table-description {
    color: #878a99;
}
@media print {
    .inv-content .invoice-table {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .inv-content .invoice-table {
        font-size: 14px;
    }
}
.inv-content .total-amountdetails {
    border-bottom: 1px solid #f2f2f2;
    padding: 15px 30px;
}
.inv-content .total-amountdetails span {
    color: #2c3038;
}
@media print {
    .inv-content .total-amountdetails {
        font-size: 12px;
        padding: 5px 30px;
    }
}
@media (max-width: 575.98px) {
    .inv-content .total-amountdetails {
        font-size: 14px;
    }
}
.inv-content .invoice-table-footer {
    padding: 5px 30px;
}
.inv-content .invoice-table-footer .table-footer-left {
    width: 70%;
}
@media (max-width: 575.98px) {
    .inv-content .invoice-table-footer .table-footer-left {
        width: auto;
        font-size: 14px;
    }
}
.inv-content .invoice-table-footer .table-footer-right {
    width: 31%;
}
.inv-content .invoice-table-footer .table-footer-right table {
    width: 100%;
}
.inv-content .invoice-table-footer .table-footer-right table td {
    text-align: right;
    width: 50%;
    padding: 5px 10px;
}
@media print {
    .inv-content .invoice-table-footer .table-footer-right table td {
        width: auto;
    }
}
@media (max-width: 992px) {
    .inv-content .invoice-table-footer .table-footer-right table td {
        width: auto;
    }
}
@media (max-width: 767px) {
    .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
@media print {
    .inv-content .invoice-table-footer .table-footer-right table {
        font-size: 12px;
    }
}
.inv-content .invoice-table-footer .table-footer-right .totalamt-table td {
    color: #2c3038;
    font-size: 24px;
    font-weight: 700;
}
@media print {
    .inv-content .invoice-table-footer .table-footer-right .totalamt-table td {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .inv-content .invoice-table-footer .table-footer-right .totalamt-table td {
        font-size: 16px;
    }
}
@media print {
    .inv-content .invoice-table-footer .table-footer-right .totalamt-table {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .inv-content .invoice-table-footer .table-footer-right {
        width: 60%;
    }
}
@media (max-width: 767px) {
    .inv-content .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media print {
    .inv-content .invoice-table-footer .table-footer-right {
        width: 50%;
    }
}
@media print {
    .inv-content .invoice-table-footer {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .inv-content .invoice-table-footer .total-info {
        display: none;
    }
}
.inv-content .bank-details {
    margin: 0;
    padding: 15px 30px;
    border-bottom: 1px solid #f2f2f2;
}
.inv-content .bank-details .bank-title {
    margin: 0 0 10px;
    color: #2c3038;
    font-weight: 700;
    font-size: 24px;
    display: block;
}
@media print {
    .inv-content .bank-details .bank-title {
        font-size: 12px;
    }
}
.inv-content .bank-details .account-details {
    margin: 0 0 5px;
}
.inv-content .bank-details .account-details span {
    color: #2c3038;
}
.inv-content .bank-details .company-sign {
    text-align: right;
}
.inv-content .bank-details .company-sign span {
    display: block;
    margin: 0 0 15px;
    color: #000;
}
@media (max-width: 575.98px) {
    .inv-content .bank-details .company-sign {
        text-align: left;
    }
}
@media print {
    .inv-content .bank-details {
        font-size: 12px;
        margin: 0;
        padding: 10px 30px;
    }
}
@media (max-width: 767px) {
    .inv-content .bank-details {
        font-size: 14px;
        display: block;
        margin: 0;
        padding: 5px 30px;
    }
}
.inv-content .terms-condition {
    margin: 0;
    padding: 15px 30px;
}
.inv-content .terms-condition ol {
    list-style: number;
    padding-left: 26px;
    color: #2c3038;
}
.inv-content .terms-condition span {
    display: block;
    margin: 0 0 5px;
}
@media print {
    .inv-content .terms-condition {
        font-size: 12px;
        margin: 0;
        padding: 10px 30px;
    }
}
@media (max-width: 575.98px) {
    .inv-content .terms-condition {
        font-size: 14px;
    }
}
.inv-content .thanks-msg {
    margin: 0;
    padding: 15px 0;
}
@media print {
    .inv-content .thanks-msg {
        font-size: 10px;
        margin: 0;
        padding: 10px;
    }
}
@media (max-width: 575.98px) {
    .inv-content .thanks-msg {
        font-size: 12px;
    }
}

.file-link {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}
.file-link .download-link {
    color: #7638ff;
    margin: 0px;
    padding: 12px 22px;
    margin-right: 10px;
    border: 1px solid #7638ff;
    border-radius: 5px;
    background: #fff;
    height: 46px;
}
.file-link .download-link:hover {
    background: #7638ff;
    color: #fff;
}
.file-link .print-link {
    color: #4361ee;
    margin: 0 0 0 0;
    padding: 15px 22px;
    border: 1px solid #4361ee;
    border-radius: 5px;
    height: 46px;
    background: #fff;
    display: flex;
    align-items: center;
}
.file-link .print-link i {
    margin-right: 6px;
}
.file-link .print-link:hover {
    background: #4361ee;
    color: #fff;
}
@media (max-width: 992px) {
    .file-link {
        margin: 20px;
    }
}

.no-stickybar .right-side-views {
    display: none;
}

.invoice-wrapper {
    background: #fff;
    margin: 60px 0 40px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
@media print {
    .invoice-wrapper {
        padding: 0px;
    }
}
@media (max-width: 767px) {
    .invoice-wrapper {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .inv-details {
        display: block;
    }
}

.index-two .company-details {
    padding: 25px 0;
    border-bottom: 1px solid #95979b;
}
.index-two .company-details .company-name {
    color: #2c3038;
    display: block;
    margin-bottom: 7px;
}
.index-two .company-details .gst-details {
    width: 20%;
}
.index-two .company-details .gst-details span {
    display: block;
    color: #2c3038;
}
@media (max-width: 992px) {
    .index-two .company-details .gst-details {
        width: 32%;
    }
}
@media (max-width: 767px) {
    .index-two .company-details .gst-details {
        width: auto;
        margin: 10px 0;
    }
}
@media (max-width: 767px) {
    .index-two .company-details .gst-details:last-child {
        margin: 0px 0;
    }
}
.index-two .company-details .company-address {
    width: auto;
}
@media (max-width: 992px) {
    .index-two .company-details .company-address {
        width: 32%;
    }
}
@media (max-width: 767px) {
    .index-two .company-details .company-address {
        width: auto;
    }
}
@media (max-width: 767px) {
    .index-two .company-info {
        display: block;
    }
}
.index-two .inv-content {
    border: 1px solid #95979b;
    margin: 0px;
    padding: 40px 40px 0;
}
.index-two .inv-content .invoice-header {
    border-bottom: 1px solid #95979b;
    margin: 0;
    padding: 0 0 15px;
}
.index-two .inv-content .invoice-address {
    margin: 0;
    padding: 25px 0;
    border-bottom: none;
    justify-content: normal;
}
.index-two .inv-content .invoice-address .invoice-to {
    color: #95979b;
    width: 50%;
}
.index-two .inv-content .invoice-table {
    margin: 0;
    padding: 0;
}
.index-two .inv-content .invoice-table table {
    border: 1px solid #95979b;
}
.index-two .inv-content .invoice-table table tr {
    border: 1px solid #95979b;
}
.index-two .inv-content .invoice-table-footer {
    margin: 0;
    padding: 15px;
    border-bottom: none;
    color: #2c3038;
}
.index-two .inv-content .invoice-table-footer .table-footer-left span {
    display: block;
    color: #95979b;
}
@media (max-width: 767px) {
    .index-two .inv-content .invoice-table-footer .table-footer-left {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-two .inv-content .invoice-table-footer .notes {
        display: none;
    }
}
.index-two .inv-content .invoice-table-footer .notes .delivery-notes {
    max-width: 500px;
}
.index-two .inv-content .invoice-table-footer .table-footer-right table td {
    padding: 5px 0px;
}
@media (max-width: 767px) {
    .index-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .index-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:last-child {
        text-align: right;
    }
}
.index-two
    .inv-content
    .invoice-table-footer
    .table-footer-right
    .totalamt-table
    tr
    td {
    padding: 0;
}
@media (max-width: 767px) {
    .index-two .inv-content .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-two .inv-content .invoice-table-footer {
        flex-direction: column-reverse;
        margin: 0;
        padding: 15px 0;
    }
}
.index-two .inv-content .totalamount-footer {
    background: #f2f2f2;
    border: 1px solid #95979b;
    margin: 10px 0 0;
    padding: 15px;
}
.index-two .inv-content .total-amountdetails {
    border-bottom: 1px solid #95979b;
    margin: 0;
    padding: 15px;
}
@media (max-width: 767px) {
    .index-two .inv-content .total-amountdetails {
        margin: 0;
        padding: 15px 0;
    }
}
.index-two .inv-content .bank-details {
    margin: 0;
    padding: 15px 0;
    border-bottom: 1px solid #95979b;
}
.index-two .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.index-two .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.index-two .inv-content .bank-details .payment-info div {
    margin: 0 0 5px;
    padding: 0;
}
.index-two .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.index-two .inv-content .terms-condition {
    margin: 0;
    padding: 15px 0;
    border-bottom: 1px solid #95979b;
}
.index-two .inv-content .terms-condition ol {
    list-style: disc;
    padding-left: 16px;
}
@media (max-width: 767px) {
    .index-two .inv-content {
        padding: 25px;
    }
}

.invoice-wrapper {
    background: #fff;
    margin: 60px 0 40px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
@media print {
    .invoice-wrapper {
        padding: 0px;
    }
}
@media (max-width: 767px) {
    .invoice-wrapper {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .inv-details {
        display: block;
    }
}

.index-three .invoice-header {
    display: block;
}
.index-three .company-details {
    padding: 0px 20px;
    border-right: 1px solid #95979b;
    width: 50%;
}
.index-three .company-details .company-name {
    margin-bottom: 10px;
}
.index-three .company-details .gst-details {
    width: 40%;
    margin-bottom: 10px;
}
.index-three .company-details .gst-details span {
    display: block;
    color: #2c3038;
}
@media (max-width: 992px) {
    .index-three .company-details .gst-details {
        width: 50%;
    }
}
@media (max-width: 767px) {
    .index-three .company-details .gst-details {
        width: auto;
        margin: 10px 0;
    }
}
@media (max-width: 767px) {
    .index-three .company-details .gst-details:last-child {
        margin: 0px 0;
    }
}
.index-three .company-details .company-address {
    width: auto;
}
@media (max-width: 767px) {
    .index-three .company-details .company-address {
        width: auto;
    }
}
@media (max-width: 767px) {
    .index-three .company-details {
        border-right: 0;
        width: 100%;
        padding: 0px 10px;
    }
}
.index-three .invoice-header-top .inv-header-left {
    width: 50%;
    margin: 0;
    padding: 20px;
    border-right: 1px solid #95979b;
}
.index-three .invoice-header-top .inv-header-left img {
    max-width: 200px;
}
@media (max-width: 767px) {
    .index-three .invoice-header-top .inv-header-left {
        border-right: 0;
    }
}
.index-three .invoice-header-top .inv-header-right {
    width: 50%;
}
.index-three .invoice-header-top .inv-header-right .inv-details .inv-date {
    width: 50%;
    border-right: 1px solid #95979b;
    border-bottom: 1px solid #95979b;
    margin: 0;
    padding: 24px;
}
@media (max-width: 767px) {
    .index-three .invoice-header-top .inv-header-right .inv-details .inv-date {
        border-right: 0;
        border-bottom: 0;
        margin: 0;
        padding: 10px;
    }
}
.index-three .invoice-header-top .inv-header-right .inv-details .inv-date span {
    color: #28084b;
    font-weight: 700;
    font-size: 24px;
    display: block;
}
.index-three
    .invoice-header-top
    .inv-header-right
    .inv-details
    .inv-date:last-child {
    border-right: 0;
}
@media (max-width: 767px) {
    .index-three .invoice-header-top .inv-header-right {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-three .invoice-header-top {
        display: block;
    }
}
@media (max-width: 767px) {
    .index-three .invoice-header-bottom {
        display: block;
    }
}
@media (max-width: 767px) {
    .index-three .company-info {
        display: block;
    }
}
.index-three .inv-content {
    border: 1px solid #95979b;
    margin: 0px;
    padding: 0;
}
.index-three .inv-content .invoice-header {
    border-bottom: 0;
    margin: 0;
    padding: 0;
}
.index-three .inv-content .invoice-header .inv-header-right {
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid #2c3038;
    line-height: 20px;
}
.index-three .inv-content .invoice-address {
    margin: 0;
    padding: 0;
    width: 50%;
    border-bottom: none;
    justify-content: normal;
}
.index-three .inv-content .invoice-address .invoice-to {
    width: 50%;
    margin: 0;
    padding: 24px;
    border-right: 1px solid #95979b;
}
.index-three .inv-content .invoice-address .invoice-to:last-child {
    border-right: 0;
}
@media (max-width: 767px) {
    .index-three .inv-content .invoice-address .invoice-to {
        width: auto;
        margin: 0;
        padding: 10px;
        border-right: 0;
    }
}
@media (max-width: 767px) {
    .index-three .inv-content .invoice-address {
        margin: 0 0 20px;
        padding: 10px 0;
    }
}
.index-three .inv-content .invoice-table {
    margin: 0;
    padding: 0;
}
.index-three .inv-content .invoice-table table {
    border-left: 0;
    border-top: 1px solid #2c3038;
    border-right: 0;
}
.index-three .inv-content .invoice-table table .table_width_2 {
    width: 45%;
}
.index-three .inv-content .invoice-table table .table-description {
    color: #2c3038;
}
.index-three .inv-content .invoice-table table .table-description span {
    display: block;
    color: #95979b;
}
.index-three .inv-content .invoice-table table th,
.index-three .inv-content .invoice-table table td {
    border-right: 1px solid #95979b;
    padding: 25px;
}
.index-three .inv-content .invoice-table table th:last-child,
.index-three .inv-content .invoice-table table td:last-child {
    border-right: 0;
}
@media (max-width: 992px) {
    .index-three .inv-content .invoice-table table th,
    .index-three .inv-content .invoice-table table td {
        white-space: nowrap;
    }
}
.index-three .inv-content .invoice-table table tr {
    border-right: 0;
    border-bottom: 1px solid #95979b;
}
@media (max-width: 767px) {
    .index-three .inv-content .invoice-table table {
        border-left: 1px solid #95979b;
        border-right: 1px solid #95979b;
    }
}
.index-three .inv-content .invoice-table-footer {
    margin: 0;
    padding: 10px 25px;
    border-bottom: 1px solid #95979b;
    color: #2c3038;
}
.index-three .inv-content .invoice-table-footer .table-footer-left span {
    display: block;
    color: #95979b;
}
@media (max-width: 767px) {
    .index-three .inv-content .invoice-table-footer .table-footer-left {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-three .inv-content .invoice-table-footer .notes {
        display: none;
    }
}
.index-three .inv-content .invoice-table-footer .notes .delivery-notes {
    max-width: 500px;
}
.index-three .inv-content .invoice-table-footer .table-footer-right {
    width: 53%;
}
.index-three .inv-content .invoice-table-footer .table-footer-right table td {
    padding: 0px;
}
@media (max-width: 767px) {
    .index-three
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .index-three
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:last-child {
        text-align: right;
    }
}
.index-three
    .inv-content
    .invoice-table-footer
    .table-footer-right
    .totalamt-table
    tr
    td {
    padding: 0;
}
.index-three
    .inv-content
    .invoice-table-footer
    .table-footer-right
    .amount-payable {
    color: #2c3038;
}
@media (max-width: 767px) {
    .index-three .inv-content .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-three .inv-content .invoice-table-footer {
        flex-direction: column-reverse;
        margin: 0;
        padding: 15px 0;
    }
}
.index-three .inv-content .totalamount-footer {
    background: #f2f2f2;
    border: 1px solid #95979b;
    margin: 10px 0 0;
    padding: 15px;
}
.index-three .inv-content .total-amountdetails {
    border-bottom: 1px solid #95979b;
    margin: 0;
    padding: 15px;
    text-align: center;
}
@media (max-width: 767px) {
    .index-three .inv-content .total-amountdetails {
        margin: 0;
        padding: 15px 0;
    }
}
.index-three .inv-content .bank-details {
    margin: 0;
    padding: 0px;
    border-bottom: 1px solid #95979b;
}
.index-three .inv-content .bank-details .account-info {
    width: 78%;
    margin: 0;
    padding: 20px;
}
.index-three .inv-content .bank-details .account-info .account-details-infotop {
    width: 45%;
}
@media (max-width: 992px) {
    .index-three
        .inv-content
        .bank-details
        .account-info
        .account-details-infotop {
        width: 75%;
    }
}
@media (max-width: 767px) {
    .index-three
        .inv-content
        .bank-details
        .account-info
        .account-details-infotop {
        display: block;
        width: auto;
    }
}
@media (max-width: 767px) {
    .index-three .inv-content .bank-details .account-info {
        width: 100%;
        margin: 0;
        padding: 20px 0;
    }
}
.index-three .inv-content .bank-details .company-sign {
    width: 22%;
    margin: 0;
    padding: 20px 0;
    text-align: center;
    border-left: 1px solid #95979b;
}
@media (max-width: 992px) {
    .index-three .inv-content .bank-details .company-sign {
        border-left: 0;
    }
}
@media (max-width: 767px) {
    .index-three .inv-content .bank-details .company-sign {
        width: auto;
        text-align: left;
    }
}
@media (max-width: 767px) {
    .index-three .inv-content {
        padding: 25px;
    }
}
.index-three .invoice-footer .thanks-msg {
    margin: 0;
    padding: 20px;
    color: #2c3038;
}
.index-three .invoice-footer .thanks-msg span {
    color: #95979b;
    display: block;
}
@media (max-width: 767px) {
    .index-three .invoice-footer .thanks-msg {
        margin: 0;
        padding: 20px 0 0;
    }
}
.index-three .invoice-footer .terms-condition {
    margin: 0;
    padding: 20px;
    border-left: 1px solid #95979b;
}
.index-three .invoice-footer .terms-condition ol {
    list-style: disc;
    padding-left: 16px;
}
@media (max-width: 767px) {
    .index-three .invoice-footer .terms-condition {
        border-left: 0;
        margin: 0;
        padding: 20px 0 0;
    }
}
@media (max-width: 767px) {
    .index-three .invoice-footer {
        display: block;
    }
}

.invoice-wrapper {
    background: #fff;
    margin: 60px 0 40px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
@media print {
    .invoice-wrapper {
        padding: 0px;
    }
}
@media (max-width: 767px) {
    .invoice-wrapper {
        padding: 0;
    }
}

.index-four .invoicenine-header {
    background: #fafafa;
    padding: 20px;
}
.index-four .company-details {
    padding: 15px 0 0;
    border-bottom: 0;
}
.index-four .company-details .gst-details {
    margin: 0 125px 10px 0;
    padding: 0;
}
.index-four .company-details .gst-details span {
    display: block;
    color: #2c3038;
}
@media (max-width: 767px) {
    .index-four .company-details .gst-details {
        width: auto;
        margin: 10px 0;
    }
}
@media (max-width: 767px) {
    .index-four .company-details .gst-details:last-child {
        margin: 0px 0;
    }
}
.index-four .company-details .company-address {
    width: auto;
}
@media (max-width: 767px) {
    .index-four .company-details .company-address {
        width: auto;
    }
}
.index-four .company-details .company-content {
    margin: 10px 0 0;
    padding: 0;
}
.index-four .company-details .company-content .inv-details {
    display: block;
    text-align: right;
    font-size: 18px;
}
.index-four .company-details .company-content .inv-details .inv-date {
    margin-bottom: 10px;
}
.index-four .company-details .company-content .inv-details span {
    display: block;
    color: #28084b;
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
}
@media (max-width: 767px) {
    .index-four .company-details .company-content .inv-details {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .index-four .company-details .company-content {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .index-four .company-info {
        display: block;
    }
}
.index-four .inv-content {
    border: none;
    margin: 0px;
    padding: 0px;
}
.index-four .inv-content .invoice-header {
    border-bottom: 0;
    margin: 0;
    padding: 15px;
    background: #fff;
}
.index-four .inv-content .invoice-address {
    margin: 0;
    padding: 25px 15px;
    border-bottom: none;
    justify-content: normal;
}
.index-four .inv-content .invoice-address .invoice-to {
    width: 50%;
}
.index-four .inv-content .invoice-table {
    margin: 0;
    padding: 0;
}
.index-four .inv-content .invoice-table table {
    border: 1px solid #f2f2f2;
}
.index-four .inv-content .invoice-table table tr {
    border: 1px solid #f2f2f2;
}
.index-four .inv-content .invoice-table table tr td {
    border-right: 1px solid #f2f2f2;
}
.index-four .inv-content .invoice-table-footer {
    margin: 0;
    padding: 15px;
    border-bottom: none;
    color: #2c3038;
    align-items: center;
}
.index-four .inv-content .invoice-table-footer .table-footer-left span {
    display: block;
    color: #95979b;
}
@media (max-width: 767px) {
    .index-four .inv-content .invoice-table-footer .table-footer-left {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-four .inv-content .invoice-table-footer .notes {
        display: none;
    }
}
.index-four .inv-content .invoice-table-footer .notes .delivery-notes {
    max-width: 500px;
}
.index-four .inv-content .invoice-table-footer .table-footer-right table td {
    padding: 5px 5px;
}
@media (max-width: 767px) {
    .index-four
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .index-four
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:last-child {
        text-align: right;
    }
}
@media (max-width: 767px) {
    .index-four .inv-content .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-four .inv-content .invoice-table-footer {
        flex-direction: column-reverse;
        margin: 0;
        padding: 15px;
    }
}
.index-four .inv-content .totalamount-footer {
    background: none;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin: 0;
    padding: 15px;
}
.index-four .inv-content .total-amountdetails {
    border-bottom: 1px solid #f2f2f2;
    margin: 0;
    padding: 15px;
}
@media (max-width: 767px) {
    .index-four .inv-content .total-amountdetails {
        margin: 0;
        padding: 15px;
    }
}
.index-four .inv-content .bank-details {
    margin: 0;
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2;
}
.index-four .inv-content .bank-details .payment-info {
    color: #2c3038;
}
.index-four .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.index-four .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.index-four .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.index-four .inv-content .terms-condition {
    margin: 0;
    padding: 15px 15px;
    border-bottom: 1px solid #f2f2f2;
}
.index-four .inv-content .terms-condition ol {
    list-style: disc;
    padding-left: 16px;
}
@media (max-width: 767px) {
    .index-four .inv-content {
        padding: 25px;
    }
}
.index-four .inv-content .thanks-msg {
    border-bottom: 1px solid #f2f2f2;
}

.index-five .invoice-wrapper {
    background: #252526;
}
.index-five .invoiceten-header {
    background: rgba(250, 250, 250, 0.1);
    padding: 20px;
}
@media (max-width: 767px) {
    .index-five .invoiceten-header .invoice-header {
        display: block;
    }
}
.index-five .invoiceten-header .inv-header-right .invoice-title {
    color: #fff;
}
.index-five .company-details {
    padding: 15px 0 0;
    border-bottom: 0;
}
.index-five .company-details .company-name {
    color: #fff;
}
.index-five .company-details .gst-details {
    margin: 0 125px 10px 0;
    padding: 0;
}
.index-five .company-details .gst-details span {
    display: block;
    color: #fff;
}
@media (max-width: 767px) {
    .index-five .company-details .gst-details {
        width: auto;
        margin: 10px 0;
    }
}
@media (max-width: 767px) {
    .index-five .company-details .gst-details:last-child {
        margin: 0px 0;
    }
}
.index-five .company-details .company-address {
    width: auto;
}
@media (max-width: 767px) {
    .index-five .company-details .company-address {
        width: auto;
    }
}
.index-five .company-details .company-content {
    margin: 10px 0 0;
    padding: 0;
}
.index-five .company-details .company-content .inv-details {
    display: block;
    text-align: right;
    font-size: 18px;
}
.index-five .company-details .company-content .inv-details .inv-date {
    margin-bottom: 10px;
}
.index-five .company-details .company-content .inv-details span {
    display: block;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
}
@media (max-width: 767px) {
    .index-five .company-details .company-content .inv-details {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .index-five .company-details .company-content {
        flex-direction: column-reverse;
    }
}
@media (max-width: 767px) {
    .index-five .company-info {
        display: block;
    }
}
.index-five .inv-content {
    border: none;
    margin: 0px;
    padding: 0px;
}
.index-five .inv-content .invoice-header {
    border-bottom: 0;
    margin: 0;
    padding: 15px;
    background: rgba(255, 255, 255, 0.1);
}
.index-five .inv-content .invoice-address {
    margin: 0;
    padding: 25px 15px;
    border-bottom: none;
    justify-content: normal;
}
.index-five .inv-content .invoice-address .invoice-to {
    width: 50%;
}
.index-five .inv-content .invoice-address .invoice-to .inv-to-address {
    color: #fff;
}
.index-five .inv-content .invoice-table {
    margin: 0;
    padding: 0;
}
.index-five .inv-content .invoice-table table {
    border: 1px solid #f2f2f2;
    color: #fff;
}
.index-five .inv-content .invoice-table table thead tr {
    background: rgba(242, 242, 242, 0.1);
}
.index-five .inv-content .invoice-table table tr {
    border: 1px solid #f2f2f2;
}
.index-five .inv-content .invoice-table table tr td {
    border-right: 1px solid #f2f2f2;
}
.index-five .inv-content .invoice-table table .table-qty {
    width: 10%;
}
.index-five .inv-content .invoice-table table .table-price {
    width: 10%;
    padding: 15px 25px;
}
.index-five .inv-content .invoice-table-footer {
    margin: 0;
    padding: 15px 0 15px 15px;
    border-bottom: none;
    color: #2c3038;
    align-items: center;
}
.index-five .inv-content .invoice-table-footer .table-footer-left {
    color: #95979b;
}
.index-five .inv-content .invoice-table-footer .table-footer-left span {
    display: block;
    color: #95979b;
}
@media (max-width: 767px) {
    .index-five .inv-content .invoice-table-footer .table-footer-left {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-five .inv-content .invoice-table-footer .notes {
        display: none;
    }
}
.index-five .inv-content .invoice-table-footer .notes .delivery-notes {
    max-width: 500px;
    color: #fff;
}
.index-five .inv-content .invoice-table-footer .table-footer-right {
    color: #fff;
}
.index-five .inv-content .invoice-table-footer .table-footer-right table tr {
    border-bottom: 1px solid #f2f2f2;
}
.index-five
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    tr:last-child {
    border-bottom: 0;
}
.index-five .inv-content .invoice-table-footer .table-footer-right table td {
    padding: 8px 15px;
}
.index-five
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    td:first-child {
    padding: 8px 25px;
}
@media (max-width: 767px) {
    .index-five
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
        margin: 0;
        padding: 8px 15px;
    }
}
@media (max-width: 767px) {
    .index-five
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:last-child {
        text-align: right;
    }
}
.index-five
    .inv-content
    .invoice-table-footer
    .table-footer-right
    .totalamt-table
    tr
    td {
    padding: 0;
    color: #fff;
}
@media (max-width: 767px) {
    .index-five .inv-content .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .index-five .inv-content .invoice-table-footer {
        flex-direction: column-reverse;
        margin: 0;
        padding: 15px 0;
    }
}
.index-five .inv-content .totalamount-footer {
    background: none;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin: 0;
    padding: 15px;
}
.index-five .inv-content .total-amountdetails {
    border-bottom: 1px solid #f2f2f2;
    margin: 0;
    padding: 15px;
}
.index-five .inv-content .total-amountdetails span {
    color: #fff;
}
@media (max-width: 767px) {
    .index-five .inv-content .total-amountdetails {
        margin: 0;
        padding: 15px;
    }
}
.index-five .inv-content .bank-details {
    margin: 0;
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2;
}
.index-five .inv-content .bank-details .payment-info {
    color: #fff;
}
.index-five .inv-content .bank-details .payment-info .payment-title {
    color: #fff;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.index-five .inv-content .bank-details .payment-info div {
    margin: 0 0 10px;
    padding: 0;
}
.index-five .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.index-five .inv-content .bank-details .company-sign span {
    color: #fff;
}
.index-five .inv-content .terms-condition {
    margin: 0;
    padding: 15px 15px;
    border-bottom: 1px solid #f2f2f2;
}
.index-five .inv-content .terms-condition ol {
    list-style: disc;
    padding-left: 16px;
    color: #fff;
}
@media (max-width: 767px) {
    .index-five .inv-content {
        padding: 25px;
    }
}
.index-five .inv-content .thanks-msg {
    border-bottom: 1px solid #f2f2f2;
}

.dartheme {
    background: #000;
}
.dartheme .file-link .download-link,
.dartheme .file-link .print-link {
    background: none;
    border: 1px solid #95979b;
    color: #95979b;
}
.dartheme .file-link .download-link:hover,
.dartheme .file-link .print-link:hover {
    border: 1px solid #fff;
    color: #fff;
}
@media (max-width: 767px) {
    .dartheme .file-link {
        margin: 40px 0;
        padding: 15px;
    }
}

.invoice-card-title h6 {
    margin-bottom: 24px;
    font-weight: 600;
    color: #212529;
}

.invoice-content-title a {
    font-size: 13px;
    padding: 10px;
    color: #212529;
    display: inline-block;
}
.invoice-content-title a:hover {
    color: #7638ff;
}

.blog.invoice-blog {
    border: 1px solid #efefef;
}
.blog.invoice-blog.active {
    border: 1px solid #7638ff;
}
.blog.invoice-blog.active .invoice-star {
    background: #eaddff;
    cursor: pointer;
}
.blog.invoice-blog.active .invoice-star i {
    color: #7539ff;
}

.invoice-star {
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background: #f3f3f3;
    cursor: pointer;
}
.invoice-star i {
    font-size: 10px;
}

.template-invoice-card .invoice-card-title h6 {
    border-bottom: 1px solid #efefef;
    margin: 0 0 15px;
    padding: 0 0 15px;
}
.template-invoice-card .blog {
    position: relative;
}
.template-invoice-card .blog .blog-image a.img-general::before {
    content: "";
    position: absolute;
    background: rgba(87, 27, 225, 0.6);
    border-radius: 10px;
    width: 95%;
    height: 97%;
    left: 4px;
    top: 4px;
    z-index: 9;
    transform: scale(0);
}
.template-invoice-card .blog .blog-image a.preview-invoice {
    background-color: #fff;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    transition: ease all 0.5s;
    bottom: 0;
    opacity: 0;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50px;
}
.template-invoice-card .blog .blog-image a.preview-invoice:hover i {
    color: #7539ff;
}
.template-invoice-card .blog .blog-image a.preview-invoice i {
    color: rgb(63, 66, 84);
    font-size: 12px;
}
.template-invoice-card .blog:hover .blog-image a.img-general::before {
    content: "";
    transform: scale(1);
    transition: all 0.3s ease-in-out;
}
.template-invoice-card .blog:hover .blog-image a.preview-invoice {
    top: 50%;
    opacity: 1;
    z-index: 9;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    transition: ease all 0.5s;
}
.template-invoice-card .blog .invoice-content-title {
    padding: 10px;
    width: 100%;
}
.template-invoice-card .blog .invoice-content-title a {
    padding: 0;
    font-weight: 600;
    font-size: 13px;
}

.invoice-model.modal .modal-dialog {
    max-width: 950px;
    margin-top: 70px;
}

.invoice-receipt-model.modal .modal-dialog {
    max-width: 400px;
    margin-top: 70px;
}
.invoice-receipt-model .receipt-wrap {
    max-width: 100%;
    margin: 0;
    padding: 0;
}

.close-model {
    background: rgba(51, 51, 51, 0.4);
    padding: 6px 14px;
    display: block;
    border-radius: 50px;
    position: absolute;
    right: 0;
    top: -45px;
    color: #fff;
    cursor: pointer;
}
.close-model i {
    margin-right: 6px;
}

.invoice-model .index-five {
    background: #252526;
    border-radius: 20px;
}

.preview-boxs a {
    color: #7539ff;
    font-weight: 600;
}

.signature-preview-page .inv-content {
    border: 0;
}
.signature-preview-page .inv-content .inv-header-left span {
    color: #95979b;
}
.signature-preview-page .inv-content .invoice-to span {
    font-size: 24px;
    color: #7638ff;
    font-weight: 700;
}

.pay-online-recipt .inv-header-left h3 {
    margin-bottom: 6px;
    color: #2c3038;
    font-weight: 600;
}
.pay-online-recipt .inv-header-left p {
    color: #333333;
    font-weight: 500;
    font-size: 14px;
}
.pay-online-recipt .inv-header-left p span {
    font-weight: 400;
}

.pay-online-recipt .invoice-to {
    width: 100%;
}
.pay-online-recipt .invoice-table h4 {
    margin-bottom: 20px;
}
.pay-online-recipt .inv-content .invoice-address {
    border-bottom: 0;
}

.pay-online-list li h6 {
    width: 10%;
}

.pay-method-group {
    padding: 24px;
    margin: 24px;
    background: #f6f2ff;
}
.pay-method-group .card-information h6 {
    font-weight: 600;
    color: #000;
    margin-bottom: 25px;
    text-align: right;
}
.pay-method-group .cal-icon-info:after {
    font-family: "Feather";
    content: "\e927";
}

.payment-btns {
    margin: 24px;
}

.receipt-pay-mail {
    max-width: 500px;
    margin: 30px auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
}
.receipt-pay-mail .company-logo {
    text-align: center;
    margin-bottom: 20px;
}
.receipt-pay-mail ul li {
    margin-bottom: 20px;
    font-weight: 500;
    color: #3f4254;
}
.receipt-pay-mail ul li span {
    font-weight: 600;
}
.receipt-pay-mail .click-invoice-btn {
    margin-bottom: 24px;
}
.receipt-pay-mail p {
    margin-bottom: 0px;
    font-weight: 500;
    color: #3f4254;
}

.invoice-center-pay {
    display: flex;
    align-items: center;
    height: 100vh;
}

.signature-success-modal .form-header i {
    color: #19a855;
    font-size: 32px;
}

.more-invoice-detail {
    text-align: center;
    margin: 0px 24px;
    padding-top: 24px;
    border-top: 1px solid #f2f2f2;
}
.more-invoice-detail a {
    font-size: 14px;
}

.invoice-template-tab.invoices-main-tabs {
    border-bottom: 1px solid #e0e0e0;
    padding: 0 0 0 0;
}
.invoice-template-tab.invoices-main-tabs .invoices-tabs ul {
    padding-top: 14px;
    padding-left: 14px;
}
.invoice-template-tab.invoices-main-tabs .invoices-tabs ul li {
    position: relative;
    display: inline-block;
    margin: 0 24px 0 0;
}
@media (max-width: 575.98px) {
    .invoice-template-tab.invoices-main-tabs .invoices-tabs ul li {
        display: block;
        margin: 0 0 20px 0;
    }
}
.invoice-template-tab.invoices-main-tabs .invoices-tabs ul li a {
    font-weight: 500;
    font-size: 14px;
    color: #878a99;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 0 0 14px 0;
}
.invoice-template-tab.invoices-main-tabs .invoices-tabs ul li a.active {
    color: #7539ff;
    border-bottom: 3px solid #7539ff;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
@media (max-width: 991.98px) {
    .invoice-template-tab.invoices-main-tabs .invoices-tabs ul li a.active {
        border-bottom: 0;
    }
}
.invoice-template-tab.invoices-main-tabs .invoices-settings-icon {
    color: #7539ff;
    margin-right: 20px;
}
.invoice-template-tab.invoices-main-tabs .btn {
    font-weight: 600;
    color: #fff;
    padding: 12px 10px;
    min-width: 167px;
    background: #1b2559;
    border: 1px solid #1b2559;
    border-radius: 6px;
}

.invoice-two .invoice-wrapper {
    background: #fff;
    margin: 60px 0 40px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
    .invoice-two .invoice-wrapper {
        padding: 10px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-details {
        display: block;
    }
}
.invoice-two .inv-content .invoice-header {
    border-bottom: 1px solid #f2f2f2;
    background: var(
        --color-gradient,
        linear-gradient(320deg, #ddceff 0%, #dbecff 100%)
    );
    padding: 45px 24px;
}
.invoice-two .inv-content .invoice-header .inv-header-left h2 {
    color: #3f4254;
    font-size: 48px;
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-header .inv-header-left h2 {
        font-size: 18px;
    }
}
.invoice-two .inv-content .invoice-header .inv-header-left a {
    display: block;
}
.invoice-two .inv-content .invoice-header .inv-header-left img {
    max-width: 200px;
}
.invoice-two .inv-content .invoice-header .inv-header-left span {
    color: #95979b;
}
.invoice-two .inv-content .invoice-header .inv-header-right {
    text-align: right;
}
.invoice-two .inv-content .invoice-header .inv-header-right img {
    max-width: 200px;
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-header .inv-header-right img {
        max-width: 131px;
    }
}
.invoice-two .inv-content .invoice-header .inv-header-right .invoice-title {
    font-size: 24px;
    font-weight: 700;
    color: #2c3038;
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-header .inv-header-right .invoice-title {
        font-size: 20px;
        margin: 10px 0 0 0;
        padding: 0;
    }
}
.invoice-two .inv-content .invoice-header .inv-header-right .inv-date {
    color: #95979b;
    font-size: 18px;
    font-weight: normal;
    margin-right: 20px;
}
.invoice-two
    .inv-content
    .invoice-header
    .inv-header-right
    .inv-date:last-child {
    margin-right: 0;
}
.invoice-two .inv-content .invoice-header .inv-header-right .inv-date span {
    color: #2c3038;
    font-weight: 700;
    font-size: 24px;
    margin-left: 8px;
}
@media print {
    .invoice-two .inv-content .invoice-header .inv-header-right .inv-date span {
        font-size: 14px;
    }
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-header .inv-header-right .inv-date span {
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-header .inv-header-right {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-header {
        display: block;
        background: none;
        padding: 0px;
    }
}
@media print {
    .invoice-two .inv-content .invoice-header {
        display: flex;
    }
}
.invoice-two .inv-content .two-invoice-details {
    margin: 30px 0 0 0;
    padding: 0 0px;
}
.invoice-two .inv-content .two-invoice-details .company-booking-address {
    display: flex;
    border-bottom: 1px solid var(--gray-gray-5, #e0e0e0);
}
.invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address
    .company-add {
    color: #000;
    font-weight: 450;
    font-size: 16px;
    margin-bottom: 30px;
}
@media (max-width: 991.98px) {
    .invoice-two
        .inv-content
        .two-invoice-details
        .company-booking-address
        .company-add {
        font-size: 15px;
    }
}
.invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address
    .company-add
    span {
    display: block;
    color: #95979b;
    margin-top: 5px;
}
@media (max-width: 991.98px) {
    .invoice-two
        .inv-content
        .two-invoice-details
        .company-booking-address
        .company-add
        span {
        font-size: 15px;
    }
}
.invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address
    .invoice-date
    h6 {
    color: #000;
    margin-bottom: 5px;
    text-align: right;
}
@media (max-width: 991.98px) {
    .invoice-two
        .inv-content
        .two-invoice-details
        .company-booking-address
        .invoice-date
        h6 {
        font-size: 12px;
    }
}
.invoice-two .inv-content .two-invoice-details h5 {
    color: #3f4254;
    margin: 0;
    padding: 24px 0;
}
@media (max-width: 991.98px) {
    .invoice-two .inv-content .two-invoice-details h5 {
        font-size: 15px;
        margin: 0 0 0 17px;
        padding: 8px 0;
    }
}
.invoice-two .inv-content .invoice-address-details {
    margin: 0;
    padding: 20px 0px;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-address-details {
        font-size: 14px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details {
        display: flex;
    }
}
@media (max-width: 575.98px) {
    .invoice-two .inv-content .invoice-address-details {
        display: flex;
    }
}
.invoice-two .inv-content .invoice-address-details .invoice-to span {
    color: #878a99;
    margin: 0 0 15px;
    display: block;
    font-size: 18px;
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details .invoice-to span {
        margin: 0 0 10px;
    }
}
.invoice-two .inv-content .invoice-address-details .invoice-to .inv-to-address {
    color: #2c3038;
    font-size: 16px;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .invoice-to
    .invoice-to-payment {
    background: rgba(242, 242, 242, 0.5);
    border-radius: 10px;
    margin: 0;
    padding: 20px 0px;
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details .invoice-to {
        margin-bottom: 20px;
    }
}
.invoice-two .inv-content .invoice-address-details .invoice-to-payment {
    background: rgba(242, 242, 242, 0.5);
    margin: 0;
    padding: 24px 24px;
}
.invoice-two .inv-content .invoice-address-details .invoice-to-payment span {
    color: #878a99;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .invoice-to-payment
    .inv-to-address {
    color: #54c1ff;
}
.invoice-two .inv-content .invoice-address-details .company-details {
    text-align: right;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .company-details
    .company-name {
    font-size: 24px;
    color: #7638ff;
    margin: 0 0 10px;
    font-weight: 700;
    display: block;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .company-details
    .gst-details {
    margin: 0 0 10px;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .company-details
    .gst-details
    span {
    color: #95979b;
    display: block;
}
@media print {
    .invoice-two
        .inv-content
        .invoice-address-details
        .company-details
        .gst-details {
        margin: 0 0 5px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details .company-details {
        text-align: left;
        margin: 0 0 20px;
    }
}
@media print {
    .invoice-two .inv-content .invoice-address-details {
        font-size: 10px;
    }
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-address-details {
        font-size: 14px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details {
        display: block;
    }
}
.invoice-two .inv-content .invoice-table-details {
    margin: 30px 0 30px 0;
    padding: 0 0 0;
}
.invoice-two .inv-content .invoice-table-details table {
    font-family: "Circular Std";
    width: 100%;
    color: #2c3038;
    border: 1px solid var(--gray-gray-3, #bebebe);
}
.invoice-two .inv-content .invoice-table-details table .table_width_1 {
    width: 3%;
}
.invoice-two .inv-content .invoice-table-details table .table_width_2 {
    width: 20%;
}
.invoice-two .inv-content .invoice-table-details table .table_width_3 {
    width: 40%;
}
.invoice-two .inv-content .invoice-table-details table .table_width_4 {
    width: 15%;
}
.invoice-two .inv-content .invoice-table-details table .table_width_5 {
    width: 15%;
}
.invoice-two .inv-content .invoice-table-details table thead tr {
    background: #fff;
}
.invoice-two .inv-content .invoice-table-details table thead tr th {
    font-size: 18px;
    font-weight: 450;
    color: #000;
}
.invoice-two .inv-content .invoice-table-details table tr {
    border-bottom: 1px solid #bebebe;
}
.invoice-two .inv-content .invoice-table-details table tr td,
.invoice-two .inv-content .invoice-table-details table tr th {
    padding: 15px;
}
@media print {
    .invoice-two .inv-content .invoice-table-details table tr td,
    .invoice-two .inv-content .invoice-table-details table tr th {
        padding: 8px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-table-details table tr td,
    .invoice-two .inv-content .invoice-table-details table tr th {
        white-space: nowrap;
    }
}
.invoice-two .inv-content .invoice-table-details table tr td {
    vertical-align: middle;
    font-size: 16px;
    height: 69px;
    white-space: pre-wrap;
}
@media print {
    .invoice-two .inv-content .invoice-table-details table tr td {
        height: 72px;
    }
}
.invoice-two .inv-content .invoice-table-details table tr .table-description {
    vertical-align: middle;
    width: 33%;
    color: #2c3038;
    font-size: 16px;
    font-weight: 400;
}
.invoice-two .inv-content .invoice-table-details table tr h6 {
    color: #7539ff;
    font-size: 12px;
}
@media print {
    .invoice-two .inv-content .invoice-table-details {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-table-details {
        font-size: 14px;
    }
}
.invoice-two .inv-content .invoice-table-footer {
    justify-content: flex-end;
    padding: 5px 30px;
}
.invoice-two .inv-content .invoice-table-footer .table-footer-left {
    width: 70%;
}
@media (max-width: 575.98px) {
    .invoice-two .inv-content .invoice-table-footer .table-footer-left {
        width: auto;
        font-size: 14px;
    }
}
.invoice-two .inv-content .invoice-table-footer .table-footer-right {
    width: 80%;
}
.invoice-two .inv-content .invoice-table-footer .table-footer-right table {
    width: 100%;
}
.invoice-two .inv-content .invoice-table-footer .table-footer-right table td {
    color: #2c3038;
    font-size: 18px;
    text-align: right;
    width: 50%;
    padding: 5px 10px;
}
@media print {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        width: auto;
    }
}
@media (max-width: 991.98px) {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        width: auto;
    }
}
@media (max-width: 768px) {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        margin: 0;
        padding: 15px 0px;
    }
}
@media (max-width: 768px) {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
.invoice-two .inv-content .invoice-table-footer .table-footer-right table span {
    color: #878a99;
}
@media print {
    .invoice-two .inv-content .invoice-table-footer .table-footer-right table {
        font-size: 12px;
    }
}
.invoice-two
    .inv-content
    .invoice-table-footer
    .table-footer-right
    .totalamt-table
    td {
    color: #2c3038;
    font-size: 24px;
    font-weight: 700;
}
@media print {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table
        td {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table
        td {
        font-size: 16px;
    }
}
@media print {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-table-footer .table-footer-right {
        width: 60%;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media print {
    .invoice-two .inv-content .invoice-table-footer .table-footer-right {
        width: 50%;
    }
}
@media print {
    .invoice-two .inv-content .invoice-table-footer {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-table-footer .total-info {
        display: none;
    }
}
.invoice-two .inv-content .totalamount-footer {
    border: 1px solid #95979b;
    border-left: none;
    border-right: none;
    margin: 10px 0 0;
    padding: 15px;
}
.invoice-two .inv-content .bank-details {
    margin: 30px 0;
    padding: 0 20px 0 20px;
    border: none;
}
.invoice-two .inv-content .bank-details .payment-info {
    display: flex;
}
.invoice-two .inv-content .bank-details .payment-info .qr-code {
    margin-right: 24px;
}
.invoice-two .inv-content .bank-details .payment-info .qr-code .scan-details {
    font-size: 14px;
}
.invoice-two .inv-content .bank-details .payment-info .qr-code .img {
    max-width: 140px;
}
.invoice-two .inv-content .bank-details .payment-info .debitcard {
    color: #000;
}
.invoice-two .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
}
.invoice-two .inv-content .bank-details .payment-info .debit-card {
    margin-top: 15px;
    margin-bottom: 10px;
}
.invoice-two .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.invoice-two .inv-content .terms-condition {
    margin: 0;
    padding: 0 0;
}
.invoice-two .inv-content .terms-condition .amount {
    margin-bottom: 24px;
}
.invoice-two .inv-content .terms-condition ol {
    list-style: number;
    padding-left: 18px;
    color: #878a99;
}
.invoice-two .inv-content .terms-condition span {
    display: block;
    margin: 0 0 15px;
    color: #2c3038;
}
@media print {
    .invoice-two .inv-content .terms-condition {
        font-size: 12px;
        margin: 0;
        padding: 10px 30px;
    }
}
@media (max-width: 575.98px) {
    .invoice-two .inv-content .terms-condition {
        font-size: 14px;
    }
}
.invoice-two .inv-content .thanks-msg {
    color: #3f4254;
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;
    margin: 0;
    padding: 15px 0;
}
@media print {
    .invoice-two .inv-content .thanks-msg {
        font-size: 10px;
        margin: 0;
        padding: 10px;
    }
}
@media (max-width: 575.98px) {
    .invoice-two .inv-content .thanks-msg {
        font-size: 12px;
    }
}
.invoice-two .file-link {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.invoice-two .file-link .download-link {
    color: #7539ff;
    margin: 0px;
    padding: 12px 22px;
    margin-right: 10px;
    border: 1px solid #7638ff;
    border-radius: 5px;
    background: #fff;
    height: 46px;
}
.invoice-two .file-link .download-link:hover {
    background: #7638ff;
    color: #fff;
}
.invoice-two .file-link .print-link {
    color: #7539ff;
    margin: 0 0 0 0;
    padding: 15px 22px;
    border: 1px solid #4361ee;
    border-radius: 5px;
    height: 46px;
    background: #fff;
    display: flex;
    align-items: center;
}
.invoice-two .file-link .print-link i {
    margin-right: 6px;
}
.invoice-two .file-link .print-link:hover {
    background: #4361ee;
    color: #fff;
}
@media (max-width: 992px) {
    .invoice-two .file-link {
        margin: 20px;
    }
}

.custom-modal.signature-add-modal .modal-body {
    padding: 20px;
}
.custom-modal.signature-add-modal .modal-body .service-upload {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-modal.signature-add-modal .modal-body .service-upload-info {
    min-height: 70px;
}
.custom-modal.signature-add-modal .modal-body p {
    color: #bdbdbd;
    margin-top: 10px;
    font-size: 12px;
}
.custom-modal.signature-add-modal .modal-body .custom_check {
    font-size: 14px;
    color: #333333;
    margin-bottom: 0;
    padding-left: 26px;
}
.custom-modal.signature-add-modal .modal-footer {
    padding: 0 20px 20px;
}
.custom-modal.signature-delete-modal .modal-body .form-header i {
    color: #ae2c2c;
    font-size: 40px;
    margin-bottom: 15px;
}
.custom-modal.signature-delete-modal .modal-body .form-header p {
    color: #4f4f4f;
}

.signature-preview {
    background-color: #f7f8f9;
    padding: 5px;
    border-radius: 10px;
    position: relative;
    height: 70px;
}
.signature-preview a {
    position: absolute;
    right: 3px;
    top: 3px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffecec;
}
.signature-preview a i {
    color: #ae2c2c;
}
@media (max-width: 991.98px) {
    .signature-preview img {
        height: 60px;
    }
}

.signature-table tbody tr td a.btn-action-icon.active {
    background: #eaddff;
    color: #7539ff;
}

.signature-invoice {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
}
.signature-invoice .form-group-bank {
    background-color: #f3f3f9;
}
.signature-invoice .form-group-bank .nav-pills {
    background-color: #fff;
    padding: 15px;
}
.signature-invoice
    .form-group-bank
    .custom_radio.active
    input
    ~ .checkmark:after,
.signature-invoice
    .form-group-bank
    .custom_radio_one.active
    input
    ~ .checkmark:after {
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.signature-invoice .service-upload-info {
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signature-invoice .service-upload-info span {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signature-invoice .e-signature-block .signature-draw-area {
    height: 100px;
    background-color: #fff;
    border-radius: 8px;
}
.signature-invoice .e-signature-block a {
    display: block;
    color: #ed3a3a;
    font-size: 14px;
    margin-top: 10px;
}

.notifications {
    color: #3f4254;
}
.notifications .page-header {
    margin: 0 0 20px 0;
}
.notifications .page-header .content-page-header {
    border-bottom: 1px solid #eeeeee;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
}
.notifications .page-header .noti-action-btns .btn {
    font-weight: 500;
}
.notifications .page-header .noti-action-btns .btn:hover {
    color: #fff;
}
.notifications .page-header .noti-action-btns .btn-mark-read {
    color: #3f4254;
    margin-right: 10px;
}
.notifications .page-header .noti-action-btns .btn-delete-all {
    border-color: #ed3a3a;
    color: #ed3a3a;
}
.notifications .page-header .noti-action-btns .btn-delete-all:hover {
    box-shadow: inset 0 50px 0 0 #ed3a3a;
}
.notifications .user-list-item {
    margin: 0 0 17px 0;
    padding: 16px;
    flex-direction: inherit;
    border-radius: 0;
}
.notifications .user-list-item .avatar {
    width: 32px;
    height: 32px;
    margin-left: 16px;
}
.notifications .user-list-item .avatar.avatar-online:before {
    left: -16px;
    top: 0;
    width: 8px;
    height: 8px;
    border: none;
}
.notifications .user-list-item .users-list-body {
    width: 100%;
}
.notifications .user-list-item .users-list-body h6 {
    margin: 0 0 6px 0;
    font-weight: 600;
    font-size: 14px;
    color: #3f4254;
}
.notifications .user-list-item .users-list-body h6 span {
    font-weight: 400;
}
.notifications .user-list-item .users-list-body blockquote {
    margin: 0 0 8px 0;
    padding: 0 0 0 14px;
    position: relative;
}
.notifications .user-list-item .users-list-body blockquote:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px;
    background-color: #dddee1;
    width: 4px;
    height: 100%;
}
.notifications .user-list-item .users-list-body .follow-btn {
    margin: 10px 0 10px 0;
}
.notifications .user-list-item .users-list-body .follow-btn .btn {
    padding: 7px 20px;
    font-size: 12px;
    min-width: 83px;
}
@media (max-width: 575.98px) {
    .notifications .user-list-item .users-list-body .follow-btn .btn {
        margin-top: 8px;
    }
}
.notifications
    .user-list-item
    .users-list-body
    .follow-btn
    .btn-outline-primary {
    margin-left: 8px;
}
@media (max-width: 575.98px) {
    .notifications
        .user-list-item
        .users-list-body
        .follow-btn
        .btn-outline-primary {
        margin-left: 0;
    }
}
.notifications .user-list-item .users-list-body .time {
    color: #bebebe;
    font-weight: 500;
}
.notifications .user-list-item .users-list-body .chats-delete {
    min-width: 36px;
    padding: 10px;
    height: 36px;
    background: #f3f3f3;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}
.notifications .user-list-item .users-list-body .chats-delete i {
    color: #3f4254;
    font-size: 16px;
}
.notifications .user-list-item .users-list-body .chats-delete:hover {
    background: #ed3a3a;
}
.notifications .user-list-item .users-list-body .chats-delete:hover i {
    color: #fff;
}
@media (max-width: 575.98px) {
    .notifications .user-list-item .users-list-body .chats-delete {
        margin-left: 10px;
    }
}

.pay-online-recipt .pay-method-group h6 {
    font-weight: 600;
    color: #000000;
    margin-bottom: 15px;
}
@media (max-width: 575.98px) {
    .pay-online-recipt .pay-method-group ul li {
        margin-bottom: 24px;
    }
    .pay-online-recipt .pay-method-group ul li:last-child {
        margin-bottom: 0;
    }
}
.pay-online-recipt .pay-method-group ul li label {
    padding: 20px 20px 20px 42px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    width: 100%;
    color: #333;
}
.pay-online-recipt .pay-method-group ul li label .checkmark {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    left: 20px;
}
.pay-online-recipt .pay-method-group ul li label img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    right: 14px;
}
.pay-online-recipt .pay-method-group ul li label.active {
    background: #eaddff;
}

.logo-darkmode {
    display: none;
}

.invoice-one .inv-content {
    border: 1px solid #bdbdbd;
    margin: 0;
    padding: 24px;
}
.invoice-one .inv-content span.line {
    display: block;
    background: linear-gradient(320deg, #ddceff 0%, #dbecff 100%);
    height: 10px;
}
.invoice-one .inv-content .invoice-header {
    margin: 0;
    padding: 20px 20px;
    background: #fff;
}
.invoice-one .inv-content .invoice-header .inv-header-left {
    width: 54%;
}
@media (max-width: 1199.98px) {
    .invoice-one .inv-content .invoice-header .inv-header-left {
        width: 56%;
    }
}
@media (max-width: 767px) {
    .invoice-one .inv-content .invoice-header .inv-header-left {
        width: 100%;
    }
}
.invoice-one .inv-content .invoice-header .inv-header-left h4 {
    color: #7638ff;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 29px;
}
@media (max-width: 767px) {
    .invoice-one .inv-content .invoice-header .inv-header-left h4 {
        font-size: 28px;
        margin-bottom: 0;
    }
}
.invoice-one .inv-content .invoice-header .inv-header-right p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: #000000;
    font-weight: 700;
    align-items: center;
}
@media (max-width: 992px) {
    .invoice-one .inv-content .invoice-header .inv-header-right p {
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .invoice-one .inv-content .invoice-header .inv-header-right p {
        font-size: 15px;
    }
}
@media (max-width: 767px) {
    .invoice-one .inv-content .invoice-header .inv-header-right p {
        flex-direction: unset;
    }
}
.invoice-one .inv-content .invoice-header .inv-header-right p span {
    background: #e1ffed;
    border-radius: 5px;
    padding: 5px 10px;
}
@media (max-width: 767px) {
    .invoice-one .inv-content .invoice-header .inv-header-right p span {
        background: none;
        padding: 0;
        font-size: 14px;
    }
}
.invoice-one .inv-content .invoice-header .company-details {
    background: var(
        --color-gradient,
        linear-gradient(320deg, #dbecff 0%, #ddceff 100%)
    );
    border-radius: 14px 77px 14px 14px;
}
@media (max-width: 992px) {
    .invoice-one .inv-content .invoice-header .company-details {
        background: none;
    }
}
.invoice-one .inv-content .invoice-header .company-details .gst-details {
    position: relative;
    margin: 0;
    padding: 30px 30px;
    color: #fff;
    z-index: 2;
}
.invoice-one .inv-content .invoice-header .company-details .gst-details span {
    color: #3f4254;
    font-size: 16px;
    font-weight: 450;
}
@media (max-width: 992px) {
    .invoice-one
        .inv-content
        .invoice-header
        .company-details
        .gst-details
        span {
        color: #000;
    }
}
@media (max-width: 992px) {
    .invoice-one .inv-content .invoice-header .company-details .gst-details {
        color: #000;
        margin: 0;
        padding: 10px 0px;
    }
}
@media (max-width: 767px) {
    .invoice-one .inv-content .invoice-header .company-details .gst-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.invoice-one .inv-content .invoice-header .company-details .address-bg {
    position: absolute;
    width: 473.11px;
    height: 126px;
    top: 0px;
    overflow: hidden;
}
@media (max-width: 992px) {
    .invoice-one .inv-content .invoice-header .company-details .address-bg {
        right: 58px;
        background: none;
    }
}
@media (max-width: 767px) {
    .invoice-one .inv-content .invoice-header .company-details .address-bg {
        background: none;
    }
}
.invoice-one .add-details {
    color: #2c3038;
    font-size: 16px;
    font-weight: 450;
    margin-bottom: 25px;
}
.invoice-one .bill-add {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 450;
    color: #878a99;
}
.invoice-one .customer-name {
    font-size: 24px;
    font-weight: 700;
    color: #2c3038;
    margin-bottom: 15px;
}
@media (max-width: 767px) {
    .invoice-one .customer-name {
        font-size: 20px;
    }
}
.invoice-one .customer-name span {
    color: #878a99;
    font-size: 16px;
    font-weight: 600;
}
.invoice-one .payment-status {
    color: #878a99;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.invoice-one .payment-status span {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #33b469;
}
.invoice-one .patient-info {
    display: flex;
    margin: 0 0 20px;
    padding: 0;
}
.invoice-one .patient-info .patient-details,
.invoice-one .patient-info .treatment-info {
    margin: 0 20px 0 0;
    padding: 20px;
    width: 50%;
}
.invoice-one .patient-info .patient-details h5,
.invoice-one .patient-info .treatment-info h5 {
    color: #0c0c20;
    margin-bottom: 15px;
    font-size: 18px;
}
.invoice-one .patient-info .patient-details div,
.invoice-one .patient-info .treatment-info div {
    display: flex;
    justify-content: space-between;
}
.invoice-one .patient-info .patient-details div span,
.invoice-one .patient-info .treatment-info div span {
    color: #2c3038;
    width: 70%;
    text-align: right;
}
@media (max-width: 992px) {
    .invoice-one .patient-info .patient-details div,
    .invoice-one .patient-info .treatment-info div {
        font-size: 12px;
        display: block;
    }
}
@media (max-width: 767px) {
    .invoice-one .patient-info .patient-details div,
    .invoice-one .patient-info .treatment-info div {
        font-size: 15px;
    }
}
@media (max-width: 767px) {
    .invoice-one .patient-info .patient-details,
    .invoice-one .patient-info .treatment-info {
        width: 100%;
        margin-bottom: 20px;
        margin: 0 10px 20px 0;
        padding: 15px;
    }
}
@media (max-width: 767px) {
    .invoice-one .patient-info {
        display: block;
    }
}
.invoice-one h5 {
    background: #f4f4f4;
    color: #2c3038;
    font-size: 18px;
    font-weight: 450;
    margin: 20px 0;
    padding: 10px 20px;
}
@media (max-width: 767px) {
    .invoice-one h5 {
        background: none;
        padding: 0;
    }
}
.invoice-one .invoice-table {
    margin: 0;
    padding: 0 0 20px;
}
.invoice-one .invoice-table table {
    color: #2c3038;
    margin: 0;
    padding: 0px;
}
.invoice-one .invoice-table table thead tr {
    border-bottom: none;
    font-size: 18px;
}
.invoice-one .invoice-table table thead .amount {
    font-size: 16px;
    font-weight: 450;
}
.invoice-one .invoice-table table .table_width {
    font-size: 18px;
    font-weight: 450;
}
.invoice-one .invoice-table table .table_width_2 {
    width: 40%;
}
.invoice-one .invoice-table table .table_width_3 {
    width: 20%;
}
.invoice-one .invoice-table table tr {
    border-bottom: 1px solid #bebebe;
}
.invoice-one .invoice-table table tr .table-description {
    color: #2c3038;
    font-size: 16px;
}
.invoice-one .invoice-table table tr .table-description span {
    display: block;
    color: #95979b;
}
.invoice-one .invoice-table table tr td {
    margin: 0;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 450;
    height: 82px;
    vertical-align: middle;
}
.invoice-one .invoice-table table tr td span {
    color: #7539ff;
    display: block;
    font-size: 12px;
    font-weight: 400;
}
.invoice-one .invoice-table table tr td:last-child,
.invoice-one .invoice-table table tr th:last-child {
    padding-right: 25px;
}
.invoice-one .invoice-table-footer {
    justify-content: flex-end;
    padding: 5px 30px;
}
@media (max-width: 768px) {
    .invoice-one .invoice-table-footer {
        padding: 0;
    }
}
.invoice-one .invoice-table-footer .table-footer-left {
    width: 58%;
}
@media (max-width: 575.98px) {
    .invoice-one .invoice-table-footer .table-footer-left {
        width: auto;
        font-size: 14px;
    }
}
.invoice-one .invoice-table-footer .table-footer-right {
    width: 80%;
}
.invoice-one .invoice-table-footer .table-footer-right table {
    width: 100%;
}
.invoice-one .invoice-table-footer .table-footer-right table td {
    color: #2c3038;
    font-size: 18px;
    text-align: right;
    width: 50%;
    padding: 5px 10px;
}
@media print {
    .invoice-one .invoice-table-footer .table-footer-right table td {
        width: auto;
    }
}
@media (max-width: 992px) {
    .invoice-one .invoice-table-footer .table-footer-right table td {
        width: auto;
    }
}
@media (max-width: 767px) {
    .invoice-one
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
.invoice-one .invoice-table-footer .table-footer-right table span {
    color: #878a99;
}
@media print {
    .invoice-one .invoice-table-footer .table-footer-right table {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-one .invoice-table-footer .table-footer-right table {
        font-size: 15px;
    }
}
.invoice-one .invoice-table-footer .table-footer-right .totalamt-table td {
    color: #2c3038;
    font-size: 24px;
    font-weight: 700;
}
@media print {
    .invoice-one .invoice-table-footer .table-footer-right .totalamt-table td {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-one .invoice-table-footer .table-footer-right .totalamt-table td {
        font-size: 16px;
    }
}
@media print {
    .invoice-one .invoice-table-footer .table-footer-right .totalamt-table {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-one .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media (max-width: 768px) {
    .invoice-one .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media print {
    .invoice-one .invoice-table-footer .table-footer-right {
        width: 50%;
    }
}
@media print {
    .invoice-one .invoice-table-footer {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-one .invoice-table-footer .total-info {
        display: none;
    }
}
.invoice-one .totalamount-footer {
    border: 1px solid #95979b;
    border-left: none;
    border-right: none;
    margin: 10px 0 0;
    padding: 15px;
}
.invoice-one .table-total-item {
    margin: 0;
    padding: 15px 10px 15px 20px;
}
@media (max-width: 767px) {
    .invoice-one .table-total-item {
        margin: 0;
        padding: 15px 0px;
    }
}
.invoice-one .total-amountdetails {
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid #bebebe;
    text-align: end;
}
@media (max-width: 992px) {
    .invoice-one .total-amountdetails {
        text-align: end;
    }
}
.invoice-one .bank-details {
    margin: 0;
    padding: 25px 15px;
    border-bottom: 1px solid #bebebe;
}
.invoice-one .bank-details .payment-info {
    color: #2c3038;
    display: flex;
}
.invoice-one .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}
.invoice-one .bank-details .payment-info div {
    font-size: 16px;
    font-weight: 450;
    margin: 0 0 10px;
    padding: 0;
}
.invoice-one .bank-details .payment-info span {
    color: #95979b;
}
.invoice-one .bank-details .payment-info .pay-details {
    padding-left: 20px;
}
.invoice-one .bank-details .terms-condition {
    margin: 0;
    padding: 0;
    color: #2c3038;
    border: 0;
}
.invoice-one .bank-details .terms-condition span {
    font-size: 18px;
    margin: 0 10px 10px;
}
.invoice-one .bank-details .terms-condition ol {
    color: #95979b;
}
@media (max-width: 767px) {
    .invoice-one .bank-details .terms-condition {
        margin: 10px 0;
        padding: 0px 0px;
    }
}
@media (max-width: 767px) {
    .invoice-one .bank-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.invoice-one .thanks-msg {
    background: #f2f2f2;
    border-bottom: 1px solid #bebebe;
    color: #000;
    font-size: 16px;
    font-weight: 450;
}
@media (max-width: 767px) {
    .invoice-one {
        margin: 0;
        padding: 20px;
    }
}
.invoice-one .table-data {
    border: none !important;
}
.invoice-one .file-one-link {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.invoice-one .duedate-bg span {
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #e1ffed;
    font-size: 16px;
}
.invoice-one .invoice-wrapper {
    background: #fff;
    margin: 60px 0 40px;
    padding: 48px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
    .invoice-one .invoice-wrapper {
        padding: 0px;
    }
}
.invoice-one .file-link {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    padding-top: 24px;
    justify-content: flex-start;
}
.invoice-one .qr {
    width: 140px;
    height: 140px;
    margin: 0px 0 20px !important;
}
.invoice-one .qr .scan-details {
    font-size: 13px;
    font-weight: 600;
}
.invoice-one .gst-details h6 {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    margin-bottom: 5px;
}
@media (max-width: 767px) {
    .invoice-one .gst-details h6 {
        font-size: 15px;
    }
}
.invoice-one .inv-header-right a img {
    max-width: 271px !important;
    margin-bottom: 19px;
}
@media (max-width: 767px) {
    .invoice-one .inv-header-right a img {
        width: 100%;
    }
}
.invoice-one .inv-header-right h6 {
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}
.invoice-one .inv-header-right h6 span {
    font-weight: 400;
}
.invoice-one .text-end {
    padding-top: 10px;
    padding-bottom: 10px;
}

.invoice-two .invoice-wrapper {
    background: #fff;
    margin: 60px 0 40px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
    .invoice-two .invoice-wrapper {
        padding: 10px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-details {
        display: block;
    }
}
.invoice-two .inv-content .invoice-header {
    border-bottom: 1px solid #f2f2f2;
    background: var(
        --color-gradient,
        linear-gradient(320deg, #ddceff 0%, #dbecff 100%)
    );
    padding: 45px 24px;
}
.invoice-two .inv-content .invoice-header .inv-header-left h2 {
    color: #3f4254;
    font-size: 48px;
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-header .inv-header-left h2 {
        font-size: 18px;
    }
}
.invoice-two .inv-content .invoice-header .inv-header-left a {
    display: block;
}
.invoice-two .inv-content .invoice-header .inv-header-left img {
    max-width: 200px;
}
.invoice-two .inv-content .invoice-header .inv-header-left span {
    color: #95979b;
}
.invoice-two .inv-content .invoice-header .inv-header-right {
    text-align: right;
}
.invoice-two .inv-content .invoice-header .inv-header-right img {
    max-width: 200px;
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-header .inv-header-right img {
        max-width: 131px;
    }
}
.invoice-two .inv-content .invoice-header .inv-header-right .invoice-title {
    font-size: 24px;
    font-weight: 700;
    color: #2c3038;
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-header .inv-header-right .invoice-title {
        font-size: 20px;
        margin: 10px 0 0 0;
        padding: 0;
    }
}
.invoice-two .inv-content .invoice-header .inv-header-right .inv-date {
    color: #95979b;
    font-size: 18px;
    font-weight: normal;
    margin-right: 20px;
}
.invoice-two
    .inv-content
    .invoice-header
    .inv-header-right
    .inv-date:last-child {
    margin-right: 0;
}
.invoice-two .inv-content .invoice-header .inv-header-right .inv-date span {
    color: #2c3038;
    font-weight: 700;
    font-size: 24px;
    margin-left: 8px;
}
@media print {
    .invoice-two .inv-content .invoice-header .inv-header-right .inv-date span {
        font-size: 14px;
    }
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-header .inv-header-right .inv-date span {
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-header .inv-header-right {
        text-align: left;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-header {
        display: block;
        background: none;
        padding: 0px;
    }
}
@media print {
    .invoice-two .inv-content .invoice-header {
        display: flex;
    }
}
.invoice-two .inv-content .two-invoice-details {
    margin: 30px 0 0 0;
    padding: 0 0px;
}
.invoice-two .inv-content .two-invoice-details .company-booking-address {
    display: flex;
    border-bottom: 1px solid var(--gray-gray-5, #e0e0e0);
}
.invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address
    .company-add {
    color: #000;
    font-weight: 450;
    font-size: 16px;
    margin-bottom: 30px;
}
@media (max-width: 991.98px) {
    .invoice-two
        .inv-content
        .two-invoice-details
        .company-booking-address
        .company-add {
        font-size: 15px;
    }
}
.invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address
    .company-add
    span {
    display: block;
    color: #95979b;
    margin-top: 5px;
}
@media (max-width: 991.98px) {
    .invoice-two
        .inv-content
        .two-invoice-details
        .company-booking-address
        .company-add
        span {
        font-size: 15px;
    }
}
.invoice-two
    .inv-content
    .two-invoice-details
    .company-booking-address
    .invoice-date
    h6 {
    color: #000;
    margin-bottom: 5px;
    text-align: right;
}
@media (max-width: 991.98px) {
    .invoice-two
        .inv-content
        .two-invoice-details
        .company-booking-address
        .invoice-date
        h6 {
        font-size: 12px;
    }
}
.invoice-two .inv-content .two-invoice-details h5 {
    color: #3f4254;
    margin: 0;
    padding: 24px 0;
}
@media (max-width: 991.98px) {
    .invoice-two .inv-content .two-invoice-details h5 {
        font-size: 15px;
        margin: 0 0 0 17px;
        padding: 8px 0;
    }
}
.invoice-two .inv-content .invoice-address-details {
    margin: 0;
    padding: 20px 0px;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-address-details {
        font-size: 14px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details {
        display: flex;
    }
}
@media (max-width: 575.98px) {
    .invoice-two .inv-content .invoice-address-details {
        display: flex;
    }
}
.invoice-two .inv-content .invoice-address-details .invoice-to span {
    color: #878a99;
    margin: 0 0 15px;
    display: block;
    font-size: 18px;
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details .invoice-to span {
        margin: 0 0 10px;
    }
}
.invoice-two .inv-content .invoice-address-details .invoice-to .inv-to-address {
    color: #2c3038;
    font-size: 16px;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .invoice-to
    .invoice-to-payment {
    background: rgba(242, 242, 242, 0.5);
    border-radius: 10px;
    margin: 0;
    padding: 20px 0px;
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details .invoice-to {
        margin-bottom: 20px;
    }
}
.invoice-two .inv-content .invoice-address-details .invoice-to-payment {
    background: rgba(242, 242, 242, 0.5);
    margin: 0;
    padding: 24px 24px;
}
.invoice-two .inv-content .invoice-address-details .invoice-to-payment span {
    color: #878a99;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .invoice-to-payment
    .inv-to-address {
    color: #54c1ff;
}
.invoice-two .inv-content .invoice-address-details .company-details {
    text-align: right;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .company-details
    .company-name {
    font-size: 24px;
    color: #7638ff;
    margin: 0 0 10px;
    font-weight: 700;
    display: block;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .company-details
    .gst-details {
    margin: 0 0 10px;
}
.invoice-two
    .inv-content
    .invoice-address-details
    .company-details
    .gst-details
    span {
    color: #95979b;
    display: block;
}
@media print {
    .invoice-two
        .inv-content
        .invoice-address-details
        .company-details
        .gst-details {
        margin: 0 0 5px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details .company-details {
        text-align: left;
        margin: 0 0 20px;
    }
}
@media print {
    .invoice-two .inv-content .invoice-address-details {
        font-size: 10px;
    }
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-address-details {
        font-size: 14px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-address-details {
        display: block;
    }
}
.invoice-two .inv-content .invoice-table-details {
    margin: 30px 0 30px 0;
    padding: 0 0 0;
}
.invoice-two .inv-content .invoice-table-details table {
    font-family: "Circular Std";
    width: 100%;
    color: #2c3038;
    border: 1px solid var(--gray-gray-3, #bebebe);
}
.invoice-two .inv-content .invoice-table-details table .table_width_1 {
    width: 3%;
}
.invoice-two .inv-content .invoice-table-details table .table_width_2 {
    width: 20%;
}
.invoice-two .inv-content .invoice-table-details table .table_width_3 {
    width: 40%;
}
.invoice-two .inv-content .invoice-table-details table .table_width_4 {
    width: 15%;
}
.invoice-two .inv-content .invoice-table-details table .table_width_5 {
    width: 15%;
}
.invoice-two .inv-content .invoice-table-details table thead tr {
    background: #fff;
}
.invoice-two .inv-content .invoice-table-details table thead tr th {
    font-size: 18px;
    font-weight: 450;
    color: #000;
}
.invoice-two .inv-content .invoice-table-details table tr {
    border-bottom: 1px solid #bebebe;
}
.invoice-two .inv-content .invoice-table-details table tr td,
.invoice-two .inv-content .invoice-table-details table tr th {
    padding: 15px;
}
@media print {
    .invoice-two .inv-content .invoice-table-details table tr td,
    .invoice-two .inv-content .invoice-table-details table tr th {
        padding: 8px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-table-details table tr td,
    .invoice-two .inv-content .invoice-table-details table tr th {
        white-space: nowrap;
    }
}
.invoice-two .inv-content .invoice-table-details table tr td {
    vertical-align: middle;
    font-size: 16px;
    height: 69px;
    white-space: pre-wrap;
}
@media print {
    .invoice-two .inv-content .invoice-table-details table tr td {
        height: 72px;
    }
}
.invoice-two .inv-content .invoice-table-details table tr .table-description {
    vertical-align: middle;
    width: 33%;
    color: #2c3038;
    font-size: 16px;
    font-weight: 400;
}
.invoice-two .inv-content .invoice-table-details table tr h6 {
    color: #7539ff;
    font-size: 12px;
}
@media print {
    .invoice-two .inv-content .invoice-table-details {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-table-details {
        font-size: 14px;
    }
}
.invoice-two .inv-content .invoice-table-footer {
    justify-content: flex-end;
    padding: 5px 30px;
}
.invoice-two .inv-content .invoice-table-footer .table-footer-left {
    width: 70%;
}
@media (max-width: 575.98px) {
    .invoice-two .inv-content .invoice-table-footer .table-footer-left {
        width: auto;
        font-size: 14px;
    }
}
.invoice-two .inv-content .invoice-table-footer .table-footer-right {
    width: 80%;
}
.invoice-two .inv-content .invoice-table-footer .table-footer-right table {
    width: 100%;
}
.invoice-two .inv-content .invoice-table-footer .table-footer-right table td {
    color: #2c3038;
    font-size: 18px;
    text-align: right;
    width: 50%;
    padding: 5px 10px;
}
@media print {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        width: auto;
    }
}
@media (max-width: 991.98px) {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        width: auto;
    }
}
@media (max-width: 768px) {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        margin: 0;
        padding: 15px 0px;
    }
}
@media (max-width: 768px) {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
.invoice-two .inv-content .invoice-table-footer .table-footer-right table span {
    color: #878a99;
}
@media print {
    .invoice-two .inv-content .invoice-table-footer .table-footer-right table {
        font-size: 12px;
    }
}
.invoice-two
    .inv-content
    .invoice-table-footer
    .table-footer-right
    .totalamt-table
    td {
    color: #2c3038;
    font-size: 24px;
    font-weight: 700;
}
@media print {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table
        td {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table
        td {
        font-size: 16px;
    }
}
@media print {
    .invoice-two
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-two .inv-content .invoice-table-footer .table-footer-right {
        width: 60%;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media print {
    .invoice-two .inv-content .invoice-table-footer .table-footer-right {
        width: 50%;
    }
}
@media print {
    .invoice-two .inv-content .invoice-table-footer {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-two .inv-content .invoice-table-footer .total-info {
        display: none;
    }
}
.invoice-two .inv-content .totalamount-footer {
    border: 1px solid #95979b;
    border-left: none;
    border-right: none;
    margin: 10px 0 0;
    padding: 15px;
}
.invoice-two .inv-content .bank-details {
    margin: 30px 0;
    padding: 0 20px 0 20px;
    border: none;
}
.invoice-two .inv-content .bank-details .payment-info {
    display: flex;
}
.invoice-two .inv-content .bank-details .payment-info .qr-code {
    margin-right: 24px;
}
.invoice-two .inv-content .bank-details .payment-info .qr-code .scan-details {
    font-size: 14px;
}
.invoice-two .inv-content .bank-details .payment-info .qr-code .img {
    max-width: 140px;
}
.invoice-two .inv-content .bank-details .payment-info .debitcard {
    color: #000;
}
.invoice-two .inv-content .bank-details .payment-info .payment-title {
    color: #2c3038;
    font-size: 18px;
}
.invoice-two .inv-content .bank-details .payment-info .debit-card {
    margin-top: 15px;
    margin-bottom: 10px;
}
.invoice-two .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.invoice-two .inv-content .terms-condition {
    margin: 0;
    padding: 0 0;
}
.invoice-two .inv-content .terms-condition .amount {
    margin-bottom: 24px;
}
.invoice-two .inv-content .terms-condition ol {
    list-style: number;
    padding-left: 18px;
    color: #878a99;
}
.invoice-two .inv-content .terms-condition span {
    display: block;
    margin: 0 0 15px;
    color: #2c3038;
}
@media print {
    .invoice-two .inv-content .terms-condition {
        font-size: 12px;
        margin: 0;
        padding: 10px 30px;
    }
}
@media (max-width: 575.98px) {
    .invoice-two .inv-content .terms-condition {
        font-size: 14px;
    }
}
.invoice-two .inv-content .thanks-msg {
    color: #3f4254;
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;
    margin: 0;
    padding: 15px 0;
}
@media print {
    .invoice-two .inv-content .thanks-msg {
        font-size: 10px;
        margin: 0;
        padding: 10px;
    }
}
@media (max-width: 575.98px) {
    .invoice-two .inv-content .thanks-msg {
        font-size: 12px;
    }
}
.invoice-two .file-link {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.invoice-two .file-link .download-link {
    color: #7539ff;
    margin: 0px;
    padding: 12px 22px;
    margin-right: 10px;
    border: 1px solid #7638ff;
    border-radius: 5px;
    background: #fff;
    height: 46px;
}
.invoice-two .file-link .download-link:hover {
    background: #7638ff;
    color: #fff;
}
.invoice-two .file-link .print-link {
    color: #7539ff;
    margin: 0 0 0 0;
    padding: 15px 22px;
    border: 1px solid #4361ee;
    border-radius: 5px;
    height: 46px;
    background: #fff;
    display: flex;
    align-items: center;
}
.invoice-two .file-link .print-link i {
    margin-right: 6px;
}
.invoice-two .file-link .print-link:hover {
    background: #4361ee;
    color: #fff;
}
@media (max-width: 992px) {
    .invoice-two .file-link {
        margin: 20px;
    }
}

.invoice-three .invoice-wrapper {
    padding: 50px;
}
.invoice-three .invoice-wrapper .inv-content {
    border: none;
}
.invoice-three .invoice-wrapper .inv-content .invoice-header {
    padding: 0 0 50px 0;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-header-left
    .tax-invoice {
    color: #7539ff;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-header-right
    img {
    max-width: 270px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .invoice-title {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    display: inline-block;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details {
    margin: 0 0 10px 0;
    padding: 0;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details:last-child {
    margin: 0;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details
    span {
    color: #000;
    font-weight: 450;
    font-family: "Circular Std";
    font-size: 16px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details:first-child
    span {
    font-size: 18px;
}
.invoice-three .invoice-wrapper .inv-content .invoice-address {
    padding: 0;
    border: 1px solid #bdbdbd;
    margin-bottom: 50px;
}
@media (max-width: 767px) {
    .invoice-three .invoice-wrapper .inv-content .invoice-address {
        display: flex;
    }
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-address
    .invoice-address-details {
    padding: 24px 0 24px 24px;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-address
    .invoice-details-content {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-address
    .invoice-details-content
    .invoice-status-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    border: 1px solid #bdbdbd;
    border-top: none;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-address
    .invoice-details-content
    .invoice-status-details
    span {
    display: block;
    margin-bottom: 15px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-address
    .invoice-details-content
    .invoice-status-details
    span:last-child {
    color: #7539ff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
}
@media print {
    .invoice-three
        .invoice-wrapper
        .inv-content
        .invoice-address
        .invoice-details-content
        .invoice-status-details
        span:last-child {
        font-size: 18px;
    }
}
.invoice-three .invoice-wrapper .inv-content .invoice-address .invoice-to span {
    margin: 0 0 15px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-address
    .invoice-to
    .inv-to-address {
    line-height: 24px;
}
.invoice-three .invoice-wrapper .inv-content .invoice-table {
    padding: 0;
    margin-bottom: 50px;
}
.invoice-three .invoice-wrapper .inv-content .invoice-table table {
    font-family: "Circular Std";
    border: 1px solid #bebebe;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table
    table
    .table_width_2 {
    width: 30%;
}
.invoice-three .invoice-wrapper .inv-content .invoice-table table tr {
    border-bottom: 1px solid #bebebe;
}
.invoice-three .invoice-wrapper .inv-content .invoice-table table thead tr {
    background: #fff;
}
.invoice-three .invoice-wrapper .inv-content .invoice-table table thead tr th {
    font-size: 18px;
    font-weight: 450;
    color: #000;
}
.invoice-three .invoice-wrapper .inv-content .invoice-table table td {
    vertical-align: middle;
    color: #2c3038;
    font-weight: 400;
    font-size: 16px;
    height: 80px;
    white-space: pre-wrap;
}
@media print {
    .invoice-three .invoice-wrapper .inv-content .invoice-table table td {
        height: 72px;
    }
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table
    table
    .unit-price-data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table
    table
    .unit-price-data
    span {
    color: #7539ff;
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
}
.invoice-three .invoice-wrapper .inv-content .invoice-table-footer {
    padding: 0;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right {
    width: 50%;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    tr
    td {
    width: 60%;
    color: #2c3038;
    padding: 7px 0;
    font-size: 18px;
    font-weight: 600;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    tr
    td.table-footer-right-title {
    color: #878a99;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-left {
    display: flex;
    flex-direction: column;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-left
    span {
    color: #2c3038;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-left
    span.total-item {
    color: #878a99;
}
.invoice-three .invoice-wrapper .inv-content .bank-details {
    padding: 50px 0;
}
.invoice-three .invoice-wrapper .inv-content .bank-details .account-info {
    display: flex;
    gap: 24px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .bank-details
    .account-info
    .qr-code
    img {
    margin-bottom: 10px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .bank-details
    .account-info
    .qr-code
    .scan-details {
    font-size: 14px;
    font-weight: 600;
}
.invoice-three .invoice-wrapper .inv-content .bank-details .account-details {
    margin: 0 0 10px;
}
.invoice-three
    .invoice-wrapper
    .inv-content
    .bank-details
    .account-details
    span {
    color: #000;
    font-weight: 450;
}
.invoice-three .invoice-wrapper .inv-content .invoice-notes {
    background: #ddf3ff;
    padding: 14px;
    border-radius: 10px;
    border: 1px solid #2da7ff;
}
.invoice-three .invoice-wrapper .inv-content .thanks-msg {
    padding: 40px 0 0 0;
}

.invoice-four .invoice-wrapper {
    padding: 24px;
}
.invoice-four .invoice-wrapper .inv-content {
    border: none;
}
.invoice-four .invoice-wrapper .inv-content .invoice-header {
    border: none;
    padding: 0px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-header-left
    .tax-invoice {
    color: #7539ff;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 15px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-header-right
    a
    img {
    text-align: right;
    max-width: 271px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-header
        .inv-header-right
        a
        img {
        max-width: 200;
    }
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .invoice-title {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    display: inline-block;
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-header
        .company-details
        .invoice-title {
        font-size: 20px;
    }
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details-four {
    margin: 0 0 10px 0;
    padding: 0;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details-four:last-child {
    font-size: 16px;
    margin: 0;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details-four
    span {
    color: #000;
    font-weight: 450;
    font-family: "Circular Std";
    font-size: 16px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .company-details
    .gst-details-four:first-child
    span {
    font-size: 18px;
}
.invoice-four .invoice-wrapper .inv-content .invoice-header .inv-date {
    width: 100%;
    float: right;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-date:last-child {
    margin-right: 0;
    margin-bottom: 20px;
}
.invoice-four .invoice-wrapper .inv-content .invoice-header .inv-details {
    position: relative;
}
.invoice-four .invoice-wrapper .inv-content .invoice-header .inv-date-no {
    font-size: 18px;
    color: #3f4254;
    background: linear-gradient(320deg, #dbecff 0%, #ddceff 100%);
    padding: 14px 20px 15px 87px;
}
@media (max-width: 767px) {
    .invoice-four .invoice-wrapper .inv-content .invoice-header .inv-date-no {
        padding: 0px;
    }
}
@media (max-width: 992px) {
    .invoice-four .invoice-wrapper .inv-content .invoice-header .inv-date-no {
        padding: 0px;
    }
}
.invoice-four .invoice-wrapper .inv-content .invoice-header .inv-date-no span {
    font-weight: 700;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-header
    .inv-date-no:last-child {
    margin-right: 0;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .invoice-four .invoice-wrapper .inv-content .invoice-header .inv-date-no {
        background: none;
    }
}
.invoice-four .invoice-wrapper .inv-content .invoice-header .triangle-right {
    width: 56px;
    height: 49px;
    border-top: 30px solid transparent;
    border-left: 58px solid #fff;
    border-bottom: 26px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-header
        .triangle-right {
        display: none;
    }
}
@media (max-width: 992px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-header
        .triangle-right {
        display: none;
    }
}
.invoice-four .invoice-wrapper .inv-content .invoice-address-four {
    margin: 24px 0;
    padding: 24px;
    border-radius: 20px;
    background-color: rgba(242, 242, 242, 0.24);
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
    border: 1px solid #e0e0e0;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to
    span {
    color: #878a99;
    margin: 0 0 15px;
    display: block;
    font-size: 18px;
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-address-four
        .invoice-to
        span {
        margin: 0 0 10px;
    }
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to
    .inv-to-address {
    color: #2c3038;
    font-size: 16px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to
    .invoice-to-payment {
    background: rgba(242, 242, 242, 0.5);
    border-radius: 10px;
    margin: 0;
    padding: 20px 0px;
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-address-four
        .invoice-to {
        margin-bottom: 20px;
    }
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to-payment {
    border-left: 1px solid #bdbdbd;
    margin: 0;
    padding: 24px 0px 24px 50px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to-payment
    span {
    color: #878a99;
    font-size: 18px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to-payment
    .inv-to-address {
    color: #54c1ff;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to-payment
    .inv-to-address
    h6 {
    margin: 0 0 50px 0;
    padding: 5px 18px;
    background-color: #ffe0bb;
    border-radius: 5px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .invoice-to-payment
    h5
    span {
    background-color: #ddf3ff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    color: #54c1ff;
    padding: 5px 18px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .company-details {
    text-align: right;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .company-details
    .company-name {
    font-size: 24px;
    color: #7638ff;
    margin: 0 0 10px;
    font-weight: 700;
    display: block;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .company-details
    .gst-details {
    margin: 0 0 10px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-address-four
    .company-details
    .gst-details
    span {
    color: #95979b;
    display: block;
}
@media print {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-address-four
        .company-details
        .gst-details {
        margin: 0 0 5px;
    }
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-address-four
        .company-details {
        text-align: left;
        margin: 0 0 20px;
    }
}
@media print {
    .invoice-four .invoice-wrapper .inv-content .invoice-address-four {
        font-size: 10px;
    }
}
@media (max-width: 992px) {
    .invoice-four .invoice-wrapper .inv-content .invoice-address-four {
        font-size: 14px;
    }
}
@media (max-width: 767px) {
    .invoice-four .invoice-wrapper .inv-content .invoice-address-four {
        display: flex;
        padding: 0px 30px;
    }
}
.invoice-four .invoice-wrapper .inv-content .invoice-table-details {
    margin: 0 0 30px 0;
    padding: 0 0 0;
}
.invoice-four .invoice-wrapper .inv-content .invoice-table-details table {
    font-family: "Circular Std";
    width: 100%;
    color: #2c3038;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    .table_width_1 {
    width: 3%;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    .table_width_2 {
    width: 20%;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    .table_width_3 {
    width: 40%;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    .table_width_4 {
    width: 15%;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    .table_width_5 {
    width: 15%;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    thead
    tr {
    background: #f2f2f2;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    thead
    tr
    th {
    font-size: 18px;
    font-weight: 450;
    color: #000;
}
.invoice-four .invoice-wrapper .inv-content .invoice-table-details table tr {
    border-bottom: 1px solid #bebebe;
}
.invoice-four .invoice-wrapper .inv-content .invoice-table-details table tr td,
.invoice-four .invoice-wrapper .inv-content .invoice-table-details table tr th {
    padding: 15px;
}
@media print {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-details
        table
        tr
        td,
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-details
        table
        tr
        th {
        padding: 8px;
    }
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-details
        table
        tr
        td,
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-details
        table
        tr
        th {
        white-space: nowrap;
    }
}
.invoice-four .invoice-wrapper .inv-content .invoice-table-details table tr td {
    vertical-align: middle;
    font-size: 16px;
    white-space: pre-wrap;
    height: 69px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-details
    table
    tr
    .table-description {
    width: 33%;
    color: #2c3038;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
}
.invoice-four .invoice-wrapper .inv-content .invoice-table-details table tr h6 {
    color: #7539ff;
    font-size: 12px;
}
@media print {
    .invoice-four .invoice-wrapper .inv-content .invoice-table-details {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-four .invoice-wrapper .inv-content .invoice-table-details {
        font-size: 14px;
    }
}
.invoice-four .invoice-wrapper .inv-content .invoice-table-footer {
    border: none;
    justify-content: space-between;
    padding: 5px 30px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-left {
    width: 70%;
}
@media (max-width: 575.98px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-left {
        width: auto;
        font-size: 14px;
    }
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right {
    width: 80%;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table {
    width: 100%;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    td {
    color: #2c3038;
    font-size: 18px;
    text-align: right;
    width: 50%;
    padding: 5px 10px;
}
@media print {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        width: auto;
    }
}
@media (max-width: 992px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td {
        width: auto;
    }
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    table
    span {
    color: #878a99;
}
@media print {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right
        table {
        font-size: 12px;
    }
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .invoice-table-footer
    .table-footer-right
    .totalamt-table
    td {
    color: #7539ff;
    font-size: 24px;
    font-weight: 700;
}
@media print {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table
        td {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table
        td {
        font-size: 16px;
    }
}
@media print {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right
        .totalamt-table {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right {
        width: 60%;
    }
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right {
        width: 100%;
    }
}
@media print {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .table-footer-right {
        width: 50%;
    }
}
@media print {
    .invoice-four .invoice-wrapper .inv-content .invoice-table-footer {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-four
        .invoice-wrapper
        .inv-content
        .invoice-table-footer
        .total-info {
        display: none;
    }
}
.invoice-four .invoice-wrapper .inv-content .totalamount-footer {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}
.invoice-four .invoice-wrapper .inv-content .total-amountdetails {
    padding: 0;
    border-bottom: 1px solid #e0e0e0;
}
.invoice-four .invoice-wrapper .inv-content .total-amountdetails p {
    text-align: center;
    font-size: 16px;
    margin: 0;
    padding: 20px 0;
}
.invoice-four .invoice-wrapper .inv-content .total-amountdetails span {
    color: #2c3038;
}
.invoice-four .invoice-wrapper .inv-content .bank-details {
    margin: 0;
    padding: 15px 30px;
    border-bottom: 1px solid #f2f2f2;
}
.invoice-four .invoice-wrapper .inv-content .bank-details .payment-info {
    display: flex;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    .qr-code {
    margin-right: 24px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    .qr-code
    .scan-details {
    font-size: 13px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    .qr-code
    .img {
    max-width: 140px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    .debitcard {
    color: #000;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    .payment-title {
    color: #2c3038;
    font-size: 18px;
}
.invoice-four
    .invoice-wrapper
    .inv-content
    .bank-details
    .payment-info
    .debit-card {
    margin-top: 15px;
    margin-bottom: 10px;
}
.invoice-four .invoice-wrapper .inv-content .bank-details .payment-info span {
    color: #95979b;
}
.invoice-four .invoice-wrapper .inv-content .terms-condition {
    margin: 0;
    padding: 0 0;
}
.invoice-four .invoice-wrapper .inv-content .terms-condition ol {
    list-style: number;
    padding-left: 13px;
    color: #878a99;
}
.invoice-four .invoice-wrapper .inv-content .terms-condition span {
    display: block;
    margin: 0 0 15px;
    color: #2c3038;
}
@media print {
    .invoice-four .invoice-wrapper .inv-content .terms-condition {
        font-size: 12px;
        margin: 0;
        padding: 10px 30px;
    }
}
@media (max-width: 575.98px) {
    .invoice-four .invoice-wrapper .inv-content .terms-condition {
        font-size: 14px;
    }
}
.invoice-four .invoice-wrapper .inv-content .thanks-msg {
    color: #000000;
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;
    background: #f2f2f2;
    margin: 0;
    padding: 15px 0;
}
@media print {
    .invoice-four .invoice-wrapper .inv-content .thanks-msg {
        font-size: 10px;
        margin: 0;
        padding: 10px;
    }
}
@media (max-width: 575.98px) {
    .invoice-four .invoice-wrapper .inv-content .thanks-msg {
        font-size: 12px;
    }
}

.file-link-four {
    margin-bottom: 40px;
}
.file-link-four .download-link {
    color: #7539ff;
    margin: 0px;
    padding: 12px 22px;
    margin-right: 10px;
    border: 1px solid #7638ff;
    border-radius: 5px;
    background: #fff;
    height: 46px;
}
.file-link-four .download-link:hover {
    background: #7638ff;
    color: #fff;
}
.file-link-four .print-link {
    color: #7539ff;
    margin: 0 0 0 0;
    padding: 15px 22px;
    border: 1px solid #4361ee;
    border-radius: 5px;
    height: 46px;
    background: #fff;
    display: flex;
    align-items: center;
}
.file-link-four .print-link i {
    margin-right: 6px;
}
.file-link-four .print-link:hover {
    background: #4361ee;
    color: #fff;
}
@media (max-width: 992px) {
    .file-link-four {
        margin: 20px;
    }
}

.invoice-five.invoice-wrapper {
    padding: 50px;
}
@media (max-width: 767px) {
    .invoice-five.invoice-wrapper {
        padding: 15px;
    }
}
@media print and (max-width: 767px) {
    .invoice-five.invoice-wrapper {
        padding: 0;
    }
}
.invoice-five .inv-content {
    background: #fafafa;
    padding: 24px;
    margin-bottom: 40px;
}
@media (max-width: 767px) {
    .invoice-five .inv-content {
        padding: 15px;
    }
}
.invoice-five .inv-content .invoice-header {
    border-bottom: 0;
    background: transparent;
    padding: 0;
}
.invoice-five .inv-content .invoice-header .inv-header-right h2 {
    font-family: "Circular Std";
    color: #7539ff;
    font-size: 24px;
    font-weight: 700;
}
.invoice-five .inv-content .invoice-five-details {
    gap: 24px;
    padding: 50px 0;
}
@media (max-width: 767px) {
    .invoice-five .inv-content .invoice-five-details {
        display: block;
    }
}
.invoice-five .inv-content .invoice-five-details .gradient-block {
    width: 729px;
    height: 36px;
    background: linear-gradient(320deg, #ddceff 0%, #dbecff 100%);
    border-radius: 59px;
}
@media (max-width: 1399.98px) {
    .invoice-five .inv-content .invoice-five-details .gradient-block {
        width: 550px;
    }
}
@media (max-width: 991.98px) {
    .invoice-five .inv-content .invoice-five-details .gradient-block {
        width: 300px;
    }
}
@media (max-width: 767px) {
    .invoice-five .inv-content .invoice-five-details .gradient-block {
        width: 100%;
        margin-bottom: 15px;
    }
}
.invoice-five .inv-content .invoice-five-details .invoice-date {
    gap: 50px;
}
.invoice-five
    .inv-content
    .invoice-five-details
    .invoice-date
    .invoice-date-details {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}
.invoice-five
    .inv-content
    .invoice-five-details
    .invoice-date
    .invoice-date-details
    span {
    font-weight: 400;
}
.invoice-five .invoice-table-details {
    margin: 30px 0 30px 0;
    padding: 0 0 0;
}
.invoice-five .invoice-table-details table {
    width: 100%;
    color: #2c3038;
    border: 1px solid var(--gray-gray-3, #bebebe);
}
.invoice-five .invoice-table-details table .table_width_1 {
    width: 3%;
}
.invoice-five .invoice-table-details table .table_width_2 {
    width: 20%;
}
.invoice-five .invoice-table-details table .table_width_3 {
    width: 40%;
}
.invoice-five .invoice-table-details table .table_width_4 {
    width: 15%;
}
.invoice-five .invoice-table-details table .table_width_5 {
    width: 15%;
}
.invoice-five .invoice-table-details table thead tr {
    color: #000;
}
.invoice-five .invoice-table-details table .ecommercetable {
    background: #e0e0e0;
}
.invoice-five .invoice-table-details table thead tr {
    background: #f2f2f2;
}
.invoice-five .invoice-table-details table thead tr th {
    font-size: 18px;
    font-weight: 450;
    color: #000;
}
.invoice-five .invoice-table-details table tr {
    border-bottom: 1px solid #bebebe;
}
.invoice-five .invoice-table-details table tr td,
.invoice-five .invoice-table-details table tr th {
    padding: 15px;
}
@media print {
    .invoice-five .invoice-table-details table tr td,
    .invoice-five .invoice-table-details table tr th {
        padding: 8px;
    }
}
@media (max-width: 767px) {
    .invoice-five .invoice-table-details table tr td,
    .invoice-five .invoice-table-details table tr th {
        white-space: nowrap;
    }
}
.invoice-five .invoice-table-details table tr td {
    height: 69px;
    vertical-align: middle;
    white-space: pre-wrap;
}
.invoice-five .invoice-table-details table tr .table-description {
    width: 33%;
    color: #2c3038;
    font-size: 18px;
    font-weight: 400;
}
@media (max-width: 1399.98px) {
    .invoice-five .invoice-table-details table tr .table-description {
        font-size: 16px;
    }
}
.invoice-five .invoice-table-details table tr h6 {
    color: #7539ff;
    font-size: 12px;
}
@media print {
    .invoice-five .invoice-table-details {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-five .invoice-table-details {
        font-size: 14px;
    }
}
.invoice-five .invoice-table-footer {
    padding: 5px 0px;
}
.invoice-five .invoice-table-footer .table-footer-left {
    width: 70%;
}
@media (max-width: 575.98px) {
    .invoice-five .invoice-table-footer .table-footer-left {
        width: auto;
        font-size: 14px;
    }
}
.invoice-five .invoice-table-footer .table-footer-right {
    width: 80%;
}
.invoice-five .invoice-table-footer .table-footer-right table {
    width: 100%;
}
.invoice-five .invoice-table-footer .table-footer-right table td {
    color: #2c3038;
    font-size: 18px;
    text-align: right;
    width: 50%;
    padding: 5px 10px;
    font-weight: 500;
}
@media print {
    .invoice-five .invoice-table-footer .table-footer-right table td {
        width: auto;
    }
}
@media (max-width: 1399.98px) {
    .invoice-five .invoice-table-footer .table-footer-right table td {
        font-size: 16px;
    }
}
@media (max-width: 992px) {
    .invoice-five .invoice-table-footer .table-footer-right table td {
        width: auto;
        font-size: 15px;
    }
}
@media (max-width: 767px) {
    .invoice-five
        .invoice-table-footer
        .table-footer-right
        table
        td:first-child {
        text-align: left;
    }
}
.invoice-five .invoice-table-footer .table-footer-right table td.tax {
    color: #878a99;
    font-weight: 600;
}
.invoice-five .invoice-table-footer .table-footer-right table span {
    color: #878a99;
}
@media print {
    .invoice-five .invoice-table-footer .table-footer-right table {
        font-size: 12px;
    }
}
.invoice-five .invoice-table-footer .table-footer-right .totalamt-table td {
    color: #2c3038;
    font-size: 24px;
    font-weight: 700;
}
@media print {
    .invoice-five .invoice-table-footer .table-footer-right .totalamt-table td {
        font-size: 12px;
    }
}
@media (max-width: 1399.98px) {
    .invoice-five .invoice-table-footer .table-footer-right .totalamt-table td {
        font-size: 20px;
    }
}
@media (max-width: 992px) {
    .invoice-five .invoice-table-footer .table-footer-right .totalamt-table td {
        font-size: 16px;
    }
}
@media print {
    .invoice-five .invoice-table-footer .table-footer-right .totalamt-table {
        font-size: 12px;
    }
}
@media (max-width: 992px) {
    .invoice-five .invoice-table-footer .table-footer-right {
        width: 60%;
    }
}
@media (max-width: 767px) {
    .invoice-five .invoice-table-footer .table-footer-right {
        width: 100%;
    }
}
@media print {
    .invoice-five .invoice-table-footer .table-footer-right {
        width: 50%;
    }
}
@media print {
    .invoice-five .invoice-table-footer {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .invoice-five .invoice-table-footer .total-info {
        display: none;
    }
}
.invoice-five .inv-content .bank-details {
    margin: 0;
    padding: 0;
    border-bottom: 0;
    margin-top: 50px;
}
@media (max-width: 767px) {
    .invoice-five .inv-content .bank-details .bank-qr-detail img {
        max-width: 90px;
    }
}
.invoice-five .inv-content .invoice-header .inv-header-left {
    width: 54%;
}
@media (max-width: 1199.98px) {
    .invoice-five .inv-content .invoice-header .inv-header-left {
        width: 56%;
    }
}
@media (max-width: 767px) {
    .invoice-five .inv-content .invoice-header .inv-header-left {
        width: 100%;
        margin-bottom: 15px;
    }
}
.invoice-five .inv-content .invoice-header .inv-header-left h4 {
    color: #7638ff;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 15px;
}
.invoice-five .inv-content .invoice-header .inv-header-right p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: #000000;
    font-weight: 700;
    display: flex;
    align-items: center;
}
@media (max-width: 992px) {
    .invoice-five .inv-content .invoice-header .inv-header-right p {
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .invoice-five .inv-content .invoice-header .inv-header-right p {
        font-size: 15px;
    }
}
@media (max-width: 767px) {
    .invoice-five .inv-content .invoice-header .inv-header-right p {
        flex-direction: unset;
    }
}
.invoice-five .inv-content .invoice-header .inv-header-right p span {
    display: flex;
    margin: 5px 0;
    color: #000000;
}
.invoice-five .inv-content .invoice-header .company-details {
    position: relative;
    overflow: hidden;
}
.invoice-five .inv-content .invoice-header .company-details .gst-details {
    position: relative;
    margin: 0;
    padding: 30px 30px;
    color: #fff;
    z-index: 2;
}
.invoice-five .inv-content .invoice-header .company-details .gst-details span {
    color: #3f4254;
    font-size: 16px;
    font-weight: 450;
}
@media (max-width: 992px) {
    .invoice-five
        .inv-content
        .invoice-header
        .company-details
        .gst-details
        span {
        color: #000;
    }
}
@media (max-width: 992px) {
    .invoice-five .inv-content .invoice-header .company-details .gst-details {
        color: #000;
        margin: 0;
        padding: 10px 0px;
    }
}
@media (max-width: 767px) {
    .invoice-five .inv-content .invoice-header .company-details .gst-details {
        margin: 0;
        padding: 10px 0px;
    }
}
.invoice-five .inv-content .invoice-header .company-details .address-bg {
    position: absolute;
    width: 473.11px;
    height: 126px;
    top: 0px;
    right: 15px;
    overflow: hidden;
    border-radius: 14px 77px 14px 14px;
    background: var(
        --color-gradient,
        linear-gradient(320deg, #ddceff 0%, #dbecff 100%)
    );
    background-color: #7638ff;
}
@media (max-width: 992px) {
    .invoice-five .inv-content .invoice-header .company-details .address-bg {
        right: 58px;
    }
}
.invoice-five .terms-condition {
    margin-bottom: 40px;
}
.invoice-five .terms-condition ol li {
    color: #2c3038;
}
.invoice-five .thanks-msg {
    margin-bottom: 40px;
}
@media (max-width: 991.98px) {
    .invoice-five .file-link {
        margin: 0;
    }
}
.invoice-five .file-link a {
    color: #7539ff;
    font-size: 18px;
    font-weight: 500;
}
@media (max-width: 1399.98px) {
    .invoice-five .file-link a {
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .invoice-five .file-link a {
        font-size: 15px;
    }
}

.invoice-address-details.invoice-five-group .invoice-to span {
    margin-bottom: 15px;
    display: block;
}
.invoice-address-details.invoice-five-group .invoice-to .inv-to-address {
    color: #2c3038;
}
.invoice-address-details.invoice-five-group .invoice-to:last-child {
    text-align: right;
}
@media (max-width: 767px) {
    .invoice-address-details.invoice-five-group .invoice-to:last-child {
        margin-top: 15px;
    }
}

.company-booking-address.company-five-address {
    margin-bottom: 24px;
}
.company-booking-address.company-five-address .company-add span {
    margin-bottom: 15px;
    display: block;
}
.company-booking-address.company-five-address .company-add h4 {
    color: #2c3038;
    margin-bottom: 0;
}
.company-booking-address.company-five-address .company-add h5 {
    color: #878a99;
    margin-bottom: 15px;
    font-size: 18px;
}
.company-booking-address.company-five-address
    .company-add
    .paid-status-add
    span {
    color: #878a99;
    margin-bottom: 5px;
    display: block;
}
.company-booking-address.company-five-address .company-add .paid-status-add h6 {
    color: #33b469;
    font-size: 16px;
}
@media (max-width: 767px) {
    .company-booking-address.company-five-address .company-add {
        margin-bottom: 15px;
    }
}
.company-booking-address.company-five-address .invoice-date {
    text-align: right;
}
.company-booking-address.company-five-address .invoice-date p {
    color: #2c3038;
}
.company-booking-address.company-five-address .invoice-date p span {
    color: #878a99;
}

.total-amt-word tr td span {
    display: block;
    color: #2c3038;
    font-weight: 500;
}
@media (max-width: 767px) {
    .total-amt-word tr td {
        display: none;
    }
}

.tab-content .blog-image > a {
    padding: 5px;
}

.bg-subscribe {
    background-color: #ffecec;
    color: #ed3a3a;
    padding: 4px 10px;
    border-radius: 4px;
}

.bg-payment {
    background-color: #e9f2ff;
    color: #2465bc;
    padding: 4px 10px;
    border-radius: 4px;
}

.btn-primary .fa-plus {
    margin-right: 5px;
}

.import-company .btn-import {
    color: #7539ff;
    border: 1px solid #7539ff;
}
.import-company .btn-import:hover {
    background-color: #7539ff;
    color: #fff;
}

.companies-table .table tbody td h2.table-avatar a {
    color: #1f0066;
}

.companies-table .table tbody td h2 span {
    color: #808080;
}

.card-table .companies-table .table td {
    color: #1f0066;
}

.companies-table .dropdown-item .far.fa-trash-alt {
    border-bottom: 1px solid #e0e0e0;
}

.page-content .list-btn .btn-filters:hover {
    background-color: #eaddff;
}

.companies-table .table .dropdown-menu {
    width: 176px;
}

.companies-table .table p {
    color: #808080;
}

.avatar-img.rounded-circle .company {
    height: 40px;
    width: 40px;
}

.companies-table .table tbody td h2.table-avatar .companies {
    height: 40px;
    width: 40px;
    display: inline-block;
}

.p-20 .modal-header {
    padding: 20px;
    justify-content: space-between;
}
.p-20 .modal-body,
.p-20 .modal-footer {
    padding: 0 20px 20px 20px;
}

#add_package .modal-body h6 {
    color: #808080;
    margin-bottom: 10px;
}
#add_package .modal-body ul {
    margin: 0;
    border: none;
    background: #f7f8f9;
    border-radius: 10px;
}
@media (max-width: 767px) {
    #add_package .modal-body ul li {
        padding-right: 0;
        padding-left: 0;
    }
}
#add_package .modal-body ul li:first-child {
    padding-left: 0;
}
#add_package .modal-body ul li:last-child {
    padding-right: 0;
}
#add_package .modal-body ul li h6 {
    padding: 13px 0;
    margin-bottom: 0;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #333;
    font-size: 16px;
    font-weight: 500;
}
#add_package .modal-body ul li h6.active,
#add_package .modal-body ul li h6:hover {
    background: #7539ff;
    color: #fff;
}
#add_package .modal-body .tab-content .form-check {
    padding-left: 0;
}
#add_package .modal-body .tab-content .form-check label {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 10px 10px 10px 30px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
}
#add_package .modal-body .tab-content .form-check label input {
    min-width: 18px;
    height: 18px;
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
    margin-right: 12px;
}
#add_package .modal-body .tab-content .form-check label input:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background: #7539ff;
    display: none;
}
#add_package .modal-body .tab-content .form-check label input:focus {
    box-shadow: none;
}
#add_package .modal-body .tab-content .form-check label input:checked {
    background: transparent;
    border-color: #7539ff;
}
#add_package .modal-body .tab-content .form-check label input:checked:after {
    display: block;
}
#add_package .modal-body .tab-content .form-check label .text-dark {
    font-weight: 500;
}

.bootstrap-datetimepicker-widget .timepicker td a {
    padding: 0;
}
.bootstrap-datetimepicker-widget .timepicker td a span {
    padding: 0;
}
.bootstrap-datetimepicker-widget .timepicker td a span:hover {
    background: #28084b;
}
.bootstrap-datetimepicker-widget .datepicker th,
.bootstrap-datetimepicker-widget .datepicker td {
    text-align: center;
    padding: 5px 6px;
    font-size: 12px;
}
.bootstrap-datetimepicker-widget .datepicker td.active {
    background: #7539ff;
}
.bootstrap-datetimepicker-widget .datepicker td.active:hover {
    background: #28084b;
}

span.bg-purple {
    background: rgb(89, 42, 198);
}

.company-avatar.company-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgb(224, 224, 224);
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

.super-admin-list-head {
    border-bottom: 1px solid rgb(224, 224, 224);
    margin-bottom: 24px;
}
.super-admin-list-head .grid-info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.super-admin-list-head .grid-info-item h4 {
    font-weight: 600;
    margin-top: 5px;
}
.super-admin-list-head .grid-info-item span {
    color: #0f0033;
    font-weight: 600;
}
.super-admin-list-head .grid-info-item .grid-head-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    border-radius: 10px;
    background: #ffefdd;
    color: #ec8000;
    flex-shrink: 0;
}
.super-admin-list-head .grid-info-item.total-items h4 {
    color: #ec8000;
}
.super-admin-list-head .grid-info-item.active-items h4 {
    color: rgb(30, 142, 223);
}
.super-admin-list-head .grid-info-item.active-items .grid-head-icon {
    background: #ddf3ff;
    color: rgb(30, 142, 223);
}
.super-admin-list-head .grid-info-item.inactive-items h4 {
    color: #ed3a3a;
}
.super-admin-list-head .grid-info-item.inactive-items .grid-head-icon {
    background: #ffecec;
    color: #ed3a3a;
}
.super-admin-list-head .grid-info-item.location-info h4 {
    color: #1d8147;
}
.super-admin-list-head .grid-info-item.location-info .grid-head-icon {
    background: #e1ffed;
    color: #1d8147;
}

.custom-modal.custom-lg-modal .modal-dialog {
    max-width: 800px;
}

.profile-img.company-profile-img {
    width: 60px;
    height: 60px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.input-block .modal-password-field .toggle-password {
    transform: translateY(-50%);
    right: 15px;
}

.toggle-password-two {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    color: #757575;
}

.url-text-box {
    position: relative;
}
.url-text-box input {
    padding-right: 115px !important;
}
.url-text-box .url-text {
    background: #7539ff;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    line-height: normal;
    position: absolute;
    right: 5px;
    top: 7px;
}

.company-detail-head {
    justify-content: space-between;
    background: #f7f8f9;
    padding: 15px;
    border-radius: 10px;
}
.company-detail-head .company-profile-img {
    background: #fff;
}
.company-detail-head .upload-profile {
    margin-right: 0;
}
.company-detail-head .upload-profile .add-profile h5 {
    font-size: 18px;
    font-weight: 700;
}

.plane-basic-info h5 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 600;
}
.plane-basic-info .basic-info-detail {
    margin-bottom: 24px;
}
.plane-basic-info .basic-info-detail h6 {
    font-size: 14px;
    margin-bottom: 5px;
}
.plane-basic-info.plane-detail {
    border-top: 1px solid #e0e0e0;
    padding-top: 24px;
}

.details-edit-link {
    align-items: center;
}
.details-edit-link .modal-edit-link {
    color: #808080;
}

.delete-modal-icon span {
    width: 64px;
    height: 64px;
    background: #1d8147;
    color: #fff;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 24px;
}

.list-btn .filter-list li a.active {
    background: #eaddff;
}

td span.plane-type {
    color: rgb(72, 72, 72);
}

.badge.bg-orange-light {
    background: #ffefdd;
    color: #ec8000;
}

table th.no-sort::before {
    display: none !important;
}
table th.no-sort::after {
    display: none !important;
}

.modal-header .bg-info-light {
    background: #ddf3ff;
    color: #2da7ff;
    font-weight: normal;
}

.company-detail-head span.badge {
    font-weight: normal;
    line-height: normal;
}

#checkBoxes1,
#checkBoxes {
    position: relative;
    height: 100%;
}

.search-dropdown-item {
    opacity: 0;
    position: absolute;
    right: 0;
    transition: 0.5s all;
    border: 1px solid #e0e0e0;
    background: #fff;
    padding: 24px;
    padding-bottom: 0;
    z-index: 9;
    border-radius: 10px;
    top: 0;
    display: none;
}
.search-dropdown-item.show {
    opacity: 1;
    transition: 0.5s all;
    display: block;
    top: 40px;
    display: block;
}

.btn.btn-greys.bg-history-light {
    background-color: #ffefdd;
    color: #ec8000;
}
.btn.btn-greys.bg-history-light:hover {
    background-color: #ffefdd;
    color: #ec8000;
}

.card-table .card-body .inventory-table .table td {
    color: #1f0066;
}

.table tbody td h2.table-avatar a {
    display: block;
}

.customer-table .btn-greys {
    background-color: #efefef;
    color: #7539ff;
}
.customer-table .btn-greys:hover {
    background-color: #efefef;
    border: 1px solid #efefef;
    color: #7539ff;
}

.customer-table .table tbody td h2.table-avatar a {
    color: #1f0066;
}

.companies-table.Ledger .table tbody tr td {
    height: 36px;
}

.companies-table.Ledger .dataTables_length {
    display: none;
}

.companies-table.Ledger div.dataTables_wrapper div.dataTables_paginate {
    display: none;
}

.content-page-header.add {
    margin: 0;
}

.page-header.add {
    margin: 0;
    padding: 20px 20px 0;
}

.modal-footer.add i {
    margin-right: 5px;
}

.sidebar-header.ledge {
    border: 0;
}

.sidebar-header.submenu {
    padding: 0 20px 20px;
}

.accordion .modal-footer {
    padding: 20px 0;
}

.toggle-sidebar.ledge .sidebar-body .accordion {
    border: 0;
    margin-bottom: 0;
}

.card-table .card-body .customer-table .table td {
    height: 40px;
}

.modal-footer.add .btn-success:hover {
    color: #33b469;
    background-color: #fff;
    border: 1px solid #33b469;
}

.modal-footer.add .btn-danger:hover {
    color: #ed3a3a;
    background-color: #fff;
    border: 1px solid #ed3a3a;
}

#add_ledger .add-header h6 {
    font-size: 16px;
}
#add_ledger .add-header p {
    font-weight: 500;
}
#add_ledger .table tbody td h2 span {
    font-size: 12px;
    /*color: $__gray__white__light; */
}

.ledge .cal-icon-info:after {
    top: 5px;
}
.ledge .notes-form-group-info textarea.form-control {
    font-size: 14px;
}

.bg-pending-light {
    background-color: #fff6dc;
    color: #c99700;
}

.card-table .card-body .purchase-table .table td {
    height: 40px;
}

.bg-inactive {
    background-color: #ffecec;
    color: #ed3a3a;
    padding: 4px 10px;
    border-radius: 4px;
}

.avatar-img.rounded-circle.subscription-circle {
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.table tbody td h2.table-avatar .subscription-img {
    height: 40px;
    width: 40px;
    display: inline-block;
}

.packages {
    padding: 24px;
    border: 1px solid #eff0f6;
    box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
    margin-bottom: 24px;
    border-radius: 24px;
}
.packages .recommend-text {
    background: #ec8000;
    color: #fff;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 14px;
    position: absolute;
    right: 24px;
    top: -10px;
}
.packages.package-selected {
    margin: 0;
    padding: 39px 24px;
    border-radius: 24px;
    background: #7539ff;
}
.packages.package-selected h6,
.packages.package-selected h4,
.packages.package-selected p,
.packages.package-selected h2,
.packages.package-selected ul li,
.packages.package-selected ul li i {
    color: #fff;
}
.packages.package-selected .package-header h6 {
    color: #fff;
}
.packages.package-selected .package-header .btn-action-icon:hover {
    border-color: #fff;
}
.packages.package-selected .btn-primary {
    background: #fff;
    border-color: #fff;
    color: #7539ff;
}
.packages.package-selected .btn-primary:hover {
    box-shadow: inset 0 50px 0 0 #7539ff;
    border-color: #fff;
    color: #fff;
}
.packages .package-header {
    margin-bottom: 15px;
}
.packages .package-header .icon-frame {
    background: #ecebff;
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 16px;
}
@media (max-width: 1199.98px) {
    .packages .package-header .icon-frame {
        min-width: 50px;
        height: 50px;
    }
    .packages .package-header .icon-frame img {
        width: 25px;
    }
}
@media (max-width: 1199.98px) {
    .packages .package-header .icon-frame {
        min-width: 70px;
        height: 70px;
    }
    .packages .package-header .icon-frame img {
        width: 38px;
    }
}
.packages .package-header h6 {
    color: #808080;
    margin-bottom: 5px;
}
.packages .package-header h4 {
    font-weight: 700;
}
@media (max-width: 1199.98px) {
    .packages .package-header h4 {
        font-size: 20px;
    }
}
@media (max-width: 991.98px) {
    .packages .package-header h4 {
        font-size: 24px;
    }
}
.packages .package-header .btn-action-icon {
    min-width: 30px;
    height: 30px;
    background: #f3f3f3;
    border-radius: 100%;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.packages .package-header .btn-action-icon i {
    color: #808080;
}
.packages .package-header .btn-action-icon:hover {
    background: #7539ff;
    border-color: #7539ff;
}
.packages .package-header .btn-action-icon:hover i {
    color: #fff;
}
.packages p {
    font-size: 14px;
    color: #808080;
}
.packages h2 {
    margin: 24px 0;
    font-size: 36px;
    font-weight: 700;
    color: #7539ff;
}
.packages h6 {
    font-weight: 600;
    margin-bottom: 18px;
}
.packages ul {
    border-bottom: 1px solid rgb(224, 224, 224);
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.packages ul li {
    color: #808080;
    margin-bottom: 14px;
}
.packages ul li i {
    color: #7539ff;
    margin-right: 8px;
    font-size: 16px;
}
.packages ul li:last-child {
    margin-bottom: 0;
}
.packages .active-btn {
    margin-top: 34px;
}
.packages .active-btn a {
    font-size: 16px;
}

#add_newpackage .modal-dialog {
    max-width: 800px;
}
#add_newpackage h6 {
    margin-bottom: 20px;
}
#add_newpackage form .checkboxes {
    margin: 0 0 24px 0;
    padding: 0;
}
#add_newpackage form .checkboxes label {
    display: flex;
    align-items: center;
    margin: 0;
}
#add_newpackage form .checkboxes label input {
    border: 1px solid #e0e0e0;
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
    font-size: 16px;
}
#add_newpackage form .checkboxes label input:checked {
    accent-color: #7539ff;
    border-color: #592ac6;
}
#add_newpackage form .status-toggle {
    margin-left: 24px;
}
#add_newpackage form .status-toggle .checktoggle {
    transform: translate(0);
}

#edit_package .modal-dialog {
    max-width: 800px;
}
#edit_package h6 {
    margin-bottom: 20px;
}
#edit_package form .checkboxes {
    margin: 0 0 24px 0;
    padding: 0;
}
#edit_package form .checkboxes label {
    display: flex;
    align-items: center;
    margin: 0;
}
#edit_package form .checkboxes label input {
    border: 1px solid #e0e0e0;
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
    font-size: 16px;
}
#edit_package form .checkboxes label input:checked {
    accent-color: #7539ff;
    border-color: #592ac6;
}
#edit_package form .status-toggle {
    margin-left: 24px;
}
#edit_package form .status-toggle .checktoggle {
    transform: translate(0);
}

.grid-info-item.total-plane .grid-head-icon {
    background: #ec8000;
    color: #fff;
}
.grid-info-item.active-plane .grid-head-icon {
    background: #1d8147;
    color: #fff;
}
.grid-info-item.inactive-plane .grid-head-icon {
    background: #ed3a3a;
    color: #fff;
}
.grid-info-item.total-type .grid-head-icon {
    background: rgb(30, 142, 223);
    color: #fff;
}

.subscription-plane-head ul {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgb(224, 224, 224);
    margin-bottom: 24px;
    padding-bottom: 5px;
}
.subscription-plane-head ul li a {
    color: rgb(128, 128, 128);
    position: relative;
}
.subscription-plane-head ul li a.active {
    color: #7539ff;
    padding-bottom: 8px;
}
.subscription-plane-head ul li a.active::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #7539ff;
    position: absolute;
    left: 0;
    bottom: 0;
}
.subscription-plane-head ul li + li {
    margin-left: 24px;
}

.package-edit a {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(243, 243, 243);
    color: rgb(72, 72, 72);
    border-radius: 20px;
    transition: 0.5s all;
}
.package-edit a:hover {
    background: #7539ff;
    color: #fff;
    transition: 0.5s all;
}

.no-pagination .dataTables_length {
    display: none;
}
.no-pagination .dataTables_paginate {
    display: none;
}

.grid-info-item.subscription-list {
    justify-content: flex-start;
}
.grid-info-item.subscription-list .grid-head-icon {
    margin-right: 15px;
}
.grid-info-item.subscription-list.total-transaction .grid-head-icon {
    background: rgb(30, 142, 223);
    color: #fff;
}
.grid-info-item.subscription-list.total-subscriber .grid-head-icon {
    background: #ec8000;
    color: #fff;
}
.grid-info-item.subscription-list.active-subscriber .grid-head-icon {
    background: #1d8147;
    color: #fff;
}
.grid-info-item.subscription-list.expired-subscriber .grid-head-icon {
    background: #ed3a3a;
    color: #fff;
}

td .invoice-detail i {
    color: rgb(236, 128, 0);
    font-size: 20px;
}

.card-body.two-factor .content-page-header.factor {
    margin: 0 0 20px 0;
}
.card-body.two-factor .two-factor.content h5 {
    margin: 0 0 5px 0;
    font-size: 16px;
}
.card-body.two-factor .two-factor.content p {
    margin: 0 0 30px 0;
    font-size: 16px;
}
.card-body.two-factor .two-factor.icon h5 {
    padding: 30px 0 0px 0;
    font-size: 16px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
}
.card-body.two-factor .two-factor.icon h5 img {
    margin-right: 7px;
}
.card-body.two-factor .status-toggle .checktoggle.factor {
    transform: none;
}
.card-body.two-factor .factor-checkbox {
    display: flex;
    text-align: right;
    justify-content: flex-end;
}
@media (max-width: 575.98px) {
    .card-body.two-factor .factor-checkbox {
        text-align: left;
        justify-content: flex-start;
        margin-bottom: 30px;
    }
}

.preference-title h5 {
    font-size: 25px;
    margin-bottom: 32px;
}

#plan-billing-slider .owl-stage-outer {
    padding-top: 10px;
}
#plan-billing-slider .owl-item img {
    width: auto;
}
#plan-billing-slider .owl-item .packages {
    box-shadow: none;
    border-color: #7539ff;
    background: #eaddff;
}
#plan-billing-slider .owl-item .packages .package-header {
    margin-bottom: 0;
}
@media (max-width: 575.98px) {
    #plan-billing-slider .owl-item .packages .package-header .icon-frame {
        margin-bottom: 20px;
    }
}
#plan-billing-slider .owl-item .packages .package-header h5 {
    font-size: 18px;
}
#plan-billing-slider .owl-item .packages .package-header p {
    font-weight: 500;
    margin-bottom: 20px;
}
#plan-billing-slider .owl-item .packages .package-header span {
    font-weight: 500;
    color: #7539ff;
}
#plan-billing-slider .owl-item .packages.active {
    border-color: rgba(0, 0, 0, 0.2);
    background: #fff;
    position: relative;
}
#plan-billing-slider .owl-item .packages.active a {
    color: #2da7ff;
}
#plan-billing-slider .owl-item .packages.active:before {
    position: absolute;
    top: -4px;
    right: -11px;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    background: #fff;
    content: "";
    z-index: 1;
}
#plan-billing-slider .owl-item .packages.active:after {
    position: absolute;
    top: -12px;
    right: -12px;
    content: "\f058";
    font-family: "Font Awesome 6 Free";
    color: #19a855;
    font-size: 24px;
    font-weight: 900;
    z-index: 1;
}
#plan-billing-slider .owl-dots {
    text-align: center;
}
#plan-billing-slider .owl-dots .owl-dot {
    margin-bottom: 24px;
}
#plan-billing-slider .owl-dots .owl-dot span {
    width: 23px;
    height: 5px;
    margin-right: 10px;
    border-radius: 5px;
    display: block;
    background: #d9d9d9;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
#plan-billing-slider .owl-dots .owl-dot.active span {
    background: #7539ff;
}

.table-plan-billing .dataTables_length,
.table-plan-billing .dataTables_paginate {
    display: none;
}

.avatar-img.rounded-circle.report-circle {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid #7539ff;
}

.table tbody td h2.table-avatar .report-img {
    height: 40px;
    width: 40px;
    display: inline-block;
}

.list-btn .btn-filters.form-date {
    padding: 10px;
}

.btn-path.tax-purchase {
    margin: 0 10px 32px;
}
.btn-path.tax-purchase .btn-gray {
    margin: 0 0 0 10px;
}
@media (max-width: 575.98px) {
    .btn-path.tax-purchase .btn-gray {
        display: inline-block;
        margin: 10px 0 0 0;
    }
}

.from-to-date .form-control.tax {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    direction: rtl;
    width: 130px;
    padding: 9px 8px 9px 12px;
}
.from-to-date .form-control.taxes {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    width: 98px;
    padding: 9px 12px 9px 0;
}

.cal-icon-info.profitloss:after {
    left: 10px;
}

.filter-img-top {
    width: 73px;
}

.btn-primary.profit-loss {
    display: block;
    margin-top: 30px;
}

.card-table .profit-table .table {
    background: #fff;
    margin-bottom: 0px !important;
    border-radius: 8px;
}

.table-profit-loss thead {
    background-color: #f7f8f9;
}
.table-profit-loss tr td h6 {
    font-size: 14px;
}

.table-bg {
    background: #f7f8f9;
    border-top: 1px solid #e0e0e0;
}

.card-table .card-body .table .profitpadding {
    padding-left: 10px !important;
}

.card-table .card-body .table .table-profit-head {
    padding-left: 15px !important;
    background: #fff;
}

.card-table .card-body .companies-table.profit-border .table td {
    border: 0;
}

.ledger-list {
    color: #1f0066;
}
.ledger-list a {
    color: #1f0066;
}
.ledger-list h2 {
    font-size: 14px;
}
.ledger-list .mail-to {
    color: #808080;
}
.ledger-list .ledger-info .avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
}
.ledger-list .closing-balance {
    color: #808080;
    font-weight: 500;
    margin-right: 20px;
}
.ledger-list table thead th:first-child {
    border-top-left-radius: 8px;
}
.ledger-list table thead th:last-child {
    border-top-right-radius: 8px;
}
.ledger-list table tr td h2 span {
    font-size: 12px;
}
.ledger-list table tr:last-child td {
    background-color: #f7f8f9;
    border-top: 10px solid transparent;
}
.ledger-list table tr:last-child td p {
    color: #333;
    font-weight: 500;
}
.ledger-list table tr:last-child td p + span {
    font-size: 12px;
    color: #808080;
}
.ledger-list .btn-danger {
    background-color: #ed3a3a;
    border-color: #ed3a3a;
}
.ledger-list .btn-danger:hover {
    background-color: #fff;
    color: #ed3a3a;
}
.ledger-list .btn-success {
    background-color: #33b469;
    border-color: #33b469;
}
.ledger-list .btn-success:hover {
    background-color: #fff;
    color: #33b469;
}
.ledger-list .fw-600 {
    font-weight: 600;
}

.customer-address {
    display: none;
}

.view-delivery-challans .file-link .download-link {
    padding: 10px 15px;
}
@media (max-width: 992px) {
    .view-delivery-challans .file-link {
        margin: 20px 0;
    }
}
.view-delivery-challans .invoice-one .invoice-wrapper {
    margin: 0;
    padding: 0;
    box-shadow: none;
}
.view-delivery-challans .invoice-one .inv-content {
    border-radius: 10px;
}
.view-delivery-challans .invoice-one .inv-content .invoice-header {
    border-bottom: none;
}
.view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-left
    h4 {
    font-size: 20px;
    margin-bottom: 0;
}
.view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-left
    h5 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    background: none;
    padding: 0;
    margin: 5px 0;
}
.view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-left
    h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}
.view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-right
    h3 {
    color: #808080;
    font-size: 18px;
    font-weight: 450;
}
.view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-right
    p {
    background: #e1ffed;
    border-radius: 5px;
    padding: 5px 10px;
    display: inline-block;
    font-weight: 600;
}
.view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-right
    p
    span {
    background: none;
    font-weight: 400;
    padding-right: 0;
}
.view-delivery-challans .invoice-one .inv-content .terms-condition span {
    color: #2c3038;
    font-size: 18px;
}
.view-delivery-challans .invoice-one .inv-content .terms-condition ol {
    padding-left: 15px;
    color: #808080;
}
.view-delivery-challans .invoice-one .bill-add {
    color: #808080;
}
.view-delivery-challans .invoice-one .customer-name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
.view-delivery-challans .invoice-one .customer-name h4 {
    font-size: 18px;
    font-weight: 600;
}
.view-delivery-challans .invoice-one .customer-name span {
    font-weight: 400;
    color: #808080;
}
.view-delivery-challans .invoice-one .reference h4 {
    color: #3f4254;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.view-delivery-challans .invoice-one .reference p {
    color: #4f4f4f;
    font-size: 16px;
}
.view-delivery-challans .invoice-one .reference hr {
    margin: 20px 0;
    border-color: #e0e0e0;
}
.view-delivery-challans .invoice-one .invoice-table tr th {
    font-size: 18px;
    font-weight: 450;
    color: #000000;
}
.view-delivery-challans .invoice-one .invoice-table h5 {
    font-size: 24px;
    font-weight: 700;
}
@media (max-width: 992px) {
    .view-delivery-challans .invoice-one .invoice-table h5 {
        font-size: 18px;
    }
}
.view-delivery-challans .invoice-one .authorization h6 {
    font-size: 14px;
    font-weight: 700;
    color: #000000;
}
.view-delivery-challans .powered h6 {
    font-size: 14px;
    font-weight: 700;
    color: #000000;
}

[data-layout-mode="dark"] .invoice-one .inv-content .invoice-header {
    background: none;
}
[data-layout-mode="dark"]
    .view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-left
    h6,
[data-layout-mode="dark"]
    .view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-right
    p,
[data-layout-mode="dark"] .view-delivery-challans .invoice-one .reference h4 {
    color: #fff;
}
[data-layout-mode="dark"]
    .view-delivery-challans
    .invoice-one
    .inv-content
    .invoice-header
    .inv-header-right
    p {
    background: rgba(225, 255, 237, 0.3);
}
[data-layout-mode="dark"] .view-delivery-challans .invoice-one .reference p,
[data-layout-mode="dark"]
    .view-delivery-challans
    .invoice-one
    .authorization
    h6,
[data-layout-mode="dark"]
    .view-delivery-challans
    .invoice-one
    .inv-content
    .terms-condition
    span,
[data-layout-mode="dark"] .view-delivery-challans .powered h6 {
    color: #878a99;
}

.subscription-invoice {
    padding: 35px;
}
.subscription-invoice .invoice-header {
    margin-bottom: 20px;
    padding: 0 0 20px;
}
.subscription-invoice .inv-header-right span {
    display: block;
}
.subscription-invoice .inv-header-left h4 {
    font-size: 20px;
    font-weight: 600;
}
.subscription-invoice .invoice-num-date ul {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.subscription-invoice .invoice-num-date ul li {
    color: #95979b;
    margin-right: 20px;
}
.subscription-invoice .invoice-num-date ul li:last-child {
    margin-right: 0;
}
.subscription-invoice .invoice-num-date ul li span {
    color: #2c3038 !important;
    font-weight: 500;
    display: inline-block;
    margin-left: 15px;
}
.subscription-invoice .sub-invoive-detail {
    margin-bottom: 24px;
}
.subscription-invoice .sub-invoive-detail h5 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 700;
}
.subscription-invoice .sub-invoive-detail p {
    color: #484848;
    font-weight: 500;
}
.subscription-invoice .sub-invoive-detail ul li {
    color: #484848;
    font-weight: 500;
}
.subscription-invoice .sub-invoive-detail.detail-right {
    text-align: right;
}
@media (max-width: 575.98px) {
    .subscription-invoice .sub-invoive-detail.detail-right {
        text-align: left;
    }
}
.subscription-invoice td h4 {
    font-weight: 600;
}
.subscription-invoice .bank-details {
    margin: 15px 0;
    padding: 0 0 15px;
}
.subscription-invoice .bank-details .payment-info {
    margin-bottom: 15px;
}
.subscription-invoice .bank-details .payment-info h5 {
    margin-bottom: 15px;
}
.subscription-invoice .bank-details .company-sign {
    margin-bottom: 15px;
}
.subscription-invoice .terms-condition span {
    color: #484848;
    font-weight: 600;
}
.subscription-invoice .terms-condition ol {
    padding-left: 15px;
    margin-top: 5px;
}
.subscription-invoice .terms-condition ol li {
    font-weight: 600;
}

.subscription-invoice-foot.file-link .print-link {
    background: #eaddff;
    color: #7539ff;
    border-color: #eaddff;
    transition: 0.5s all;
}
.subscription-invoice-foot.file-link .print-link:hover {
    background: #7539ff;
    color: #fff;
    transition: 0.5s all;
}
.subscription-invoice-foot.file-link .download-link {
    background: #7539ff;
    color: #fff;
    transition: 0.5s all;
}
.subscription-invoice-foot.file-link .download-link:hover {
    background-color: #fff;
    border: 1px solid #7539ff;
    color: #7539ff;
    transition: 0.5s all;
}

.super-admin-dashboard .dash-user-card {
    background: #7539ff;
    padding: 24px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin-bottom: 24px;
}
.super-admin-dashboard .dash-user-card h4 {
    color: #fff;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    margin-bottom: 10px;
}
.super-admin-dashboard .dash-user-card h4 i {
    margin-right: 5px;
}
.super-admin-dashboard .dash-user-card p {
    color: #fff;
}
.super-admin-dashboard .dash-user-card .dash-btns {
    margin-top: 75px;
}
@media (max-width: 575.98px) {
    .super-admin-dashboard .dash-user-card .dash-btns {
        margin-top: 45px;
    }
}
.super-admin-dashboard .dash-user-card .dash-btns a {
    padding: 8px 15px;
    color: #fff;
}
@media (max-width: 575.98px) {
    .super-admin-dashboard .dash-user-card .dash-btns a {
        padding: 8px 6px;
    }
}
.super-admin-dashboard .dash-user-card .dash-btns a.view-company-btn {
    background: #2da7ff;
    border: 1px solid #2da7ff;
    box-shadow: inset 0 0 0 0 #fff;
}
.super-admin-dashboard .dash-user-card .dash-btns a.view-company-btn:hover {
    color: #2da7ff;
    border: 1px solid #2da7ff;
    background: #fff;
}
.super-admin-dashboard .dash-user-card .dash-btns a.view-package-btn {
    color: #2da7ff;
    border: 1px solid #2da7ff;
    background: #fff;
    margin-left: 5px;
}
.super-admin-dashboard .dash-user-card .dash-btns a.view-package-btn:hover {
    background: #2da7ff;
    border: 1px solid #2da7ff;
    color: #fff;
}
.super-admin-dashboard .dash-img {
    position: absolute;
    right: 10px;
    bottom: 0;
    line-height: 0;
    z-index: -1;
}

.company-detail-card {
    padding: 24px;
    background: #ffefdd;
    border-radius: 10px;
    margin-bottom: 24px;
}
@media (max-width: 1599.98px) {
    .company-detail-card {
        padding: 24px 17px;
    }
}
.company-detail-card h6 {
    font-size: 14px;
    color: #1d1d1d;
    font-weight: 600;
    margin-bottom: 5px;
}
@media (max-width: 1599.98px) {
    .company-detail-card h6 {
        font-size: 13px;
    }
}
.company-detail-card .company-icon {
    width: 68px;
    height: 68px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}
.company-detail-card h5 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #ec8000;
}
.company-detail-card p {
    color: #484848;
}
.company-detail-card p span {
    color: #1d8147;
}
.company-detail-card.bg-info-light {
    background: #ddf3ff;
}
.company-detail-card.bg-info-light h5 {
    color: #1e8edf;
}
.company-detail-card.bg-pink-light {
    background: #ffecec;
}
.company-detail-card.bg-pink-light h5 {
    color: #ed3a3a;
}
.company-detail-card.bg-success-light h5 {
    color: #1d8147;
}

.super-admin-dash-card {
    border: 1px solid #e0e0e0;
    padding: 24px;
    width: 100%;
}
.super-admin-dash-card .card-header {
    padding: 0 0 15px;
    margin-bottom: 5px;
}
.super-admin-dash-card .card-header .card-title {
    font-size: 18px;
    font-weight: 600;
}
.super-admin-dash-card table td {
    vertical-align: middle;
}
.super-admin-dash-card table td .view-companies {
    border: 1px solid #e0e0e0;
    color: #484848;
    border-radius: 5px;
}
.super-admin-dash-card table td .view-companies:hover {
    background: #7539ff;
    border-color: #7539ff;
    color: #fff;
}
.super-admin-dash-card table td.expired-date {
    color: #484848;
}
.super-admin-dash-card table td .active-inactive-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.super-admin-dash-card table td .active-inactive-btn a {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
}
.super-admin-dash-card table td .active-inactive-btn a.active-domain {
    background: #33b469;
    margin-right: 5px;
}
.super-admin-dash-card table td .active-inactive-btn a.inactive-domain {
    background: #ed3a3a;
}
.super-admin-dash-card .dropdown.main .btn-white {
    color: #484848;
}
.super-admin-dash-card .dropdown.main .btn-white:hover {
    color: #fff;
}
.super-admin-dash-card .dash-plane-list {
    background: #f7f8f9;
    padding: 24px;
    border-radius: 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 1399.98px) {
    .super-admin-dash-card .dash-plane-list {
        padding: 15px;
    }
}
.super-admin-dash-card .dash-plane-list .plane-info {
    display: flex;
    align-items: center;
}
.super-admin-dash-card .dash-plane-list .plane-info .icon-plane {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #ecebff;
    margin-right: 8px;
    flex-shrink: 0;
}
@media (max-width: 1399.98px) {
    .super-admin-dash-card .dash-plane-list .plane-info .icon-plane {
        width: 45px;
        height: 45px;
    }
}
.super-admin-dash-card .dash-plane-list .plane-info .icon-company {
    width: 40px;
    height: 40px;
    border: 1px solid #e0e0e0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 8px;
    border-radius: 40px;
}
.super-admin-dash-card .dash-plane-list .plane-info .name-company {
    color: #1d1d1d;
    font-size: 16px;
}
.super-admin-dash-card .dash-plane-list .plane-info .plane-name {
    color: #1d1d1d;
    font-weight: 500;
}
.super-admin-dash-card .dash-plane-list .plane-info .plane-name span {
    color: #484848;
    font-weight: normal;
}
.super-admin-dash-card .dash-plane-list .plane-info .plane-name h6 {
    font-size: 14px;
    color: #484848;
    font-weight: normal;
    margin-top: 3px;
}
.super-admin-dash-card .dash-plane-list .plane-rate {
    color: #1d1d1d;
    font-size: 14px;
}
.super-admin-dash-card .dash-incoice-table {
    color: #1d1d1d;
    font-weight: 500;
}
.super-admin-dash-card .dash-incoice-date {
    color: #484848;
    font-size: 14px;
    display: block;
    margin-top: 3px;
}

#plane-chart .apexcharts-legend {
    display: none;
}

.earning-income-text {
    color: #7539ff;
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
}
.earning-income-text i {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: #7539ff;
    margin-right: 5px;
}

.input-block-email {
    padding: 20px 22px 10px;
}

.input-block-email .form-control {
    font-size: 15px;
    padding: 14px 16px;
}

.email-btn {
    padding: 20px 20px;
}

.addnew-modal .input-block.mb-3 {
    margin-bottom: 24px !important;
}

.addnew-modal .select2-selection__rendered {
    padding-left: 12px;
}

.addnew-checkbox .status-toggle .checktoggle {
    transform: none;
}

.modal-footer.add {
    padding: 24px 0 0;
}

.custom-modal .modal-body.add {
    padding: 20px;
}

.input-block.add label {
    display: flex;
    gap: 5px;
}

.input-block.add .checkbox {
    display: flex;
}
.input-block.add .checkbox input {
    min-width: 15px;
    height: 15px;
}
.input-block.add .checkbox input:checked {
    accent-color: #7539ff;
    border-color: #592ac6;
}

.companies-table.filed .dataTables_length {
    display: none;
}

.companies-table.filed div.dataTables_wrapper div.dataTables_paginate {
    display: none;
}

.card-table .card-body .companies-table .table td {
    height: 40px;
}

.avatar-img.sales-rep {
    border-radius: 0 !important;
}

.btn-center {
    float: right;
}

/* .input-block .form-control.tax{
    direction: rtl;
} */
.input-block.tax {
    padding: 0 5px 0 0;
}

/* .cal-icon-info.tax:after{
    left: 12px;
}
.cal-icon-info.taxes:after{
    left: 12px;
} */
/* .input-block.tax{
    max-width: 181px;
} */
.btn-primary.tax {
    margin-right: 10px;
    width: 181px;
}
@media (max-width: 575.98px) {
    .btn-primary.tax.tax {
        margin-bottom: 10px;
    }
}

.btn-primary.taxs {
    min-width: 154px;
}

.filter-btn.tax {
    padding: 0 0 30px;
}
@media (max-width: 575.98px) {
    .filter-btn.tax.tax {
        display: block;
    }
}

.from-to-date .form-control.tax {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    direction: rtl;
    width: 130px;
    padding: 9px 8px 9px 12px;
}
.from-to-date .form-control.taxes {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    width: 98px;
    padding: 9px 12px 9px 0;
}

.cal-icon-info.profitloss:after {
    left: 10px;
}

.table-profit-loss thead {
    background-color: #f7f8f9;
}
.table-profit-loss tr td h6 {
    font-size: 14px;
}

.card-table .card-body .table .profit.space {
    padding: 10px 10px 20px 10px !important;
    margin-bottom: 10px;
}

.card-table .card-body .table .loss-space {
    padding: 10px !important;
}

.card-table .card-body .table .table-profit-head {
    padding-left: 15px !important;
}

.profitloss-bg {
    background-color: #f7f8f9;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.proft-head {
    padding-left: 25px;
}

.card-table .table.profit {
    margin-bottom: 0 !important;
    padding: 15px 20px;
}

.card-table .card-body .table-profit-loss tr td {
    border: none;
}

.profit thead.profitloss-heading tr th {
    background-color: #fff !important;
}

.profit-menu {
    padding: 0 0 30px;
}
.profit-menu .input-block input.form-control {
    min-height: 43px;
}

.card-table .card-body .table .thead-light.loss th {
    padding: 21px 25px 18px;
}

.card-table .card-body .companies-table.loss .table td {
    border: none;
}

.btn-primary.loss {
    display: block;
    margin-top: 30px;
    height: 41px;
}

.daterangepicker-wrap {
    padding-left: 23px;
}
.daterangepicker-wrap.cal-icon:after {
    left: 10px;
    right: auto;
    top: 6px;
}
.daterangepicker-wrap input {
    width: 100%;
    padding-left: 35px;
    padding-right: 10px;
}
.daterangepicker-wrap input.btn-filters {
    font-weight: normal;
}

.login-right-wrap.admin h1 {
    margin: 0;
    padding-bottom: 20px;
}

.register-btn {
    margin-top: 45px;
}

.form-control.employees {
    height: 43px;
}

.freetrail .input-block {
    margin-bottom: 20px;
}

.saas-login-wrapper {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #fff;
}
.saas-login-wrapper .login-content {
    width: 100%;
    height: 100vh;
    padding: 60px 0;
    overflow-y: auto;
    overflow-x: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    flex-wrap: wrap;
}
.saas-login-wrapper .login-content .login-logo {
    display: block;
    text-align: center;
}
@media (max-width: 575.98px) {
    .saas-login-wrapper .login-content {
        padding: 40px 15px;
    }
}
@media (max-width: 575.98px) {
    .saas-login-wrapper form {
        width: 100%;
    }
}
.saas-login-wrapper .login-userset {
    width: 496px;
}
@media (max-width: 1199.98px) {
    .saas-login-wrapper .login-userset {
        width: 400px;
    }
}
@media (max-width: 991.98px) {
    .saas-login-wrapper .login-userset {
        width: 496px;
    }
}
@media (max-width: 575.98px) {
    .saas-login-wrapper .login-userset {
        max-width: 100%;
        width: 100%;
    }
}

.login-heading {
    margin-bottom: 20px;
}
.login-heading h3 {
    font-weight: 600;
    margin-bottom: 5px;
}
.login-heading p {
    margin-bottom: 0;
}

.form-wrap {
    position: relative;
    margin-bottom: 24px;
}

.login-card {
    padding: 24px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 16px 32px 0px rgba(190, 190, 190, 0.2);
    margin: 40px 0;
}
.login-card .toggle-password {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    color: #757575;
    cursor: pointer;
}
.login-card .toggle-password.fa-eye {
    margin-right: 1px;
}
.login-card .pass-group {
    position: relative;
}

.login-content .btn {
    width: 100%;
    height: auto;
    padding: 8px 20px;
}

.acc-in {
    text-align: center;
}
.acc-in p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
}
.acc-in p a {
    color: #1d1d1d;
}
.acc-in p a:hover {
    color: #7539ff;
}

.authentication-wrapper {
    background: #7539ff;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    padding: 24px 0 0 24px;
}
.authentication-wrapper .authen-img {
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 0;
    max-width: 600px;
}
@media (max-width: 1399.98px) {
    .authentication-wrapper .authen-img {
        max-width: 520px;
    }
}
@media (max-width: 1199.98px) {
    .authentication-wrapper .authen-img {
        max-width: 380px;
    }
}
@media (max-width: 991.98px) {
    .authentication-wrapper {
        display: none;
    }
}

.authentication-content {
    height: 100%;
    -webkit-box-align: end;
    -ms-flex-align: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
}
.authentication-content h1 {
    font-size: 36px;
    color: #fff;
    max-width: 490px;
    margin-bottom: 22px;
    font-weight: 700;
}
.authentication-content p {
    color: #fff;
    font-size: 18px;
}

.forgot-link {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1d;
    text-decoration: underline;
}

.pass-group input[type="password"] {
    padding-right: 40px;
}

.login-bg-img .vector-bg-one {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
.login-bg-img .vector-bg-two {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.login-bg-img .vector-bg-three {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.login-bg-img .vector-bg-four {
    position: absolute;
    left: 15%;
    top: 35%;
    z-index: -1;
}

.saas-pass-box .input-block:first-child {
    margin-right: 5px;
}
@media (max-width: 575.98px) {
    .saas-pass-box .input-block:first-child {
        margin-right: 0;
    }
}
@media (max-width: 575.98px) {
    .saas-pass-box {
        flex-direction: column;
    }
}

.visible-btn {
    border-radius: 0px 6px 7px 0px;
}

.aia-model-xl {
    width: 400px;
    transition-duration: 0.3s;
}
.aia-modal-overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(1px);
}
.aia-modal-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 400px;
}

.custom-close-btn {
    border-radius: 5px;
    border: 1px solid #9f9f9f;
    padding: 3px 8px;
    color: #7539ff;
    background-color: #eaddff;
    border: 1px solid #7539ff;
    font-weight: 900;
    transition-duration: 0.3s;
    cursor: pointer;
}

.custom-close-btn:hover {
    background-color: #7539ff;
    color: white;
}

.selected-files {
    display: flex;
}
.selected-files p {
    padding-right: 5px;
}
.custom-focus:focus {
    border-color: #86b7fe;
}

.custom-swal-popup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-swal-icon {
    margin: 10px auto;
    position: relative;
    top: 5px;
}
.text-primary-blue {
    color: #0d6efd;
}
.cursor-sign {
    cursor: default;
}
tr th.ant-table-cell:last-child {
    text-align: center;
}
.ant-select-selector {
    border-radius: 5px !important;
}
.import-data-center {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    color: #333;
}
.image-data-center {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: nowrap;
}
.logo-container {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
}
.sticky-footer {
    position: sticky;
    bottom: 0;
    padding: 10px;
    z-index: 1;
    text-align: end;
}
